const env = {
  production: {
    API_URL: "https://api-gateway.ilife.tech",
    API_URL_AGENT:
      "https://api-gateway.ilife.tech",
    CLIENT_URL:
      "https://client.ilife.tech",
    CLIENT_DOMAIN_NAME: ".ilife.tech",
    AGENT_PORTAL_URL: "https://agent.ilife.tech",
    LANDING_PAGE_URL: 'https://ilife.tech/',
    PORT: 3015,
    API_POINT: "/ilifeService",
    API_POINT_UNDERWRITE: "/underwritingService",
    API_POINT_PAYMENT: "/paymentService",
    API_POINT_MUNICH: "/munichreService",
    API_URL_CHATSERVICE: "https://chat-service.ilife.tech",
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
    GTM_APP_ID: process.env.REACT_APP_GTM_ID,
    HASH_KEY:process.env.REACT_APP_HASH_KEY
  },
  staging: {
    API_URL: "https://beta-api-gateway.ilife.tech",
    API_URL_AGENT:
      "https://beta-api-gateway.ilife.tech",
    CLIENT_URL:
      "https://client.ilife.tech",
    CLIENT_DOMAIN_NAME: ".ilife.tech",
    AGENT_PORTAL_URL: "https://agent.ilife.tech",
    LANDING_PAGE_URL: 'https://ilifestaging.tech/',
    PORT: 3015,
    API_POINT: "/ilifeService",
    API_POINT_UNDERWRITE: "/underwritingService",
    API_POINT_PAYMENT: "/paymentService",
    API_POINT_MUNICH: "/munichreService",
    API_URL_CHATSERVICE: "https://beta-chat-service.ilife.tech",
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
    GTM_APP_ID: process.env.REACT_APP_GTM_ID,
    HASH_KEY:process.env.REACT_APP_HASH_KEY
  },
  development: {
    API_URL: "http://10.2.1.202:9000",
    API_URL_AGENT: "http://10.2.1.202:9000",
    CLIENT_URL: "http://10.2.1.202:3005",
    CLIENT_DOMAIN_NAME: ".devpress.net",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    LANDING_PAGE_URL: 'http://ilife-tech.devpress.net',
    PORT: 3015,
    API_POINT: "/ilifeService",
    API_POINT_UNDERWRITE: "/underwritingService",
    API_POINT_PAYMENT: "/paymentService",
    API_POINT_MUNICH: "/munichreService",
    API_URL_CHATSERVICE: "http://10.2.1.202:9007",
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
    GTM_APP_ID: process.env.REACT_APP_GTM_ID,
    HASH_KEY:process.env.REACT_APP_HASH_KEY
  },
  local: {
    API_URL: "http://10.2.1.202:9000",
    API_URL_AGENT: "http://10.2.1.202:9000",
    CLIENT_URL: "http://10.2.1.202:3005",
    CLIENT_DOMAIN_NAME: ".devpress.net",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    LANDING_PAGE_URL: 'http://ilife-tech.devpress.net',
    API_POINT: "/ilifeService",
    API_POINT_UNDERWRITE: "/underwritingService",
    API_POINT_PAYMENT: "/paymentService",
    API_POINT_MUNICH: "/munichreService",
    API_URL_CHATSERVICE: "http://10.2.1.202:9007",
    PORT: 3015,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
    STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
    GTM_APP_ID: process.env.REACT_APP_GTM_ID,
    HASH_KEY:process.env.REACT_APP_HASH_KEY
  }
};

module.exports = env[process.env.REACT_APP_NODE_ENV];
