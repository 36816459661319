/* eslint-disable no-useless-escape */
import React from "react";
import swal from "sweetalert";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  ALGORITHM,
  ALGORITHM_KEY,
  ALGORITHM_IV,
  HASH_KEY,
} from "../config/config";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import crypto from "crypto";
import errorMessages from "../redux/actions/errorMessages";
import { segmentIdentify, formatDate } from "./commonFunction";
import { DashboardPagesRoutes, timeZoneOffsetsInMinutes } from "./constant";

toast.configure();

// /**********************************************************************
//  @PURPOSE : We can use following function to store data in localstorage
// //Validations and
// //Messages

const returnPopOver = (id, content) => {
  return (
    <Popover id={id}>
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );
};

const getDocContent = (fileName) => (
  <div className="document-name">
    <span className="list-label" style={{ cursor: "pointer" }}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        overlay={returnPopOver("popover-basic2", fileName)}
      >
        <span>{truncate(fileName, 20)}</span>
      </OverlayTrigger>
    </span>
  </div>
);

// //*********************************************************************/
// export default {
/*************************************************************************************
                              Data to encrypt
    **************************************************************************************/
export const encryptData = (data) => {
  if (!data) return "";
  const cipher = crypto.createCipheriv(ALGORITHM, ALGORITHM_KEY, ALGORITHM_IV);
  let encrypted = cipher.update(data, "utf8", "Base64");
  encrypted += cipher.final("Base64");
  return encrypted;
};

export const hashData = (data) => {
  const hash = crypto.createHmac("sha256", HASH_KEY).update(data).digest("hex");
  return hash;
};
//   /*************************************************************************************/
//   /*************************************************************************************
//                               Data to Decrypt
//     **************************************************************************************/
export const decryptData = (resp) => {
  try {
    if (resp) {
      const decipher = crypto.createDecipheriv(
        ALGORITHM,
        ALGORITHM_KEY,
        ALGORITHM_IV
      );
      let decrypted = decipher.update(resp, "Base64", "utf8");
      decrypted += decipher.final("utf8");
      return decrypted;
    } else {
      return false;
    }
  } catch (err) {
    // toast.error("Internal error occured!");
  }

  // const algorithm = ALGORITHM;
  // const key = ALGORITHM_KEY;
  // const iv = ALGORITHM_IV;
  // // const cipher = crypto.createDecipheriv(algorithm, key, iv);
  // const cipher = crypto.createCipheriv(
  //   "aes-256-cbc",
  //   "(G+KbPeSgVkYp3s6v9y$B&E)H@McQfTj",
  //   "-JaNdRgUkXp2s5v8"
  // );
  // let decrypted = cipher.update(data, "Base64", "utf8");
  // decrypted += cipher.final("utf8");
  // return decrypted;
};
//   /*************************************************************************************/
//   /*************************************************************************************
//                               Get Baseplan
//     **************************************************************************************/
export const getBasePlan = (plan) => ({
  isAmbassador: plan === "AMBASSADOR",
  isProfessional: plan === "PROFESSIONAL",
  isProfessionalPlus: plan === "PROFESSIONAL-PLUS",
  isProPlus: plan == "PRO+",
  isEnterprise: plan === "ENTERPRISE",
  isExpert: plan === "EXPERT",
  isIndividual: plan === "INDIVIDUAL",
});
//   /*************************************************************************************/
//   /*************************************************************************************
//                              Number Format
//    **************************************************************************************/
export const NumberFormatter = (
  amount,
  preFix = "",
  sufFix = "",
  fixedDecimalScale = true
) => {
  return (
    <NumberFormat
      prefix={preFix}
      suffix={sufFix}
      decimalScale={2}
      value={amount}
      displayType={"text"}
      thousandSeparator={true}
      fixedDecimalScale={fixedDecimalScale}
      renderText={(value) => <span>{value}</span>}
    />
  );
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                           Date format from unix
//    **************************************************************************************/

export const formatDateInTimezone = (inputDate = new Date(), timezone) => {
  if (timezone && timezone !== "null") {
    return moment.tz(inputDate, timezone);
  } else {
    return moment.tz(
      inputDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                                  Name Validation
//  **************************************************************************************/
export const validateName = (name) => {
  var pattern = new RegExp(/^[ A-Za-z0-9_@./'#&+-+-\w\s]{1,50}$/);
  return pattern.test(name);
};

export const emptyName = /^[^\s].*(\s[^\s].*)*$/;

export const validateWebsite = (name) => {
  //  const urlRegex =
  //    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9\-]+[a-zA-Z0-9]\.[^\s{}%^#&+?^{}|~]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9\-]+[a-zA-Z0-9]\.[^\s{}%^#&+?^{}|~]{2,}|[a-zA-Z0-9]+\.[^\s{}%^#&+?^{}|~]{2,}|www\.[a-zA-Z0-9]+\.[^\s{}%^#&+?^{}|~]{2,})$/
  const urlRegex =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9\-]+[a-zA-Z0-9]\.[^\s{}%^#+^{}|$~()`!*]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9\-]+[a-zA-Z0-9]\.[^\s{}%^#+^{}|!`$~()*]{2,}|[a-zA-Z0-9]+\.[^\s{}%^#+^{}|!`$~()*]{2,}|www\.[a-zA-Z0-9]+\.[^\s{}%^#+^{}|`!$~()*]{2,})$/;
  return urlRegex.test(name);
};

//allow only character and space
export const validateCardHolder = (name) => {
  let pattern = name.match(/^[a-zA-Z\s]*$/);
  return pattern !== null;
};

//   /*************************************************************************************
//                                  Name Validation
//  **************************************************************************************/
export const validateDomainName = (name) => {
  var pattern = new RegExp(/^[a-z0-9"-]+$/);
  return pattern.test(name);
};

export const validateLocation = (name) => {
  var pattern = new RegExp(/^[a-zA-Z0-9 ]*$/);
  return pattern.test(name);
};
//   /*************************************************************************************
//                                   Store Data To local Storage
//   **************************************************************************************/
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                              Get Data From local Storage
//  **************************************************************************************/
export const getItem = (key) => {
  return localStorage.getItem(key);
};
//   /*************************************************************************************
//                                Remove Data in local Storage
//  **************************************************************************************/
export const removeItem = (key) => {
  localStorage.removeItem(key);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                              Email Validation
//   **************************************************************************************/
export const validateEmail = (email,isArray=false) => {
  var pattern = new RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if(isArray){
    return email.split(",").every(e => pattern.test(e.trim()));
  }
  return pattern.test(email);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                              Otp Validation
//   **************************************************************************************/
export const validateOtp = (otp) => {
  var pattern = new RegExp(/^[0-9]{1,6}$/);
  return pattern.test(otp);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                              Password Validation
//   **************************************************************************************/
export const validatePassword = (pass) => {
  // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{4,}$/);
  // return pattern.test(pass);
  var pattern = new RegExp(/^.{6,}$/);
  return pattern.test(pass);
};

export const validateMobileNumber = (mobileNo) => {
  // var pattern = new RegExp(
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  // );
  var pattern = new RegExp(/^[0-9]\d{9}$|^[0-9]\d{9}$/);
  return pattern.test(mobileNo);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                               Character Validation
//   **************************************************************************************/
export const allowChar = (e) => {
  const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
  return pattern.test(e);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                               Number Validation
//   **************************************************************************************/
export const allowNumbers = (e) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(e);
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                               Set Success Message
//   **************************************************************************************/
export const showSuccessMessage = (msg) => {
  swal({
    position: "center",
    icon: "success",
    text: msg,
    buttons: false,
    timer: 2500,
    className: "custom-toaster",
  });
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                               Set Error Message
//   **************************************************************************************/
export const showErrorMessage = (msg) => {
  swal({
    position: "center",
    icon: "error",
    text: msg,
    buttons: false,
    timer: 3000,
    className: "custom-toaster",
  });
};
//   /*************************************************************************************/

//   /*************************************************************************************
//                               Set Warning Message
//   **************************************************************************************/
export const showWarningMessage = (msg) => {
  swal({
    position: "center",
    icon: "warning",
    text: msg,
    buttons: false,
    timer: 3000,
    className: "custom-toaster",
  });
};
//   /*******************************************************************************************/

//   // /*************************************************************************************
//   //                            PinCode Validate
//   // **************************************************************************************/
//   // // for pincode number validate
export const validatePincodeNumber = (mobileNo) => {
  var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
  return pattern.test(mobileNo);
};

/*************************************************************************************
                            Set success Toast Message
  **************************************************************************************/
export const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const showAdviceToast = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
/*************************************************************************************/

/*************************************************************************************
                            Set Error Toast Message
  **************************************************************************************/
export const showErrorToast = (msg) => {
  if (!toast.isActive(msg)) {
    toast.error(msg, {
      toastId: msg,
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};
/*************************************************************************************/

/*************************************************************************************
                            Set WARNING Toast Message
  **************************************************************************************/
export const showWarnToast = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

/*************************************************************************************/
/*************************************************************************************
                            Get Cookie value
  **************************************************************************************/

export const getCookieValue = (cookie = null) => {
  if (cookie) {
    const _cookie = document.cookie.split(";");
    const value = _cookie.filter((x) => x.includes(cookie));
    const trimValue = value.length > 0 ? value[0].trim().split("=")[1] : "";
    return trimValue;
  } else {
    return "";
  }
};

export const setCookies = (cname, cvalue) => {
  const d = new Date();
  d.setMonth(d.getMonth() + 14);
  let expires = "expires=" + d.toUTCString();

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
};
export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
};
/*************************************************************************************/
/*************************************************************************************
                            Dropdown for datatable select
  **************************************************************************************/
export const paginationDropdown = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

export const purchasePaginationDropdown = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
];

/*************************************************************************************/
const openDocuments = (url) => {
  try {
    window.open(url, "_blank");
  } catch (err) {
    showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
  }
};

export const addDefaultSrcForChat = (ev) => {
  //ev.target.src = '/images/no-image-user.png';
  ev.target.src = "/assets/images/noimage-1.jpg";
};

/*************************************************************************************
                            Custom Filter for Selectbox
  **************************************************************************************/
export const customFilter = (option, searchText) => {
  if (option.data.label.toLowerCase().startsWith(searchText.toLowerCase())) {
    return true;
  } else {
    return false;
  }
};
/*************************************************************************************/

/*************************************************************************************
                            Truncate Name
  **************************************************************************************/
export const truncate = (textstring, n) => {
  if (textstring.length <= n) {
    return textstring;
  }
  let subString = textstring.substr(0, n - 1);
  return subString + "...";
};

export const passUserSegmentIdentify = (
  email,
  name,
  agent_id,
  subscription,
  subscription_active = "",
  phone
) => {
  const identifyObject = {
    email: encryptData(email),
    name: encryptData(name),
    agent_id: encryptData(agent_id.toString()),
    subscription: encryptData(subscription),
    subscription_active: encryptData(subscription_active?.toString()),
    phone: encryptData(phone),
  };
  segmentIdentify(encryptData(agent_id.toString()), identifyObject);
};

export const passUserIdentifyInHtml = (
  email,
  name,
  agent_id,
  subscription,
  subscription_active,
  phone,
  free_trial,
  user_hash
) => {
  let intercom = document.createElement("script");
  intercom.type = "text/javascript";
  intercom.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_ID}`;
  intercom.async = true;
  intercom.onload = (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      w.intercomSettings = {
        email: email,
        name: name,
        agent_id: agent_id,
        user_id: agent_id,
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_ID,
        subscription: subscription,
        subscription_active: subscription_active,
        phone: phone,
        free_trial: free_trial,
        user_hash: user_hash,
      };
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(intercom, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  document.head.appendChild(intercom);
};
/*************************************************************************************/

export const intercomAlvis = () => {
  let intercomAlvis = document.createElement("script");
  intercomAlvis.type = "text/javascript";
  intercomAlvis.async = true;
  intercomAlvis.onload = !(function (s, i, e, a, l) {
    s._Alviss = [];
    s.Alvis = function () {
      s._Alviss.push(Array.prototype.slice.call(arguments));
    };
    s.alvis_config = {
      alvis_id: process.env.REACT_APP_INTERCOM_ID,
    };
    a = i.getElementsByTagName("head")[0];
    (l = i.createElement("script")).async = 1;
    l.src = "//assets.getalvis.com/alvis-onboard-1.0.10.js";
    a.appendChild(l);
  })(window, document);
};

export const hotjar = () => {
  let hotjar = document.createElement("script");
  hotjar.type = "text/javascript";
  hotjar.async = true;
  hotjar.onload = (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  document.head.appendChild(hotjar);
};

export const googleTagManager = (gtmId) => {
  let intercom = document.createElement("script");
  intercom.type = "text/javascript";
  intercom.async = true;
  intercom.onload = (function () {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtmId);
  })();
  document.head.appendChild(intercom);
};

export const noScriptTagGoogleTagManager = (gtmId) => {
  let iframeTag = document.createElement("noscript");
  iframeTag.onload = (
    <iframe
      title="google tag manager"
      src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    ></iframe>
  );
  document.body.appendChild(iframeTag);
};

export const segmentTrackingMethod = () => {
  let segment = document.createElement("script");
  segment.type = "text/javascript";
  segment.async = true;
  let segmentAPIKey = process.env.REACT_APP_SEGMENT_API_KEY;
  // const domainNamePath = window.location.hostname;
  // const domainName = domainNamePath.split(".");
  segment.onload = (function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src =
            "https://cdn.segment.com/analytics.js/v1/" +
            key +
            "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = segmentAPIKey;
        analytics.SNIPPET_VERSION = "4.13.2";
        analytics.load(segmentAPIKey, { obfuscate: true });
        analytics.page();
      }
  })();
  document.head.appendChild(segment);
};

export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let browser;

  if (userAgent.indexOf("Edge") > -1) {
    browser = "Microsoft Edge";
  } else if (userAgent.indexOf("Edg") > -1) {
    browser = "Microsoft Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browser = "Google Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browser = "Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    browser = "Safari";
  } else {
    browser = "Other";
  }
  return browser;
};
/*************************************************************************************/
export const SunEditorToolBarOption = [
  "removeFormat",
  "formatBlock",
  "font",
  "fontColor",
  "fontSize",
  "bold",
  "blockquote",
  "underline",
  "italic",
  "list",
  "align",
  "hiliteColor",
  "horizontalRule",
  "paragraphStyle",
  "lineHeight",
  "textStyle",
  "subscript",
  "superscript",
  "outdent",
  "indent",
  "link",
  "table",
  // "template",
  // "image",
  // "video",
  // "audio",
  // "fullScreen",
  "showBlocks",
  "codeView",
  // "preview",
  // "print",
  "save",
  "undo",
  "redo",
];

/*************************************************************************************
                            Remove HTML from string
  **************************************************************************************/
export const removeHTML = (str) => {
  let tmpEle = document.createElement("DIV");
  tmpEle.innerHTML = str;
  return tmpEle.textContent || tmpEle.innerText || "";
};

/**************************************************************************************/

/*************************************************************************************
                            PDF common HTMl
  **************************************************************************************/
export const getFileType = (fileType, fileName, url, authName = "") => {
  let fileExe = fileType.slice(((fileType.lastIndexOf(".") - 1) >>> 0) + 2);
  switch (fileExe) {
    case "pdf":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon material-icons pdf-icon">
              picture_as_pdf
            </div>
            <div className="document-content">
              {typeof fileName === "number" ? (
                <div className="document-name">
                  {fileType}
                  <span className="d-block">{formatDate(fileName)}</span>
                </div>
              ) : (
                getDocContent(fileName)
              )}
              {/* <div className="document-name"></div> */}
            </div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "doc":
    case "docx":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );

    case "xlsx":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#4CAF50"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                />
                <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                />
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "zip":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg height="40px" width="40px" version="1.1" viewBox="0 0 40 40">
                <title>ZIP</title>
                <desc>Created with Sketch.</desc>
                <defs>
                  <path
                    id="path-1"
                    d="m0 39.814v-39.814l13.567 0.42274v1.6666h1.854v1.6665h-1.854v1.6666h1.854v1.6666h-1.854v1.6665h1.854v1.6665h-1.854v1.667h1.854v1.666h-1.854v1.667h1.854v1.666h-1.854v1.667h1.854v1.666h-1.854v1.667h0.927c-0.512 0-0.927 0.373-0.927 0.833v8.333c0 0.461 0.415 0.833 0.927 0.833h3.707c0.512 0 0.926-0.372 0.926-0.833v-8.333c0-0.46-0.414-0.833-0.926-0.833h-0.927v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.666h-1.853v-1.667h1.853v-1.6665h-1.853v-1.6665h1.853v-1.6666h-1.853v-1.6666h1.853v-1.6665h-1.853v-1.6666l1.853 0.00004h5.561 8.267c0 0.92036-1.024 1.6666 0 1.6666v8.3327 29.392h-32.955z"
                  />
                </defs>
                <g fillRule="evenodd" fill="none">
                  <g transform="translate(4)">
                    <mask id="mask-2" fill="white">
                      <use href="#path-1" />
                    </mask>
                    <path
                      fill="#F4AD49"
                      mask="url(#mask-2)"
                      d="m0 4.9161v31.329c0 2.154 1.7723 3.916 3.9384 3.916h23.631c2.166 0 3.939-1.762 3.939-3.916v-23.497l-11.816-11.748h-15.754c-2.1657 0-3.938 1.7622-3.938 3.9161z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "png":
    case "gif":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 1024 1024">
                <g>
                  <g>
                    <path
                      d="M146.901,249.004V964c0,33,27,60,60,60h613.857c33,0,60-27,60-60V60c0-33-27-60-60-60H395.906"
                      fill="#F1C40F"
                    />
                    <g>
                      <path
                        d="M146.901,249.004h189.005c33,0,60-27,60-60V0"
                        fill="#F39C12"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="M81.83,670.833h65.071V585l-70.946,88.375h0.012C77.167,671.805,79.198,670.833,81.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M945.83,670.833h-65.071V585l70.946,88.375h-0.012C950.493,671.805,948.462,670.833,945.83,670.833z"
                    fill="#262626"
                  />
                  <path
                    d="M884.273,861.471c-1.934,5.149-8.015,9.362-13.515,9.362H156.901c-5.5,0-11.582-4.213-13.514-9.362   L75.344,680.195c-1.933-5.149,0.986-9.362,6.486-9.362h864c5.5,0,8.419,4.213,6.486,9.362L884.273,861.471z"
                    fill="#F39C12"
                  />
                </g>
                <g>
                  <path
                    d="M312.407,818.323c0-5.013,1.484-8.881,4.454-11.606c2.97-2.724,7.287-4.087,12.955-4.087   c5.395,0,9.535,1.363,12.424,4.087c2.888,2.726,4.332,6.594,4.332,11.606c0,4.903-1.485,8.732-4.455,11.483   c-2.971,2.751-7.069,4.127-12.301,4.127c-5.395,0-9.645-1.362-12.75-4.086C313.96,827.123,312.407,823.282,312.407,818.323z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M453.397,750.402c0,13.351-3.938,23.662-11.811,30.937c-7.874,7.273-19.058,10.911-33.552,10.911h-9.072   v39.641h-32.284V712.396h41.356c15.093,0,26.427,3.297,34.001,9.89C449.609,728.88,453.397,738.252,453.397,750.402z    M398.963,765.932h5.885c4.85,0,8.705-1.361,11.565-4.087c2.86-2.724,4.291-6.483,4.291-11.279c0-8.063-4.469-12.097-13.404-12.097   h-8.337V765.932z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M586.214,831.891h-42.338l-43.646-84.186h-0.735c1.034,13.241,1.553,23.349,1.553,30.323v53.862h-28.606   V712.396h42.175l43.482,83.041h0.49c-0.764-12.042-1.145-21.713-1.145-29.016v-54.025h28.77V831.891z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M660.021,761.927h51.572v64.406c-14.004,4.795-29.396,7.192-46.178,7.192   c-18.418,0-32.652-5.34-42.707-16.02c-10.053-10.68-15.08-25.91-15.08-45.689c0-19.289,5.504-34.3,16.512-45.035   c11.006-10.733,26.426-16.102,46.26-16.102c7.52,0,14.617,0.709,21.293,2.125c6.674,1.418,12.49,3.216,17.449,5.395l-10.217,25.338   c-8.609-4.251-18.063-6.376-28.361-6.376c-9.428,0-16.715,3.065-21.863,9.195c-5.15,6.13-7.725,14.89-7.725,26.277   c0,11.171,2.33,19.685,6.988,25.542s11.375,8.786,20.148,8.786c4.793,0,9.207-0.462,13.24-1.39v-18.717h-21.332V761.927z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <g>
                    <path
                      d="M729.983,369.821c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,354.821,729.983,361.571,729.983,369.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.148,459.821c0,8.25-9,15-20,15H314.017c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.148,444.821,729.148,451.571,729.148,459.821z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g>
                    <path
                      d="M729.983,550.016c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,535.016,729.983,541.766,729.983,550.016z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    //break;
    case "jpeg":
    case "jpg":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                  fill="#cfd2fc"
                />
                <path
                  d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                  fill="#5153ff"
                />
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
                <path d="m152 136h208v160h-208z" fill="#fff" />
                <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
                <circle cx="288" cy="200" fill="#5153ff" r="16" />
                <g fill="#fff">
                  <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                  <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                  <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "htm":
    case "html":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg
                width="33px"
                height="40px"
                viewBox="0 0 33 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-4.000000, 0.000000)">
                    <g transform="translate(4.000000, 0.000000)">
                      <path
                        d="M4.04761905,0 C1.82142857,0 0,1.8 0,4 L0,36 C0,38.2 1.82142857,40 4.04761905,40 L28.3333333,40 C30.5595238,40 32.3809524,38.2 32.3809524,36 L32.3809524,12 L20.2380952,0 L4.04761905,0 L4.04761905,0 Z"
                        id="Shape"
                        fill="#708A9F"
                      ></path>
                      <text
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="19.047619"
                        fontWeight="normal"
                        fill="#FFFFFF"
                      >
                        <tspan x="4" y="28">
                          &lt;/&gt;
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    case "mp4":
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                width="40px"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 40 40"
              >
                <title>files/video</title>
                <desc>Created with Sketch.</desc>
                <g fillRule="evenodd" fill="none">
                  <path
                    fill="#DB4564"
                    d="m32 4h8v28c0 2.2-1.8 4-4 4h-32c-2.2 0-4-1.8-4-4v-24c0-2.2 1.8-4 4-4h2l4 8h6l-4-8h4l4 8h6l-4-8h4l4 8h6l-4-8zm-16 14v12l9.429-6-9.429-6z"
                  />
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
    default:
      return (
        <React.Fragment>
          <div
            className="document-row with-border"
            onClick={() => openDocuments(url)}
          >
            <div className="document-icon svg-icon">
              <svg
                width="33px"
                height="40px"
                viewBox="0 0 33 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              >
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-4.000000, 0.000000)">
                    <g transform="translate(4.000000, 0.000000)">
                      <path
                        d="M4.04761905,0 C1.82142857,0 0,1.8 0,4 L0,36 C0,38.2 1.82142857,40 4.04761905,40 L28.3333333,40 C30.5595238,40 32.3809524,38.2 32.3809524,36 L32.3809524,12 L20.2380952,0 L4.04761905,0 L4.04761905,0 Z"
                        id="Shape"
                        fill="#708A9F"
                      ></path>
                      <text
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="19.047619"
                        fontWeight="normal"
                        fill="#FFFFFF"
                      >
                        <tspan x="4" y="28">
                          &lt;/&gt;
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="document-content">{getDocContent(fileName)}</div>
            <div className="document-content">
              <div className="document-name"> {authName} </div>
            </div>
          </div>
        </React.Fragment>
      );
    // break;
  }
};

/**
 *
 * @param {attachmentURL}
 */
export const getAttchmentHtml = (attachmentURL) => {
  let fileExe = attachmentURL.split(".").pop();
  switch (true) {
    case fileExe === "pdf":
      return (
        <div className="document-icon material-icons pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            picture_as_pdf
          </a>
        </div>
      );
    // break;
    case fileExe === "csv":
    case fileExe === "xlsx":
      return (
        <div className="document-icon pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#4CAF50"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              ></path>
              <path
                fill="#FFF"
                d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
              ></path>
              <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z"></path>
              <path
                fill="#FFF"
                d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
              ></path>
            </svg>
          </a>
        </div>
      );

    case fileExe === "jpg":
    case fileExe === "jpeg":
    case fileExe === "png":
      return (
        <div className="document-icon image_type_jpg">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={attachmentURL}
              alt="chat"
              onError={(e) => addDefaultSrcForChat(e)}
            />
          </a>
        </div>
      );

    // break;
    case fileExe === "doc":
    case fileExe === "docx":
      return (
        <div className="document-icon pdf-icon svg-icon">
          <a
            className="view_application"
            href={attachmentURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#2196F3"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              ></path>
              <path
                fill="#FFF"
                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
              ></path>
              <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z"></path>
              <path
                fill="#FFF"
                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
              ></path>
            </svg>
          </a>
        </div>
      );

    default:
      return null;
    // break;
  }
};
export const SplitName = (name = "") => {
  if (!name) {
    return {
      firstName: "",
      lastName: "",
    };
  }
  const data = name.split(" ");
  return {
    firstName: data.length ? data[0] : "",
    lastName: data.length ? data[1] : "",
  };
};

export const GetDateTimeFromTimestamp = (timestamp) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Ap",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const today = new Date();
  const thatDay = new Date(timestamp);

  let hours = thatDay?.getHours();
  if (hours < 10) hours = `0${hours}`;
  if (hours === 0) hours = "00";
  let minutes = thatDay?.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;
  if (minutes === 0) minutes = "00";

  if (today?.getDate() === thatDay.getDate()) {
    return moment(timestamp).format("hh:mm a");
  }
  let date = thatDay?.getDate();
  if (date < 10) date = `0${date}`;
  if (date === 0) date = "00";

  const dayAndMonth = thatDay?.getDate() + " - " + month[thatDay?.getMonth()];
  return dayAndMonth;
};

/**
 *
 * @param {attachmentName}
 */
export const getAttachmentName = (attachmentURL) => {
  let fileExe = attachmentURL.split(".").pop();
  switch (true) {
    case fileExe === "pdf":
      return true;
    // break;
    case fileExe === "csv":
    case fileExe === "xlsx":
      return true;

    case fileExe === "jpg":
    case fileExe === "jpeg":
    case fileExe === "png":
      return false;

    // break;
    case fileExe === "docx":
    case fileExe === "doc":
      return true;

    default:
      return null;
    // break;
  }
};

export const getFileIcon = (filetype, fileName) => {
  let fileExe = filetype.split(".").pop();
  switch (fileExe) {
    case "pdf":
      return (
        <div className="pdf-info">
          <span className="material-icons">picture_as_pdf</span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "png":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg viewBox="0 0 1024 1024" width="22px" height="22px">
              <g>
                <g>
                  <path
                    d="M146.901,249.004V964c0,33,27,60,60,60h613.857c33,0,60-27,60-60V60c0-33-27-60-60-60H395.906"
                    fill="#F1C40F"
                  />
                  <g>
                    <path
                      d="M146.901,249.004h189.005c33,0,60-27,60-60V0"
                      fill="#F39C12"
                    />
                  </g>
                </g>
              </g>
              <g>
                <path
                  d="M81.83,670.833h65.071V585l-70.946,88.375h0.012C77.167,671.805,79.198,670.833,81.83,670.833z"
                  fill="#262626"
                />
                <path
                  d="M945.83,670.833h-65.071V585l70.946,88.375h-0.012C950.493,671.805,948.462,670.833,945.83,670.833z"
                  fill="#262626"
                />
                <path
                  d="M884.273,861.471c-1.934,5.149-8.015,9.362-13.515,9.362H156.901c-5.5,0-11.582-4.213-13.514-9.362   L75.344,680.195c-1.933-5.149,0.986-9.362,6.486-9.362h864c5.5,0,8.419,4.213,6.486,9.362L884.273,861.471z"
                  fill="#F39C12"
                />
              </g>
              <g>
                <path
                  d="M312.407,818.323c0-5.013,1.484-8.881,4.454-11.606c2.97-2.724,7.287-4.087,12.955-4.087   c5.395,0,9.535,1.363,12.424,4.087c2.888,2.726,4.332,6.594,4.332,11.606c0,4.903-1.485,8.732-4.455,11.483   c-2.971,2.751-7.069,4.127-12.301,4.127c-5.395,0-9.645-1.362-12.75-4.086C313.96,827.123,312.407,823.282,312.407,818.323z"
                  fill="#FFFFFF"
                />
                <path
                  d="M453.397,750.402c0,13.351-3.938,23.662-11.811,30.937c-7.874,7.273-19.058,10.911-33.552,10.911h-9.072   v39.641h-32.284V712.396h41.356c15.093,0,26.427,3.297,34.001,9.89C449.609,728.88,453.397,738.252,453.397,750.402z    M398.963,765.932h5.885c4.85,0,8.705-1.361,11.565-4.087c2.86-2.724,4.291-6.483,4.291-11.279c0-8.063-4.469-12.097-13.404-12.097   h-8.337V765.932z"
                  fill="#FFFFFF"
                />
                <path
                  d="M586.214,831.891h-42.338l-43.646-84.186h-0.735c1.034,13.241,1.553,23.349,1.553,30.323v53.862h-28.606   V712.396h42.175l43.482,83.041h0.49c-0.764-12.042-1.145-21.713-1.145-29.016v-54.025h28.77V831.891z"
                  fill="#FFFFFF"
                />
                <path
                  d="M660.021,761.927h51.572v64.406c-14.004,4.795-29.396,7.192-46.178,7.192   c-18.418,0-32.652-5.34-42.707-16.02c-10.053-10.68-15.08-25.91-15.08-45.689c0-19.289,5.504-34.3,16.512-45.035   c11.006-10.733,26.426-16.102,46.26-16.102c7.52,0,14.617,0.709,21.293,2.125c6.674,1.418,12.49,3.216,17.449,5.395l-10.217,25.338   c-8.609-4.251-18.063-6.376-28.361-6.376c-9.428,0-16.715,3.065-21.863,9.195c-5.15,6.13-7.725,14.89-7.725,26.277   c0,11.171,2.33,19.685,6.988,25.542s11.375,8.786,20.148,8.786c4.793,0,9.207-0.462,13.24-1.39v-18.717h-21.332V761.927z"
                  fill="#FFFFFF"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M729.983,369.821c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,354.821,729.983,361.571,729.983,369.821z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <path
                    d="M729.148,459.821c0,8.25-9,15-20,15H314.017c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.148,444.821,729.148,451.571,729.148,459.821z"
                    fill="#FFFFFF"
                  />
                </g>
                <g>
                  <path
                    d="M729.983,550.016c0,8.25-9,15-20,15H314.852c-11,0-20-6.75-20-15s9-15,20-15h395.132    C720.983,535.016,729.983,541.766,729.983,550.016z"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "jpg":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              width="22px"
              height="22px"
            >
              <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#cfd2fc"
              />
              <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#5153ff"
              />
              <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
              <path d="m152 136h208v160h-208z" fill="#fff" />
              <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
              <circle cx="288" cy="200" fill="#5153ff" r="16" />
              <g fill="#fff">
                <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
              </g>
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "jpeg":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              width="22px"
              height="22px"
            >
              <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#cfd2fc"
              />
              <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#5153ff"
              />
              <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
              <path d="m152 136h208v160h-208z" fill="#fff" />
              <path d="m216 216 40 48 48-16 56 48h-208z" fill="#8690fa" />
              <circle cx="288" cy="200" fill="#5153ff" r="16" />
              <g fill="#fff">
                <path d="m248 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m200 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
              </g>
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "xlsx":
    case "xls":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#4CAF50"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              />
              <path
                fill="#FFF"
                d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
              />
              <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
              <path
                fill="#FFF"
                d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
              />
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );

    case "docx":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#2196F3"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              />
              <path
                fill="#FFF"
                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
              />
              <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
              <path
                fill="#FFF"
                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
              />
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "doc":
      return (
        <div className="pdf-info">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="22px"
              height="22px"
            >
              <path
                fill="#2196F3"
                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
              />
              <path
                fill="#FFF"
                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
              />
              <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
              <path
                fill="#FFF"
                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
              />
            </svg>
          </span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    case "csv":
      return (
        <div className="pdf-info">
          <img width={22} height={22} src={"/assets/images/csv.png"} alt="" />
          <span className="pdf-name">{fileName}</span>
        </div>
      );
    default:
      return (
        <div className="pdf-info">
          <span className="svg-icon material-icons">picture_as_pdf</span>
          <span className="pdf-name">{fileName}</span>
        </div>
      );
  }
};

export const getUrlExtension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

//render event in enmail tab
export const renderEventName = (event) => {
  if (event === "open") {
    return "done_all";
  } else if (event === "click") {
    return "done_all";
  } else if (event === "delivered") {
    return "done";
  } else if (event === "group_unsubscribe") {
    return "notifications_off";
  } else if (event === "bounce") {
    return "block";
  } else {
    return "done";
  }
};

//render classname in enmail tab
export const renderClassName = (event) => {
  if (event === "open") {
    return "icon-read";
  } else if (event === "click") {
    return "icon-read";
  } else if (event === "delivered") {
    return "icon-unread";
  } else if (event === "group_unsubscribe") {
    return "icon-group_unsubscribe";
  } else if (event === "bounce") {
    return "icon-not_delivered";
  } else {
    return "icon-unread";
  }
};

//render constantname in enmail tab
export const returnConstantName = (event) => {
  if (event === "open") {
    return "Opened on";
  } else if (event === "click") {
    return "Clicked on";
  } else if (event === "delivered") {
    return "Delivered on";
  } else if (event === "group_unsubscribe") {
    return " Unsubscribe on";
  } else if (event === "bounce") {
    return "Bounced on";
  } else {
    return "Delivered On";
  }
};

/////convert time
export const convertTime = (event, time) => {
  if (event !== "delivered") {
    return new Date(parseInt(time));
  } else {
    return new Date(parseInt(time));
  }
};

/// no of employee list

export const EmployeeList = [
  { value: "1-5", label: "1-5" },
  { value: "5-25", label: "5-25" },
  { value: "25-50", label: "25-50" },
  { value: "50-100", label: "50-100" },
  { value: "100-500", label: "100-500" },
  { value: "500-1000", label: "500-1000" },
  { value: "1000-more", label: "1000 or more" },
];

export const comments = (comnt) => {
  let expression =
    /([\w+]+\:\/\/)?([\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.\%]?[\w-]+)*\/?/gm;
  let regex = new RegExp(expression);
  let match = "";
  let splitText = [];
  let startIndex = 0;
  while ((match = regex.exec(comnt)) != null) {
    splitText.push(comnt.substr(startIndex, match.index - startIndex));

    let cleanedLink = comnt.substr(match.index, match[0].length);

    splitText.push(
      `<a target='_blank' href= ${cleanedLink}>${cleanedLink}</a>`
    );

    startIndex = match.index + match[0].length;
  }
  if (startIndex < comnt.length) splitText.push(comnt.substr(startIndex));

  return splitText.join("");
};

export const fakeClientDetails = {
  ApplicationId: "ABC",
  Carrier: "N/A",
  City: "New Orleans",
  Date: "11/12/2022",
  Email: "email@email.com",
  LeadLevel: "N/A",
  LeadType: "Paid Lead",
  Location: "LA",
  Name: "Example Client",
  PolicyStatus: "NEW",
  Reference: "Browsing Link",
  Status: "NEW",
  postalCode: "909090",
  agentCustomStatus: "New",
  applicationStatus: "NEW",
  falsecarrierLogo:
    "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/1653544424_4dwk0q97.jpeg",
  carrierType: "offline",
  color: "",
  nullid: 51728,
  id1: 51728,
  falseleadSource: "Contact",
  logo: "https://d29x6p11e4scrv.cloudfront.net/admin/lead-packages/1670938328_fa8u3enx.jpeg",
  mobileNumber: "0000000000",
  policyType: "Term",
  productName: "N/A",
  falsesignedStatus: false,
};

export const callList = [
  {
    date: "11/12/2023",
    time: "12:00PM",
    call_duration: "1 mins 55 secs",
    call_url:
      "https://callpixels.s3.amazonaws.com/recordings/38f76464-d7d4-4514-84f0-9427bfe5072a/23637f2eeac9512c2c86ff1102b6ac39/embed/call_38f76464d7d4451484f09427bfe5072a_1677876289.mp3",
  },
];

export const planList = [
  {
    image:
      "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1648713438_wz5i7ak3.jpeg",
    plan_name: "Select-A-Term 15",
    plan_type: "Monthly",
    credit_rating: "A+",
    covrage: "$35000",
    time: "10 years",
    price: "$24.00",
  },
  {
    image:
      "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1630407535_jsg2m8b9.jpeg",
    plan_name: "PL Promise 10",
    plan_type: "Monthly",
    credit_rating: "A++",
    covrage: "$5000",
    time: "2 years",
    price: "$20.00",
  },
  {
    image:
      "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1630650747_0gsc49nm.jpeg",
    plan_name: "Trendsetter Super 10",
    plan_type: "Monthly",
    credit_rating: "A+",
    covrage: "$15000",
    time: "6 years",
    price: "$22.00",
  },
  {
    image:
      "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1648727646_zn8phjfb.jpeg",
    plan_name: "Level 10 Year Term",
    plan_type: "Monthly",
    credit_rating: "A+",
    covrage: "$25000",
    time: "8 years",
    price: "$29.00",
  },
  {
    image:
      "https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1630655291_vy6e1qjh.jpeg",
    plan_name: "Strong Foundation 10",
    plan_type: "Monthly",
    credit_rating: "A+",
    covrage: "$45000",
    time: "7 years",
    price: "$28.00",
  },
  {
    image:
      "	https://d29x6p11e4scrv.cloudfront.net/admin/carrier/carrier-original-image/1631109891_sgfl2onv.jpeg",
    plan_name: "Term Life Answers 10",
    plan_type: "Monthly",
    credit_rating: "A++",
    covrage: "$550000",
    time: "10 years",
    price: "$24.00",
  },
];

export const progressBarBoolean = (path) => {
  if (
    path === DashboardPagesRoutes.WELCOMEPAGE ||
    path === DashboardPagesRoutes.PRODUCT_SELECTION ||
    path === DashboardPagesRoutes.CARRIERS_SELECTION ||
    path === DashboardPagesRoutes.STATE_SELECTION ||
    path === DashboardPagesRoutes.TIME_ZONE_SELECTION
  )
    return true;
  else return false;
};

export const renderTooltipOfUnverifiedEmails = (props) => (
  <div id="button-tooltip" {...props}>
    <div className="tooltip-outer">
      <p>
        Unverified Email: Email will not be sent
        <br />
      </p>
    </div>
  </div>
);


export const getTimezoneCode = (timezoneID) => {
  // Get the timezone namefor the provided timezone ID
  const zone = moment.tz(timezoneID).format('z');

  if (zone) {
    // Check the name to determine the timezone
    if (zone === 'CST' || zone === "CDT") {
      return 'CT';
    } else if (zone === 'EST' || zone === "EDT") {
      return 'ET';
    } else if (zone === 'MST' || zone === "MDT") {
      return 'MT';
    }
    else if (zone === 'HST' || zone === "HDT") {
      return 'Hawaii';
    }
    else if (zone === 'AKST' || zone === "AKDT") {
      return 'AKST';
    }
    else if (zone === 'PST' || zone === "PDT") {
      return 'PT';
    } else {
      return 'ET'; // Handle other timezones as needed
    }
  } else {
    return 'ET'; // Handle invalid timezone IDs
  }
};


export function getChatDate(date,timezone=moment.tz.guess() ) {
  const receivedDate = moment.tz(date, timezone);
  const today = moment().tz(timezone);
  const yesterday = moment().subtract(1, 'days').tz(timezone);

  if (receivedDate.isSame(today, 'day')) {
      // Date is today
      return receivedDate.format('h:mm A');
  } else if (receivedDate.isSame(yesterday, 'day')) {
      // Date is yesterday
      return 'Yesterday';
  } else {
      // Date is previous to yesterday
      return receivedDate.format('MMM Do');
  }
}