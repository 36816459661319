import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { connect } from "react-redux";
import { setAgencyFilters } from "../../../utlis";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

const DateFilterModel = ({
  show,
  handleFilter,
  filter,
  onHide,
  setSearchDateFilter,
  setDateModal,
  dateValue,
  dateFilter,
  getMyAgents,
}) => {
  const [minHeight, setMinHeight] = useState("100px");
  const [calenderView, setCalenderView] = useState(false);

  return (
    <Modal
      className='invite-agent-modal date-filter-modal'
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose custom filter dates</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: minHeight }}>
        {/* <div
          className='form-control mb-4'
          style={{ maxWidth: "300px" }}
          onClick={() => setCalenderView(true)}
        >
          <div className='d-flex align-items-center justify-content-between '>
            {value.start ? value.start.format("YYYY-MM-DD") : "MM/DD/YYYY"}
            <img
              src='/assets/images/two-way-arrow.svg'
              width={16}
              height={6}
              alt=''
            />{" "}
            {value.end ? value.end.format("YYYY-MM-DD") : "MM/DD/YYYY"}
          </div>
        </div> */}
        <Row>
          <Col md={5}>
            <div
              className='form-control mb-4'
              // style={{ maxWidth: "300px" }}
              onClick={() => setCalenderView(true)}
            >
              <div className='d-flex align-items-center justify-content-between '>
                {dateValue?.start
                  ? dateValue?.start?.format("YYYY-MM-DD")
                  : "MM/DD/YYYY"}
                {/* <img
              src='/assets/images/two-way-arrow.svg'
              width={16}
              height={6}
              alt=''
            />{" "}
            {value.end ? value.end.format("YYYY-MM-DD") : "MM/DD/YYYY"} */}
              </div>
            </div>
          </Col>
          <Col md={2} className='date-img'>
            <img
              src='/assets/images/two-way-arrow.svg'
              width={16}
              height={6}
              alt=''
            />
          </Col>
          <Col md={5}>
            <div
              className='form-control mb-4'
              // style={{ maxWidth: "300px" }}
              onClick={() => setCalenderView(true)}
            >
              <div className='d-flex align-items-center justify-content-between '>
                {/* {value.start ? value.start.format("YYYY-MM-DD") : "MM/DD/YYYY"}
            <img
              src='/assets/images/two-way-arrow.svg'
              width={16}
              height={6}
              alt=''
            />{" "} */}
                {dateValue?.end
                  ? dateValue?.end?.format("YYYY-MM-DD")
                  : "MM/DD/YYYY"}
              </div>
            </div>
          </Col>
        </Row>
        {calenderView ? (
          <div className='form-group custom-datepicker date-pickar-range'>
            <DateRangePicker
              value={dateValue}
              onSelect={(date) => handleFilter(date)}
              singleDateRange={true}
              numberOfCalendars={2}
              selectionType='range'
              maximumDate={new Date()}
            />
          </div>
        ) : null}
        {/* <div className="position-relative w-100">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selectsStart
              selected={filter.startDate}
              placeholderText={"Date added"}
              onChange={(date) => handleFilter("startDate", date)}
              popperPlacement="bottom"
              onCalendarOpen={() => setMinHeight("350px")}
              onCalendarClose={() => setMinHeight("100px")}
              maxDate={filter.endDate}
            />
            <span className="date-picker-placholder"> Start</span>
          </div>
          <img
            src="/assets/images/two-way-arrow.svg"
            width={16}
            height={6}
            alt=""
          />
          <div className="position-relative w-100">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selectsStart
              selected={filter.endDate}
              popperPlacement="bottom"
              placeholderText={"Date added"}
              onChange={(date) => handleFilter("endDate", date)}
              onCalendarOpen={() => setMinHeight("350px")}
              onCalendarClose={() => setMinHeight("100px")}
              minDate={filter.startDate}
            />
            <span className="date-picker-placholder"> End</span>
          </div> */}
      </Modal.Body>
      <Modal.Footer
        className='border-0 text-right pt-0'
        style={{ marginRight: "14px" }}
      >
        <button
          className='m-0 btn btn-gradient'
          onClick={() => {
            if (dateFilter.startDate && dateFilter.endDate) {
              getMyAgents();
              setDateModal(false);
            } else {
              toast.error("Both start and end dates are required.");
            }
          }}
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default connect(null, {
  setAgencyFilters,
})(withRouter(DateFilterModel));
