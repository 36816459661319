import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import Switch from "react-switch";
import { SettingsRoutes } from "../../utlis";
import {
  commonApiCall,
  showSuccessToast,
  showErrorToast,
  ShowForPermission,
} from "../../utlis";
import { API_POINT } from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";

const NotificationSettings = ({ commonApiCall, planDetails, sign_in }) => {
  const [signIn, setSignIn] = useState({});
  const [vistiorStatus, setVistiorStatus] = useState([]);
  const [prospectStatus, setProspectStatus] = useState([]);
  const [newApplicationStatus, setNewApplicationStatus] = useState([]);
  const [leadManagement, setLeadManagement] = useState(false);
  const [websiteManagement, setWebsiteManagement] = useState(false);

  useEffect(() => {
    if (sign_in && sign_in?.data) setSignIn(sign_in?.data);
  }, [sign_in]);

  const getNotificationStatus = async () => {
    let response = await commonApiCall(
      API_POINT + "/notification",
      "",
      "get",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      let visitor = [];
      let prospect = [];
      let newApplication = [];
      let data = response.data.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].eventActions.eventKey == "prospect_send_message") {
          prospect.push({
            emailStatus: data[i].status,
            mobileStatus: data[i].mobileStatus,
          });
          setProspectStatus(prospect);
        }
        if (data[i].eventActions.eventKey == "new_application_recieved") {
          newApplication.push({
            emailStatus: data[i].status,
            mobileStatus: data[i].mobileStatus,
          });
          setNewApplicationStatus(newApplication);
        }
        if (data[i].eventActions.eventKey == "visitor_send_message") {
          visitor.push({
            emailStatus: data[i].status,
            mobileStatus: data[i].mobileStatus,
          });
          setVistiorStatus(visitor);
        }
      }
    }
  };

  const onChangeManualAssignLead = async (flag) => {
    let response = await commonApiCall(
      API_POINT + "/menualAssignLeads?flag=" + flag,
      "",
      "post",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      setLeadManagement(response?.data?.data);
      showSuccessToast(response?.data?.status?.message);
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onChangeWebManualAssignLead = async (flag) => {
    let response = await commonApiCall(
      API_POINT + "/manualWebsiteLeads?flag=" + flag,
      "",
      "post",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      setWebsiteManagement(response?.data?.data);
      showSuccessToast(response?.data?.status?.message);
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const changeNotificationStatus = async (status, type, platform) => {
    let body = {};
    if (platform === "email") {
      body = {
        eventKey: type,
        status: status,
      };
    }
    if (platform === "mobile") {
      body = {
        eventKey: type,
        mobileStatus: status,
      };
    }
    let response = await commonApiCall(
      API_POINT + "/notification",
      body,
      "post",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      showSuccessToast(response?.data?.status?.message);
      getNotificationStatus();
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getManualAssignLead = async () => {
    let response = await commonApiCall(
      API_POINT + "/menualAssignLeads",
      "",
      "get",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      setLeadManagement(response?.data?.data);
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getWebManualAssignLead = async () => {
    let response = await commonApiCall(
      API_POINT + "/manualWebsiteLeads",
      "",
      "get",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      setWebsiteManagement(response?.data?.data);
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    getNotificationStatus();
    getManualAssignLead();
    getWebManualAssignLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div className="setting-box">
              <ShowForPermission permission="NOTIFICATION_SETTING">
                <div className="text-align-center mt-2">
                  <h3 className="text-left font-24 mb-1">Messages</h3>
                </div>
                <h5 className="title-text">
                  Select which types of communication you would like to receive.
                </h5>
                <hr className="light-thinborder w-100 mt-0" />
                <React.Fragment>
                  <div className="setting-title mt-4  mb-0">
                    <h4>Email Notifications</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Send me an email when a website visitor sends me a
                          message
                        </label>
                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "visitor_send_message",
                              "email"
                            )
                          }
                          checked={
                            vistiorStatus.length >= 1 &&
                            vistiorStatus[0].emailStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Send me an email when a prospect sends me a message
                        </label>
                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "prospect_send_message",
                              "email"
                            )
                          }
                          checked={
                            prospectStatus.length >= 1 &&
                            prospectStatus[0].emailStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Send me an email when I receive a new prospect
                        </label>

                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "new_application_recieved",
                              "email"
                            )
                          }
                          checked={
                            newApplicationStatus.length >= 1 &&
                            newApplicationStatus[0].emailStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="setting-title mt-md-5 md-4">
                        <h4>Mobile Notifications</h4>
                        {(signIn.currentPlanSlug === "free" &&
                          planDetails.data.data.Upgrade) ||
                        signIn.role === "AMBASSADOR" ? (
                          <div>
                            <Link
                              to={SettingsRoutes.MYSETTINGS}
                              className="update"
                            >
                              <button
                                className="btn btn-yellow upgrade-btn"
                                type="submit"
                              >
                                Upgrade{" "}
                                <span className="material-icons">flash_on</span>
                              </button>
                            </Link>
                          </div>
                        ) : null}
                        <h5>
                          We recommend keeping these messages turned on so that
                          you can respond quickly to prospects.
                        </h5>
                        <hr className="light-thinborder w-100 mt-0" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className={
                          (signIn.currentPlanSlug === "free" &&
                            planDetails.data.data.Upgrade) ||
                          signIn.role === "AMBASSADOR"
                            ? "form-group setting-disable"
                            : "form-group"
                        }
                      >
                        <label>
                          Send me a text message when a website visitor sends me
                          a message
                        </label>
                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "visitor_send_message",
                              "mobile"
                            )
                          }
                          checked={
                            vistiorStatus.length >= 1 &&
                            vistiorStatus[0].mobileStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          disabled={
                            (signIn.currentPlanSlug === "free" &&
                              planDetails.data.data.Upgrade) ||
                            signIn.role === "AMBASSADOR"
                              ? true
                              : false
                          }
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className={
                          (signIn.currentPlanSlug === "free" &&
                            planDetails.data.data.Upgrade) ||
                          signIn.role === "AMBASSADOR"
                            ? "form-group setting-disable"
                            : "form-group"
                        }
                      >
                        <label>
                          Send me a text message when a prospect sends me a
                          message
                        </label>
                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "prospect_send_message",
                              "mobile"
                            )
                          }
                          checked={
                            prospectStatus.length >= 1 &&
                            prospectStatus[0].mobileStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          disabled={
                            (signIn.currentPlanSlug === "free" &&
                              planDetails.data.data.Upgrade) ||
                            signIn.role === "AMBASSADOR"
                              ? true
                              : false
                          }
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className={
                          (signIn.currentPlanSlug === "free" &&
                            planDetails.data.data.Upgrade) ||
                          signIn?.role === "AMBASSADOR"
                            ? "form-group setting-disable"
                            : "form-group"
                        }
                      >
                        <label>
                          Send me a text message when I receive a new prospect
                        </label>
                        <Switch
                          onChange={(checked) =>
                            changeNotificationStatus(
                              checked,
                              "new_application_recieved",
                              "mobile"
                            )
                          }
                          checked={
                            newApplicationStatus.length >= 1 &&
                            newApplicationStatus[0].mobileStatus
                              ? true
                              : false
                          }
                          height={24}
                          width={48}
                          handleDiameter={18}
                          disabled={
                            (signIn.currentPlanSlug === "free" &&
                              planDetails.data.data.Upgrade) ||
                            signIn.role === "AMBASSADOR"
                              ? true
                              : false
                          }
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </ShowForPermission>
              {sign_in?.data?.basePlan === "ENTERPRISE" ? (
                <React.Fragment>
                  <div className="setting-title mt-5">
                    {/* <h4>Lead Management</h4> */}
                    <h4>Purchased Leads</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Assign all purchased leads manually</label>
                        <Switch
                          onChange={(flag) => onChangeManualAssignLead(flag)}
                          checked={leadManagement}
                          height={24}
                          width={48}
                          handleDiameter={18}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Assign all leads from my website manually</label>
                        <Switch
                          onChange={(flag) => onChangeWebManualAssignLead(flag)}
                          checked={websiteManagement}
                          height={24}
                          width={48}
                          handleDiameter={18}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 16,
                                color: "#fff",
                                paddingRight: 2,
                                fontWeight: "500",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </Animated>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    referal_code: state.agentReducer.referalCode,
    planDetails: state.agentReducer.planDetails,
  };
};

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(NotificationSettings));
