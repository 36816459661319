import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LeadPackagesLoader = () => {
  const columns = [...Array(6)];
  return (
    <SkeletonTheme>
      <div className="row">
        {columns.map((column) => (
          <div className="col-sm-6 col-lg-4">
            <div className="package-box pt-3">
              <Skeleton count={1} height={32} />
              <div className="package-type">
                <Skeleton count={1} height={26} />
              </div>
              <div className="package-price mb-0">
                <Skeleton count={1} height={26} />
              </div>
              <div className="mb-3">
                <Skeleton count={1} height={26} />
              </div>
              <Skeleton count={1} height={40} />
            </div>
          </div>
        ))}
      </div>
    </SkeletonTheme>
  );
};
