import React from "react";

const Search = ({
  search,
  keyPressed,
  setSearch,
  loader,
  doSearch,
  className,
  clearSearch,
}) => {
  return (
    <div className="common-search px-0">
      <div className="form-group mb-0">
        <div className="position-relative search-input">
          <input
            className={`${className} form-control rounded-sm hover-primary-border`}
            placeholder="Search"
            name="search"
            id="search"
            type="text"
            // autoFocus
            value={search}
            autoComplete="off"
            onKeyDown={keyPressed}
            onChange={(e) => setSearch(e.target.value)}
          />

          {!search && (
            <div className="common-search-common-icon">
              {/* <span
                className={"material-icons" + (loader ? "inputDisable" : "")}
                onClick={doSearch}
              >
                search
              </span> */}
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#818181"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
              <label className="mb-0 ml-1 lh-15">Search</label>
            </div>
          )}
          {search && (
            <span
              className="cancel-search material-icons-outlined material-icons"
              onClick={() => clearSearch()}
            >
              cancel
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
