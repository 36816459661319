/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import * as _ from "lodash";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SidebarLoader = () => {
  let card = [];
  _.times(8, (i) => {
    card.push(
      <li className="sidebar-link mb-4" key={i}>
        <div className="active d-flex align-items-center ml-3">
          <Skeleton
            count={1}
            height={40}
            width={40}
            style={{ borderRadius: "40px", marginRight: "30px" }}
          />
        </div>
      </li>
    );
  });
  return (
    <SkeletonTheme>
      <div className="loader-sidebar sidebar" style={{ height: "100vh", marginTop: "10px" }}>
        <div className="sidebar-links-outer">
          <ul>
            <li className="sidebar-link ">
              <div>
                <div className="crm-tour d-flex align-items-start flex-column">
                  <span className="material-icons mr-2 mb-md-5 mb-4">
                    <Skeleton count={1} height={60} width={180} />
                  </span>
                </div>
                <ul className="submenu">{card}</ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </SkeletonTheme>
  );
};
