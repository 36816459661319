import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const SuccessModel = ({
  isShow,
  handleModel,
  confirmAction,
  gaurdianSuccesModel,
  sbliSuccessModel,
  bannerSuccessModel,
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="success-model rounded-0"
      show={isShow}
      onHide={() => handleModel(!isShow)}
      backdrop="static"
    >
      <Modal.Header className="rounded-0">
        <Modal.Title className="h5">Success!</Modal.Title>
      </Modal.Header>
      {gaurdianSuccesModel ? (
        <Modal.Body className="py-0">
          <p>You have successfully submitted your request for appointment.</p>
          <p>For Guardian, please contact</p>
          <p>
            Evan Kidwell at <a href="#">evan_kidwell@pacificadvisors.com</a>
          </p>
          <p>
            to confirm your intent to become appointed so that he can personally
            help you with next steps.
          </p>
        </Modal.Body>
      ) : sbliSuccessModel && sbliSuccessModel ? (
        <Modal.Body className="py-0">
          <p>
            You have successfully submitted your request for appointment. Look
            for an email with a contracting link and instructions to apply. You
            will also receive an email when your request is approved and SBLI
            has been automatically added to your client browsing journey.
          </p>
        </Modal.Body>
      ) : (
        <Modal.Body className="py-0">
          <p>You have successfully submitted your request for appointment.</p>
          <p>Carriers will follow up with you directly via email.</p>
        </Modal.Body>
      )}
      <Modal.Footer className="border-0 mt-md-4 mt-2 px-4 rounded-0">
        <Link to="#" variant="primary" onClick={() => confirmAction()}>
          {bannerSuccessModel ? "CLOSE" : "RETURN TO ACCOUNT"}
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModel;
