import React, { useEffect, useRef } from "react";
import sample from "./designSample.json";
import EmailEditor from "react-email-editor";
import Layout from "../components/layoutComponents/layout";

const DemoEmailTemplate = () => {
  const emailEditorRef = useRef(null);
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
    });
  };

  const onLoad = () => {
    // emailEditorRef.current.editor.loadDesign(sample);
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    //console.log("onReady");
  };

  return (
    <Layout>
      <div className="demo-email-template content-container">
        <div className="content-container p-3 mb-3">
          <div className="row mb-3 align-items-end">
            <div className="col-md-4">
              <div className="form-group mt-2 mb-0">
                <h5 className="font-16 font-weight-bold  txt-light-black">
                  Subject
                </h5>

                <div className="input-effect mw-475 ">
                  <input
                    className="form-control"
                    name="campaignName"
                    id="campaignName"
                    type="text"
                  />
                </div>
                {/* <span className="text-danger">{errors.campaignName}</span> */}
              </div>
            </div>
            <div className="col-md-6">
              <button className="btn btn-primary " onClick={() => exportHtml()}>
                Save template
              </button>
              <button
                className="btn btn-primary px-3 mx-3"
                onClick={() => exportHtml()}
              >
                Save & Send
              </button>
            </div>
          </div>

          <EmailEditor
            ref={emailEditorRef}
            className="text-class"
            onLoad={onLoad}
            onReady={onReady}
            projectId={process.env.REACT_APP_UNLAYER}
            options={{
              features: {
                ai: {
                  magicImage: false,
                },
              },
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default DemoEmailTemplate;
