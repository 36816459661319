import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LeafLoader } from "./ilifeLogoLoader";

export const TransactionTableLoader = ({ displayRows, displayColumns }) => {
  const rows = [...Array(parseInt(displayRows))];
  const columns = [...Array(parseInt(displayColumns))];
  return (
    <SkeletonTheme>
      <div className="transaction-wrap table-responsive transaction-table">
        <LeafLoader />
        <table border="1" className="table">
          <thead>
            <tr>
              {rows.map((row, key) => (
                <th key={key}>
                  <Skeleton count={1} height={22} width={70} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {columns.map((column, key) => (
              <tr key={key}>
                {rows.map((row, rKey) => (
                  <td key={rKey}>
                    <Skeleton count={1} height={18} width={30} />
                  </td>
                ))}
                {/* <td>
                  <Skeleton count={1} height={18} width={72} />
                </td>
                <td>
                  <Skeleton count={1} height={18} width={50} />
                </td>
                <td>
                  <Skeleton count={1} height={18} width={55} />
                </td>
                <td>
                  <Skeleton count={1} height={18} width={35} />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </SkeletonTheme>
  );
};
