import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect } from "react-redux";

const AccountPageLoader = () => {
  return (
    <SkeletonTheme>
      <div className="white-box2">
        <h3 className="font-24 box-main-title">
          <Skeleton count={1} height={30} width={150} />
        </h3>
        <div className="profile-details">
          <div className="profile-pic">
            <img src={"/assets/images/no-image.jpg"} alt="" />
          </div>
        </div>
        <form className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <Skeleton count={1} height={50} width={400} />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <Skeleton count={1} height={50} width={400} />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <Skeleton count={1} height={50} width={400} />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <Skeleton count={1} height={50} width={400} />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <Skeleton count={1} height={50} width={400} />
              </div>
            </div>
          </div>
        </form>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="title-header">
              <h3 className="text-left font-24 box-main-title">
                What is your preferred timezone?
              </h3>
            </div>
            <div className="default-select with-border mt-0 mb-3">
              <Skeleton count={1} height={50} width={400} />
            </div>
          </div>
        </div> */}
        {/* {signIn.role !== "AMBASSADOR" ? ( */}
        {/* <React.Fragment>
            <div className="row">
              <div className="col-md-6">
                <div className="title-header">
                  <h3 className="text-left font-24 box-main-title">
                    License State
                  </h3>
                </div>
                <form className="carrierpart-outer border-0 width-text">
                  <div className="row addmore-outer pr-0">
                    <div className="col-10">
                      <div className="form-group fixed-floating-label mb-4">
                        <div className="default-select with-border">
                          <Skeleton count={1} height={50} width={180} />
                        </div>
                        <div className="default-select with-border">
                          <Skeleton count={1} height={50} width={180} />
                        </div>
                        <div className="default-select with-border">
                          <Skeleton count={1} height={50} width={180} />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <React.Fragment>
                  <div className="title-header">
                    <h3 className="text-left font-24 box-main-title tooltip-btn">
                      Show prospects products by:
                    </h3>
                  </div>
                  <ul className="productist">
                    <li>
                      <div className="custom-checkbox">
                        <label>
                          <Skeleton count={1} height={25} width={180} />
                        </label>
                      </div>

                      <ul className="sublist">
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="custom-checkbox">
                        <label>
                          <Skeleton count={1} height={25} width={180} />
                        </label>
                      </div>

                      <ul className="sublist">
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="custom-checkbox">
                        <label>
                          <Skeleton count={1} height={25} width={180} />
                        </label>
                      </div>

                      <ul className="sublist">
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-checkbox">
                            <label className="mr-1">
                              <Skeleton count={1} height={20} width={100} />
                              <span></span>
                              <Skeleton count={1} height={20} width={20} />
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              </div>
            </div>
            <div className="d-flex flex-row mt-md-5 mt-3">
              <p className="leadModelText">
                <Skeleton count={1} height={50} width={800} />
              </p>
            </div>
            <div className={"col-12 px-0 mt-3"}>
              <Skeleton count={1} height={25} width={180} />
            </div>
            <hr className="thinborder" />
            {!formData?.retreaverId === null || !formData?.retreaverId === "" ? (
            <>
              <div className="text-align-center">
                <h3 className="text-left font-24"> Paid Leads</h3>
              </div>
              <p className="leadModelText mb-2" style={{ fontSize: "14px" }}>
                <Skeleton count={1} height={15} width={180} />
              </p>
              <div className="custom-checkbox">
                <label className="mr-4 mb-0 txt-black tooltip-btn">
                  <Skeleton count={1} height={25} width={180} />
                </label>
              </div>
              <hr className="thinborder" />{" "}
            </>
          ) : null}
            {sign_in?.data?.currentPlanSlug?.toLowerCase() === "enterprise" && (
              <>
                <div className="text-align-center">
                  <h3 className="text-left font-24">Agents Appointments</h3>
                </div>
                <p className="leadModelText mb-2" style={{ fontSize: "14px" }}>
                  <Skeleton count={1} height={15} width={180} />
                </p>
                <div className="custom-checkbox">
                  <label className="mr-4 mb-0 txt-black tooltip-btn">
                    <Skeleton count={1} height={25} width={180} />
                    <span></span>
                  </label>
                </div>
                <hr className="thinborder" />{" "}
              </>
            )}
            <div className={"appointment-with-carriers"}>
              <p className="appointment-with-carriers-header">
                <Skeleton count={1} height={25} width={200} />
              </p>
              <p className="text-center">
                <Skeleton count={1} height={25} width={400} />
              </p>
              <div className="update">
                <Skeleton count={1} height={25} width={180} />
              </div>
            </div>
          </React.Fragment> */}
      </div>
    </SkeletonTheme>
  );
};
const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
  };
};
export default connect(mapStateToProps, {})(AccountPageLoader);
