import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import AppRouting from "./AppRouting";
import {
  setItem,
  setCookies,
  getJwt,
  segmentTrackingMethod,
  googleTagManager,
  intercomAlvis,
  hotjar,
  noScriptTagGoogleTagManager,
} from "./utlis";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-image-crop/dist/ReactCrop.css";
import "./scss/styles.scss";

require("dotenv").config();

class App extends Component {
  requireAuth = () => {
    return getJwt() ? true : false;
  };

  componentDidMount() {
    const path = window.location.pathname;
    setItem("lastScreen", path);
    const referrerCode = new URLSearchParams(window.location.search).get(
      "referrer"
    );
    if (referrerCode) {
      setCookies("referrer", referrerCode);
    }
    segmentTrackingMethod();
    if (process.env.REACT_APP_NODE_ENV !== "development") {
      googleTagManager(process.env.REACT_APP_GTM_ID);
      noScriptTagGoogleTagManager(process.env.REACT_APP_GTM_ID);
    }
    if (process.env.REACT_APP_NODE_ENV == "production") {
      intercomAlvis();
      hotjar();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App" >
            <AppRouting />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
