import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { isEmpty } from "lodash";
import { Modal, OverlayTrigger } from "react-bootstrap";
import { DashboardPagesRoutes } from "../../utlis";
import { LeafLoader } from "../../components/skeletonLoaders/ilifeLogoLoader";
// import Select from "react-select";

import {
  commonApiCall,
  updateAgent,
  decryptData,
  validateName,
  validateEmail,
  validateMobileNumber,
  encryptData,
  showSuccessToast,
  // showAdviceToast,
  showErrorToast,
  setProfilePending,
  getJwt,
  BasicsPagesRoutes,
  profilePicture,
  CarrierAppointmentData,
  checkAccess,
} from "../../utlis";
import { UseInterval } from "../../hooks/index";
import { API_POINT } from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";
import { getBasePlan } from "../../utlis/common";
import IlifePrompt from "../../components/commonComponents/IlifePrompt";
import SuccessModel from "../../components/modelComponents/successModel";

// const signatureFontList = [
//   "Default",
//   "Arial",
//   "ArialNarrow",
//   "Calibri",
//   "CourierNew",
//   "Garamond",
//   "Georgia",
//   "Helvetica",
//   "LucidaConsole",
//   "MSGothic",
//   "MSMincho",
//   "OCR-A",
//   "Tahoma",
//   "TimesNewRoman",
//   "Trebuchet",
//   "Verdana",
// ];

// const fontColorList = [
//   "Black",
//   "BrightBlue",
//   "BrightRed",
//   "DarkGreen",
//   "DarkRed",
//   "Gold",
//   "Green",
//   "NavyBlue",
//   "Purple",
//   "White",
// ];

// const fontSizeList = [
//   "Size7",
//   "Size8",
//   "Size9",
//   "Size10",
//   "Size11",
//   "Size12",
//   "Size14",
//   "Size16",
//   "Size18",
//   "Size20",
//   "Size22",
//   "Size24",
//   "Size26",
//   "Size28",
//   "Size36",
//   "Size48",
//   "Size72",
// ];

function CarrierOptions({
  commonApiCall,
  updateAgent,
  profilePicture,
  CarrierAppointmentData,
  profile_pic,
  sign_in,
  planData,
  ...props
}) {
  const [formData, setFormData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobile: "",
    managerCode: "",
    errors: {},
    verificationModal: false,
    hideGetAppointedSection: false,
    otp: "",
    photo: "",
    counter: 0,
    retreaverId: "",
    showParentAgencyCode: false,
  });

  const childRef = useRef();

  const [forceChangeDone] = useState(false);
  const [iSCheckOfflineCarrierChange, setIsCheckOfflineCarrierChange] =
    useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [isGettingAppointed, setIsGettingAppointed] = useState(false);
  const [temporaryState, setTemporaryState] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pauseLead, setPausedLead] = useState(false);
  const [
    hideGetAppointedSectionEnterprise,
    sethideGetAppointedSectionEnterprise,
  ] = useState(false);
  const [signIn, setSignIn] = useState({});
  // const [retriever, setRetriever] = useState(false);
  // const [showInput, setShowInput] = useState({});
  const [isState, setIsState] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [carrierAgentCodeInfo, setCarrierAgentCodeInfo] = useState({
    carrierName: "",
    carrierAgentCode: "",
    carrierAgentCodeStore: "",
    GACodeValue: "",
    GACodeValueStore: "",
    carrierID: "",
    carrierType: "",
  });

  const [assuritySignature, setAssuritySignature] = useState({
    font: { value: "Arial", label: "Arial" },
    fontColor: { value: "Black", label: "Black" },
    fontSize: { value: "Size20", label: "Size20" },
    italic: true,
    signature:
      decryptData(sign_in?.data?.firstName) +
      " " +
      decryptData(sign_in?.data?.lastName),
  });

  const [carriers, setCarriers] = useState([
    {
      stateId: "",
      licenseNumber: "",
    },
  ]);

  // const [managerInfo, setManagerInfo] = useState({
  //   managerFirstName: "",
  //   managerLastName: "",
  //   managerEmail: "",
  // });

  const [allCarriers, setAllCarriers] = useState();
  const [iLifeGACode, setiLifeGACode] = useState({
    GACodeiLife: "",
    isiLifeGACode: false,
  });
  const [onlineCarrierAgnetCodeModel, setOnlineCarrierAgnetCodeModel] =
    useState(false);
  // let showUpgradeText = false;

  const getAllCarriers = async () => {
    let referalCode;
    if (sign_in.data && sign_in.data.referralCode) {
      referalCode = sign_in.data.referralCode;
    }
    if (referalCode != undefined) {
      let response = await commonApiCall(
        API_POINT + "/getCarriersProfile?referralCode=" + referalCode,
        "",
        "get"
      );
      if (response.status === 200) {
        const { finalexpense, medicare, permanent, term } =
          response.data.carriersProfile;
        const onlineApproval = term.filter(
          (item) => item.carrierType === "online"
        );
        const filterTerm = term.filter((item) => item.carrierType !== "online");
        const carrierProfileResult = {
          finalexpense: finalexpense,
          permanent: permanent,
          term: filterTerm,
          termOnlineApproval: onlineApproval,
        };
        const medicareValuePair = { medicare: medicare };

        const carrierOptionsListings = checkAccess(planData, "MEDICARE")
          ? { ...carrierProfileResult, ...medicareValuePair }
          : carrierProfileResult;
        var result = Object.keys(carrierOptionsListings).map((key) => [
          key,
          carrierOptionsListings[key],
        ]);

        setiLifeGACode({
          ...iLifeGACode,
          GACodeiLife: response?.data?.bannerGaCode,
        });

        setAllCarriers(result);
      }
    }
  };

  const getCheckedOrNot = (arr) => {
    let temp = arr.filter((x) => x.isActiveForAgent);
    return temp.length > 0 ? true : false;
  };

  const handleCarrierLabel = (e, val) => {
    setDisabledButton(false);
    setTemporaryState(true);
    let itemName = val;
    let checked = e.target.checked;
    Array.isArray(allCarriers) &&
      allCarriers.length > 0 &&
      allCarriers.map((obj) => {
        if (obj[0] == itemName) {
          Array.isArray(obj[1]) &&
            obj[1].length > 0 &&
            obj[1].map((carrier) => {
              if (val !== "termOnlineApproval") {
                carrier.isActiveForAgent = checked;
                setAllCarriers([...allCarriers]);
                setIsCheckOfflineCarrierChange(true);
              }
            });
        }
      });
  };

  const renderTooltipOfOnlineApproval = (props) => (
    <div id="button-tooltip" {...props}>
      <div className="tooltip-outer">
        {planData?.getFeatureList?.filter(
          (item) => item?.featureSlug === "ONLINE_CARRIER"
        ).length > 0 ? (
          <p>
            Most iLife carriers require you to <br />
            send an e-app link for leads to
            <br />
            complete their applications.
            <br />
            Online approval carriers allow clients
            <br /> to instantly complete carrier
            <br />
            applications through your iLife website
            <br />
          </p>
        ) : (
          <>
            {planData?.newSegment ? (
              <p>Coming soon!</p>
            ) : (
              <p>
                <a
                  className="text-white mt-2 d-inline-block"
                  href="/my-subscriptions"
                >
                  <span>Upgrade</span>
                </a>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );

  const carrierListings = () => {
    if (allCarriers) {
      return allCarriers.map((item, index) => {
        return (
          <div
            className={
              checkAccess(planData, "MEDICARE")
                ? "col-md-3 carrierListings"
                : "col-md-3"
            }
            key={index}
          >
            <ul className="productist pl-0" key={index}>
              <li>
                <div className="custom-checkbox green-border-checkbox with-icon">
                  <label className={`${item[0] === "termOnlineApproval" ? "pl-0" : null}`}>
                    {item[0] !== "termOnlineApproval" && (
                      <input
                        type="checkbox"
                        disabled={
                          getBasePlan(signIn.role).isAmbassador ||
                            (!checkAccess(planData, "ONLINE_CARRIER") &&
                              item[0] == "termOnlineApproval")
                            ? true
                            : false
                        }
                        checked={getCheckedOrNot(item[1])}
                        onChange={(e) => handleCarrierLabel(e, item[0])}
                      />
                    )}
                    <span></span>
                    {item[0] == "finalexpense"
                      ? "Final Expense"
                      : item[0] == "termOnlineApproval"
                        ? "Term Online Approval"
                        : item[0].charAt(0).toUpperCase() + item[0].substring(1)}

                    {item[0] == "termOnlineApproval" && (
                      <div className="tooltip-btn text-right checkbox-icon d-inline-flex position-static ml-2">
                        <OverlayTrigger
                          placement="bottom-start"
                          delay={{ show: 250, hide: 1000 }}
                          overlay={renderTooltipOfOnlineApproval}
                        >
                          <span className="material-icons">help_outline</span>
                        </OverlayTrigger>
                      </div>
                    )}
                  </label>
                </div>
                <ul className="sublist pl-0">
                  {item[1]
                    .sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1))
                    .map((carrier, j) => {
                      return (
                        <li key={j}>
                          <div className="custom-checkbox green-border-checkbox">
                            <label className="mr-1">
                              <input
                                disabled={
                                  getBasePlan(signIn.role).isAmbassador ||
                                    carrier.isUpgread ||
                                    (carrier.carrierName == "SBLI" &&
                                      (carrier.pendingverified === true ||
                                        carrier.agentCode === undefined ||
                                        carrier.agentCode === "" ||
                                        carrier.agentCode === null))
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleCarrierSelection(
                                    carrier.isActiveForAgent,
                                    carrier.carrierId,
                                    item[0],
                                    carrier.carrierName,
                                    carrier.agentCodeRequired,
                                    carrier?.agentCode,
                                    carrier?.gaCode
                                  )
                                }
                                type="checkbox"
                                checked={carrier.isActiveForAgent}
                              />
                              <span></span>
                              {carrier.carrierName}
                            </label>
                            {(carrier.agentCodeRequired == true &&
                              carrier?.agentCode &&
                              carrier?.carrierName !== "SBLI") ||
                              (carrier?.gaCode &&
                                carrier?.carrierName !== "SBLI") ? (
                              <>
                                {/* -{" "}
                                <span className="font-14">
                                  {carrier?.agentCode}
                                </span>
                                <span className="font-14">
                                  {carrier?.gaCode && <>/{carrier?.gaCode}</>}
                                </span> */}
                                <span
                                  className="material-icons d-inline-block ml-1 banner-code-edit"
                                  onClick={() =>
                                    agentCodeRequiredCarrier(
                                      carrier.carrierName,
                                      carrier?.agentCode,
                                      carrier.carrierId,
                                      item[0],
                                      carrier?.gaCode
                                    )
                                  }
                                  style={{ fontSize: "20px" }}
                                >
                                  edit
                                </span>
                              </>
                            ) : null}
                            {carrier?.carrierName == "SBLI" &&
                              carrier?.pendingverified == false &&
                              carrier?.isUpgread == false &&
                              carrier?.isActiveForAgent == false &&
                              carrier?.agentCode === undefined ? (
                              <Link
                                to="/sbli-appointment-form"
                                className="text-decoration-underline font-weight-bold"
                                onClick={() =>
                                  CarrierAppointmentData([carrier?.carrierId])
                                }
                              >
                                Get appointed
                              </Link>
                            ) : carrier?.carrierName == "SBLI" &&
                              carrier?.pendingverified == true &&
                              carrier?.isUpgread == false &&
                              carrier?.isActiveForAgent == false &&
                              (carrier?.agentCode === undefined ||
                                carrier?.agentCode === "" ||
                                carrier?.agentCode === null) ? (
                              <Link
                                to="#"
                                className="font-weight-bold"
                                style={{ pointerEvents: "none" }}
                              >
                                verifying
                              </Link>
                            ) : null}
                            {/* {carrier.isUpgread ? (
                              <>
                                <span className="tooltip-btn">
                                  <OverlayTrigger
                                    placement="bottom-start"
                                    trigger={["hover", "focus"]}
                                    delay={{ show: 250, hide: 2000 }}
                                    overlay={renderTooltipOfUpgrade(
                                      carrier.carrierName
                                    )}
                                  >
                                    <img
                                      src="/assets/images/upgradestart.svg"
                                      width="10"
                                      height="10"
                                      className="upgrade-start"
                                      alt=""
                                    />
                                  </OverlayTrigger>
                                </span>
                                {(showUpgradeText = true)}
                              </>
                            ) : carrier.agentCodeRequired ? (
                              <>
                                <span className="tooltip-btn">
                                  <img
                                    src="/assets/images/upgradestart.svg"
                                    width="10"
                                    height="10"
                                    className="upgrade-start"
                                    alt=""
                                  />
                                </span>
                                {(showUpgradeText = true)}{" "}
                              </>
                            ) : null} */}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </li>
            </ul>
          </div>
        );
      });
    }
  };

  useEffect(() => {
    const token = getJwt();
    if (token) {
      getProfileDetails();
    } else {
      props.history.push(BasicsPagesRoutes.LOGIN);
    }
    if (sign_in && sign_in.data) {
      const signIn = sign_in.data;
      setSignIn(signIn);
    }
    if (!isEmpty(profile_pic)) {
      let profilePhoto = profile_pic.profilePic;
      setFormData({ ...formData, photo: profilePhoto });
    }
    getAllCarriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sign_in]);

  UseInterval(
    () => {
      setFormData({ ...formData, counter: formData.counter - 1 });
    },
    formData.counter > 0 ? 1000 : null
  );

  const handleSignature = (event) => {
    if (event.target.name === "italic") {
      setAssuritySignature({
        ...assuritySignature,
        italic: event.target.checked ? "true" : "false",
      });
    } else {
      setAssuritySignature({
        ...assuritySignature,
        [event.target.name]: event.target.value,
      });
    }
  };

  const getProfileDetails = async () => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT + "/getUsersProfile",
      "",
      "get",
      null,
      true
    );

    if (response.data.status.code === 200) {
      let {
        id,
        firstName,
        lastName,
        email,
        countryCode,
        mobile,
        managerCode,
        profilePic,
        hideGetAppointedSection,
        pauseLead,
        retreaverId,
        showParentAgencyCode,
        font,
        fontColor,
        fontSize,
        italic,
        timeZone
      } = response.data.data;
      setFormData({
        ...formData,
        firstName: firstName ? await decryptData(firstName) : "",
        lastName: lastName ? await decryptData(lastName) : "",
        email: email ? await decryptData(email) : "",
        mobile: mobile ? await decryptData(mobile) : "",
        countryCode: countryCode ? await decryptData(countryCode) : "",
        userId: id,
        timeZone: timeZone ? timeZone : null,
        managerCode: managerCode ? managerCode : "",
        photo: profilePic,
        hideGetAppointedSection: hideGetAppointedSection
          ? hideGetAppointedSection
          : false,
        retreaverId: retreaverId,
        showParentAgencyCode: showParentAgencyCode,
      });

      setAssuritySignature({
        ...assuritySignature,
        font: font
          ? { value: font, label: font }
          : { value: "Default", label: "Default" },
        fontColor: fontColor
          ? { value: fontColor, label: fontColor }
          : { value: "Black", label: "Black" },
        fontSize: fontSize
          ? { value: fontSize, label: fontSize }
          : { value: "Size16", label: "Size16" },
        italic: italic ? italic : "true",
      });
      // setRetriever(pauseLead);
      setIsUpdating(false);
      setPausedLead(pauseLead);
      sethideGetAppointedSectionEnterprise(
        response.data.data.hideGetAppointedSection
      );
      response.data.data.carriers &&
        response.data.data.carriers.length &&
        response.data.data.carriers.map((obj, key) => {
          var t = {};
          t.value = obj.state.stateId;
          t.label = obj.state.stateName;
          obj.state = t;
          return null;
        });

      if (response.data.data.carriers && response.data.data.carriers.length) {
        setCarriers([...response.data.data.carriers]);
      }
    }
  };

  const validateForm = (specificField = "") => {
    const { firstName, lastName, email, mobile } = formData;

    let errors = {};
    if (specificField !== "Mobile" || specificField !== "Email") {
      if (formData.hasOwnProperty("firstName")) {
        if (isEmpty(firstName))
          errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
        else if (!validateName(firstName))
          errors.firstName = errorMessages.PROVIDE_VALID_FIRST_NAME;
        else delete errors.firstName;
      }
      if (formData.hasOwnProperty("lastName")) {
        if (isEmpty(lastName))
          errors.lastName = errorMessages.PROVIDE_LAST_NAME;
        else if (!validateName(lastName))
          errors.lastName = errorMessages.PROVIDE_VALID_LAST_NAME;
        else delete errors.lastName;
      }
      if (formData.hasOwnProperty("email")) {
        if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!validateEmail(email))
          errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else delete errors.email;
      }
      if (formData.hasOwnProperty("mobile")) {
        if (isEmpty(mobile))
          errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
        else if (
          !validateMobileNumber(mobile) &&
          mobile.toString().length !== 10
        )
          errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        else delete errors.mobile;
      }
    } else if (`${specificField}` === "Email") {
      if (formData.hasOwnProperty("email")) {
        if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!validateEmail(email))
          errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else delete errors.email;
      }
    } else if (`${specificField}` === "Mobile") {
      if (formData.hasOwnProperty("mobile")) {
        if (isEmpty(mobile))
          errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
        else if (
          !validateMobileNumber(mobile) &&
          mobile.toString().length !== 10
        )
          errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        else delete errors.mobile;
      }
    }
    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isFormValid;
  };

  const validateStateCarriers = () => {
    let isFormValid = true;
    for (let i = 0; i < carriers.length; i++) {
      if (!isState && carriers[i].stateId === "") {
        isFormValid = false;
        showErrorToast(errorMessages.PROVIDE_STATEID);
      }
      if (
        isState &&
        carriers[i].stateId &&
        Object.keys(carriers[i].stateId).length === 0
      ) {
        isFormValid = false;
        showErrorToast(errorMessages.PROVIDE_STATEID);
        setIsState(true);
      } else {
        if (
          isState &&
          carriers[i].stateId &&
          Object.keys(carriers[i].stateId).length
        ) {
          setIsState(false);
        }
      }
    }

    return isFormValid;
  };

  const submitAmbassadorProfile = async (e, forceChange = false) => {
    e.preventDefault();
    if (validateForm("Ambassador")) {
      let { userId, firstName, lastName, managerCode, timeZone } = formData;
      setIsUpdating(true);
      if (!managerCode || managerCode !== sign_in.referralCode) {
        let body = {
          id: userId,
          firstName: await encryptData(firstName),
          timeZone: timeZone,
          lastName: await encryptData(lastName),
          managerCode: !getBasePlan(signIn?.role).isEnterprise
            ? managerCode
            : "",
        };
        let response = await commonApiCall(
          API_POINT + "/saveUserAgentProfile",
          body,
          "put",
          null,
          true
        );
        if (response.status === 200 || response.data.status.code === 200) {
          let reduxStore = {
            profilePic: photo,
            firstName: await encryptData(formData.firstName),
          };
          await profilePicture(reduxStore);
          await setProfilePending("");
          showSuccessToast(errorMessages.PROFILE_UPDATE_SUCCESS);
          setiLifeGACode({ ...iLifeGACode, isiLifeGACode: false });
          setIsUpdating(true);
          setDisabledButton(true);
          setTemporaryState(false);
          if (forceChange) {
            childRef.current.getAlert();
          }
        } else {
          showErrorToast(response.data.status.message);
          setIsUpdating(false);
        }
      } else {
        showErrorToast(errorMessages.USE_DIFF_MANAGER_CODE);
        setIsUpdating(false);
      }
    }
  };

  const submitEnterpriseProfessionalProfile = async (
    e,
    val,
    key,
    forceChange = false
  ) => {
    e.preventDefault();
    if (validateForm() && validateStateCarriers()) {
      let pauseLeadVal = pauseLead;
      let hideGetAppointedSectionVal = hideGetAppointedSection;
      if (key === "pauseLead") {
        pauseLeadVal = val;
      } else if (key === "hideGetAppointedSection") {
        hideGetAppointedSectionVal = val;
      }
      setIsUpdating(true);
      let { userId, firstName, lastName, managerCode, timeZone } = formData;
      if (!managerCode || managerCode !== signIn.referralCode) {
        let body = {
          id: userId,
          firstName: await encryptData(firstName),
          lastName: await encryptData(lastName),
          timeZone: timeZone,
          managerCode: managerCode,
          pauseLead: pauseLeadVal,
          hideGetAppointedSection: hideGetAppointedSectionVal,
        };
        let disabelCarrierArray = [];
        let enableCarrierPayload = [];
        let agentCodeList = [];
        Array.isArray(allCarriers) &&
          allCarriers.length > 0 &&
          allCarriers.map((obj) => {
            Array.isArray(obj[1]) &&
              obj[1].length > 0 &&
              obj[1].map((carrier) => {
                if (carrier.isActiveForAgent) {
                  enableCarrierPayload.push({
                    carrersId: carrier.carrierId.toString(),
                  });
                }
                if (!carrier.isActiveForAgent) {
                  disabelCarrierArray.push({
                    carrersId: carrier.carrierId,
                    category: carrier.category,
                  });
                }
                if (carrier.agentCodeRequired) {
                  agentCodeList.push({
                    carrersId: carrier.carrierId,
                    agentCode: carrier.agentCode,
                    gaCode: carrier.gaCode,
                  });
                }
              });
          });
        let carrierPayload = [];
        carriers &&
          carriers.length &&
          carriers.map((obj) => {
            if (obj.stateId !== "") {
              var arr_c = {};
              arr_c["carriersList"] = [];
              if (obj.id !== undefined) {
                arr_c.id = obj.id ? obj.id : null;
              }
              arr_c.licenseNumber = obj.licenseNumber
                ? obj.licenseNumber
                : null;
              arr_c.stateId =
                obj.state && obj.state.value ? obj.state.value : null;
              obj.carriersList && obj.carriersList.length
                ? enableCarrierPayload.map((obj1) => {
                  var arr_CL = {};
                  arr_CL.carrersId = obj1.carrersId;
                  if (obj.carriersList && obj.carriersList.length) {
                    for (let i = 0; i < obj.carriersList.length; i++) {
                      if (
                        obj1.carrersId.includes(
                          obj.carriersList[i].carrier.carrersId.toString()
                        )
                      ) {
                        if (
                          obj.carriersList[i].id &&
                          obj.carriersList[i].id !== undefined
                        ) {
                          arr_CL.id = obj.carriersList[i].id;
                        }
                      }
                    }
                  }
                  if (arr_CL.carrersId) {
                    arr_c["carriersList"].push(arr_CL);
                  }
                })
                : (arr_c["carriersList"] = enableCarrierPayload);
              carrierPayload.push(arr_c);
            }
          });
        body["carriers"] = carrierPayload;
        body["carriersList"] = carrierPayload[0].carriersList;
        body["disabledCarriers"] = disabelCarrierArray;
        body["agentCodeList"] = agentCodeList;
        carrierPayload.forEach((item) => {
          delete item.carriersList;
        });

        let assuritySignaturePayload = {};
        assuritySignaturePayload.font = assuritySignature.font?.value;
        assuritySignaturePayload.fontColor = assuritySignature.fontColor?.value;
        assuritySignaturePayload.fontSize = assuritySignature.fontSize?.value;
        assuritySignaturePayload.signature = assuritySignature.signature;
        assuritySignaturePayload.italic = assuritySignature.italic;
        body.assuritySignature = assuritySignaturePayload;
        let response = await commonApiCall(
          API_POINT + "/saveUserAgentProfile",
          body,
          "put",
          null,
          true
        );
        if (response.status === 200 && response.data.status.code === 200) {
          // setRetriever(body?.pauseLead);
          setOnlineCarrierAgnetCodeModel(false);
          let reduxStore = {
            profilePic: photo,
            firstName: await encryptData(formData.firstName),
          };
          await profilePicture(reduxStore);
          await setProfilePending("");
          showSuccessToast("Carrier Options successfully updated!");
          getProfileDetails();
          getAllCarriers();
          setIsUpdating(false);
          setDisabledButton(true);
          setTemporaryState(false);

          if (forceChange) {
            childRef.current.getAlert();
          }
        } else if (
          response.status === 400 &&
          response.data.status.message.includes("Manager Code")
        ) {
          setIsUpdating(false);
          showErrorToast(errorMessages.CANNOT_ADD_AMBASSADOR_MANAGER);
        } else {
          setIsUpdating(false);
          showErrorToast(response.data.status.message);
        }
      } else {
        setIsUpdating(false);
        showErrorToast(errorMessages.USE_DIFF_MANAGER_CODE);
      }
    }
  };
  const agentCodeRequiredCarrier = (name, code, id, type, gaCode) => {
    setOnlineCarrierAgnetCodeModel(true);
    setCarrierAgentCodeInfo({
      ...carrierAgentCodeInfo,
      carrierName: name,
      carrierAgentCode: code,
      carrierAgentCodeStore: code,
      GACodeValue: gaCode,
      GACodeValueStore: gaCode,
      carrierID: id,
      carrierType: type,
    });
    const { GACodeiLife } = iLifeGACode;
    if (gaCode == GACodeiLife) {
      setiLifeGACode({ ...iLifeGACode, isiLifeGACode: true });
    }
  };

  const handleCarrierAgnetCode = (e, type, id, field) => {
    for (let i = 0, len = allCarriers.length; i < len; i++) {
      if (allCarriers[i][0] == type) {
        for (let j = 0; j < allCarriers[i][1].length; j++) {
          if (allCarriers[i][1][j]["carrierId"] == id && field == "agentCode") {
            allCarriers[i][1][j]["agentCode"] = e.target.value;
            setAllCarriers([...allCarriers]);
            break;
          }
          if (allCarriers[i][1][j]["carrierId"] == id && field == "gaCode") {
            allCarriers[i][1][j]["gaCode"] = e.target.value;
            setAllCarriers([...allCarriers]);
            setCarrierAgentCodeInfo({
              ...carrierAgentCodeInfo,
              GACodeValue: e.target.value,
            });
            break;
          }
        }
      }
    }
  };

  const handleDefaultGACode = (e, type, id, iLifeCode, otherGACode) => {
    const { checked } = e.target;
    for (let i = 0, len = allCarriers.length; i < len; i++) {
      if (allCarriers[i][0] == type) {
        for (let j = 0; j < allCarriers[i][1].length; j++) {
          if (checked === true) {
            if (allCarriers[i][1][j]["carrierId"] == id) {
              allCarriers[i][1][j]["gaCode"] = iLifeCode;
              setAllCarriers([...allCarriers]);
              setCarrierAgentCodeInfo({
                ...carrierAgentCodeInfo,
                GACodeValue: iLifeCode,
              });
              setiLifeGACode({ ...iLifeGACode, isiLifeGACode: true });
              break;
            }
          } else {
            if (checked === false) {
              if (allCarriers[i][1][j]["carrierId"] == id) {
                allCarriers[i][1][j]["gaCode"] =
                  otherGACode && otherGACode != iLifeCode ? otherGACode : "";
                setAllCarriers([...allCarriers]);
                setCarrierAgentCodeInfo({
                  ...carrierAgentCodeInfo,
                  GACodeValue:
                    otherGACode && otherGACode != iLifeCode ? otherGACode : "",
                });
                setiLifeGACode({ ...iLifeGACode, isiLifeGACode: false });
                break;
              }
            }
          }
        }
      }
    }
  };

  const handleCarrierSelection = (
    isChecked,
    id,
    type,
    name,
    agentCodeRequired,
    code,
    gaCode
  ) => {
    setDisabledButton(false);
    setTemporaryState(true);
    let isSelected = !isChecked;
    if (isSelected && agentCodeRequired == true && name !== "SBLI") {
      agentCodeRequiredCarrier(name, code, id, type, gaCode);
    }
    if (type !== "termOnlineApproval") {
      setIsCheckOfflineCarrierChange(true);
    }
    for (let i = 0, len = allCarriers.length; i < len; i++) {
      if (allCarriers[i][0] == type) {
        for (let j = 0; j < allCarriers[i][1].length; j++) {
          if (allCarriers[i][1][j]["carrierId"] == id) {
            allCarriers[i][1][j]["isActiveForAgent"] =
              !allCarriers[i][1][j]["isActiveForAgent"];
            setAllCarriers([...allCarriers]);
            break;
          }
        }
      }
    }
  };

  const onHideAgnetCodeModel = (action, type, id) => {
    const { carrierAgentCodeStore, GACodeValueStore } = carrierAgentCodeInfo;
    setTemporaryState(true);
    setDisabledButton(false);
    Array.isArray(allCarriers) &&
      allCarriers.length > 0 &&
      allCarriers.map((obj) => {
        if (obj[0] == type) {
          Array.isArray(obj[1]) &&
            obj[1].length > 0 &&
            obj[1].map((carrier) => {
              if (action === "submit") {
                if (
                  carrier.carrierId == id &&
                  carrier.agentCodeRequired == true &&
                  carrier.carrierName === "Assurity" &&
                  (!carrier.hasOwnProperty("agentCode") ||
                    carrier.agentCode == "" ||
                    !carrier.agentCode)
                ) {
                  carrier.isActiveForAgent = false;
                  setAllCarriers([...allCarriers]);
                  setOnlineCarrierAgnetCodeModel(false);
                } else if (
                  carrier.carrierId == id &&
                  carrier.agentCodeRequired == true &&
                  carrier.carrierName !== "Assurity" &&
                  ((!carrier.hasOwnProperty("agentCode") &&
                    !carrier.hasOwnProperty("gaCode")) ||
                    (carrier.agentCode == "" && carrier.gaCode == "") ||
                    (!carrier.agentCode && !carrier.gaCode))
                ) {
                  carrier.isActiveForAgent = false;
                  setAllCarriers([...allCarriers]);
                  setOnlineCarrierAgnetCodeModel(false);
                } else if (
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName !== "Assurity" &&
                    carrier.hasOwnProperty("agentCode") &&
                    carrier.agentCode != "" &&
                    carrier.hasOwnProperty("gaCode") &&
                    carrier.gaCode != "") ||
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName === "Assurity" &&
                    carrier.hasOwnProperty("agentCode") &&
                    carrier.agentCode != "")
                ) {
                  carrier.isActiveForAgent = true;
                  setAllCarriers([...allCarriers]);
                  setOnlineCarrierAgnetCodeModel(false);
                } else if (
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName !== "Assurity" &&
                    carrier.hasOwnProperty("gaCode") &&
                    carrier.gaCode != "" &&
                    !carrier.hasOwnProperty("agentCode")) ||
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName !== "Assurity" &&
                    !carrier.hasOwnProperty("gaCode") &&
                    carrier.hasOwnProperty("agentCode") &&
                    carrier.agentCode != "") ||
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName !== "Assurity" &&
                    carrier.hasOwnProperty("gaCode") &&
                    carrier.hasOwnProperty("agentCode") &&
                    ((carrier.gaCode == "" && carrier.agentCode != "") ||
                      (carrier.gaCode != "" && carrier.agentCode == "")))
                ) {
                  setOnlineCarrierAgnetCodeModel(true);
                  showErrorToast("Please enter both codes");
                }
              } else {
                if (action === "close") {
                  if (
                    type === "termOnlineApproval" &&
                    !iSCheckOfflineCarrierChange
                  ) {
                    setDisabledButton(true);
                    setTemporaryState(false);
                  }
                  if (
                    carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName == "Assurity" &&
                    (!carrier.hasOwnProperty("agentCode") ||
                      !carrierAgentCodeStore)
                  ) {
                    carrier.isActiveForAgent = false;
                    carrier.agentCode = "";
                    setAllCarriers([...allCarriers]);
                  } else if (
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrier.carrierName == "Assurity" &&
                      carrierAgentCodeStore) ||
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrier.carrierName == "Assurity" &&
                      carrier.agentCode == "")
                  ) {
                    carrier.agentCode = carrierAgentCodeStore;
                    setAllCarriers([...allCarriers]);
                  } else if (
                    carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.carrierName !== "Assurity" &&
                    ((!carrier.hasOwnProperty("agentCode") &&
                      !carrier.hasOwnProperty("gaCode")) ||
                      !carrierAgentCodeStore ||
                      !GACodeValueStore)
                  ) {
                    carrier.isActiveForAgent = false;
                    carrier.agentCode = "";
                    carrier.gaCode = "";
                    setAllCarriers([...allCarriers]);
                  } else if (
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrier.carrierName !== "Assurity" &&
                      carrierAgentCodeStore &&
                      GACodeValueStore) ||
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrier.carrierName !== "Assurity" &&
                      carrierAgentCodeStore &&
                      GACodeValueStore &&
                      (carrier.agentCode == "" || carrier.gaCode == ""))
                  ) {
                    carrier.agentCode = carrierAgentCodeStore;
                    carrier.gaCode = GACodeValueStore;
                    setAllCarriers([...allCarriers]);
                  }
                }
                setOnlineCarrierAgnetCodeModel(false);
              }
            });
        }
      });
  };
  const handleModel = (isHide) => {
    setConfirmModel(isHide);
  };
  const onSuccess = () => {
    handleModel(false);
  };

  const getAppointedWithCarrier = async (action, type, id) => {
    await setTemporaryState(false);
    setIsGettingAppointed(true);
    let body = {
      carrierId: id.toString().split(","),
    };
    let response = await commonApiCall(
      API_POINT + "/carrierAppointment",
      body,
      "post",
      null,
      true
    );

    if (response?.data?.status?.code === 200) {
      setIsGettingAppointed(false);
      handleModel(true);
      setIsUpdating(false);
    }
  };

  let { errors, photo, hideGetAppointedSection } = formData;

  const {
    carrierName,
    carrierAgentCode,
    GACodeValue,
    GACodeValueStore,
    carrierID,
    carrierType,
  } = carrierAgentCodeInfo;

  const { GACodeiLife, isiLifeGACode } = iLifeGACode;

  return (
    <>
      <div className="row">
        <div className="col-lg-12 my-profile-page">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            {isUpdating ? (
              <div style={{ height: "100vh" }}>
                <LeafLoader />
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-between flex-column flex-md-row w-100">
                  <div className="text-align-center mt-2">
                    <h3 className="text-left font-24 mb-1">Quoting Options</h3>
                    <h5 className="title-text">
                      Select the policies and carriers displayed in the browsing link and Quoting Tool. <b>*This section does not apply to live transfer leads.</b>
                    </h5>
                  </div>
                  <div className="text-right">
                    {disabledButton ? (
                      <button
                        className="btn btn-disable disable"
                        disabled={disabledButton ? true : false}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-gradient"
                        disabled={disabledButton ? true : false}
                        onClick={(e) =>
                          getBasePlan(signIn.role).isAmbassador
                            ? submitAmbassadorProfile(e)
                            : submitEnterpriseProfessionalProfile(
                              e,
                              pauseLead,
                              true
                            )
                        }
                      >
                        <span className="align-middle d-inline-block">
                          {" "}
                          {isUpdating ? "Updating..." : "Save"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <hr className="light-thinborder w-100 mt-0" />
                <React.Fragment>
                  <div className="row">
                    <>{carrierListings()}</>
                  </div>
                  {!hideGetAppointedSectionEnterprise &&
                    checkAccess(planData, "GET_APPOINTMENT_") && (
                      <div className={"appointment-with-carriers"}>
                        <p
                          className="leadModelText mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          See a carrier you're not appointed with yet? Let iLife
                          help you get appointed.*
                        </p>
                        <Link
                          to={DashboardPagesRoutes.SELECT_APPOINTMENTS}
                          className="update"
                        >
                          <button
                            className="btn btn-yellow get-appointed-btn"
                            type="submit"
                          >
                            Get appointed{"  "}
                          </button>
                        </Link>
                        <hr className="light-thinborder d-block w-100 mt-0" />{" "}
                        <p className="leadModelText font-14">
                          *Although we are happy to provide referrals for
                          appointment providers, they are in no way affiliated
                          with iLife, and usage of any specific appointment BGA
                          is not required.
                        </p>
                      </div>
                    )}
                </React.Fragment>
                {signIn?.currentPlanSlug?.toLowerCase() === "enterprise" ||
                  (!getBasePlan(signIn.role).isAmbassador &&
                    !hideGetAppointedSectionEnterprise)}
              </div>
            )}
          </Animated>
        </div>
      </div>
      <Modal
        className="cancel-popup"
        show={onlineCarrierAgnetCodeModel}
        onHide={() => onHideAgnetCodeModel("close", carrierType, carrierID)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        size="lg"
        dialogClassName="agent-code-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="pr-4 mw-100">
            {isGettingAppointed && (
              <div className="text-align-center">
                <LeafLoader />
              </div>
            )}
            <h3 className="mb-md-4 mb-2">
              <strong>{carrierName} Agent Code</strong>
            </h3>
            <p>
              {carrierName} is a seamless integration product. This means that
              when your prospect completes their pre-form they will
              automatically receive an invitation from {carrierName} to complete
              their application. You will still receive their contact
              information and status updates.
            </p>
            <h4 className="mb-md-4 mb-2">
              <strong className="txt-black">
                Please enter your {carrierName} agent code.
              </strong>
            </h4>
            <div className="form-group col-md-8 p-0">
              <div className="input-effect">
                <input
                  className="form-control"
                  placeholder="Banner Life Agent Code"
                  name="agentCode"
                  id="agentCode"
                  type="text"
                  maxLength="10"
                  minLength="7"
                  defaultValue={carrierAgentCode}
                  onChange={(e) =>
                    handleCarrierAgnetCode(
                      e,
                      carrierType,
                      carrierID,
                      "agentCode"
                    )
                  }
                />
                <label htmlFor="firstName">{carrierName} Agent Code</label>
                <span className="text-danger">{errors.agentCode}</span>
              </div>
            </div>

            {carrierName !== "Assurity" && (
              <div>
                <h4 className="mb-md-2 mb-2">
                  <strong className="txt-black">
                    Please enter your {carrierName} GA code.
                  </strong>
                </h4>
                <div className="custom-checkbox">
                  <label style={{ color: "#333", fontSize: "1em" }}>
                    <input
                      type="checkbox"
                      id="defaultIlifeGaCode"
                      checked={GACodeValue && isiLifeGACode}
                      onChange={(e) =>
                        handleDefaultGACode(
                          e,
                          carrierType,
                          carrierID,
                          GACodeiLife,
                          GACodeValueStore
                        )
                      }
                    />
                    <span></span>I am appointed with {carrierName} through
                    iLIfe.
                  </label>
                </div>
                <div className="form-group col-md-8 p-0 mt-2">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder="Banner Life GA Code"
                      name="gaCode"
                      id="gaCode"
                      type="text"
                      disabled={GACodeValue && isiLifeGACode}
                      value={GACodeValue}
                      onChange={(e) =>
                        handleCarrierAgnetCode(
                          e,
                          carrierType,
                          carrierID,
                          "gaCode"
                        )
                      }
                    />
                    <label htmlFor="firstName">{carrierName} GA Code</label>
                  </div>
                </div>
              </div>
            )}
            {carrierName === "Assurity" && (
              <div className="mb-4">
                <h4 className="mb-md-2 mb-2">
                  <strong className="txt-black">
                    Signing documents electronically is as easy as typing your
                    name below.for Assurity. The signature will be automatically
                    applied to your Assurity applications through iLife.
                  </strong>
                </h4>

                <div className="form-group col-md-12 p-0 mt-2">
                  <div className="input-effect">
                    <input
                      className="form-control"
                      placeholder="Agent Signature"
                      name="signature"
                      id="signature"
                      type="text"
                      readOnly
                      value={assuritySignature.signature}
                      style={{
                        opacity: 1,
                        fontFamily: assuritySignature.font?.value,
                        color: assuritySignature.fontColor?.value,
                        fontSize: assuritySignature.fontSize
                          ? parseInt(
                            assuritySignature.fontSize?.value?.split(
                              "Size"
                            )[1]
                          )
                          : 10,
                        fontStyle:
                          assuritySignature.italic === "true" ? "italic" : "",
                      }}
                      onChange={handleSignature}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 p-0 mt-2">
                  <div className="row align-items-end">
                    <div className="col-md-3">
                      <label className="clabel ">Signature Font</label>
                      <Select
                        value={assuritySignature.font}
                        onChange={(value) =>
                          handleSignature({
                            target: { name: "font", value: value },
                          })
                        }
                        options={signatureFontList.map((font) => ({
                          value: font,
                          label: font,
                        }))}
                        isSearchable={false}
                        placeholder="Select Font Type"
                        className="custom-select-menu"
                        classNamePrefix="custom-select"
                        defaultMenuIsOpen={false}
                      />
                    </div>

                    <div className="col-md-3">
                      <label className="clabel ">Signature Font Color</label>
                      <Select
                        value={assuritySignature.fontColor}
                        onChange={(value) =>
                          handleSignature({
                            target: { name: "fontColor", value: value },
                          })
                        }
                        options={fontColorList.map((font) => ({
                          value: font,
                          label: font,
                        }))}
                        isSearchable={false}
                        placeholder="Select Font Type"
                        className="custom-select-menu"
                        classNamePrefix="custom-select"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="clabel ">Signature Font Size</label>
                      <Select
                        value={assuritySignature.fontSize}
                        onChange={(value) =>
                          handleSignature({
                            target: { name: "fontSize", value: value },
                          })
                        }
                        options={fontSizeList.map((font) => ({
                          value: font,
                          label: font,
                        }))}
                        isSearchable={false}
                        placeholder="Select Font Type"
                        className="custom-select-menu"
                        classNamePrefix="custom-select"
                        defaultMenuIsOpen={false}
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="custom-checkbox green-border-checkbox with-icon">
                        <label>
                          <input
                            type="checkbox"
                            name="italic"
                            checked={assuritySignature.italic === "true"}
                            onChange={handleSignature}
                          />
                          <span></span>
                          Italic
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
            {(carrierName === "Assurity" &&
              !carrierAgentCode &&
              !hideGetAppointedSectionEnterprise) ||
              (carrierName === "Banner Life" &&
                !hideGetAppointedSectionEnterprise) ? (
              <>
                <p
                  className={`text-decoration-underline font-weight-bold text-green cursor-pointer`}
                  onClick={() =>
                    getAppointedWithCarrier("close", carrierType, carrierID)
                  }
                >
                  Get appointed With {carrierName}
                </p>
                <p className="font-14">
                  *Need an agent code to use {carrierName}? Let iLife help you
                  to get appointed.
                </p>{" "}
              </>
            ) : null}

            <div className="d-flex ">
              <button
                className="btn btn-link text-primary p-0 text-right ml-auto font-weight-bold"
                onClick={() =>
                  onHideAgnetCodeModel("submit", carrierType, carrierID)
                }
              >
                SUBMIT
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <IlifePrompt
        when={temporaryState}
        title="Changes not saved"
        subtitle="Are you sure want to navigate away from this page? Your changes have not been saved."
        cancelText="Cancel"
        loading={isUpdating}
        okText="Save Changes"
        onOK={forceChangeDone}
        ref={childRef}
        validateFunc={() => true}
        closeState={setTemporaryState}
        handleOK={(e) =>
          getBasePlan(signIn.role).isAmbassador
            ? submitAmbassadorProfile(e, true)
            : submitEnterpriseProfessionalProfile(e, pauseLead, "", true)
        }
        // handleCancel={() => setForceChange(true)}
        onCancel={() => true}
      />
      {confirmModel && (
        <SuccessModel
          isShow={confirmModel}
          handleModel={() => handleModel()}
          confirmAction={() => onSuccess()}
          bannerSuccessModel={true}
        ></SuccessModel>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    referal_code: state.agentReducer.referalCode,
    profile_pic: state.agentReducer.profile_pic,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};

export default connect(mapStateToProps, {
  commonApiCall,
  updateAgent,
  profilePicture,
  CarrierAppointmentData,
})(withRouter(CarrierOptions));
