import React from "react";

import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import errorMessages from "../../../redux/actions/errorMessages";
import { showSuccessToast } from "../../../utlis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isEmpty } from "lodash";

const InviteAgentModel = ({
  show,
  intialValueOfFied,
  // agentTypeOptions,
  // paymentTypeOptions,
  formData,
  // changePaymentType,
  handleChange,
  inviteAgent,
  isChangeInInviteModel,
}) => {
  const {
    email,
    // remainingProSeats,
    // paymentType,
    agentType,
    inviteLink,
    errors,
  } = formData;
  return (
    <Modal
      className="invite-agent-modal"
      centered
      show={show}
      onHide={() => intialValueOfFied()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invite Agents{" "}
          {/* <button className="btn btn-gradient">
            You have {remainingProSeats} Pro+ Invites
          </button> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="agent-filter-ac-type">
          {/* <div className="filter-item mb-3">
            <h4 className="font-16 font-weight-medium text-light-black">Account Type</h4>
            <Select
              value={agentType}
              options={agentTypeOptions}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "agentType",
                    value: e,
                  },
                })
              }
              className={"custom-select-menu"}
              classNamePrefix={"custom-select"}
            />
            <span className="text-danger">{errors.agentType}</span>
          </div> */}
          {/* <div className="filter-item mb-3">
            <h4 className="font-16 font-weight-medium text-light-black">Payment Method</h4>
            <Select
              value={agentType?.value === "ambassador" ? null : paymentType}
              options={paymentTypeOptions}
              onChange={(e) => {
                changePaymentType(e.value);
                handleChange({
                  target: {
                    name: "paymentType",
                    value: e,
                  },
                });
              }}
              isDisabled={agentType?.value === "ambassador" ? true : false}
              className={"custom-select-menu"}
              classNamePrefix={"custom-select"}
            />
            <span className="text-danger">{errors.paymentType}</span>
          </div> */}
          {agentType?.value !== "ambassador" && (
            <>
              <div className="start-earning-block mt-0">
                <h4 className="font-16 font-weight-medium text-light-black">
                  Invite Link
                </h4>
                <div className="input-group">
                  <div className="earning-copy">{inviteLink}</div>
                  <CopyToClipboard
                    text={inviteLink}
                    onCopy={() => showSuccessToast(errorMessages.LINK_COPIED)}
                  >
                    <div className="input-group-append">
                      <button
                        className="btn d-flex align-items-center px-3 text-primary"
                        type="button"
                      >
                        <span className="material-icons font-14 mr-1">
                          content_copy
                        </span>
                        <strong className="font-14">Copy</strong>
                      </button>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
              {/* <div className="or-text">
                <span className="position-relative">
                  <strong>or</strong>
                </span>
              </div> */}
            </>
          )}
          <div
            className="mt-4 with-placeholder"
            // className={`input-effect with-placeholder ${
            //   agentType?.value !== "ambassador" ? "mt-5 pt-1" : ""
            // }`}
          >
            <h4 className="font-16 font-weight-medium text-light-black">Invite by Email</h4>
            <input
              className="form-control"
              name="email"
              id="email"
              type="mail"
              value={email}
              onChange={(e) => handleChange(e)}
              placeholder="Enter email address, separate by commas"
            ></input>
            <span className="text-danger">{errors.email}</span>
          </div>

          <div
            className="mt-4"
          >
            <h4 className="font-16 font-weight-medium text-light-black">Email Content</h4>
            <div className="email-container">
              <div className="email-subject">
                <p className="py-1 font-14">
                  <strong>Subject:</strong>{formData?.emailContent?.subject}
                </p>
              </div>
              <div className="email-body">
                <p
                  className='mr-2 pb-0 pt-2 font-14'
                  dangerouslySetInnerHTML={{
                    __html: formData?.emailContent?.body,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end pt-0 px-4">
        <button 
          className="btn btn-gradient "
          disabled={isEmpty(email) || isChangeInInviteModel ? true : false}
          variant="primary"
          onClick={() => inviteAgent()}
        >
        Send Invites 
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default InviteAgentModel;
