import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  commonApiCall,
  getBasePlan,
  showErrorToast,
  showSuccessToast,
} from "../../utlis";
import { API_POINT } from "../../config/config";
import Spinner from "react-bootstrap/Spinner";
import IlifePrompt from "../../components/commonComponents/IlifePrompt";
import { LeafLoader } from "../../components/skeletonLoaders/ilifeLogoLoader";
import { Animated } from "react-animated-css";

const LicensedStates = ({ commonApiCall, sign_in, ...props }) => {
  const [states, setStates] = useState([]);

  const [checkedState, setCheckedState] = useState([]);
  const [userData, setUserData] = useState([]);
  const [saveStateLoader, setsaveStateLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [temporaryState, setTemporaryState] = useState(false);
  const [forceChangeDone] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const childRef = useRef();

  useEffect(() => {
    getAllStates();
    getUsersProfile();
  }, []);

  const getAllStates = async () => {
    const response = await commonApiCall(
      API_POINT + "/getState?countryId=" + 1,
      "",
      "get"
    );
    if (response) {
      setStates(response?.data?.data);
    }
  };

  const getUsersProfile = async () => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT + "/getUsersProfile",
      "",
      "get",
      null,
      true
    );
    if (response) {
      setCheckedState(response?.data?.data?.carriers);
      setUserData(response?.data?.data);
      setIsUpdating(false);
    }
  };

  const handleChange = (list) => {
    setDisabledButton(false);
    setTemporaryState(true);

    const selectedCheckbox = checkedState?.findIndex((item) => {
      const stateID = item?.state?.stateId || item?.state?.id;
      return stateID == list?.id;
    });
    if (selectedCheckbox > -1) {
      checkedState.splice(selectedCheckbox, 1);
    } else {
      checkedState.push({ state: list });
    }
    setCheckedState([...checkedState]);
  };

  const saveUsersProfile = async (forceChange = true) => {
    setDisabledButton(true);
    if (checkedState?.length === 0) {
      showErrorToast("Please select state");
    } else {
      setsaveStateLoader(true);
      try {
        let body = {
          id: userData?.id,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          pauseLead: userData?.pauseLead,
          managerCode: !getBasePlan(sign_in?.data.role).isEnterprise
            ? userData?.managerCode
            : "",
          timeZone: userData?.timeZone,
          hideGetAppointedSection: userData?.hideGetAppointedSection,
          carriers: checkedState
            .filter((item) => item?.state?.stateId || item?.state?.id)
            .map((item) => ({
              stateId: parseInt(item?.state?.stateId) || item?.state?.id,
            })),
        };
        let response = await commonApiCall(
          API_POINT + "/saveUserAgentProfile",
          body,
          "put",
          null,
          true
        );
        if (response.status === 200 && response.data.status.code === 200) {
          showSuccessToast("Licensed States successfully updated!");
          setTemporaryState(false);
          if (forceChange) {
            childRef.current.getAlert();
          }
          getUsersProfile();
          setDisabledButton(true);
        }
        setsaveStateLoader(false);
      } catch (error) {
        showErrorToast(error);
        setDisabledButton(false);
      }
    }
  };

  const selectAll = () => {
    setDisabledButton(false);
    setTemporaryState(true);
    let result = checkedState?.filter(
      (item) => item?.state?.stateId || item?.state?.id
    );
    if (states?.length == result.length) {
      setCheckedState([]);
    } else {
      let stateResult = states.map((item) => ({ state: item }));
      setCheckedState([...stateResult]);
    }
  };

  return (
    <>
      <Animated
        animationIn="fadeIn"
        animationOut="faderOutLeft"
        animationInDuration={1200}
        animationOutDuration={800}
        isVisible={true}
      >
        {isUpdating ? (
          <div style={{ height: "100vh" }}>
            <LeafLoader />
          </div>
        ) : (
          <div className="text-center">
            <div>
              <div>
                <div className="d-flex justify-content-between flex-column flex-md-row w-100">
                  <div className="text-align-center mt-2">
                    <h3 className="text-left font-24 mb-1">Licensed States</h3>
                    <h5 className="title-text">
                      Select the states in which you are licensed.
                    </h5>
                  </div>
                  <div className="text-right">
                    {disabledButton ? (
                      <button
                        className="btn btn-disable disable"
                        disabled={disabledButton ? true : false}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-gradient"
                        onClick={() => saveUsersProfile(false)}
                        disabled={disabledButton ? true : false}
                      >
                        {" "}
                        {saveStateLoader ? (
                          <Spinner
                            animation="border"
                            role="status"
                            size="sm"
                            variant="light"
                          >
                            <span className="align-middle d-inline-block"></span>
                          </Spinner>
                        ) : (
                          "Save"
                        )}{" "}
                      </button>
                    )}
                  </div>
                </div>
                <hr className="light-thinborder" />{" "}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="state-selection select-all">
                    <div className="checkbox-block custom-checkbox green-border-checkbox mb-0 bg-white">
                      <label className="mr-4 mb-0 txt-black tooltip-btn">
                        <input
                          type="checkbox"
                          onChange={() => selectAll()}
                          disabled={
                            getBasePlan(sign_in?.data?.role).isAmbassador
                              ? true
                              : false
                          }
                          checked={
                            states?.length === checkedState?.length &&
                            states?.length !== 0 &&
                            checkedState?.length !== 0
                          }
                        />
                        <span></span>
                        <strong
                          onChange={() => selectAll()}
                          className="font-14"
                        >
                          Select All
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="state-selection col-12">
                  {states &&
                    states.length >= 1 &&
                    states.map((list, key) => {
                      const isChecked = checkedState?.find((item) => {
                        const stateID = item?.state?.stateId || item?.state?.id;
                        return stateID == list?.id;
                      });

                      return (
                        <div
                          className="checkbox-block custom-checkbox green-border-checkbox bg-white"
                          key={key}
                        >
                          <label
                            htmlFor={list.value}
                            className="mr-4 mb-0 txt-black tooltip-btn d-block"
                          >
                            <input
                              type="checkbox"
                              checked={
                                isChecked === undefined ? false : isChecked
                              }
                              disabled={
                                getBasePlan(sign_in?.data?.role).isAmbassador
                                  ? true
                                  : false
                              }
                              onChange={() => handleChange(list)}
                            />
                            <span></span>
                            <span className="country-name font-14 txt-light-black w-100">
                              {list.name.replace(
                                "District of Columbia",
                                "D.C."
                              )}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </Animated>
      <IlifePrompt
        when={temporaryState}
        title="Changes not saved"
        subtitle="Are you sure want to navigate away from this page? Your changes have not been saved."
        cancelText="Cancel"
        loading={isUpdating}
        okText="Save Changes"
        onOK={forceChangeDone}
        ref={childRef}
        validateFunc={() => true}
        closeState={setTemporaryState}
        handleOK={(e) => saveUsersProfile(true)}
        // handleCancel={() => setForceChange(true)}
        onCancel={() => true}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
  };
};

export default connect(mapStateToProps, {
  commonApiCall,
})(LicensedStates);
