import React from "react";
import logo from "../assets/images/logo.svg";

export const BasicsPagesRoutes = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  LOGIN_EMAIL: "/loginWithEmail",
  CLIENT:
    "http://ilife-staging-reactclient-ecs-1584822021.us-east-1.elb.amazonaws.com",
};

export const DashboardPagesRoutes = {
  DASHBOARD: "/dashboard",
  CLIENTPIPELINE: "/clientPipeline",
  CLIENT_DETAIL_PAGE: "/client-detail-page",
  FAKE_CLIENT_PAGE: "/demo-client-detail-page",
  CONTACTS: "/contacts",
  TEXT: "/text",
  PAYMENTS: "/payments",
  AVBL_LEADPACKAGES: "/availableleadPackages",
  PAYOUTS: "/payouts",
  WELCOMEPAGE: "/welcomePage",
  PRODUCT_SELECTION: "/product-selection",
  CARRIERS_SELECTION: "/carriers-selection",
  STATE_SELECTION: "/state-selection",
  TIME_ZONE_SELECTION: "/time-zone-selection",
  // MYPROFILE: "/myProfile",
  // NOTIFICATION_SETTINGS: "/notification-settings",
  // LIVELT_SETTINGS: "/live-lt-settings",
  ACCOUNTSETTINGS: "/account-settings/*",
  ACCOUNTSETTINGS_PROFILE: "/account-settings/profile",
  ACCOUNTSETTINGS_LICENSE_STATE:"/account-settings/license-state",
  ACCOUNTSETTINGS_CARRIER_OPTIONS:"/account-settings/carrier-options",
  ACCOUNTSETTINGS_MESSAGES:"/account-settings/messages",
  ACCOUNTSETTINGS_ADMIN_USERS:"/account-settings/admin-users",
  MYSUBSCRIPTIONS: "/my-subscriptions",
  MYPROFILE_PROFESSIONAL: "/myProfile-professional",
  APPLICATIONS: "/applications",
  LEADCONVERSATIONS: "/chat",
  FAQ: "/faq",
  APPLICATIONSBASICDETAILS: "/application/basicDetails",
  APPLICATIONSDOCUMENTS: "/application/documents",
  APPLICATIONSEDITABLEPDF: "/application/editablePdf",
  APPLICATIONPOLICYINFORMATION: "/application/policyInformation",
  APPLICATIONUNDERWRITINGSTEPS: "/application/underwritingSteps",
  APPLICATIONSCONVERSATIONS: "/application/conversations",
  SETTINGDEXPERT: "/settingsExpert",
  BASICDETAILSEXPERT: "/basicDetailExpert",
  POLICYINFORMATIONEXPERT: "/policyInformationExpert",
  CONVERSATIONEXPERT: "/conversationExpert",
  MYREFERENCEDETAILSEXPERT: "/myReferenceDetailExpert",
  MYEARNINGBASICDETAILSEXPERT: "/myEarningBasicDetailsExpert",
  NOTIFICATIONS: "/notifications",
  MANAGERIALVIEW: "/agency/dashboard",
  MYAGENTS: "/managerialview",
  PROSPECTSVIAAFFILIATE: "/myAffiliates",
  AGENTDETAILS: "/agentDetails",
  AGENTREQUESTS: "/agentRequests",
  INVITEAGENT: "/inviteAgent",
  LEADASSIGNMENT: "/assignLeads",
  UNASSIGNLEADS: "/unassignLeads",
  INVITEDAGENTS: "/invitedAgents",
  THEMESETTINGS: "/theme-setting",
  UNDERWRITE_QUESTION: "/underwrite-question",
  SELECT_APPOINTMENTS: "/selectAppointments",
  APPOINTMENT_FORM: "/application-form",
  SBLI_APPOINTMENT_FORM: "/sbli-appointment-form",
  ASSURITY_APPOINTMENT_FORM: "/assurity-appointment-form",
  ILIFESTORE: "/store",
  PURCHASES: "/purchases",
  STORETRANSACTION: "/store-transactions",
  EMAIL: "/email",
  REMINDERS: "/reminder",
  REMINDER_DETAILS: "/reminder",
  CREATE_EMAIL_CAMPAIGN: "/email-campaign",
  CREATE_BLANK_EMAIL_CAMPAIGN: "/create-email-campaign",
  EDIT_EMAIL_TEMPLATE: "/email-template",
  EDIT_TEMPLATE: "/edit-template",
  EDITE_EMAIL_CONTENT: "/edite-email",
  SENT_EAMIL_DETAILS: "/sent-email-preview",
  SENT_EMAIL_DETAILS_WITHOUT_METRICS: "/sent-email-details",
  CLIENT_EMAIL_DETAIL: "/client-email-detail",
  SOCIALMEDIA: "/social-media",
  PRODUCT_BROWSING: "/product-browsing",
  RESOURCE: "/https://help.ilife.tech/en",
  AGENT_CHAT: "/agentsChat",
};

export const RegistrationPagesRoutes = {
  // GETSTART: "/getStart",
  // GETSTART_1: "/getStart-1",
  // GETSTART_2: "/getStart-2",
  // GETSTART_3: "/getStart-3",
  // GETSTART_4: "/getStart-4",
  GETSTART: "/getStart-3",
  GETSTART_1: "/getStart-4",
  // GETSTART_2: "/getStart-2",
  GETSTART_3: "/getStart",
  GETSTART_4: "/financial-question",
  GETSTART_5: "/carrier-license-question",
  BECOMEMEMBER: "/becomeMember",
  CARRIERAPPOINMENTS: "/carrierAppointments",
  // REGISTERSTEPSASPROFESSIONALS: "/registerStepsAsProfessionals",
  SCANDETAILS: "/scanDetails",
  SCANRESULT: "/scanResult",
  LEADQUESTIONS: "/leadQuestions",
  LEADPACKAGE: "/leadPackage",
  PAYMENT: "/payment",
  LEADPACKAGEOFFER: "/leads/purchase",
};

export const MyWebsiteRoutes = {
  HOMEPAGE: "/my-home",
  ABOUTPAGE: "/my-about",
  WEBSETTING: "/website-setting",
  MYWEBSITE: "/my-website",
};

export const CmsPagesRoutes = {
  ABOUTUS: "/aboutUs",
  SERVICE_REPRESENTATIVE: "/service-representative",
  CONTACTUS: "/contactUs",
  NOTFOUND: "/404",
};

export const SettingsRoutes = {
  MYSETTINGS: "/my-subscriptions",
  SUBUSERS: "/sub-users",
  PAYMENTINFORMATION: "/update-payment-information",
};

export const AmbassadorPagesRoutes = {
  MYREFERRALS: "/visitors",
  MYREFERRALSDETAILS: "/myReferrals-details",
};

// ""/assets/images/logo.svg

export const getPageHeader = (url) => {
  if (!sidebarRoutes(url)) {
    return (
      <img
        src={logo}
        alt="iLife logo"
        width={140}
        style={{ minWidth: "80px" }}
      />
    );
  }
  if (url.includes("account-settings")) {
    return "Account settings";
  }
  switch (url) {
    case DashboardPagesRoutes.CLIENTPIPELINE:
      return "Client Pipeline";
    case DashboardPagesRoutes.LEADCONVERSATIONS:
      return "Chat";
    case DashboardPagesRoutes.PRODUCT_BROWSING:
      return "Product Browsing";
    case DashboardPagesRoutes.EMAIL:
      return "Email Campaigns";
    case DashboardPagesRoutes.SOCIALMEDIA:
      return "Social media";
    case DashboardPagesRoutes.ILIFESTORE:
      return "iLife Store";
    case DashboardPagesRoutes.PURCHASES:
      return "Purchases";
    case AmbassadorPagesRoutes.MYREFERRALS:
      return "Visitor Analytics";
    case MyWebsiteRoutes.HOMEPAGE:
      return "Landing Page Design";
    case MyWebsiteRoutes.ABOUTPAGE:
      return "About Page Design";
    case MyWebsiteRoutes.WEBSETTING:
      return "Presentation Links";
    case DashboardPagesRoutes.MANAGERIALVIEW:
      return "Agency Dashboard";
    case DashboardPagesRoutes.MYAGENTS:
      return "My Agents";
    case DashboardPagesRoutes.AGENT_CHAT:
      return "Chat";
    case DashboardPagesRoutes.AGENTDETAILS:
      return "Agent details";
    case DashboardPagesRoutes.LEADASSIGNMENT:
      return "Lead assignment";
    case DashboardPagesRoutes.INVITEDAGENTS:
      return "Grow your team";
    case DashboardPagesRoutes.NOTIFICATIONS:
      return "Notifications";
    case DashboardPagesRoutes.REMINDERS:
      return "Reminders";
    case DashboardPagesRoutes.MYSUBSCRIPTIONS:
      return "My Subscriptions";
    default:
      return "";
  }
};

const hideSidebarRoutes = [
  DashboardPagesRoutes.PAYMENTS,
  RegistrationPagesRoutes.PAYMENT,
  DashboardPagesRoutes.PRODUCT_SELECTION,
  DashboardPagesRoutes.CARRIERS_SELECTION,
  DashboardPagesRoutes.STATE_SELECTION,
  DashboardPagesRoutes.TIME_ZONE_SELECTION,
  RegistrationPagesRoutes.LEADPACKAGEOFFER,
  DashboardPagesRoutes.SELECT_APPOINTMENTS,
  DashboardPagesRoutes.CLIENT_DETAIL_PAGE,
  DashboardPagesRoutes.APPOINTMENT_FORM,
  RegistrationPagesRoutes.LEADPACKAGEOFFER,
  DashboardPagesRoutes.SBLI_APPOINTMENT_FORM,
  DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN,
  DashboardPagesRoutes.CREATE_BLANK_EMAIL_CAMPAIGN,
  DashboardPagesRoutes.SENT_EAMIL_DETAILS,
  DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS,
  DashboardPagesRoutes.CLIENT_EMAIL_DETAIL,
  DashboardPagesRoutes.EDIT_EMAIL_TEMPLATE,
  DashboardPagesRoutes.EDIT_TEMPLATE,
  DashboardPagesRoutes.WELCOMEPAGE,
];

export const sidebarRoutes = (path) => {
  return hideSidebarRoutes.filter((x) => path.includes(x)).length > 0
    ? false
    : true;
};

export const ReminderInfo = {
  "New Live Transfer": "after I receive a new live transfer lead.",
  "New Chat Message": "after I receive a new chat message.",
  Birthday: "before a client’s birthday.",
  "New Lead": "after I receive a new lead from my browsing link.",
  "E-App Incomplete": "after a client doesn’t complete an e-application.",
  "E-App Complete": "after a client completes an e-application.",
  "Follow Up": "if I have not emailed or texted a new client.",
  "Medical Records": "after a client uploads medical records.",
};

export const DEFAULT_TIMEZONE = {
  defaultTimeZone: {
    label: "Eastern Time (U.S & Canada)",
    value: "ET",
  },
};
