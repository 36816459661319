// *************************************************************//
//@Purpose : We can use following function to use localstorage
//*************************************************************//
export default {
  PROVIDE_EMAIL: "Please enter email",
  PROVIDE_VALID_EMAIL: "Please enter valid email format",
  PLEASE_VALIDATE_EMAIL: "Please verify your email",

  PROVIDE_FIRST_NAME: "Please enter first name",
  PROVIDE_VALID_FIRST_NAME: "Please enter valid first name",
  PROVIDE_LAST_NAME: "Please enter last name",
  PROVIDE_VALID_LAST_NAME: "Please enter valid last name",

  PROVIDE_MOBILE_NUMBER: "Please enter mobile phone number",
  PROVIDE_VALID_START_PAGE: "Please choose start page",
  PROVIDE_VALID_MOBILE_NUMBER:
    "Please enter a valid 10 digit mobile phone number",
  PROVIDE_ENTER_VALID_MOBILE_NUMBER: "Please enter a valid mobile phone number",
  PROVIDE_VERIFICATIONCODE_NUMBER: "Please enter verification code",
  PROVIDE_VALID_VERIFICATIONCODE_NUMBER:
    "Please enter valid 6-digit verification code",
  PROVIDE_DOB: "Please enter date of birth",
  PROVIDE_STREET_ADDRESS: "Please enter street address",
  PROVIDE_ADDRESS: "Please enter address",
  PROVIDE_ADDRESSLINE_2: "Please enter address line 2",
  PROVIDE_REGISTERED_EMAIL: "Please enter registered email address",
  PROVIDE_CITY: "Please enter city",
  SELECT_GENDER: "Please select gender",
  PROVIDE_STATE: "Please select state",
  PROVIDE_POSTAL_CODE: "Please enter postal/zip code",
  SELECT_ISAGREE: "Please agree to the iLife Terms of Service",
  PROVIDE_NOTES: "Please enter notes",
  PROVIDE_COMPANY_IMAGE: "Company Logo is required",
  PROVIDE_COMPANY_NAME: "Please enter company name",
  PROVIDE_VALID_COMPANY_NAME: "Please enter valid company name",
  PROVIDE_VALID_COMPANY_NAME_LENGTH:
    "Company name should be less than 50 characters",
  PROVIDE_NOTE: "Please enter a text",

  PROVIDE_WEBSITE_NAME: "Please enter website name",
  PROVIDE_VALID_WEBSITE_NAME:
    "Please enter a valid link (for example company.com or company.net or company.tech, etc)",

  PROVIDE_SUBDOMAIN_NAME: "Please enter domain name",
  PROVIDE_VALID_DOMAIN_NAME:
    "Domain name should contain letters and numbers only without any spaces",
  PROVIDE_VALID_SUBDOMAIN_NAME:
    "Please enter proper domain name. Domain name should only contain characters a to z and 0 to 9",
  PROVIDE_VALID_SUBDOMAIN_NAME_LENGTH:
    "Domain name should be less than 25 characters",
  PROVIDE_WELCOME_MESSAGE: "Please enter welcome message",
  PROVIDE_PROVIDE_LINK: "Please enter video link",
  PROVIDE_VALID_PROVIDE_LINK:
    "Please enter the video link of Youtube or Vimeo only",
  PROVIDE_VALID_WELCOME_MESSAGE_LENGTH:
    "Welcome message should be less than 250 characters",
  PROVIDE_LOCATION: "Please enter location",
  PROVIDE_VALID_LOCATION: "Location should be less than 250 characters",
  PROVIDE_VALID_HEADLINE: "Please enter Page headline",

  PROVIDE_LICENSE_NUMBER: "Please enter license number",
  PROVIDE_CARRIERSID: "Please select carrier ",
  PROVIDE_STATEID: "Please select state ",
  PROVIDE_AGENT_CODE: "Please enter agent code ",
  PROVIDE_CARRIER_EMAIL: "Please enter application submission email",
  PROVIDE_TIN_NUMBER: "Please enter TIN number",
  PROVIDE_PRODUCT_INTEREST: "Please enter product interest",
  SOMETHING_WENT_WRONG: "Something Went Wrong!",
  CONNECTION_LOST:"Connection lost ! Please try again later",
  DOCUMENT_FILE: "Please select file",
  ONLY_PDF: "Please upload PDF files only",
  BLACK_MESSAGE: "Please enter proper message",
  VALID_ATTACHMENT: "Only .pdf, .doc, .jpg and .png file formats are accepted",
  SEND_PROPER_DATA: "Please send proper data",
  BANK_NAME: "Please enter bank name",
  ACCOUNT_HOLDER_NAME: "Please enter account holder name",
  ROUTING_NUMBER: "Please enter routing number",
  ACCOUNT_NUMBER: "Please enter account number",
  CONFIRM_ACC_NUMBER: "Please re-enter account number",
  CONFIRM_ACC_NUMBER_ASABOVE: "Account number does not match",
  PROVIDE_VALID_ADDRESS: "Please enter numbers or letters only",
  PROVIDE_CARD_NUMBER: "Please enter card number",
  CARD_NUMBER_TOO_SHORT: "Please enter valid card number",
  PROVIDE_CARD_EXPIRE_DATE: "Please enter card expire date",
  INVALID_EXPIRE_DATE: "Please enter valid card expire date",
  PROVIDE_CARD_HOLDER_NAME: "Please enter card holder name",
  PROVIDE_VALID_CARD_NAME: "Please enter valid card holder name",
  PROVIDE_CVC_NUMBER: "Please enter CVC number",
  CVC_NUMBER_TOO_SHORT: "Please enter valid CVC number",
  PROVIDE_BILLING_ADDRESS: "Please enter billing address",
  BILLING_ADDRESS_TOO_SHORT: "The address is too short",
  PROVIDE_BILLING_STATE: "Please enter billing state",
  PROVIDE_BILLING_CITY: "Please enter billing city",
  PROVIDE_BILLING_ZIPCODE: "Please enter billing zipcode",
  PROVIDE_ZIPCODE: "Please enter zipcode",
  EMAIL_UPDATE_SUCCESS: "Your email is succesfully updated!",
  MOBILE_UPDATE_SUCCESS: "Your mobile number is updated successfully!",
  OTP_SEND_SUCCESS: "The login code has been sent successfully.",
  OTP_SUCCESS:
    "The login code has been sent successfully via SMS. Please check your message. The login code is ",
  LINK_COPIED: "Link Copied!",
  DESCRIPTION_COPIED: "Description Copied!",
  INVALID_CREDENTIALS: "Invalid credentials",
  NO_DATA_FOUND: "No Data Found",
  PROFILE_UPDATE_SUCCESS: "Profile successfully updated!",
  PROFILE_IMAGE_SUCCESS: "Profile image updated successfully",
  COMPANY_IMAGE_SUCCESS: "Company image updated successfully",
  PLEASE_ENTER_ALL_FIELDS: "Please enter all required fields",
  PLEASE_SEND_FILE: "Please send file",
  ENTER_OTP: "Enter Login Code",
  UPDATES_SUCCESS: "Status Updated successfully",
  APPLICATION_CARRIER_ID_NULL: "Application Carrier Id is NULL",
  SIGN_UP_SUCCESS: "Sign Up Success!",

  PROVIDE_VALID_DOB: "DOB must be greater than 18 years",
  PROVIDE_AMOUNT: "Please enter amount",
  PROVIDE_MIN_AMOUNT:
    "You can't recharge with entered amount. Minimum recharge amount is $",
  PROVIDE_MAX_AMOUNT:
    "You can't recharge with entered amount. Maximum recharge amount is $",
  SESSTION_EXPIRED: "Your session has expired. Please log in again.",
  INTERNAL_SERVER_ERROR:
    "System is experiencing issues. Please come back later.",
  UNAUTHORIZED: "UnAuthorized!",
  USE_DIFF_MANAGER_CODE: "Please use a different manager code.",
  CANNOT_ADD_AMBASSADOR_MANAGER: "Only agency users can be added as managers",
  SUB_USER_ADDED_SUCCESS: "Admin user added successfully",
  SUB_USER_UPDATED_SUCCESS: "Admin user details updated successfully",
  SUB_USER_DELETED_SUCCESS: "Admin user deleted successfully",
  IMAGE_UPLAOD_SUCCESS: "Image updated successfully",
  IMAGE_DELETE_SUCCESS: "Image deleted successfully",
  HOME_DETAILS_SUCCESS: "Landing Page Design updated successfully",
  ABOUT_DETAILS_SUCCESS: "About page details updated successfully",
  WEBSITE_DETAILS_SUCCESS: "Website settings updated successfully",
  BROWSING_DETAILS_SUCCESS: "Browsing settings updated successfully",
  PROVIDE_SUBJECT: "Please enter subject of your email",
  PROVIDE_DETAILS: "Please enter Details of your email",
  CAMPAIGN_NAME: "Please enter your campaign name",
  PREVIEW_TEXT: "Please enter preview text!",
  EMAIL_CAMPAIGN_LIST: "Please select list from your email campaign",
  PRODUCT_SELECTION: "Please select at least one product type.",
  CARRIER_SELECTION: "Please select at least one carrier.",
  STATE_SELECTION: "Please select at least one licensed state.",
  PROVIDE_TIME_ZONE: "Please select your preferred timezone",
  NATIONAL_PRODUCER_NUMBER: "Please enter your national producer number",
  Preferences: "Select Preferences.",
  ZipCode: "Enter ZIP Code.",
  valideZipcode: "Enter valid ZIP Code",
  dob: "Select Date of Birth.",
  isSmoker: "Select Smoke.",
  healthrating: "Select Health.",
  gender: "Select Gender.",
  amount: "Enter Amount.",
  frequency: "Select Frequency.",
  termLength: "Select Term Length.",
  policyType: "Select Type.",
  FILE_VALIDATION: "The total size of all attachment should be less than 10MB",
  FILE_UNIQUE: "Please select unique file",
  PROVIDE_VALID_CHARACTER: "Please enter valid characters",
  LISTNAME: "Please enter the list name.",
  NOTES: "Note added successfully",
  NOTES_UPDATE: "Note updated successfully",
  SINGLE_FILE_VALIDATION: "File size should be less than 10MB",
  SELECT_FILE: "Please select a file",
  LOGO_UPLOAD: "File size should be less than 512KB",
};
