import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { isEmpty } from "lodash";
import DataTable from "react-data-table-component";
// import moment from "moment";
import NumberFormat from "react-number-format";
// import { find } from "lodash";

// icons
import editIcon from "../../assets/images/crm-icons/edit-icon.png";
import trashIcon from "../../assets/images/crm-icons/trash-icon.png";

import {
  commonApiCall,
  encryptData,
  decryptData,
  validateName,
  validateMobileNumber,
  showSuccessToast,
  showErrorToast,
  checkAccess,
  formatDateInTimezone,
} from "../../utlis";
import { API_POINT } from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";

import UpsertSubUser from "../../components/modelComponents/upsertSubUser";
import ConfirmModel from "../../components/modelComponents/confirmationModel";

const SubUsers = ({ commonApiCall, sign_in, planData, ...props }) => {
  const [upsertUser, setUpsertUser] = useState({
    agent_id: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    remove_agent_id: "",
    errors: {},
  });
  const [subUsersList, setSubUsersList] = useState([]);
  const [isCheckAddChildAgentlimit, setCheckAddChildAgentlimit] =
    useState(false);
  const [model, setModel] = useState({
    upsertModel: false,
    confirmModel: false,
  });

  //handle state manupulations
  const handleChange = (e) => {
    if (e.target.value)
      setUpsertUser({
        ...upsertUser,
        [e.target.name]: e.target.value,
        errors: Object.assign(upsertUser.errors, { [e.target.name]: "" }),
      });
    else
      setUpsertUser({
        ...upsertUser,
        [e.target.name]: "",
      });
  };

  const handleModel = (name, isHide) => {
    setModel({
      ...model,
      [name]: isHide,
    });
    if (name === "upsertModel" && !isHide)
      setUpsertUser({
        agent_id: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        errors: {},
      });
  };

  const setUserInformation = (userInfo) => {
    setUpsertUser({
      ...upsertUser,
      agent_id: userInfo.id,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      mobileNumber: userInfo.mobileNumber,
      errors: {},
    });
    handleModel("upsertModel", true);
  };

  const validateUser = () => {
    const { firstName, lastName, mobileNumber, errors } = upsertUser;
    if (upsertUser.hasOwnProperty("firstName")) {
      if (isEmpty(firstName))
        errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
      else if (!validateName(firstName))
        errors.firstName = errorMessages.PROVIDE_VALID_FIRST_NAME;
      else delete errors.firstName;
    }
    //for last name
    if (upsertUser.hasOwnProperty("lastName")) {
      if (isEmpty(lastName)) errors.lastName = errorMessages.PROVIDE_LAST_NAME;
      else if (!validateName(lastName))
        errors.lastName = errorMessages.PROVIDE_VALID_LAST_NAME;
      else delete errors.lastName;
    }

    if (upsertUser.hasOwnProperty("mobileNumber")) {
      if (isEmpty(mobileNumber))
        errors.mobileNumber = errorMessages.PROVIDE_MOBILE_NUMBER;
      else if (
        !validateMobileNumber(mobileNumber) &&
        mobileNumber.toString().length !== 10
      )
        errors.mobileNumber = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
      else delete errors.mobileNumber;
    }
    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setUpsertUser({
      ...upsertUser,
      errors: errors,
    });
    return isFormValid;
  };

  const doSubmit = async () => {
    try {
      if (validateUser()) {
        const { agent_id, firstName, lastName, mobileNumber } = upsertUser;
        let body = {
          id: agent_id,
          firstName: await encryptData(firstName),
          lastName: await encryptData(lastName),
          mobile: await encryptData(mobileNumber),
        };
        let response = await commonApiCall(
          API_POINT + "/childAgents",
          body,
          "POST",
          null,
          true
        );
        if (response.status === 200 || response.data.status.code === 200) {
          if (agent_id)
            showSuccessToast(errorMessages.SUB_USER_UPDATED_SUCCESS);
          else showSuccessToast(errorMessages.SUB_USER_ADDED_SUCCESS);
          setUpsertUser({
            agent_id: "",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            errors: {},
          });
          fetchSubUsers();
          handleModel("upsertModel", false);
        } else {
          showErrorToast(response.data.status.message);
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const fetchSubUsers = async () => {
    try {
      let response = await commonApiCall(
        API_POINT + "/childAgents",
        "",
        "GET",
        null,
        true
      );

      if (response.status === 200 || response.data.status.code === 200) {
        const userListData =
          response.data.data.data &&
          response.data.data.data.map((userdetails) => {
            return {
              id: userdetails.id,
              firstName: userdetails.firstName
                ? decryptData(userdetails.firstName)
                : "N/A",
              lastName: userdetails.lastName
                ? decryptData(userdetails.lastName)
                : "N/A",
              mobileNumber: userdetails.mobile
                ? decryptData(userdetails.mobile)
                : "N/A",
              createdDate: formatDateInTimezone(
                userdetails.createdDate,
                planData?.timeZoneId ?? null
              ).format("MM/DD/YYYY"),
            };
          });
        setSubUsersList(userListData);
        setCheckAddChildAgentlimit(response.data.data.limitReach);
      } else if (response.data.status.code === 403 || response.status === 403) {
        showErrorToast(response.data.status.message);
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const removeUser = async () => {
    try {
      const { remove_agent_id } = upsertUser;
      if (remove_agent_id) {
        let response = await commonApiCall(
          API_POINT + "/childAgents?userId=" + remove_agent_id,
          "",
          "DELETE",
          null,
          true
        );
        if (response.status === 200 || response.data.status.code === 200) {
          fetchSubUsers();
          // const updatedUsers = [...subUsersList];
          // remove(updatedUsers, function (n) {
          //   return n.id === remove_agent_id;
          // });

          // setSubUsersList(updatedUsers);
          showSuccessToast(errorMessages.SUB_USER_DELETED_SUCCESS);
        } else {
          showErrorToast(response.data.status.message);
        }
      } else {
        showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
      }

      handleModel("confirmModel", false);
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const doConfirm = (id) => {
    setUpsertUser({ ...upsertUser, remove_agent_id: id });
    handleModel("confirmModel", true);
  };

  //DATA TABLE FOR USER LISTING
  const subUserColumns = [
    {
      name: "Date",
      selector: (row) => row.createdDate,
      sortable: false,
      width: "auto",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: false,
      width: "auto",
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: false,
      width: "auto",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: false,
      width: "auto",
      cell: (row) => {
        return (
          <NumberFormat
            displayType={"text"}
            format="(###) ###-####"
            value={row.mobileNumber ? row.mobileNumber : "N/A"}
          />
        );
      },
    },
    {
      name: "",
      selector: (row) => row.id,
      width: "80px",
      // Use below code to integrate link inside Data-table
      cell: (row) => {
        return (
          <div className="d-flex" style={{ gap: "10px", alignItems: "center" }}>
            {/* <span
              className="material-icons"
              onClick={() => setUserInformation(row)}
              style={{ cursor: "pointer" }}
            >
              mode_edit
            </span> */}
            <img
              src={editIcon}
              alt=""
              width="20"
              height="20"
              style={{ cursor: "pointer" }}
              onClick={() => setUserInformation(row)}
            />
            <img
              src={trashIcon}
              alt=""
              width="24"
              style={{ cursor: "pointer" }}
              onClick={() => doConfirm(row.id)}
            />
            {/* <span
              className="material-icons"
              onClick={() => doConfirm(row.id)}
              style={{ cursor: "pointer" }}
            >
              delete
            </span> */}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (checkAccess(planData, "AUTH_USERS")) {
      fetchSubUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableVideoBlock = () => {
    if (
      (sign_in?.data?.currentPlanSlug?.toUpperCase() === "FREE" ||
        sign_in?.data?.currentPlanSlug?.toLowerCase() === "professional") &&
      !checkAccess(planData, "AUTH_USERS")
    ) {
      return true;
    }
  };

  const addChildUser = () => {
    if (!isCheckAddChildAgentlimit) {
      handleModel("upsertModel", true);
    } else {
      showErrorToast("You have reached the maximum limit of Admin Users");
    }
  };
  const { upsertModel, confirmModel } = model;

  return (
    <React.Fragment>
      <Animated
        animationIn="fadeIn"
        animationOut="faderOutLeft"
        animationInDuration={1200}
        animationOutDuration={800}
        isVisible={true}
      >
        {disableVideoBlock() ? (
          <p className="leadModelText">
            Add users who will be able to log in and manage your account.
            Available{" "}
            {sign_in?.data?.currentPlanSlug?.toUpperCase() === "FREE" ? (
              <>
                to Pro+ and Agency users.{" "}
                <Link
                  to="/my-subscriptions"
                  className="text-decoration-underline font-weight-bold"
                >
                  View iLife plans.
                </Link>{" "}
              </>
            ) : (
              <>
                {" "}
                in the{" "}
                <Link
                  to="/store"
                  className="text-decoration-underline font-weight-bold"
                >
                  iLife Store
                </Link>{" "}
                and to Pro+ and Agency users.{" "}
                <Link
                  to="/my-subscriptions"
                  className="text-decoration-underline font-weight-bold"
                >
                  View iLife plans.
                </Link>
              </>
            )}
          </p>
        ) : null}
        <div
          className={
            disableVideoBlock() ? "position-relative disable-free-acc mt-2" : ""
          }
        >
          {disableVideoBlock() ? (
            <span className="disable-feature"></span>
          ) : null}
          <div className="title-header mb-1">
            <h3 className="text-left font-24 box-main-title mb-1">
              Admin Users
            </h3>
          </div>
          <h5 className="title-text mb-4 ">
            Add users who will be able to log in and manage your account
            <hr className="light-thinborder" />{" "}
          </h5>
          <div className="plan-icons-outer mb-3">
            <div
              className="add-icons with-search"
              title="Search"
              onClick={() => addChildUser()}
            >
              <span className="material-icons">add</span>
            </div>
            <p onClick={() => addChildUser()}>Add users</p>
          </div>
          {disableVideoBlock() || subUsersList.length == 0 ? null : (
            <DataTable
              columns={subUserColumns}
              data={subUsersList}
              className="custom-datatable"
            />
          )}
        </div>
      </Animated>
      {UpsertSubUser && (
        <UpsertSubUser
          upsertModel={upsertModel}
          userDetails={upsertUser}
          handleChange={handleChange}
          handleModel={handleModel}
          doSubmit={doSubmit}
        ></UpsertSubUser>
      )}
      {confirmModel && (
        <ConfirmModel
          isShow={confirmModel}
          handleModel={handleModel}
          confirmAction={removeUser}
        ></ConfirmModel>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};
export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(SubUsers));
