import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModel = ({ isShow, handleModel, confirmAction }) => {
  return (
    <Modal
      show={isShow}
      onHide={() => handleModel("confirmModel", !isShow)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Alert!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete this Admin User?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => handleModel("confirmModel", !isShow)}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={() => confirmAction()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModel;
