import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import * as _ from "lodash";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { isEmpty } from "lodash";
import { Modal, OverlayTrigger } from "react-bootstrap";
import OtpInput from "react-otp-input";
import AccountPageLoader from "../../components/skeletonLoaders/accountPageLoader";
import {
  commonApiCall,
  updateAgent,
  decryptData,
  validateName,
  validateEmail,
  validateMobileNumber,
  encryptData,
  showSuccessToast,
  showAdviceToast,
  showErrorToast,
  setProfilePending,
  getJwt,
  BasicsPagesRoutes,
  profilePicture,
  CarrierAppointmentData,
} from "../../utlis";
import { UseInterval } from "../../hooks/index";
import { API_POINT } from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";
import ImageCrop from "../../components/commonComponents/ImageCrop";
import { getBasePlan } from "../../utlis/common";
import IlifePrompt from "../../components/commonComponents/IlifePrompt";
import DeleteModel from "../emailCampaign/emailModels/deleteModel";

const styles = {
  control: (base) => ({
    ...base,
    "&:hover": {
      borderColor: "red",
    },
  }),
};

function MyProfile({
  commonApiCall,
  updateAgent,
  profilePicture,
  CarrierAppointmentData,
  profile_pic,
  sign_in,
  planData,
  ...props
}) {
  const [formData, setFormData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    emailValue: "",
    countryCode: "",
    mobile: "",
    mobileValue: "",
    managerCode: "",
    errors: {},
    verificationModal: false,
    hideGetAppointedSection: false,
    otp: "",
    photo: "",
    counter: 0,
    retreaverId: "",
    timezone: null,
    showParentAgencyCode: false,
  });
  const [showCompany, setShowCompany] = useState(false);
  const [temporaryState, setTemporaryState] = useState(false);
  const [forceChangeDone, setForceChange] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [pauseLead, setPausedLead] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // const [
  //   hideGetAppointedSectionEnterprise,
  //   sethideGetAppointedSectionEnterprise,
  // ] = useState(false);
  const [signIn, setSignIn] = useState({});
  // const [retriever, setRetriever] = useState(false);
  const [showInput, setShowInput] = useState({});
  const [isState, setIsState] = useState(false);
  const [carrierAgentCodeInfo, setCarrierAgentCodeInfo] = useState({
    carrierName: "",
    carrierAgentCode: "",
    carrierAgentCodeStore: "",
    GACodeValue: "",
    GACodeValueStore: "",
    carrierID: "",
    carrierType: "",
  });

  const childRef = useRef();

  const [carriers, setCarriers] = useState([
    {
      stateId: "",
      licenseNumber: "",
    },
  ]);
  const [managerInfo, setManagerInfo] = useState({
    managerFirstName: "",
    managerLastName: "",
    managerEmail: "",
  });
  const [allCarriers, setAllCarriers] = useState();
  const [iLifeGACode, setiLifeGACode] = useState({
    GACodeiLife: "",
    isiLifeGACode: false,
  });
  const [BannerLifeAgentCode, setBannerLifeAgentCode] = useState(false);
  // let showUpgradeText = false;

  const getEnterpriseAgent = async () => {
    if (formData.managerCode.length > 0) {
      let response = await commonApiCall(
        API_POINT + `/managerInfo?referralCode=${formData.managerCode}`,
        "",
        "get",
        null,
        true
      );
      if (response.data.status.code === 200) {
        setManagerInfo({
          ...managerInfo,
          managerFirstName: response.data.data.firstName
            ? await decryptData(response.data.data.firstName)
            : "",
          managerLastName: response.data.data.lastName
            ? await decryptData(response.data.data.lastName)
            : "",
          managerEmail: response.data.data.email
            ? await decryptData(response.data.data.email)
            : "",
        });
      } else {
        showErrorToast(response.data.status.message);
      }
    }
  };
  const getAllCarriers = async () => {
    let referalCode;
    if (sign_in.data && sign_in.data.referralCode) {
      referalCode = sign_in.data.referralCode;
    }
    if (referalCode != undefined) {
      let response = await commonApiCall(
        API_POINT + "/getCarriersProfile?referralCode=" + referalCode,
        "",
        "get"
      );
      if (response.status === 200) {
        var result = Object.keys(response.data.carriersProfile).map((key) => [
          key,
          response.data.carriersProfile[key],
        ]);
        setiLifeGACode({
          ...iLifeGACode,
          GACodeiLife: response?.data?.bannerGaCode,
        });
        setAllCarriers(result);
      }
    }
  };

  useEffect(() => {
    const token = getJwt();
    if (token) {
      getTimeZoneList();
    } else {
      props.history.push(BasicsPagesRoutes.LOGIN);
    }
    if (sign_in && sign_in.data) {
      const signIn = sign_in.data;
      setSignIn(signIn);
    }
    if (!isEmpty(profile_pic)) {
      let profilePhoto = profile_pic.profilePic;
      setFormData({ ...formData, photo: profilePhoto });
    }
    getAllCarriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sign_in]);

  UseInterval(
    () => {
      setFormData({ ...formData, counter: formData.counter - 1 });
    },
    formData.counter > 0 ? 1000 : null
  );

  const getProfileDetails = async () => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT + "/getUsersProfile",
      "",
      "get",
      null,
      true
    );
    if (response.data.status.code === 200) {
      let {
        id,
        firstName,
        lastName,
        company,
        showCompany,
        email,
        countryCode,
        mobile,
        managerCode,
        profilePic,
        hideGetAppointedSection,
        pauseLead,
        retreaverId,
        showParentAgencyCode,
        timeZone,
      } = response.data.data;
      setFormData({
        ...formData,
        firstName: firstName ? await decryptData(firstName) : "",
        lastName: lastName ? await decryptData(lastName) : "",
        companyName: company.companyName
          ? await decryptData(company.companyName)
          : "",
        email: email ? await decryptData(email) : "",
        emailValue: email ? await decryptData(email) : "",
        mobile: mobile ? await decryptData(mobile) : "",
        mobileValue: mobile ? await decryptData(mobile) : "",
        countryCode: countryCode ? await decryptData(countryCode) : "",
        userId: id,
        managerCode: managerCode ? managerCode : "",
        photo: profilePic,
        hideGetAppointedSection: hideGetAppointedSection
          ? hideGetAppointedSection
          : false,
        timeZone:
          timeZone && timeZoneOptions
            ? _.find(timeZoneOptions, ["value", timeZone])
            : null,
        retreaverId: retreaverId,
        showParentAgencyCode: showParentAgencyCode,
      });

      setShowCompany(showCompany);
      // setRetriever(pauseLead);
      setIsUpdating(false);
      setPausedLead(pauseLead);
      // sethideGetAppointedSectionEnterprise(
      //   response.data.data.hideGetAppointedSection
      // );
      response.data.data.carriers &&
        response.data.data.carriers.length &&
        response.data.data.carriers.map((obj, key) => {
          var t = {};
          t.value = obj.state.stateId;
          t.label = obj.state.stateName;
          obj.state = t;
          return null;
        });

      if (response.data.data.carriers && response.data.data.carriers.length) {
        setCarriers([...response.data.data.carriers]);
      }
    }
  };

  //handle change for Input
  const handleChange = (e, removeSpaces = false) => {
    setDisabledButton(false);
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: removeSpaces ? e.target.value.trim() : e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    } else
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
  };

  //validation for personal details
  const validateForm = (specificField = "") => {
    const { firstName, lastName, email, mobile, companyName } = formData;
    let errors = {};
    if (specificField !== "Mobile" || specificField !== "Email") {
      // for firstName
      if (formData.hasOwnProperty("firstName")) {
        if (isEmpty(firstName) || firstName === "")
          errors.firstName = errorMessages.PROVIDE_FIRST_NAME;
        else if (!validateName(firstName))
          errors.firstName = errorMessages.PROVIDE_VALID_FIRST_NAME;
        else delete errors.firstName;
      }

      if (formData.hasOwnProperty("companyName")) {
        if (isEmpty(companyName) && showCompany)
          errors.companyName = errorMessages.PROVIDE_COMPANY_NAME;
        else delete errors.companyName;
      }

      //for last name
      if (formData.hasOwnProperty("lastName")) {
        if (isEmpty(lastName))
          errors.lastName = errorMessages.PROVIDE_LAST_NAME;
        else if (!validateName(lastName))
          errors.lastName = errorMessages.PROVIDE_VALID_LAST_NAME;
        else delete errors.lastName;
      }
      // for email
      if (formData.hasOwnProperty("email")) {
        if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!validateEmail(email))
          errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else delete errors.email;
      }
      if (formData.hasOwnProperty("mobile")) {
        if (isEmpty(mobile))
          errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
        else if (
          !validateMobileNumber(mobile) &&
          mobile.toString().length !== 10
        )
          errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        else delete errors.mobile;
      }
    } else if (`${specificField}` === "Email") {
      // for email
      if (formData.hasOwnProperty("email")) {
        if (isEmpty(email)) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!validateEmail(email))
          errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else delete errors.email;
      }
    } else if (`${specificField}` === "Mobile") {
      if (formData.hasOwnProperty("mobile")) {
        if (isEmpty(mobile))
          errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
        else if (
          !validateMobileNumber(mobile) &&
          mobile.toString().length !== 10
        )
          errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        else delete errors.mobile;
      }
    }

    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isFormValid;
  };

  const validateStateCarriers = () => {
    let isFormValid = true;
    for (let i = 0; i < carriers.length; i++) {
      if (!isState && carriers[i].stateId === "") {
        isFormValid = false;
        showErrorToast(errorMessages.PROVIDE_STATEID);
      }
      if (
        isState &&
        carriers[i].stateId &&
        Object.keys(carriers[i].stateId).length === 0
      ) {
        isFormValid = false;
        showErrorToast(errorMessages.PROVIDE_STATEID);
        setIsState(true);
      } else {
        if (
          isState &&
          carriers[i].stateId &&
          Object.keys(carriers[i].stateId).length
        ) {
          setIsState(false);
        }
      }
    }
    return isFormValid;
  };

  //update profile
  const submitAmbassadorProfile = async (e, forceChange = false) => {
    e.preventDefault();
    if (validateForm("Ambassador")) {
      let { userId, firstName, lastName, managerCode, timeZone } = formData;
      setIsUpdating(true);
      if (!managerCode || managerCode !== sign_in.referralCode) {
        let body = {
          id: userId,
          firstName: await encryptData(firstName),
          lastName: await encryptData(lastName),
          managerCode: !getBasePlan(signIn?.role).isEnterprise
            ? managerCode
            : "",
          timeZone: timeZone?.value,
        };
        let response = await commonApiCall(
          API_POINT + "/saveUserAgentProfile",
          body,
          "put",
          null,
          true
        );
        if (response.status === 200 || response.data.status.code === 200) {
          let reduxStore = {
            profilePic: photo,
            firstName: await encryptData(formData.firstName),
          };
          await profilePicture(reduxStore);
          await setProfilePending("");
          showSuccessToast(errorMessages.PROFILE_UPDATE_SUCCESS);

          if (forceChange) {
            childRef.current.getAlert();
          }
          setiLifeGACode({ ...iLifeGACode, isiLifeGACode: false });
          setIsUpdating(false);
          setDisabledButton(true);
          setFormData({ ...formData, errors: {} });
        } else {
          showErrorToast(response.data.status.message);
          setIsUpdating(false);
        }
      } else {
        showErrorToast(errorMessages.USE_DIFF_MANAGER_CODE);
        setIsUpdating(false);
      }
    }
  };

  // update mobile and email
  const editMobileAndEmail = async (name, value) => {
    const { emailValue, mobileValue } = formData;
    if (name === "Email") {
      setDisabledButton(false);
      setShowInput({ ...showInput, emailshow: value });
      setFormData({ ...formData, email: emailValue });
    } else {
      setDisabledButton(false);
      setShowInput({ ...showInput, mobileShow: value });
      setFormData({ ...formData, mobile: mobileValue });
    }
  };

  //update mobile number
  const getOTPtoupdateEmailAndMobile = async (name) => {
    let body = { type: name },
      isChanged = true;
    if (name === "Email") {
      setShowInput({ ...showInput, emailText: true });
      if (formData.email === decryptData(sign_in.data.email)) isChanged = false;
      body["email"] = await encryptData(formData.email);
    } else {
      setShowInput({ ...showInput, emailText: false });
      if (formData.mobile === decryptData(sign_in.data.mobile))
        isChanged = false;
      body["countryCode"] = encryptData("+1");
      body["mobile"] = encryptData(formData.mobile);
    }
    if (isChanged) {
      if (validateForm(name)) {
        const response = await commonApiCall(
          API_POINT + "/profileUpdateMobileEmail",
          body,
          "post",
          null,
          true
        );
        if (response.data.status.code === 200) {
          setFormData({ ...formData, counter: 30 });
          if (
            response.data.data &&
            response.data.data.otp &&
            response.data.data.otp !== ""
          )
            showSuccessToast(
              response.data.status.message +
                " and Login Code is " +
                response.data.data.otp
            );
          else showSuccessToast(errorMessages.OTP_SEND_SUCCESS);
          handleShow("verificationModal");
        } else {
          showErrorToast(response.data.status.message);
        }
      }
    } else {
      if (name === "Email") setShowInput({ ...showInput, emailshow: false });
      else setShowInput({ ...showInput, mobileShow: false });
    }
  };

  //handleShow modal
  const handleShow = (modelName) => {
    setFormData({ ...formData, [modelName]: true });
  };

  //handleClose modal
  const handleClose = (modelName) => {
    setFormData({ ...formData, [modelName]: false });
  };

  //handleOTP verification onChange
  const handleOTP = (otp) => {
    setFormData({ ...formData, otp: otp });
  };

  //resend  otp
  const resendOtp = async (e) => {
    e.preventDefault();
    var body = {};
    if (showInput.emailText) {
      body["type"] = "Email";
      body["email"] = await encryptData(formData.email);
    } else {
      body["type"] = "Mobile";
      body["countryCode"] = await encryptData("+1");
      body["mobile"] = await encryptData(formData.mobile);
    }
    const response = await commonApiCall(
      API_POINT + "/profileUpdateMobileEmail",
      body,
      "post",
      null,
      true
    );
    if (response.data.status.code === 200) {
      setFormData({ ...formData, counter: 30 });
      showSuccessToast(
        response.data.status.message +
          " And Login Code is " +
          response.data.data.otp
      );
    } else {
      showErrorToast(response.data.status.message);
    }
  };

  const updateEmailAndMobile = async (e) => {
    e.preventDefault();
    if (formData.otp !== "" && formData.otp.toString().length === 6) {
      let agentUserDetails = sign_in;
      let { mobile, otp, email } = formData,
        mobile_e = await encryptData(mobile),
        email_e = await encryptData(email),
        countrycode_e = await encryptData("+1"),
        body = { otp };
      if (showInput.emailText) {
        body["email"] = email_e;
        body["type"] = "Email";
        agentUserDetails.data.email = email_e;
      } else {
        body["mobile"] = mobile_e;
        body["countryCode"] = countrycode_e;
        body["type"] = "Mobile";
        agentUserDetails.data.mobile = mobile_e;
      }
      const response = await commonApiCall(
        API_POINT + "/verifiedMobileEmail",
        body,
        "post",
        null,
        true
      );
      if (response.status === 200 && response.data.status.code === 200) {
        setFormData({
          ...formData,
          otp: "",
          emailValue: formData?.email,
          mobileValue: formData?.mobile,
          verificationModal: false,
        });
        await updateAgent(agentUserDetails);
        setTemporaryState(true);
        if (showInput.emailText) {
          setShowInput({ ...showInput, emailshow: false });
          showSuccessToast(errorMessages.EMAIL_UPDATE_SUCCESS);
        } else {
          setShowInput({ ...showInput, mobileShow: false });
          showSuccessToast(errorMessages.MOBILE_UPDATE_SUCCESS);
        }
      } else {
        if (response.status === 400)
          showErrorToast(response.data.status.message);
        else showErrorToast(errorMessages.INVALID_CREDENTIALS);
      }
    } else {
      let errors = Object.assign({}, formData.errors);
      errors["OTP"] = errorMessages.PROVIDE_VALID_VERIFICATIONCODE_NUMBER;
      setFormData({ ...formData, errors });
      showErrorToast(errorMessages.ENTER_OTP);
    }
  };

  const submitEnterpriseProfessionalProfile = async (
    e,
    val,
    key,
    forceChange = false
  ) => {
    // e.preventDefault();
    if (validateForm() && validateStateCarriers()) {
      let pauseLeadVal = pauseLead;
      let hideGetAppointedSectionVal = hideGetAppointedSection;
      if (key === "pauseLead") {
        pauseLeadVal = val;
      } else if (key === "hideGetAppointedSection") {
        hideGetAppointedSectionVal = val;
      }
      setIsUpdating(true);
      let { userId, firstName, lastName, companyName, managerCode } = formData;
      if (!managerCode || managerCode !== signIn.referralCode) {
        let body = {
          id: userId,
          firstName: await encryptData(firstName),
          lastName: await encryptData(lastName),
          company: {
            companyName: await encryptData(companyName),
          },
          showCompany: showCompany,
          managerCode: managerCode,
          pauseLead: pauseLeadVal,
          hideGetAppointedSection: hideGetAppointedSectionVal,
          timeZone: timeZone?.value,
        };
        let disabelCarrierArray = [];
        let enableCarrierPayload = [];
        let agentCodeList = [];
        Array.isArray(allCarriers) &&
          allCarriers.length > 0 &&
          allCarriers.map((obj) => {
            Array.isArray(obj[1]) &&
              obj[1].length > 0 &&
              obj[1].map((carrier) => {
                if (carrier.isActiveForAgent) {
                  enableCarrierPayload.push({
                    carrersId: carrier.carrierId.toString(),
                  });
                }
                if (!carrier.isActiveForAgent) {
                  disabelCarrierArray.push({
                    carrersId: carrier.carrierId,
                    category: carrier.category,
                  });
                }
                if (carrier.agentCodeRequired) {
                  agentCodeList.push({
                    carrersId: carrier.carrierId,
                    agentCode: carrier.agentCode,
                    gaCode: carrier.gaCode,
                  });
                }
              });
          });
        let carrierPayload = [];
        carriers &&
          carriers.length &&
          carriers.map((obj) => {
            if (obj.stateId !== "") {
              var arr_c = {};
              arr_c["carriersList"] = [];
              if (obj.id !== undefined) {
                arr_c.id = obj.id ? obj.id : null;
              }
              arr_c.licenseNumber = obj.licenseNumber
                ? obj.licenseNumber
                : null;
              arr_c.stateId =
                obj.state && obj.state.value ? obj.state.value : null;
              obj.carriersList && obj.carriersList.length
                ? enableCarrierPayload.map((obj1) => {
                    var arr_CL = {};
                    arr_CL.carrersId = obj1.carrersId;
                    if (obj.carriersList && obj.carriersList.length) {
                      for (let i = 0; i < obj.carriersList.length; i++) {
                        if (
                          obj1.carrersId.includes(
                            obj.carriersList[i].carrier.carrersId.toString()
                          )
                        ) {
                          if (
                            obj.carriersList[i].id &&
                            obj.carriersList[i].id !== undefined
                          ) {
                            arr_CL.id = obj.carriersList[i].id;
                          }
                        }
                      }
                    }
                    if (arr_CL.carrersId) {
                      arr_c["carriersList"].push(arr_CL);
                    }
                  })
                : (arr_c["carriersList"] = enableCarrierPayload);
              carrierPayload.push(arr_c);
            }
          });
        body["carriers"] = carrierPayload;
        body["carriersList"] = carrierPayload[0].carriersList;
        body["disabledCarriers"] = disabelCarrierArray;
        body["agentCodeList"] = agentCodeList;
        carrierPayload.forEach((item) => {
          delete item.carriersList;
        });
        let response = await commonApiCall(
          API_POINT + "/saveUserAgentProfile",
          body,
          "put",
          null,
          true
        );
        if (response.status === 200 && response.data.status.code === 200) {
          // setRetriever(body?.pauseLead);
          let agentUserDetails = sign_in;

          agentUserDetails.data.firstName = body.firstName;
          agentUserDetails.data.lastName = body.lastName;
          agentUserDetails.data.company = body.company;
          agentUserDetails.data.showCompany = body.showCompany;
          agentUserDetails.data.managerCode = body.managerCode;
          agentUserDetails.data.pauseLead = body.pauseLead;
          agentUserDetails.data.hideGetAppointedSection =
            body.hideGetAppointedSection;
          agentUserDetails.data.timeZone = body.timeZone;

          await updateAgent(agentUserDetails);

          setBannerLifeAgentCode(false);
          let reduxStore = {
            profilePic: photo,
            firstName: await encryptData(formData.firstName),
          };
          await profilePicture(reduxStore);
          await setProfilePending("");
          showSuccessToast(errorMessages.PROFILE_UPDATE_SUCCESS);
          if (forceChange) {
            childRef.current.getAlert();
          }
          setFormData({ ...formData, errors: {} });
          setForceChange(true);
          getProfileDetails();
          getAllCarriers();
          setIsUpdating(false);
          setDisabledButton(true);
          setTemporaryState(false);
        } else if (
          response.status === 400 &&
          response.data.status.message.includes("Manager Code")
        ) {
          setIsUpdating(false);
          showErrorToast(errorMessages.CANNOT_ADD_AMBASSADOR_MANAGER);
        } else {
          setIsUpdating(false);
          showErrorToast(response.data.status.message);
        }
      } else {
        setIsUpdating(false);
        showErrorToast(errorMessages.USE_DIFF_MANAGER_CODE);
      }
    }
  };
  // const agentCodeRequiredCarrier = (name, code, id, type, gaCode) => {
  //   setBannerLifeAgentCode(true);
  //   setCarrierAgentCodeInfo({
  //     ...carrierAgentCodeInfo,
  //     carrierName: name,
  //     carrierAgentCode: code,
  //     carrierAgentCodeStore: code,
  //     GACodeValue: gaCode,
  //     GACodeValueStore: gaCode,
  //     carrierID: id,
  //     carrierType: type,
  //   });
  //   const { GACodeiLife } = iLifeGACode;
  //   if (gaCode == GACodeiLife) {
  //     setiLifeGACode({ ...iLifeGACode, isiLifeGACode: true });
  //   }
  // };

  const handleCarrierAgnetCode = (e, type, id, field) => {
    for (let i = 0, len = allCarriers.length; i < len; i++) {
      if (allCarriers[i][0] == type) {
        for (let j = 0; j < allCarriers[i][1].length; j++) {
          if (allCarriers[i][1][j]["carrierId"] == id && field == "agentCode") {
            allCarriers[i][1][j]["agentCode"] = e.target.value;
            setAllCarriers([...allCarriers]);
            break;
          }
          if (allCarriers[i][1][j]["carrierId"] == id && field == "gaCode") {
            allCarriers[i][1][j]["gaCode"] = e.target.value;
            setAllCarriers([...allCarriers]);
            setCarrierAgentCodeInfo({
              ...carrierAgentCodeInfo,
              GACodeValue: e.target.value,
            });
            break;
          }
        }
      }
    }
  };

  const handleDefaultGACode = (e, type, id, iLifeCode, otherGACode) => {
    const { checked } = e.target;
    for (let i = 0, len = allCarriers.length; i < len; i++) {
      if (allCarriers[i][0] == type) {
        for (let j = 0; j < allCarriers[i][1].length; j++) {
          if (checked === true) {
            if (allCarriers[i][1][j]["carrierId"] == id) {
              allCarriers[i][1][j]["gaCode"] = iLifeCode;
              setAllCarriers([...allCarriers]);
              setCarrierAgentCodeInfo({
                ...carrierAgentCodeInfo,
                GACodeValue: iLifeCode,
              });
              setiLifeGACode({ ...iLifeGACode, isiLifeGACode: true });
              break;
            }
          } else {
            if (checked === false) {
              if (allCarriers[i][1][j]["carrierId"] == id) {
                allCarriers[i][1][j]["gaCode"] =
                  otherGACode && otherGACode != iLifeCode ? otherGACode : "";
                setAllCarriers([...allCarriers]);
                setCarrierAgentCodeInfo({
                  ...carrierAgentCodeInfo,
                  GACodeValue:
                    otherGACode && otherGACode != iLifeCode ? otherGACode : "",
                });
                setiLifeGACode({ ...iLifeGACode, isiLifeGACode: false });
                break;
              }
            }
          }
        }
      }
    }
  };

  const renderTooltipOfAgencyCode = (props) => (
    <div id='button-tooltip' {...props}>
      <div className='tooltip-outer'>
        <p>
          This is the code of your parent agency <br />
          that you belong to.
          <br />
          If you belong to an Agency account,
          <br />
          please request the agency code from <br />
          them to get connected.
        </p>
      </div>
    </div>
  );

  const removePhoto = async () => {
    setIsDeleting(true);
    const response = await commonApiCall(
      API_POINT + "/deleteImageFile?uploadedImageType=PROFILE",
      "",
      "delete",
      null,
      true
    );
    if (response.data.status.code === 200) {
      setIsDeleting(false);
      setDeleteModel(false);
      setFormData({ ...formData, photo: "" });
      var reduxStoreData = {
        profilePic: "",
        email: formData.email,
        firstName: await encryptData(formData.firstName),
      };
      await profilePicture(reduxStoreData);
      showSuccessToast(response.data.status.message);
    } else {
      setIsDeleting(false);
      setDeleteModel(false);
      showErrorToast(errorMessages.PLEASE_SEND_FILE);
    }
  };

  const ErrorHandle = () => {
    setFormData({ ...formData, photo1: true });
  };

  const setImage = (key, value) => {
    if (key === "profileImage") setFormData({ ...formData, photo: value });
    else setFormData({ ...formData, companyImage: value });
  };

  const onHideAgnetCodeModel = (action, type, id) => {
    const { carrierAgentCodeStore, GACodeValueStore } = carrierAgentCodeInfo;
    Array.isArray(allCarriers) &&
      allCarriers.length > 0 &&
      allCarriers.map((obj) => {
        if (obj[0] == type) {
          Array.isArray(obj[1]) &&
            obj[1].length > 0 &&
            obj[1].map((carrier) => {
              if (action === "submit") {
                if (
                  carrier.carrierId == id &&
                  carrier.agentCodeRequired == true &&
                  ((!carrier.hasOwnProperty("agentCode") &&
                    !carrier.hasOwnProperty("gaCode")) ||
                    (carrier.agentCode == "" && carrier.gaCode == "") ||
                    (!carrier.agentCode && !carrier.gaCode))
                ) {
                  carrier.isActiveForAgent = false;
                  setAllCarriers([...allCarriers]);
                  setBannerLifeAgentCode(false);
                  showAdviceToast(
                    "Clicking the Save button will save your changes"
                  );
                } else if (
                  carrier.carrierId == id &&
                  carrier.agentCodeRequired == true &&
                  carrier.hasOwnProperty("agentCode") &&
                  carrier.agentCode != "" &&
                  carrier.hasOwnProperty("gaCode") &&
                  carrier.gaCode != ""
                ) {
                  setBannerLifeAgentCode(false);
                  showAdviceToast(
                    "Clicking the Save button will save your changes"
                  );
                } else if (
                  (carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    carrier.hasOwnProperty("gaCode") &&
                    carrier.gaCode != "" &&
                    !carrier.hasOwnProperty("agentCode")) ||
                  (!carrier.hasOwnProperty("gaCode") &&
                    carrier.hasOwnProperty("agentCode") &&
                    carrier.agentCode != "") ||
                  (carrier.hasOwnProperty("gaCode") &&
                    carrier.hasOwnProperty("agentCode") &&
                    ((carrier.gaCode == "" && carrier.agentCode != "") ||
                      (carrier.gaCode != "" && carrier.agentCode == "")))
                ) {
                  setBannerLifeAgentCode(true);
                  showErrorToast("Please enter both codes");
                }
              } else {
                if (action === "close") {
                  if (
                    carrier.carrierId == id &&
                    carrier.agentCodeRequired == true &&
                    ((!carrier.hasOwnProperty("agentCode") &&
                      !carrier.hasOwnProperty("gaCode")) ||
                      !carrierAgentCodeStore ||
                      !GACodeValueStore)
                  ) {
                    carrier.isActiveForAgent = false;
                    carrier.agentCode = "";
                    carrier.gaCode = "";
                    setAllCarriers([...allCarriers]);
                  } else if (
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrierAgentCodeStore &&
                      GACodeValueStore) ||
                    (carrier.carrierId == id &&
                      carrier.agentCodeRequired == true &&
                      carrierAgentCodeStore &&
                      GACodeValueStore &&
                      (carrier.agentCode == "" || carrier.gaCode == ""))
                  ) {
                    carrier.agentCode = carrierAgentCodeStore;
                    carrier.gaCode = GACodeValueStore;
                    setAllCarriers([...allCarriers]);
                  }
                }
                setBannerLifeAgentCode(false);
              }
            });
        }
      });
  };

  const getTimeZoneList = async () => {
    let response = await commonApiCall(
      API_POINT + "/getTimezone",
      "",
      "get",
      null,
      true
    );
    if (response?.data?.status?.code === 200) {
      let timeZoneList = [];
      response.data.data &&
        response.data.data.map((element) => {
          timeZoneList.push({
            value: element.timeZoneCode,
            label: element.timeZoneName,
          });
          return true;
        });
      setTimeZoneOptions(timeZoneList);
    }
  };

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZoneOptions]);

  //destructuring
  let {
      firstName,
      lastName,
      companyName,
      email,
      mobile,
      managerCode,
      verificationModal,
      otp,
      counter,
      photo1,
      errors,
      photo,
      hideGetAppointedSection,
      showParentAgencyCode,
      timeZone,
    } = formData,
    { emailshow, mobileShow, emailText } = showInput;
  const { managerFirstName, managerLastName, managerEmail } = managerInfo;
  const {
    carrierName,
    carrierAgentCode,
    GACodeValue,
    GACodeValueStore,
    carrierID,
    carrierType,
  } = carrierAgentCodeInfo;
  const { GACodeiLife, isiLifeGACode } = iLifeGACode;
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 my-profile-page'>
          <Animated
            animationIn='fadeIn'
            animationOut='faderOutLeft'
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            {isUpdating ? (
              <AccountPageLoader formData={formData} />
            ) : (
              <div>
                <div className='d-flex justify-content-between flex-column flex-md-row w-100'>
                  <div className='text-align-center mt-2'>
                    <h3 className='text-left font-20 mb-1'>Profile</h3>
                    <h5 className='title-text font-16 text-left'>
                      Please use the email and mobile that you would like to use
                      to access your account and receive notifications.
                    </h5>
                  </div>
                  <div className='text-right pb-4'>
                    {disabledButton ? (
                      <button
                        className='btn btn-disable disable'
                        disabled={
                          emailshow ||
                          mobileShow ||
                          isUpdating ||
                          disabledButton
                            ? true
                            : false
                        }
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type='submit'
                        className='btn btn-gradient'
                        onClick={(e) =>
                          getBasePlan(signIn.role).isAmbassador
                            ? submitAmbassadorProfile(e)
                            : submitEnterpriseProfessionalProfile(e, pauseLead)
                        }
                        disabled={
                          emailshow ||
                          mobileShow ||
                          isUpdating ||
                          disabledButton
                            ? true
                            : false
                        }
                      >
                        <span className='align-middle d-inline-block'>
                          {" "}
                          {isUpdating ? "Updating..." : "Save"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <hr className='light-thinborder w-100 mt-0' />
                <div className='profile-details'>
                  <div className='profile-pic'>
                    <img
                      src={
                        photo
                          ? photo
                          : photo1
                          ? "/assets/images/noimage-1.jpg"
                          : "/assets/images/no-image.jpg"
                      }
                      alt=''
                      title={
                        sign_in?.data?.profilePicAvailable
                          ? "user profile"
                          : "This is how your client knows who you are. Take this opportunity to make an awesome first impression."
                      }
                      onError={() => ErrorHandle()}
                    />
                    <ImageCrop
                      email={email}
                      firstName={firstName}
                      setImage={setImage}
                      isCompanyImage={false}
                    />
                  </div>
                  {photo === "" || photo === undefined ? null : (
                    <button
                      className='btn btn-link'
                      onClick={() => setDeleteModel(true)}
                    >
                      Remove
                    </button>
                  )}
                </div>
                <form className='row'>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <div className='input-effect with-icon'>
                        <input
                          className='form-control'
                          placeholder='First Name'
                          name='firstName'
                          maxLength='50'
                          id='firstName'
                          type='text'
                          value={firstName}
                          onChange={(e) => {
                            setTemporaryState(true);
                            handleChange(e,true);
                          }}
                        />
                        <label htmlFor='firstName'>First Name</label>
                        <span className='text-danger'>{errors.firstName}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <div className='input-effect with-icon'>
                        <input
                          className='form-control'
                          placeholder='Last Name'
                          name='lastName'
                          id='lastName'
                          maxLength='50'
                          type='text'
                          value={lastName}
                          onChange={(e) => {
                            setTemporaryState(true);
                            handleChange(e,true);
                          }}
                        />
                        <label htmlFor='firstName'>Last Name</label>
                        <span className='text-danger'>{errors.lastName}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 mb-3'>
                    <h3 className='text-left font-14'>
                      What is your preferred time zone?
                    </h3>

                    <div className='default-select with-border mt-0 mb-3'>
                      <Select
                        style={styles}
                        value={timeZone}
                        options={timeZoneOptions}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            timeZone: value,
                          });
                          setDisabledButton(false);
                        }}
                        placeholder={"Select preferred time zone"}
                        isSearchable={true}
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                      />
                    </div>
                  </div>
                  <div className='col-sm-12'>
                    <div className='form-group mb-2'>
                      <div className='input-effect with-icon'>
                        <input
                          className='form-control'
                          placeholder='Company Name'
                          name='companyName'
                          id='companyName'
                          maxLength='50'
                          type='text'
                          value={companyName}
                          onChange={(e) => {
                            setTemporaryState(true);
                            handleChange(e);
                          }}
                        />
                        <label htmlFor='firstName'>Company Name</label>
                        <span className='text-danger'>
                          {errors.companyName}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12'>
                    <div className='form-group mb-4'>
                      <div className='custom-checkbox green-border-checkbox with-icon'>
                        <label className='text-black-color'>
                          <input
                            type='checkbox'
                            checked={showCompany}
                            onChange={(e) => {
                              setShowCompany(e.target.checked);
                              setDisabledButton(false);
                              setFormData({
                                ...formData,
                                errors: {
                                  ...errors,
                                  companyName: "",
                                },
                              });
                            }}
                          />
                          <span></span>
                          Display company name to clients
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <div className='input-effect with-icon'>
                        <input
                          className='form-control'
                          placeholder='Email'
                          name='email'
                          id='email'
                          type='text'
                          disabled={emailshow ? false : true}
                          value={email}
                          onChange={(e) => handleChange(e,true)}
                        />
                        <label htmlFor='firstName'>Email</label>
                        {emailshow ? (
                          <React.Fragment>
                            <button
                              type='button'
                              className='edit-input btn btn-primary btn-sm'
                              style={{ right: "80px" }}
                              title='Save'
                              onClick={() =>
                                getOTPtoupdateEmailAndMobile("Email")
                              }
                            >
                              Save
                            </button>
                            <button
                              type='button'
                              className='edit-input btn btn-secondary btn-sm'
                              title='Edit'
                              onClick={() => editMobileAndEmail("Email", false)}
                            >
                              Cancel
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            type='button'
                            className='edit-input btn btn-primary btn-sm'
                            title='Edit'
                            onClick={() => editMobileAndEmail("Email", true)}
                          >
                            Edit
                          </button>
                        )}
                        <span className='text-danger'>{errors.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <div className='input-effect with-icon'>
                        <NumberFormat
                          name='mobile'
                          id='mobile'
                          className='form-control'
                          placeholder='Mobile'
                          disabled={mobileShow ? false : true}
                          format='(###) ###-####'
                          value={mobile}
                          onValueChange={(e) =>
                            handleChange({
                              target: { name: "mobile", value: e.value },
                            })
                          }
                        />
                        <label htmlFor='firstName'>Mobile</label>
                        {mobileShow ? (
                          <React.Fragment>
                            <button
                              type='button'
                              className='edit-input btn btn-primary btn-sm'
                              style={{ right: "80px" }}
                              title='Save'
                              onClick={() =>
                                getOTPtoupdateEmailAndMobile("Mobile")
                              }
                            >
                              Save
                            </button>
                            <button
                              type='button'
                              className='edit-input btn btn-secondary btn-sm'
                              title='Edit'
                              onClick={() =>
                                editMobileAndEmail("Mobile", false)
                              }
                            >
                              Cancel
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            type='button'
                            className='edit-input btn btn-primary btn-sm'
                            title='Edit'
                            onClick={() => editMobileAndEmail("Mobile", true)}
                          >
                            Edit
                          </button>
                        )}
                        <span className='text-danger'>{errors.mobile}</span>
                      </div>
                    </div>
                  </div>
                  {showParentAgencyCode === true ? (
                    <>
                      <div className='col-md-12'>
                        <div>
                          <h3 className='font-24 box-main-title mt-2 mb-1'>
                            Parent Agency Code
                          </h3>
                          <h5 className='title-text'>
                            If you have a parent agency code,enter it here to be
                            connected to that agency.
                          </h5>
                        </div>
                        <hr className='thinborder w-100 mt-0' />
                      </div>
                      <div className='col-md-6'>
                        <div className='tooltip-btn text-right'>
                          <OverlayTrigger
                            placement='bottom-start'
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipOfAgencyCode}
                          >
                            <span className='material-icons'>help_outline</span>
                          </OverlayTrigger>
                        </div>
                        <div className='form-group'>
                          <div className='input-effect'>
                            <input
                              className='form-control'
                              placeholder='Parent Agency Code'
                              name='managerCode'
                              id='managerCode'
                              type='text'
                              value={managerCode}
                              onChange={(e) => handleChange(e)}
                            />
                            <label>Parent Agency Code</label>
                            <button
                              type='button'
                              className='edit-input btn btn-primary btn-sm'
                              title='Search'
                              onClick={() => getEnterpriseAgent()}
                            >
                              Search
                            </button>
                            <em className='input-icon'></em>
                            <span className='text-danger'>
                              {errors.managerCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </form>
                {(getBasePlan(signIn.role).isProfessional ||
                  getBasePlan(signIn.role).isAmbassador) &&
                managerFirstName &&
                managerFirstName.length > 0 ? (
                  <div>
                    <form className='carrierpart-outer border-0 width-text'>
                      <div className='row addmore-outer pr-0'>
                        <div className='col-10'>
                          <div className='form-group'>
                            <div className='input-effect'>
                              <input
                                className='form-control'
                                style={{ opacity: 1 }}
                                placeholder='Parent Agency Name'
                                name='managerCode'
                                id='managerCode'
                                type='text'
                                disabled
                                value={managerFirstName + " " + managerLastName}
                                onChange={(e) => handleChange(e)}
                              />
                              <label style={{ opacity: 1 }}>
                                Parent Agency Name
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-10'>
                          <div className='form-group'>
                            <div className='input-effect'>
                              <input
                                className='form-control'
                                style={{ opacity: 1 }}
                                placeholder='Parent Agency Eamil'
                                name='managerCode'
                                id='managerCode'
                                type='text'
                                disabled
                                value={managerEmail}
                                onChange={(e) => handleChange(e)}
                              />
                              <label style={{ opacity: 1 }}>
                                Parent Agency Email
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            )}
          </Animated>
        </div>
      </div>
      <Modal
        className='plan-modal verification-modal'
        show={verificationModal}
        centered
        onHide={() => handleClose("verificationModal")}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Verify Your {emailText ? "Email" : "Mobile Number"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='signup-otp'>
            <Animated
              animationIn='fadeInRight'
              animationOut='fadeOutLeft'
              animationInDuration={800}
              animationOutDuration={800}
              isVisible={true}
            >
              <div className='form-wrapper'>
                <div className='form-text'>
                  <h3 className='card-text mb-5 text-center'>
                    We've sent the login code to
                    {emailText ? (
                      <span className='text-link'>{email}</span>
                    ) : (
                      <span className='text-link'>
                        +1{" "}
                        {[
                          mobile.slice(0, 3),
                          " ",
                          mobile.slice(3, 6),
                          " ",
                          mobile.slice(6),
                        ].join("")}{" "}
                      </span>
                    )}
                    . To verify your account, please enter the login code below.
                  </h3>
                </div>
                <form>
                  <div className='otp-input-wrapper'>
                    <OtpInput
                      onChange={(otp) => handleOTP(otp)}
                      numInputs={6}
                      inputStyle={"otp-input"}
                      value={otp}
                      isInputNum={true}
                    />
                    <span className='text-danger'>{errors.OTP}</span>
                  </div>
                  <div className='form-group'>
                    <p className='text-center pb-0'>
                      Didn’t receive code yet?
                      {counter === 0 ? (
                        <button
                          className='btn btn-link text-primary'
                          type='button'
                          onClick={resendOtp}
                        >
                          Resend Code
                        </button>
                      ) : (
                        <span className='btn btn-link text-primary'>
                          ({counter})
                        </span>
                      )}
                    </p>
                  </div>
                  <div className='text-center'>
                    <button
                      className='btn btn-primary btn-lg'
                      type='submit'
                      onClick={updateEmailAndMobile}
                    >
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </form>
              </div>
            </Animated>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className='cancel-popup'
        show={BannerLifeAgentCode}
        onHide={() => onHideAgnetCodeModel("close", carrierType, carrierID)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        size='sm'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='pr-4 mw-100'>
            <h3 className='mb-md-4 mb-2'>
              <strong>{carrierName} Agent Code</strong>
            </h3>
            <p>
              {carrierName} is a seamless integration product. This means that
              when your prospect completes their pre-form they will
              automatically receive an invitation from {carrierName} to complete
              their application. You will still receive their contact
              information and status updates.
            </p>
            <h4 className='mb-md-4 mb-2'>
              <strong className='txt-black'>
                Please enter your {carrierName} agent code.
              </strong>
            </h4>
            <div className='form-group col-md-8 p-0'>
              <div className='input-effect'>
                <input
                  className='form-control'
                  placeholder='Banner Life Agent Code'
                  name='agentCode'
                  id='agentCode'
                  type='text'
                  maxLength='10'
                  minLength='7'
                  defaultValue={carrierAgentCode}
                  onChange={(e) =>
                    handleCarrierAgnetCode(
                      e,
                      carrierType,
                      carrierID,
                      "agentCode"
                    )
                  }
                />
                <label htmlFor='firstName'>{carrierName} Agent Code</label>
                <span className='text-danger'>{errors.agentCode}</span>
              </div>
            </div>
            <h4 className='mb-md-2 mb-2'>
              <strong className='txt-black'>
                Please enter your {carrierName} GA code.
              </strong>
            </h4>
            <div className='custom-checkbox'>
              <label style={{ color: "#333", fontSize: "1em" }}>
                <input
                  type='checkbox'
                  id='defaultIlifeGaCode'
                  checked={GACodeValue && isiLifeGACode}
                  onChange={(e) =>
                    handleDefaultGACode(
                      e,
                      carrierType,
                      carrierID,
                      GACodeiLife,
                      GACodeValueStore
                    )
                  }
                />
                <span></span>I am appointed with {carrierName} through iLIfe.
              </label>
            </div>
            <div className='form-group col-md-8 p-0 mt-2'>
              <div className='input-effect'>
                <input
                  className='form-control'
                  placeholder='Banner Life GA Code'
                  name='gaCode'
                  id='gaCode'
                  type='text'
                  disabled={GACodeValue && isiLifeGACode}
                  value={GACodeValue}
                  onChange={(e) =>
                    handleCarrierAgnetCode(e, carrierType, carrierID, "gaCode")
                  }
                />
                <label htmlFor='firstName'>{carrierName} GA Code</label>
              </div>
            </div>
            <div className='d-flex '>
              <Link
                to='#'
                variant='primary'
                className='ml-auto font-weight-bold'
                onClick={() =>
                  onHideAgnetCodeModel("submit", carrierType, carrierID)
                }
              >
                SUBMIT
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <IlifePrompt
        when={temporaryState}
        title='Changes not saved'
        subtitle='Are you sure want to navigate away from this page? Your changes have not been saved.'
        cancelText='Cancel'
        loading={isUpdating}
        okText='Save Changes'
        onOK={forceChangeDone}
        ref={childRef}
        validateFunc={() => true}
        closeState={setTemporaryState}
        handleOK={(e) =>
          getBasePlan(signIn.role).isAmbassador
            ? submitAmbassadorProfile(e, true)
            : submitEnterpriseProfessionalProfile(e, pauseLead, "", true)
        }
        // handleCancel={() => setForceChange(true)}
        onCancel={() => true}
      />
      <DeleteModel
        show={showDeleteModel}
        onHide={setDeleteModel}
        isDeleting={isDeleting}
        deleteList={() => removePhoto()}
        title={"Remove Profile Picture?"}
        body={
          "Are you sure you want to permanently remove this Profile Picture? This action cannot be undone."
        }
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    referal_code: state.agentReducer.referalCode,
    profile_pic: state.agentReducer.profile_pic,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};

export default connect(mapStateToProps, {
  commonApiCall,
  updateAgent,
  profilePicture,
  CarrierAppointmentData,
})(withRouter(MyProfile));
