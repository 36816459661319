import React from "react";
import { Button, Modal } from "react-bootstrap";
const DeleteModel = ({
  show,
  onHide,
  isDeleting,
  deleteList,
  title,
  body,
  subBody,
  btnText='Delete'
}) => {
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="delete-modal"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="font-14 txt-light-black">{body}</p>
        {subBody && <p className="txt-light-black">{subBody}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-grey btn-sm-radius"
          disabled={isDeleting}
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
        <Button
          variant="info btn-sm-radius on-hover-gradiant"
          disabled={isDeleting}
          onClick={() => deleteList()}
        >
          {btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModel;
