import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import Layout from "../../components/layoutComponents/layout";
import DOMPurify from "dompurify";
import { EmailDetailsLoader } from "../../components/skeletonLoaders/emailDetailsLoader";
import {
  commonApiCall,
  DashboardPagesRoutes,
  // decryptData,
  setItem,
  getItem,
  removeItem,
} from "../../utlis";

import "suneditor/dist/css/suneditor.min.css";

function SentEmailDetails({ sign_in, ...props }) {
  const [emailDetailData, setEmailDetailData] = useState(null);
  let sentEmailDetail = getItem("sentEmailData");
  useEffect(() => {
    if (sentEmailDetail && !isEmpty(sentEmailDetail)) {
      let sentEmailClientData = JSON.parse(sentEmailDetail);
      setEmailDetailData(sentEmailClientData);
    }
  }, [sentEmailDetail]);

  const onClickCampaignName = (val) => {
    setItem("sentEmailClientData", JSON.stringify(val));
    props.history.push(DashboardPagesRoutes.CLIENT_EMAIL_DETAIL);
  };

  const onBack = () => {
    setItem("defaultTabs", "sendEmail");
    props.history.push(DashboardPagesRoutes.EMAIL);
  };
  useEffect(() => {
    return () => {
      removeItem("sentEmailData");
    };
  }, []);
  return (
    <Layout>
      {emailDetailData ? (
        <div className="row content-container">
          <div className="col-12">
            <Link
              to="#"
              onClick={() => onBack()}
              className="font-16 d-block mb-4 mt-2"
            >{`< Back to Sent emails`}</Link>
            <div className="white-box2 email-detail">
              <h2 className="font-20 txt-light-black mb-4 text-capitalize">
                {emailDetailData?.emailCampaignName}
              </h2>
              <div className="mb-4">
                <p className="font-14 pb-1">Subject</p>
                <h3 className="font-16 txt-light-black">
                  {emailDetailData?.subject}
                </h3>
              </div>
              <div className="mb-4">
                <p className="font-14 pb-1">From</p>
                <h3 className="font-16 txt-light-black">
                  {emailDetailData?.fromEmail}

                  {/*emailDetailData?.fromEmail
                  ? decryptData(emailDetailData?.fromEmail)
                : "N/A"*/}
                </h3>
              </div>
              <div className="mb-4">
                <p className="font-14 pb-1">To</p>

                <Link
                  className="font-16 d-block mb-4"
                  to="#"
                  onClick={() => onClickCampaignName(emailDetailData)}
                >
                  {emailDetailData?.listName}
                </Link>
              </div>
              <div className="mb-4">
                <p className="font-14 pb-1">Content</p>
                <div className="content-box ">
                  <div className="d-flex flex-wrap ">
                    <div className="form-group  mt-2 mb-5 pb-md-3 w-100">
                      {emailDetailData?.agentTemplateEditorCategory ===
                      "Advanced" ? (
                        <p
                          className="txt-light-black pb-0"
                          dangerouslySetInnerHTML={{
                            __html: emailDetailData?.content
                              ? DOMPurify.sanitize(
                                  JSON.parse(emailDetailData.content)?.html
                                )
                              : "",
                          }}
                        ></p>
                      ) : (
                        <p
                          className="txt-light-black pb-0"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(emailDetailData.content),
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmailDetailsLoader />
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn.data,
  };
};
export default withRouter(
  connect(mapStateToProps, { commonApiCall })(SentEmailDetails)
);
