import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ReminderLoader = ({color, ...props}) => {
  return (
    <SkeletonTheme>
      <div className="row">
      {[...Array(8)].map((_,index) => (
        <div  key={index} className="col-md-3 col-sm-6">
          <div className= {`reminder-template-main ${color}`}>
            <div className="reminder-header">
              <p className="font-14 pb-0 lh-1 reminder-title">
                <Skeleton count={1} height={30} width={'100%'} />
              </p>
            </div>
            <div className="reminder-content ">
                <Skeleton count={1} height={60} width={"100%"}  />
            </div>
            <div className="reminder-btn">
              <p className="font-14 pb-0 lh-1">
                <Skeleton count={1} height={30} width={"100%"}  />
              </p>
            </div>
          </div>
        </div>
     ))}
     </div>
    </SkeletonTheme>
  );
};
