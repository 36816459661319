import React from "react";
import Layout from "../../components/layoutComponents/layout";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { ShowForPermission, checkAccess } from "../../utlis";
import MyProfile from "./myProfile";
import LiveLTSettings from "./liveLTSettings";
import SubUser from "../settings/subUsers";
import NotificationSettings from "./notificationSettings";
import LicensedStates from "./licensedStates";
import CarrierOptions from "./carrierOptions";
import Livetransfer from "./livetransfer";
import { commonApiCall } from "../../utlis";
import { API_POINT } from "../../config/config";
import { useState } from "react";
import { useEffect } from "react";

const AccountSettings = ({ sign_in, planData, commonApiCall, ...props }) => {
  const [show, setShow] = useState({
    ilife: false,
    medicare: false,
  });
  const accountPath = useLocation().pathname.split("/")[2];

  const gotoprofile = () => {
    props.history.push("/account-settings/profile");
  };

  useEffect(() => {
    getLeadData();
  }, []);

  const getLeadData = async () => {
    const response = await commonApiCall(
      API_POINT + "/getlifePauseLead",
      "",
      "get",
      null,
      true
    );
    if (response.status === 200 && response.data.status.code === 200) {
      setShow({
        ilife: response?.data?.data?.lifePurchased,
        medicare: response?.data?.data?.medicarePurchased,
      });
    }
  };

  const getAccountComponent = () => {
    switch (accountPath) {
      case "profile":
        return <MyProfile />;
      case "license-state":
        return <LicensedStates />;
      case "carrier-options":
        return <CarrierOptions />;
      case "admin-users":
        return (
          showSubUserBlock() && (
            <ShowForPermission permission="AUTH_USERS">
              <SubUser />
            </ShowForPermission>
          )
        );

      case "messages":
        return checkAccess(planData, "NOTIFICATION_SETTING") ||
          sign_in?.data?.currentPlanSlug.toLowerCase() === "enterprise" ? (
          <NotificationSettings />
        ) : null;

      case "purchase-leads":
        return <LiveLTSettings />;

      case "live-transfers":
        return <Livetransfer show={show} />;

      default:
        gotoprofile();
        break;
    }
  };
  const showSubUserBlock = () => {
    if (
      sign_in?.data?.currentPlanSlug?.toUpperCase() === "FREE" ||
      sign_in?.data?.currentPlanSlug?.toLowerCase() === "professional" ||
      checkAccess(planData, "AUTH_USERS")
    ) {
      return true;
    }
  };

  const getActiveClass = (name) => {
    return accountPath === name ? "active" : "";
  };
  return (
    <Layout>
      <div className="row">
        <div className="col-lg-12 content-container">
          <div className="white-box purchase-page">
            {/* <div className="row">
              <div className="col-12">
                <div className="box-title  border-0">
                  <h3 className="font-24 box-main-title">Account Settings</h3>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-12">
                <div className="account-setting-tab">
                  <Nav className="mb-3" variant="tabs" defaultActiveKey="/home">
                    <Nav.Item>
                      <Nav.Link
                        onClick={() =>
                          props.history.push("/account-settings/profile")
                        }
                        className={getActiveClass("profile")}
                      >
                        Profile
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        onClick={() =>
                          props.history.push("/account-settings/license-state")
                        }
                        className={getActiveClass("license-state")}
                      >
                        Licensed States
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        className={getActiveClass("carrier-options")}
                        onClick={() =>
                          props.history.push(
                            "/account-settings/carrier-options"
                          )
                        }
                      >
                        Quoting Options
                      </Nav.Link>
                    </Nav.Item>

                    {checkAccess(planData, "NOTIFICATION_SETTING") ||
                    sign_in?.data?.currentPlanSlug.toLowerCase() ===
                      "enterprise" ? (
                      <Nav.Item>
                        <Nav.Link
                          className={getActiveClass("messages")}
                          onClick={() =>
                            props.history.push("/account-settings/messages")
                          }
                        >
                          {checkAccess(planData, "NOTIFICATION_SETTING")
                            ? "Messages"
                            : "Purchased Leads"}
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {showSubUserBlock() && (
                      <Nav.Item>
                        <Nav.Link
                          className={getActiveClass("admin-users")}
                          onClick={() =>
                            props.history.push("/account-settings/admin-users")
                          }
                        >
                          Admin Users
                        </Nav.Link>
                      </Nav.Item>
                    )}

                    {sign_in?.data?.retreaverId && (
                      <Nav.Item>
                        <Nav.Link
                          className={getActiveClass("purchase-leads")}
                          onClick={() =>
                            props.history.push(
                              "/account-settings/purchase-leads"
                            )
                          }
                        >
                          Purchased Leads
                        </Nav.Link>
                      </Nav.Item>
                    )}

                    {(show?.ilife || show?.medicare) &&
                      planData?.liveTransfer && (
                        <Nav.Item>
                          <Nav.Link
                            className={getActiveClass("live-transfers")}
                            onClick={() =>
                              props.history.push(
                                "/account-settings/live-transfers"
                              )
                            }
                          >
                            Live Transfers
                          </Nav.Link>
                        </Nav.Item>
                      )}

                    {/* {show?.ilife && (
                      <Nav.Item>
                        <Nav.Link
                          className={getActiveClass("purchase-leads")}
                          onClick={() =>
                            props.history.push(
                              "/account-settings/purchase-leads"
                            )
                          }
                        >
                          Purchased Leads
                        </Nav.Link>
                      </Nav.Item>
                    )} */}

                    {/* {(show?.ilife || show?.medicare) &&
                      planData?.liveTransfer &&
                      <Nav.Item>
                        <Nav.Link
                          className={getActiveClass("live-transfers")}
                          onClick={() =>
                            props.history.push("/account-settings/live-transfers")
                          }
                        >
                          Live Transfers
                        </Nav.Link>
                      </Nav.Item>} */}
                  </Nav>
                  {/* <Tabs defaultActiveKey="Profile" className="mb-3">
                    <Tab
                      /// eventKey="Profile"
                      onClick={() => {
                        console.log("called");
                        props.history.push("/account-settings/profile");
                      }}
                      title="Profile"
                    ></Tab>
                    <Tab
                      onClick={() =>
                        props.history.push("/account-settings/license-state")
                      }
                      // eventKey="LicensedStates"
                      title="Licensed States"
                    ></Tab>
                    <Tab
                      onClick={() =>
                        props.history.push("/account-settings/carrier-options")
                      }
                      eventKey="CarrierOptions"
                      title="Carrier Options"
                    ></Tab>
                    {showSubUserBlock() && (
                      <Tab
                        eventKey="Admin Users"
                        onClick={() =>
                          props.history.push("/account-settings/admin-users")
                        }
                        title="Admin Users"
                      >
                         <ShowForPermission permission="AUTH_USERS">
                          <SubUser />
                        </ShowForPermission> 
                      </Tab>
                    )}
                    <Tab
                      eventKey="Messages"
                      onClick={() =>
                        props.history.push("/account-settings/messages")
                      }
                      title="Messages"
                    ></Tab>
                    {sign_in?.data?.retreaverId ? (
                      <Tab
                        eventKey="Purchased Leads"
                        title="Purchased Leads"
                        onClick={() =>
                          props.history.push("/account-settings/purchase-leads")
                        }
                      ></Tab>
                    ) : null}
                  </Tabs> */}

                  {getAccountComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(AccountSettings)
);
