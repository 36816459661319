import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Switch from "react-switch";
import { checkAccess, showErrorToast, showSuccessToast } from "../../utlis";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall } from "../../utlis";
import { API_POINT } from "../../config/config";
import { useEffect } from "react";

const Livetransfer = ({ commonApiCall, show }) => {
  const [checked, setChecked] = useState({
    lifePauseLead: false,
    medicarePauseLead: false,
  });

  const returnPopOver = (props) => (
    <div id='button-tooltip' {...props}>
      <div className='tooltip-outer'>
        Leads will not be assigned to you
        <br /> for as long as pause leads is
        <br />
        selected
      </div>
    </div>
  );

  useEffect(() => {
    getLeadData();
  }, []);

  const getLeadData = async () => {
    const response = await commonApiCall(
      API_POINT + "/getlifePauseLead",
      "",
      "get",
      null,
      true
    );
    if (response.status === 200 && response.data.status.code === 200) {
      setChecked({
        lifePauseLead: response?.data?.data?.lifePauseLead,
        medicarePauseLead: response?.data?.data?.medicarePauseLead,
      });
    } else {
      showErrorToast(response.status.messsage);
    }
  };

  const changeLeadStatus = async (check, name) => {
    let body;
    if (name === "medicare") {
      await setChecked({
        ...checked,
        medicarePauseLead: !check,
      });

      body = {
        medicarePauseLead: !check,
        source: "medicare",
      };
    } else {
      await setChecked({
        ...checked,
        lifePauseLead: !check,
      });
      body = {
        lifePauseLead: !check,
        source: "life",
      };
    }

    let response = await commonApiCall(
      API_POINT + "/saveLifePauseLead",
      body,
      "post",
      null,
      true
    );
    if (response.status === 200 && response.data.status.code === 200) {
      showSuccessToast(response.status.messsage);
      await getLeadData();
    } else {
      showErrorToast(response.status.messsage);
    }
  };

  return (
    <div>
      {(show.ilife || show.medicare) && (
        <h4 className='font-20 txt-light-black mb-md-4 pb-2'>Live Transfers</h4>
      )}
      {show?.ilife && (
        <div className='border p-3'>
          <strong className='font-16 txt-light-black'>
            Life Insurance Leads
          </strong>

          <div className='d-flex align-items-center mt-3'>
            {" "}
            <Switch
              onChange={(checked) => {
                changeLeadStatus(checked, "life");
              }}
              checked={
                checked?.lifePauseLead?.toString() === "true" ? false : true
              }
              height={24}
              width={48}
              handleDiameter={18}
              aria-label='test'
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 16,
                    color: "#fff",
                    paddingRight: 2,
                    fontWeight: "500",
                  }}
                />
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 16,
                    color: "#fff",
                    paddingRight: 2,
                    fontWeight: "500",
                  }}
                />
              }
            />
            <p className='mb-0 pb-0 ml-2 txt-light-black'>
              {checked.lifePauseLead ? "Paused" : "Active"}
            </p>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement='bottom-start'
              delay={{ show: 250, hide: 400 }}
              overlay={returnPopOver}
            >
              <sup>
                {" "}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  width={15}
                  height={15}
                  viewBox='0 0 256 256'
                  xmlSpace='preserve'
                  className='ml-2 flex-shrink-0'
                >
                  <defs></defs>
                  <g transform='translate(128 128) scale(0.72 0.72)' style={{}}>
                    <g
                      style={{
                        stroke: "none",
                        strokeWidth: 0,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "none",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                      transform='translate(-175.05 -175.05000000000004) scale(3.89 3.89)'
                    >
                      <path
                        d='M 45 61.898 c -1.657 0 -3 -1.344 -3 -3 v -6.612 c 0 -0.825 0.34 -1.614 0.94 -2.182 l 9.553 -9.019 c 1.873 -1.946 2.903 -4.502 2.903 -7.206 v -0.357 c 0 -2.827 -1.115 -5.471 -3.141 -7.445 c -2.025 -1.974 -4.693 -3.031 -7.532 -2.947 c -5.58 0.144 -10.12 4.941 -10.12 10.694 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 c 0 -8.978 7.162 -16.465 15.965 -16.692 c 4.467 -0.122 8.68 1.536 11.874 4.648 c 3.194 3.113 4.953 7.283 4.953 11.742 v 0.357 c 0 4.295 -1.649 8.356 -4.646 11.434 c -0.029 0.03 -0.06 0.06 -0.09 0.089 L 48 53.579 v 5.319 C 48 60.555 46.657 61.898 45 61.898 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                      <path
                        d='M 45 73.87 c -0.26 0 -0.52 -0.021 -0.78 -0.07 c -0.26 -0.06 -0.51 -0.13 -0.75 -0.23 c -0.24 -0.1 -0.47 -0.229 -0.69 -0.369 c -0.22 -0.15 -0.42 -0.311 -0.61 -0.5 C 41.43 71.96 41 70.93 41 69.87 c 0 -0.261 0.03 -0.521 0.08 -0.78 c 0.05 -0.25 0.13 -0.51 0.23 -0.75 s 0.22 -0.47 0.36 -0.69 c 0.15 -0.22 0.32 -0.42 0.5 -0.609 c 0.19 -0.181 0.39 -0.351 0.61 -0.49 c 0.22 -0.15 0.45 -0.27 0.69 -0.37 c 0.24 -0.1 0.49 -0.18 0.75 -0.229 c 0.51 -0.101 1.05 -0.101 1.56 0 c 0.26 0.05 0.51 0.13 0.75 0.229 c 0.239 0.101 0.47 0.22 0.689 0.37 c 0.22 0.14 0.42 0.31 0.61 0.49 c 0.18 0.189 0.35 0.39 0.5 0.609 c 0.14 0.221 0.26 0.45 0.359 0.69 c 0.101 0.24 0.181 0.5 0.23 0.75 c 0.05 0.26 0.08 0.52 0.08 0.78 c 0 1.06 -0.431 2.09 -1.17 2.83 C 47.08 73.45 46.05 73.87 45 73.87 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                      <path
                        d='M 45 90 C 20.187 90 0 69.813 0 45 S 20.187 0 45 0 s 45 20.187 45 45 S 69.813 90 45 90 z M 45 6 C 23.495 6 6 23.495 6 45 s 17.495 39 39 39 s 39 -17.495 39 -39 S 66.505 6 45 6 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                    </g>
                  </g>
                </svg>
              </sup>
            </OverlayTrigger>
          </div>
        </div>
      )}
      {show?.medicare && (
        <div className='border p-3'>
          <strong className='font-16 txt-light-black'>Medicare Leads</strong>

          <div className='d-flex align-items-center mt-3'>
            {" "}
            <Switch
              onChange={(checked) => {
                changeLeadStatus(checked, "medicare");
              }}
              checked={
                checked?.medicarePauseLead?.toString() === "true" ? false : true
              }
              height={24}
              width={48}
              handleDiameter={18}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 16,
                    color: "#fff",
                    paddingRight: 2,
                    fontWeight: "500",
                  }}
                />
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 16,
                    color: "#fff",
                    paddingRight: 2,
                    fontWeight: "500",
                  }}
                />
              }
            />
            <p className='mb-0 pb-0 ml-2 txt-light-black'>
              {checked?.medicarePauseLead ? "Paused" : "Active"}
            </p>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement='bottom-start'
              delay={{ show: 250, hide: 400 }}
              overlay={returnPopOver}
            >
              <sup>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  version='1.1'
                  width={15}
                  height={15}
                  viewBox='0 0 256 256'
                  xmlSpace='preserve'
                  className='ml-2 flex-shrink-0'
                >
                  <defs></defs>
                  <g transform='translate(128 128) scale(0.72 0.72)' style={{}}>
                    <g
                      style={{
                        stroke: "none",
                        strokeWidth: 0,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "none",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                      transform='translate(-175.05 -175.05000000000004) scale(3.89 3.89)'
                    >
                      <path
                        d='M 45 61.898 c -1.657 0 -3 -1.344 -3 -3 v -6.612 c 0 -0.825 0.34 -1.614 0.94 -2.182 l 9.553 -9.019 c 1.873 -1.946 2.903 -4.502 2.903 -7.206 v -0.357 c 0 -2.827 -1.115 -5.471 -3.141 -7.445 c -2.025 -1.974 -4.693 -3.031 -7.532 -2.947 c -5.58 0.144 -10.12 4.941 -10.12 10.694 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 c 0 -8.978 7.162 -16.465 15.965 -16.692 c 4.467 -0.122 8.68 1.536 11.874 4.648 c 3.194 3.113 4.953 7.283 4.953 11.742 v 0.357 c 0 4.295 -1.649 8.356 -4.646 11.434 c -0.029 0.03 -0.06 0.06 -0.09 0.089 L 48 53.579 v 5.319 C 48 60.555 46.657 61.898 45 61.898 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                      <path
                        d='M 45 73.87 c -0.26 0 -0.52 -0.021 -0.78 -0.07 c -0.26 -0.06 -0.51 -0.13 -0.75 -0.23 c -0.24 -0.1 -0.47 -0.229 -0.69 -0.369 c -0.22 -0.15 -0.42 -0.311 -0.61 -0.5 C 41.43 71.96 41 70.93 41 69.87 c 0 -0.261 0.03 -0.521 0.08 -0.78 c 0.05 -0.25 0.13 -0.51 0.23 -0.75 s 0.22 -0.47 0.36 -0.69 c 0.15 -0.22 0.32 -0.42 0.5 -0.609 c 0.19 -0.181 0.39 -0.351 0.61 -0.49 c 0.22 -0.15 0.45 -0.27 0.69 -0.37 c 0.24 -0.1 0.49 -0.18 0.75 -0.229 c 0.51 -0.101 1.05 -0.101 1.56 0 c 0.26 0.05 0.51 0.13 0.75 0.229 c 0.239 0.101 0.47 0.22 0.689 0.37 c 0.22 0.14 0.42 0.31 0.61 0.49 c 0.18 0.189 0.35 0.39 0.5 0.609 c 0.14 0.221 0.26 0.45 0.359 0.69 c 0.101 0.24 0.181 0.5 0.23 0.75 c 0.05 0.26 0.08 0.52 0.08 0.78 c 0 1.06 -0.431 2.09 -1.17 2.83 C 47.08 73.45 46.05 73.87 45 73.87 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                      <path
                        d='M 45 90 C 20.187 90 0 69.813 0 45 S 20.187 0 45 0 s 45 20.187 45 45 S 69.813 90 45 90 z M 45 6 C 23.495 6 6 23.495 6 45 s 17.495 39 39 39 s 39 -17.495 39 -39 S 66.505 6 45 6 z'
                        style={{
                          stroke: "none",
                          strokeWidth: 1,
                          strokeDasharray: "none",
                          strokeLinecap: "butt",
                          strokeLinejoin: "miter",
                          strokeMiterlimit: 10,
                          fill: "rgb(0,0,0)",
                          fillRule: "nonzero",
                          opacity: 1,
                        }}
                        transform=' matrix(1 0 0 1 0 0) '
                        strokeLinecap='round'
                      />
                    </g>
                  </g>
                </svg>
              </sup>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};

export default connect(mapStateToProps, { commonApiCall })(
  withRouter(Livetransfer)
);
