const INTIAL_STATE = {
  sendOtpViaSms: {},
  signIn: {},
  subscribedPlans: {},
  selectProfessional: {},
  questionsStep: {},
  recommended: "",
  agentPlan: "",
  register: {},
  agentUserId: "",
  referalCode: "",
  visitorsMonth: "",
  carrierAppointmentData: null,
  userOnboardEnableCarriers: null,
  productSelctionPageData: null,
  activeCarriersProduct: null,
  userOnboardDisableCarriers: null,
  userOnboardSelectedState: null,
  appointmentedCarrier: null,
  discountPrice: null,
  profile_pic: {},
  applicationDetails: {},
  applicationsDetail: {},
  getCarriersByProduct: null,
  getSelectedLicendState: null,
  getSelectedCarriersByProduct: null,
  emailVerificationData: {},
  applicationID: {},
  conversationdetails: {},
  AgtAvailiabilty: "",
  saveCarrier: {},
  leadPackages: {},
  inviteAgentDetails: {},
  paymentDetails: {},
  policyDetails: {},
  underWritingDeatils: null,
  accordionData: {
    data: {
      crm: true,
      enterprise: true,
      settings: true,
      myWebsite: true,
      businessTools: true,
    },
  },
  accordianFullDetails: {
    data: {
      basicDetails: true,
      sourceDetails: true,
      policyDetails: true,
      policyInformationTab: true,
      factFinderFirst: true,
      factFinderSecond: true,
      factFinderThird: true,
    },
    planDetails: {},
    paymentDetails: {},
  },
  leadCount: null,
  getSavedMyTemplateData: null,
  getClientPolicyDetail: null,
  getClientPolicyDetailOnCall: null,
  getLeadCount: null,
  carrierDocuments: null,
  activeConnection: null,
  callStarted: "no",
  globalLoader: false,
  redirectId: {},
  showMenu: false,
  application_details: {},
  agent_data_details: {},
  agencyFilter: {}
};
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SEND_OTP":
      return Object.assign({}, state, { sendOtpViaSms: action.payload.data });
    case "AGENT_LOGIN":
      return Object.assign({}, state, { signIn: action.payload.data });
    case "SUBSCRIPTION_PLANS":
      return Object.assign({}, state, { subscribedPlans: action.payload.data });
    case "QUESTIONS_STEP":
      return Object.assign({}, state, { questionsStep: action.payload.data });
    case "REGISTER_AGENT":
      return Object.assign({}, state, { register: action.payload.data });
    case "GET_DETAILS_APPLICATIONS":
      return Object.assign({}, state, {
        applicationDetails: action.payload.data,
      });
    case "EMAIL_VERIFICATION":
      return Object.assign({}, state, {
        emailVerificationData: action.payload.data,
      });
    case "LEAD_PACKAGES":
      return Object.assign({}, state, { leadPackages: action.payload });

    case "SELECT_PROFESSIONAL":
      return Object.assign({}, state, { selectProfessional: action.payload });

    case "RECOMMENDED_PLANS":
      return Object.assign({}, state, { recommended: action.payload });
    case "AGENT_PLAN":
      return Object.assign({}, state, { agentPlan: action.payload });
    case "AGENT_USERID":
      return Object.assign({}, state, { agentUserId: action.payload });
    case "REGISTER_URL":
      return Object.assign({}, state, { registerUrl: action.payload });
    case "REFERAL_CODE":
      return Object.assign({}, state, { referalCode: action.payload });
    case "VISITOR_MONTH":
      return Object.assign({}, state, { visitorsMonth: action.payload });
    case "CARRIER_APPOINTMENT_DATA":
      return Object.assign({}, state, {
        carrierAppointmentData: action.payload,
      });
    case "PRODUCT_SELCTION_PAGE_DATA":
      return Object.assign({}, state, {
        productSelctionPageData: action.payload,
      });
    case "ACTIVE_CARRIERS_PRODUCT":
      return Object.assign({}, state, {
        activeCarriersProduct: action.payload,
      });
    case "GET_SELECTED_CARRIERS_BY_PRODUCT":
      return Object.assign({}, state, {
        getSelectedCarriersByProduct: action.payload,
      });
    case "GET_CARRIERS_BY_PRODUCT":
      return Object.assign({}, state, {
        getCarriersByProduct: action.payload,
      });
    case "GET_SELECTED_LICEND_STATE":
      return Object.assign({}, state, {
        getSelectedLicendState: action.payload,
      });
    case "USER_ONBOARD_EBABLE_CARRIERS":
      return Object.assign({}, state, {
        userOnboardEnableCarriers: action.payload,
      });
    case "USER_ONBOARD_DISABLE_CARRIERS":
      return Object.assign({}, state, {
        userOnboardDisableCarriers: action.payload,
      });
    case "USER_ONBOARD_SELECTED_STATE":
      return Object.assign({}, state, {
        userOnboardSelectedState: action.payload,
      });
    case "UNDERWRITING_DETAILS":
      return Object.assign({}, state, {
        underWritingDeatils: action.payload,
      });
    case "APPOINTMENTED_CARRIER":
      return Object.assign({}, state, {
        appointmentedCarrier: action.payload,
      });
    case "DISCOUNT_AMOUNT":
      return Object.assign({}, state, {
        discountPrice: action.payload,
      });
    case "PROFILE_PICTURE":
      return Object.assign({}, state, { profile_pic: action.payload });
    case "APPLICATION_ID":
      return Object.assign({}, state, { applicationID: action.payload });
    case "CONVERSATION_DETAILS":
      return Object.assign({}, state, { conversationdetails: action.payload });
    case "AGT_AVALIABILITY":
      return Object.assign({}, state, { AgtAvailiabilty: action.payload });
    case "SAVE_CARRIER":
      return Object.assign({}, state, { saveCarrier: action.payload });

    case "INVITE_AGENT":
      return Object.assign({}, state, { inviteAgentDetails: action.payload });

    case "ACCORDION_DATA":
      return Object.assign({}, state, { accordionData: action.payload });
    case "FULL_DETAILS_ACCORDION_DATA":
      return Object.assign({}, state, { accordianFullDetails: action.payload });

    case "FULL_DETAILS_ACCORDION_RESET":
      return Object.assign({}, state, {
        accordianFullDetails: {
          data: {
            basicDetails: true,
            sourceDetails: true,
            policyDetails: true,
            policyInformationTab: true,
            factFinderFirst: true,
            factFinderSecond: true,
            factFinderThird: true,
          },
        },
      });
    case "PLAN_DETAILS":
      return Object.assign({}, state, { planDetails: action.payload });
    case "POLICY_DETAILS":
      return Object.assign({}, state, { policyDetails: action.payload });

    case "PAYMENT_DETAILS":
      return Object.assign({}, state, { paymentDetails: action.payload });

    case "LEAD_COUNT_DETAILS":
      return Object.assign({}, state, { leadCount: action.payload });

    case "GET_LEAD_COUNT_DETAILS":
      return Object.assign({}, state, { getLeadCount: action.payload });
    case "CARRIER_DOCUMENTS":
      return Object.assign({}, state, { carrierDocuments: action.payload });

    case "SAVED_MY_TEMPLATE_DRAFT_DATA":
      return Object.assign({}, state, {
        getSavedMyTemplateData: action.payload,
      });
    case "CLIENT_POLICY_DETAIL":
      return Object.assign({}, state, {
        getClientPolicyDetail: action.payload,
      });

    case "SAVE_APPLICATION_DETAIL":
      return Object.assign({}, state, {
        applicationsDetail: action.payload,
      });

    case "CLIENT_POLICY_DETAIL_On_CALL":
      return Object.assign({}, state, {
        getClientPolicyDetailOnCall: action.payload,
      });
    case "TWILLIO_DEVICE_CHECK":
      return Object.assign({}, state, {
        activeConnection: action.payload,
      });
    case "CALL_STARTED":
      return Object.assign({}, state, {
        callStarted: action.payload,
      });
    case "GLOBAL_LOADER":
      return Object.assign({}, state, { globalLoader: action.payload });
    case "LOGOUT":
      return INTIAL_STATE;
    case "REDIRECT":
      return Object.assign({}, state, { redirectId: action.payload });
    case "SHOWMENU":
      return Object.assign({}, state, { showMenu: action.payload });
    case "APPLICATION_DETAIL_DATA":
      return Object.assign({}, state, {
        application_details: action.payload,
      });
    case "AGENT_DETAIL_DATA":
      return Object.assign({}, state, {
        agent_data_details: action.payload,
      });
    case "SET_AGENCY_FILTER":
      return Object.assign({}, state, {
        agencyFilter: action.payload,
      });

    default:
      return state;
  }
};
