import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

const IlifePrompt = forwardRef((props, ref) => {
  const {
    when,
    title,
    loading,
    okText,
    cancelText,
    subtitle,
    handleOK,
    onOk = false,
    validateFunc,
    closeState
  } = props

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  const unblockRef = useRef()

  function handleShowModal() {
    setShowPrompt(true)
  }

  useEffect(() => {
   // console.log('called')
  }, [onOk])

  function onCancel(e) {
    if (unblockRef) {
      unblockRef.current()
    }
    e.preventDefault()
    closeState(false);
    setShowPrompt(false)
    if (validateFunc()) {
      history.push(currentPath)
    }
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname)
        handleShowModal()
        return false
      }
      return true
    })
    return () => {
      unblockRef.current && unblockRef.current()
    }
  }, [when])

  useEffect(() => {
    if (onOk) {
      setShowPrompt(false)
      history.push(currentPath)
    }
  }, [onOk])

  function handleConfirm(e) {
    if (unblockRef) {
      unblockRef.current()
    }

    handleOK(e)
  }

  useImperativeHandle(ref, () => ({
    getAlert() {
      setShowPrompt(false)
      history.push(currentPath)
    },
  }))
  // const {
  //   when,
  //   onOK,
  //   onCancel,
  //   title,
  //   okText,
  //   cancelText,
  //   subtitle,

  //   handleOK,
  //   loading,
  //   promptPath = null,
  // } = props;

  // useEffect(() => {
  //   if (when) {
  //     history.block((prompt) => {
  //       setCurrentPath(prompt.pathname);
  //       setShowPrompt(true);
  //       if (promptPath) {
  //         promptPath(prompt.pathname);
  //       }
  //       return "true";
  //     });
  //   } else {
  //     history.block(() => {});
  //   }

  //   return () => {
  //     history.block(() => {});
  //   };
  // }, [history, when]);

  // useEffect(() => {
  //   onOkCheck();
  // }, [onOK]);

  // const onOkCheck = async () => {
  //   if (onOK) {
  //     const canRoute = await Promise.resolve(onOK());

  //     if (canRoute) {
  //       history.block(() => {});
  //       history.push(currentPath);
  //     }
  //   }
  // };

  // useEffect(async () => {
  //   if (onCancel) {
  //     const canRoute = await Promise.resolve(onCancel());
  //     if (canRoute) {
  //       history.block(() => {});
  //       history.push(currentPath);
  //     }
  //   }
  //   setShowPrompt(false);
  // }, [onCancel]);

  // const handleOK = useCallback(async () => {}, [currentPath, history, onOK]);

  // const handleCancel = useCallback(async () => {
  //   if (onCancel) {
  //     const canRoute = await Promise.resolve(onCancel());
  //     if (canRoute) {
  //       history.block(() => {});
  //       history.push(currentPath);
  //     }
  //   }
  //   setShowPrompt(false);
  // }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      className="cancel-popup"
      show={showPrompt}
      onHide={() => setShowPrompt(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      size="sm"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <h3 className="font-weight-bold mb-3">{title}</h3>
        <p>{subtitle}</p>
        <div
          className="d-flex justify-content-end p-0"
          style={{ maxWidth: '100%' }}
        >
          <Link
            to="#"
            onClick={onCancel}
            className="font-weight-bold text-muted"
          >
            {cancelText}
          </Link>
          <Link
            to="#"
            variant="primary"
            onClick={handleConfirm}
            className="text-primary font-weight-bold ml-3"
          >
            {loading ? 'Processing...' : okText}
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  ) : null
})

export default IlifePrompt
