import React, { useMemo } from "react";
import { connect } from "react-redux";

const ProgressLayout = ({
  activeProduct,
  selected_product
}) => {
  const currentPath = window.location.pathname.split("/")[1];
  const productsSelected = useMemo(() => {
    return selected_product?.filter((item) => item.isChecked) || []
  }, [selected_product]);

  const getCurrentPathProgress = () => {
    switch (currentPath) {
      case "welcomePage":
        return 0;
      case "product-selection":
        return 1;
      case "carriers-selection":
        let i = 0
        while (i < productsSelected.length) {
          if (productsSelected[i].id === activeProduct) {
            return 2 + (i/productsSelected.length);
          }
          i++;
        }
      case "state-selection":
        return 3;
      case "time-zone-selection":
        return 4;
      default:
        return 0;
    }
  };

  const getProgressCount = useMemo(() => (current, total) => {
    return Math.round((current / total) * 100);
  }, [activeProduct]);

  return (
    <span className="c-progress-bar">
      <span style={{ width: `${getProgressCount(getCurrentPathProgress(), 5)}%` }}>
      </span>
    </span>

    /*  <progress
       className="progressbar"
       value={getProgressCount(getCurrentPathProgress(), 4 + productsSelected.length)}
       max="100">
     </progress> */
    /*  <ul className="progressbar">
       {journey.map((item, index) => {
         if (item.show) {
           return (
             <li key={index} className={item.className}>
               <span> {item.label}</span>
             </li>
           );
         }
       })}
     </ul> */
  );
};

const mapStateToProps = (state) => ({
  activeProduct: state.agentReducer.activeCarriersProduct,
  selected_product: state.agentReducer.productSelctionPageData,
});

export default connect(mapStateToProps, {})(ProgressLayout);
