import React from "react";
import { Route, Redirect } from "react-router-dom";
import { find } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const tokenKey = "iLifeAgentToken";
const paymentKey = "iLifePaymentPending";
const showProfileKey = "iLifeProfilePending";

export const requireAuth = () => {
  return getJwt() ? true : false;
};

export const getJwt = () => {
  return localStorage.getItem(tokenKey);
};

export const setJwt = (agentToken) => {
  return localStorage.setItem(tokenKey, agentToken);
};

export const requirePayment = () => {
  return getPayment() ? true : false;
};

export const setPayment = (paymentFlag) => {
  return localStorage.setItem(paymentKey, paymentFlag);
};

export const getPayment = () => {
  return localStorage.getItem(paymentKey);
};

export const getIsProfileSet = () => {
  return localStorage.getItem(showProfileKey);
};

export const setProfilePending = (profilePending) => {
  return localStorage.setItem(showProfileKey, profilePending);
};

export const isProfilePending = () => {
  return getIsProfileSet() ? true : false;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      requireAuth() ? (
        requirePayment() ? (
          <Redirect
            to={{
              pathname: "/payment",
            }}
          />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to="/login" />
        // document.addEventListener("readystatechange", event => {
        //   if (event.target.readyState === "complete") {
        //     window.location.href = "/login";
        //   }
        // })
      )
    }
  />
);

export const UnProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      requireAuth() ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

const ShowForPermissionComponent = (props) => {
  if (
    props?.planData?.getFeatureList &&
    (props?.userPermissions?.currentPlanSlug?.toLowerCase() === "free" ||
      props?.userPermissions?.currentPlanSlug?.toLowerCase() ===
        "professional") &&
    (props?.permission === "AUTH_USERS" ||
      props?.permission === "HOME_PAGE_BG_VIDEO")
  ) {
    return props.children;
  } else {
    const isAvilableInFeatureList = find(props?.planData?.getFeatureList, {
      featureSlug: props.permission,
    });
    // const isAvilableInAddonList = find(props?.userPermissions?.addOns, {
    //   itemSlugName: props.permission,
    // });
    return isAvilableInFeatureList ? props.children : null;
    // return isAvilableInFeatureList || isAvilableInAddonList
    //   ? props.children
    //   : null;
  }
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string,
  userPermissions: PropTypes.object,
  planData: PropTypes.object,
};
// here you will get permissions for your user from Redux store
const mapStateToProps = (state) => ({
  userPermissions: state.agentReducer.signIn.data,
  planData: state.agentReducer.planDetails?.data?.data,
});

export const ShowForPermission = connect(mapStateToProps)(
  ShowForPermissionComponent
);

export const checkAccess = (planData, addOn) => {
  const isAvilableInFeatureList = find(planData?.getFeatureList, {
    featureSlug: addOn,
  });
  return isAvilableInFeatureList ? true : false;

  // return isAvilableInFeatureList || isAvilableInAddonList ? true : false;
};

export const checkCRM = (planData, options) => {
  for (let i = 0; i < planData?.getFeatureList?.length; i++) {
    if (options.includes(planData?.getFeatureList[i].featureSlug)) {
      return true;
    }
  }
};


export const checkWebSiteSettings = (planData, options) => {
  for (let i = 0; i < planData?.getFeatureList?.length; i++) {
    if (options.includes(planData?.getFeatureList[i].featureSlug)) {
      return true;
    }
  }
};
export const CheckBusiness = (planData, options) => {
  for (let i = 0; i < planData?.getFeatureList?.length; i++) {
    if (options.includes(planData?.getFeatureList[i].featureSlug)) {
      return true;
    }
  }
};
export const CheckAgency = (planData, options) => {
  for (let i = 0; i < planData?.getFeatureList?.length; i++) {
    if (options.includes(planData?.getFeatureList[i].featureSlug)) {
      return true;
    }
  }
};

// import React from "react";

// export const withAuthSync = ComposedComponent => {
//   class HOC extends React.Component {
//     componentDidMount() {
//       this._checkAndRedirect();
//     }

//     componentDidUpdate() {
//       this._checkAndRedirect();
//     }

//     _checkAndRedirect() {
//       const token = localStorage.getItem("token");
//       if (!token) {
//         // Router.push("/login");
//         return false;
//       }
//     }

//     render() {
//       return <ComposedComponent {...this.props} />;
//     }
//   }

//   return HOC;
// };

// export const withOutAuthSync = ComposedComponent => {
//   class HOC extends React.Component {
//     componentDidMount() {
//       this._checkAndRedirect();
//     }

//     componentDidUpdate() {
//       this._checkAndRedirect();
//     }

//     _checkAndRedirect() {
//       const token = localStorage.getItem("token");
//       if (token) {
//         //Router.push("/dashboard");
//         return false;
//       }
//     }

//     render() {
//       return <ComposedComponent {...this.props} />;
//     }
//   }

//   return HOC;
// };
