import React, { useEffect, useState } from "react";
import { API_POINT } from "../config/config";
import { commonApiCall, AgentPlan, updateAgent } from "../utlis/commonFunction";
import {
  RegistrationPagesRoutes,
  setPayment,
  saveLeadCount,
  setItem,
} from "../utlis";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TryFreeLoader } from "../components/skeletonLoaders/freeTryModelLoader";
import { HubspotModel } from "./dashboard/mySubscriptionCards/hubspotMeetingPopup";
import { ScheduleDetailsModel } from "./dashboard/mySubscriptionCards/scheduleDetailsPopup";
const _meetingLink = "https://meetings.hubspot.com/nakoi/enterprise-demo";
const TryFreePopup = ({
  commonApiCall,
  AgentPlan,
  updateAgent,
  saveLeadCount,
  sign_in,
  ...props
}) => {
  const [subscribedPlans, setSubscribedPlans] = useState([]);
  const [formData, setformData] = useState({
    scheduleModal: false,
    meetingType: 15,
    scheduleDetailModal: false,
    meeting: _meetingLink,
    agentType: "",
  });
  const [scheduleDetailModal, setScheduleDetailModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const getSubcriptionPlans = async () => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT + "/getAgtTrialSubscriptionPlans?agentId=" + sign_in?.data?.id,
      "",
      "get",
      "SUBSCRIPTION_PLANS"
    );
    if (response.status === 200 && response.data.status.code === 200) {
      setSubscribedPlans(response?.data?.data);
      setIsUpdating(false);
    } else {
      setIsUpdating(false);
    }
  };
  const setHubspotDetails = async (e) => {
    let _meeting = `${e.meetLink}?firstname=${e.first_name}&email=${e.email}&lastname=${e.last_name}&company=${e.your_company_name}&phone=${e.phone_number}`;
    setScheduleDetailModal(false);
    setTimeout(() => {
      handleDemoModel("scheduleModal", true, _meeting);
    }, 100);
  };
  useEffect(() => {
    getSubcriptionPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLeadStatistics = async () => {
    let response = await commonApiCall(
      API_POINT + "/getLeadCount",
      "",
      "get",
      "PLAN_DETAILS",
      true
    );
    if (response.data.status.code === 200) {
      let { data } = response.data;
      saveLeadCount(data);
      setIsUpdating(false);
      document.body.classList.remove("active-modal");
      props.history.push(response?.data?.data?.defaultPageUrl);
    }
  };

  const onSelectTryFree = async (id, basePlan, slug) => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT +
        `/updateSubscriptionPlan?agentId=${sign_in?.data?.id}&subscriptionPlanId=${id}&isFreeTrial=true`,
      "",
      "post",
      null
    );
    if (response.status === 200 && response.data.status.code === 200) {
      sign_in.data.isPayment = true;
      sign_in.data.paymentRequired = false;
      sign_in.data.currentPlanSlug = slug;
      sign_in.data.role = basePlan;
      sign_in.data.showBecomeMemberPage = false;
      await updateAgent(sign_in);
      await setPayment("");
      await getLeadStatistics();
    } else {
      setIsUpdating(false);
    }
  };

  const signUp = async (id, planName, slug) => {
    setIsUpdating(true);
    let response = await commonApiCall(
      API_POINT +
        `/updateSubscriptionPlan?agentId=${sign_in?.data?.id}&subscriptionPlanId=${id}&isFreeTrial=false`,
      "",
      "post",
      null
    );
    if (response.status === 200 && response.data.status.code === 200) {
      setIsUpdating(false);
      setPayment(true);
      setItem("signUpFromTryFree", true);
      await AgentPlan(planName.toUpperCase());
      sign_in.data.isPayment = false;
      sign_in.data.paymentRequired = true;
      sign_in.data.showBecomeMemberPage = false;
      sign_in.data.currentPlanSlug = slug;
      await updateAgent(sign_in);
      document.body.classList.remove("active-modal");
      props.history.push(RegistrationPagesRoutes.PAYMENT);
    } else {
      setIsUpdating(false);
    }
  };

  const getMonthlyAccess = async (planName, slug) => {
    setPayment(true);
    await AgentPlan(planName.toUpperCase());
    sign_in.data.isPayment = false;
    sign_in.data.paymentRequired = true;
    sign_in.data.showBecomeMemberPage = false;
    sign_in.data.currentPlanSlug = slug;
    await updateAgent(sign_in);
    document.body.classList.remove("active-modal");
    props.history.push(RegistrationPagesRoutes.PAYMENT);
  };
  const handleDemoModel = (name, isHide, meeting) => {
    setformData({
      ...formData,
      [name]: isHide,
      meeting,
    });
  };
  const handleScheduleModel = (isHide, meeting, agentType) => {
    setScheduleDetailModal(isHide);
    setformData({
      ...formData,
      meetingType: meeting,
      agentType: agentType,
    });
  };
  const { meeting, scheduleModal, meetingType, agentType } = formData;
  return (
    <>
      <div className="try_free-model-main">
        <span className="backdrop"></span>

        <div className="model-content">
          <div className="scroll-area">
            <h2 className="font-26 txt-light-black text-center font-weight-semibold mb-md-5 mb-4">
              Your free trial is over, but this is just the start.
            </h2>
            {isUpdating ? (
              <TryFreeLoader />
            ) : (
              <div className="row">
                {subscribedPlans.length > 0 &&
                  subscribedPlans.map((list, index) => {
                    if (list.planPrice > 0) {
                      return (
                        <div className="col-md-4 mb-3" key={index}>
                          <div className="free-tiral-card text-center">
                            <div className="icon-block">
                              <img
                                src={list?.icon}
                                width={20}
                                height={20}
                                alt=""
                              />
                            </div>
                            <h4 className="font-22 lh-1 text-black-color font-weight-semibold mt-md-4 mt-3 mb-3">
                              {list.planName}
                            </h4>
                            <p className="text-black-color font-16 mb-3">
                              {list.description}
                            </p>
                            {list.expired ? (
                              <button
                                className="btn btn-gradient"
                                onClick={() =>
                                  getMonthlyAccess(list.planName, list.slug)
                                }
                                disabled={isUpdating}
                              >
                                Get Monthly Access
                              </button>
                            ) : (
                              list?.trialPeriodPlan &&
                              list?.trialPeriodPlan > 0 && (
                                <button
                                  className="btn btn-gradient"
                                  onClick={() =>
                                    onSelectTryFree(
                                      list.id,
                                      list.basePlan,
                                      list.slug
                                    )
                                  }
                                  disabled={isUpdating}
                                >
                                  {list.trialPeriodPlan}-day Free Trial
                                </button>
                              )
                            )}
                            {list.expired ? (
                              <p className="amount-text">
                                {" "}
                                ${list.planPrice}/month{" "}
                              </p>
                            ) : !list.expired &&
                              list.slug.toLowerCase() !== "enterprise" ? (
                              <p
                                className="amount-text cursor-icon"
                                onClick={() =>
                                  signUp(list.id, list.planName, list.slug)
                                }
                              >
                                {!list.expired && !list?.trialPeriodPlan
                                  ? `Sign-up for ${list.planPrice}`
                                  : `or Sign-up for ${list.planPrice}`}
                              </p>
                            ) : (
                              !list.expired &&
                              list.slug.toLowerCase() === "enterprise" && (
                                <p
                                  className="amount-text cursor-icon"
                                  onClick={() => handleScheduleModel(true, 15)}
                                >
                                  Schedule Demo
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      {scheduleModal && !scheduleDetailModal && (
        <HubspotModel
          scheduleModal={scheduleModal}
          handleDemoModel={handleDemoModel}
          meetingLink={meeting}
        ></HubspotModel>
      )}
      {scheduleDetailModal && (
        <ScheduleDetailsModel
          scheduleDetailModal={scheduleDetailModal}
          handleDemoModel={() => setScheduleDetailModal(false)}
          meetingType={meetingType}
          agentType={agentType}
          onSubmitHubspotDetails={setHubspotDetails}
        ></ScheduleDetailsModel>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
  };
};
export default connect(mapStateToProps, {
  commonApiCall,
  AgentPlan,
  updateAgent,
  saveLeadCount,
})(withRouter(TryFreePopup));
