import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LeadStatisticsLoader = () => {
  const columns = [...Array(3)];
  return (
    <SkeletonTheme>
      <div className="row m-0">
        {columns.map((column, key) => (
          <div className="col-sm-6" key={key}>
            <div className="overviewbox">
              <div className="overtitle">
                <Skeleton count={1} height={22} width={72} />
              </div>
              <div className="overcounter">
                <Skeleton count={1} height={32} width={32} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </SkeletonTheme>
  );
};
