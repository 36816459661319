/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { clsx } from "clsx";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownButton,
  Navbar,
  OverlayTrigger,
} from "react-bootstrap";
import {
  logout,
  BasicsPagesRoutes,
  DashboardPagesRoutes,
  AmbassadorPagesRoutes,
  RegistrationPagesRoutes,
  setJwt,
  setPayment,
  setProfilePending,
  // SettingsRoutes,
  MyWebsiteRoutes,
  commonApiCall,
  updateAgent,
  ShowForPermission,
  saveLeadCount,
  checkCRM,
  checkWebSiteSettings,
  CheckAgency,
  CheckBusiness,
  globelLoader,
  sidebarRoutes,
  SettingsRoutes,
  checkAccess,
  decryptData,
  showErrorToast,
} from "../../utlis";

import {
  API_POINT,
  LANDING_PAGE_URL,
  API_URL_CHATSERVICE,
} from "../../config/config";
import { SidebarLoader } from "../skeletonLoaders/sidebarLoader";
import crmIcon from "../../assets/images/sidebarIcons/crm.svg";
import quotingToolIcon from "../../assets/images/sidebarIcons/quotingTool.svg";
import marketingHubIcon from "../../assets/images/sidebarIcons/marketingHub.svg";
import businessToolsIcon from "../../assets/images/sidebarIcons/businessTools.svg";
import browsingJourneyIcon from "../../assets/images/sidebarIcons/browsingJourney.svg";
import agencyViewIcon from "../../assets/images/sidebarIcons/MyAgentsSidebarIcon.svg";
import notificationsIcon from "../../assets/images/sidebarIcons/notifications.svg";
import mySubscriptionIcon from "../../assets/images/sidebarIcons/mySubscription.svg";
import resourceIcon from "../../assets/images/sidebarIcons/resource.svg";
import settingsIcon from "../../assets/images/sidebarIcons/settings.svg";
import logoutIcon from "../../assets/images/sidebarIcons/logout.svg";
import { isEmpty } from "lodash";
import errorMessages from "../../redux/actions/errorMessages";

function Sidebar({
  commonApiCall,
  updateAgent,
  sign_in,
  router,
  referal_code,
  profile_pic,
  getLeadCount,
  planData,
  globelLoader,

  getmenuOpen,
  menu_open,
  ...props
}) {
  //variable declaration
  const [profilePhoto, setprofilePhoto] = useState("");
  const [showDropdownView, setDropdownView] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [storeLogo, setStoreLogo] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [showTextMessages, setShowTextMessages] = useState("");
  const [loading, setLoading] = useState(false);
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(0);
  let CRMtoolOptions = ["client_pipeline", "chat", "TEXT_MESSAGES"];
  let busniessToolOptions = ["purchases", "ilife_store"];
  let myWebsiteOptions = [
    "home_page",
    "about_page",
    "visitors",
    "my_website_settings",
  ];
  // let marketingOptions = ["EMAIL_MARKETING", "SOCIAL_MEDIA", "TEXT_MESSAGES"];
  let agencyViewOptions = ["agent_dashboard"];

  useEffect(() => {
    const isAuthorized = sign_in.data && sign_in.data.token ? true : false;
    if (
      isAuthorized &&
      !(
        path === RegistrationPagesRoutes.PAYMENT ||
        "/" + splitpath[1] === DashboardPagesRoutes.WELCOMEPAGE ||
        path === DashboardPagesRoutes.PRODUCT_SELECTION ||
        path === DashboardPagesRoutes.CARRIERS_SELECTION ||
        path === DashboardPagesRoutes.STATE_SELECTION ||
        path === DashboardPagesRoutes.TIME_ZONE_SELECTION
      ) &&
      checkAccess(planData, "notifications")
    ) {
      connect();
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leftSideBarOpen = () => {
    setOnLoadClass("open");
    setLogo("/assets/images/logo.svg");
    localStorage.setItem("sidebarIsOpen", "open");

    const sidebarContainer = document.querySelector(".sidebar-container");
    const sidebar = document.querySelector(".sidebar");
    const pageWrapper = document.querySelector(".page-wrapper");
    // open sidebar when user is on large window
    document.body.classList.add("sidebar-open");
    document.body.classList.add("navbar-is-open");
    pageWrapper.classList.add("sidebar-open-main");
    sidebarContainer.classList.add("sidebar-open");
    sidebar.classList.add("open");
  };

  const leftSideBarClose = () => {
    setDropdownView(false);

    setOnLoadClass("");
    setLogo("/assets/images/leaf-logo.png");
    localStorage.setItem("sidebarIsOpen", "close");

    const sidebarContainer = document.querySelector(".sidebar-container");
    const sidebar = document.querySelector(".sidebar");
    const pageWrapper = document.querySelector(".page-wrapper");

    // close sidebar when user resizes the window
    document.body.classList.remove("sidebar-open");
    document.body.classList.remove("navbar-is-open");
    pageWrapper.classList.remove("sidebar-open-main");
    sidebarContainer.classList.remove("sidebar-open");
    sidebar.classList.remove("open");
  };

  useEffect(() => {
    const handleMouseEnter = (event) => {
      const isMenuToggle = event.target.classList.contains("menu-toggle");
      if (!isMenuToggle) {
        leftSideBarOpen();
      }
    };

    // const handleMouseLeave = () => {
    //   leftSideBarClose();
    // };

    const sidebarContainer = document.querySelector(".sidebar-container");

    if (sidebarContainer) {
      sidebarContainer.addEventListener("mouseenter", handleMouseEnter);
      //sidebarContainer.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        sidebarContainer.removeEventListener("mouseenter", handleMouseEnter);
        //sidebarContainer.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 1025) {
        leftSideBarOpen();
      } else {
        leftSideBarClose();
      }
    });
  }, [windowWidth]);

  useEffect(() => {
    if (sign_in && sign_in.data) {
      async function fetchData() {
        if (sign_in && sign_in.data) {
          setShowLogin(false);
        }
      }
      fetchData();
    }
    if (!isEmpty(profile_pic) && !isEmpty(profile_pic.profilePic)) {
      let profilePhoto = profile_pic.profilePic;
      setprofilePhoto(profilePhoto);
    }
  }, [sign_in, profile_pic]);

  const [onLoadClass, setOnLoadClass] = useState("");
  const [logo, setLogo] = useState("/assets/images/leaf-logo.png");
  const sidebar = useRef();
  const sidebarOpened = localStorage.getItem("sidebarIsOpen");

  // useEffect(() => {
  //   if (menu_open) {
  //     setOnLoadClass("open");
  //   } else {
  //     setOnLoadClass("");
  //   }
  // }, [menu_open]);

  window.onload = function () {
    if (window.innerWidth >= 1025) {
      leftSideBarOpen();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("sidebarIsOpen") === null) {
      localStorage.setItem("sidebarIsOpen", "default");
    }
    let sideBarStatus = localStorage.getItem("sidebarIsOpen");
    if (window.innerWidth >= 1025 && sideBarStatus !== "close") {
      leftSideBarOpen();
    } else {
      leftSideBarClose();
    }
  }, []);

  useEffect(() => {
    if (sign_in && sign_in.data) {
      getLeadStatistics();
    }
  }, []);

  const renderTooltipOfVideo = (props) => (
    <div id="button-tooltip" {...props}>
      <div className="tooltip-outer">
        <p>
          Did you know? You can alert <br />
          yourself when someone goes through
          <br />
          your Browsing Journey Link.
        </p>
      </div>
    </div>
  );

  const getLeadStatistics = async () => {
    await globelLoader(true);
    setLoading(true);
    let response = await commonApiCall(
      API_POINT + "/getLeadCount",
      "",
      "get",
      "PLAN_DETAILS",
      true
    );
    if (response.data.status.code === 200) {
      await globelLoader(false);
      setLoading(false);
      let { data } = response.data;
      setStoreLogo(data?.storeImg ? data?.storeImg : "");
      setShowTextMessages(data?.showText);
      saveLeadCount(data);
      if (
        data?.showBecomeMemberPage === true &&
        data?.remainingTrialDays === 0
      ) {
        sign_in.data.showBecomeMemberPage = true;
        await updateAgent(sign_in);
      }
    }
  };

  useEffect(() => {
    if (getLeadCount) {
      setShowTextMessages(getLeadCount?.showText);
    }
  }, [getLeadCount]);

  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
  };

  const path = window.location.pathname;
  const splitpath = path.split("/");

  // iLife logo clicked
  const clearStorageData = async () => {
    if (sign_in?.data?.isActive !== undefined && !sign_in?.data?.isActive) {
      updateAgent("");
      await setJwt(null);
      await setPayment(null);
      await setProfilePending(null);
      await logout();
      localStorage.clear();
    }
  };

  const sidebarToggle = () => {
    // if sidebar is open then close sidebar onLoadClass is set to ""
    if (onLoadClass === "open") {
      localStorage.setItem("sidebarIsOpen", "close");
      setLogo("/assets/images/leaf-logo.png");
      setOnLoadClass("");
      document.body.classList.remove("navbar-is-open");
      document
        .querySelector(".page-wrapper")
        .classList.remove("sidebar-open-main");
    }
    // else if sidebar is closed then open sidebar by changing the class to "open"
    else {
      localStorage.setItem("sidebarIsOpen", "open");
      setOnLoadClass("open");
      setLogo("/assets/images/logo.svg");
      document.body.classList.add("navbar-is-open");
      document
        .querySelector(".page-wrapper")
        .classList.add("sidebar-open-main");
    }
  };

  // toggling submenus open and close
  const sidebarSubMenuToggle = (e) => {
    e.target.classList.toggle("open");
  };

  const checkSubmenuStatus = (route) => {
    if (route.includes(window.location.pathname)) {
      return "open";
    }
    return "";
  };
  const checkActiveStatus = (route) => {
    if (route.includes(window.location.pathname)) {
      return "active";
    }
    return "";
  };

  const { pathname } = window.location;

  const getLastAccessedScreenName = () => {
    // if (path === DashboardPagesRoutes.MYPROFILE) return "My Account";
    // else if (path === DashboardPagesRoutes.LIVELT_SETTINGS)
    //   return "Live LT Settings";
    if (path === DashboardPagesRoutes.ACCOUNTSETTINGS)
      return "Account Settings";
    else if (path === RegistrationPagesRoutes.PAYMENT) return "Payment Screen";
    else if (
      path === DashboardPagesRoutes.SELECT_APPOINTMENTS ||
      path === DashboardPagesRoutes.APPOINTMENT_FORM ||
      path === DashboardPagesRoutes.SBLI_APPOINTMENT_FORM
    )
      return "Get Appointment";
    // else if (path === DashboardPagesRoutes.NOTIFICATION_SETTINGS)
    //   return "Notification Settings";
    else if (
      path === DashboardPagesRoutes.AVBL_LEADPACKAGES ||
      path === DashboardPagesRoutes.LEADCONVERSATIONS ||
      path === DashboardPagesRoutes.CONTACTS ||
      path === DashboardPagesRoutes.TEXT ||
      path === DashboardPagesRoutes.APPLICATIONS + "/myLink" ||
      path === DashboardPagesRoutes.PROSPECTSVIAAFFILIATE ||
      path === AmbassadorPagesRoutes.MYREFERRALS + "/otherRegions" ||
      path === DashboardPagesRoutes.UNASSIGNLEADS ||
      path === DashboardPagesRoutes.DASHBOARD ||
      path === DashboardPagesRoutes.CLIENTPIPELINE ||
      path === DashboardPagesRoutes.AGENT_CHAT
    )
      return "CRM";
    else if (path === DashboardPagesRoutes.PRODUCT_BROWSING)
      return "Client Support";
    else if (
      path === DashboardPagesRoutes.EMAIL ||
      path === DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN ||
      path === DashboardPagesRoutes.SENT_EAMIL_DETAILS ||
      path === DashboardPagesRoutes.CLIENT_EMAIL_DETAIL ||
      path === DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS
    )
      return "Marketing";
    else if (
      path === MyWebsiteRoutes.HOMEPAGE ||
      path === MyWebsiteRoutes.ABOUTPAGE ||
      path === MyWebsiteRoutes.WEBSETTING ||
      path === MyWebsiteRoutes.MYWEBSITE ||
      path === AmbassadorPagesRoutes.MYREFERRALS
    )
      return "Website Settings";
    else if (
      path === DashboardPagesRoutes.ILIFESTORE ||
      path === DashboardPagesRoutes.PURCHASES ||
      path === DashboardPagesRoutes.STORETRANSACTION
    )
      return "Business Tools";
    else if (
      path === DashboardPagesRoutes.MANAGERIALVIEW ||
      path === DashboardPagesRoutes.MYAGENTS ||
      path === DashboardPagesRoutes.AGENT_CHAT ||
      path === DashboardPagesRoutes.LEADASSIGNMENT ||
      path === DashboardPagesRoutes.INVITEDAGENTS
    )
      return "Agency View";
    else if (path === DashboardPagesRoutes.NOTIFICATIONS)
      return "Notifications";
    else if (path === SettingsRoutes.MYSETTINGS) return "My Subscriptions";
    else if (path === DashboardPagesRoutes.THEMESETTINGS)
      return "Theme Settings";
    else return undefined;
  };

  const setCurrentAccessedScreen = async (logoutPath = undefined) => {
    if (
      sign_in?.data?.signUpFromAdmin === false &&
      (getLastAccessedScreenName() || logoutPath != undefined)
    ) {
      const body = {
        lastScreenAccessed: logoutPath
          ? logoutPath
          : getLastAccessedScreenName(),
      };
      let response = await commonApiCall(
        API_POINT + "/agentSettings",
        body,
        "post",
        null,
        true
      );
      if (response?.status === 200 && response?.data?.status?.code === 200) {
        if (logoutPath) {
          let response = await commonApiCall(
            API_POINT + "/signOut",
            "",
            "get",
            null,
            true
          );
          if (
            response?.status === 200 &&
            response?.data?.status?.code === 200
          ) {
            localStorage.clear();
            await logout();
            setShowLogin(true);
            window.location.replace(LANDING_PAGE_URL);
          }
        }
      } else {
        showErrorToast(response?.data?.status?.message);
      }
    }
  };

  const Logout = async (e) => {
    e.preventDefault();
    if (sign_in?.data?.signUpFromAdmin === false) {
      setCurrentAccessedScreen("Logout");
    } else {
      let response = await commonApiCall(
        API_POINT + "/signOut",
        "",
        "get",
        null,
        true
      );

      if (response?.status === 200 && response?.data?.status?.code === 200) {
        localStorage.clear();
        await logout();
        setShowLogin(true);
        window.location.replace(LANDING_PAGE_URL);
      }
    }
  };

  const getNotifications = async (isRead = false) => {
    try {
      const request = {};
      const apiEndPoint = isRead
        ? API_URL_CHATSERVICE +
          "/notifications?agentUserId=" +
          sign_in.data.id +
          "&isNotificationRead=true"
        : API_URL_CHATSERVICE +
          "/notifications?agentUserId=" +
          sign_in.data.id +
          "&type=unread&pageSize=3&pageNo=0";
      const isAuthorized = true;

      const response = await commonApiCall(
        apiEndPoint,
        request,
        "get",
        null,
        isAuthorized,
        false,
        true
      );

      if (response.data.status.code === 200) {
        setCurrentAccessedScreen();
        if (!isRead) {
          if (path !== DashboardPagesRoutes.NOTIFICATIONS)
            setUnReadNotificationCount(response.data.data.unreadCount);
        } else {
          setUnReadNotificationCount(0);
        }
      } else {
        showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  return (
    <>
      {loading ? (
        <SidebarLoader />
      ) : (
        <div className={"sidebar " + onLoadClass} ref={sidebar}>
          <span
            className="menu-toggle"
            onClick={() => {
              setDropdownView(false);
              getmenuOpen(true);
              sidebarToggle();
            }}
          >
            <span className="side-arrow"></span>
          </span>

          <div className="sidebar-links-outer d-flex flex-column">
            <Navbar expand="lg">
              {/*Begin: Logo */}
              <Navbar.Brand className={`${onLoadClass ? "margin-brand" : ""}`}>
                <h1>
                  <Link
                    to={
                      showLogin
                        ? BasicsPagesRoutes.HOME
                        : !showLogin &&
                          (path === RegistrationPagesRoutes.PAYMENT ||
                            "/" + splitpath[1] ===
                              DashboardPagesRoutes.WELCOMEPAGE ||
                            path === DashboardPagesRoutes.PRODUCT_SELECTION ||
                            path === DashboardPagesRoutes.CARRIERS_SELECTION ||
                            path === DashboardPagesRoutes.STATE_SELECTION ||
                            path === DashboardPagesRoutes.TIME_ZONE_SELECTION)
                        ? "#"
                        : DashboardPagesRoutes.CLIENTPIPELINE
                    }
                    onClick={() => {
                      clearStorageData();
                    }}
                  >
                    <img
                      className="sidebar-logo-image img-fluid"
                      src={logo}
                      alt="iLife"
                      title="iLife"
                      width="128"
                      height="36"
                    />
                  </Link>
                </h1>
              </Navbar.Brand>
            </Navbar>
            <ul className="sidebar-list-container">
              {CheckAgency(planData, agencyViewOptions) && (
                <li
                  className={
                    "sidebar-link my-agents-tour link-warning" +
                    " " +
                    checkActiveStatus([
                      DashboardPagesRoutes.MANAGERIALVIEW,
                      DashboardPagesRoutes.MYAGENTS,
                      DashboardPagesRoutes.AGENT_CHAT,
                    ])
                  }
                >
                  <div>
                    <Link
                      to="#"
                      className={
                        "my-agents-tour sidebar-menu-item link-warning " +
                        checkSubmenuStatus([
                          DashboardPagesRoutes.MANAGERIALVIEW,
                          DashboardPagesRoutes.MYAGENTS,
                          DashboardPagesRoutes.AGENT_CHAT,
                        ])
                      }
                      onClick={(e) => sidebarSubMenuToggle(e)}
                    >
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.MANAGERIALVIEW}
                        className={
                          window.location.pathname ===
                          DashboardPagesRoutes.MANAGERIALVIEW
                            ? "active p-0"
                            : "p-0"
                        }
                      >
                        <img
                          title="Agency View"
                          src={agencyViewIcon}
                          alt=""
                          className="sidebar-icon"
                        />
                      </Link>
                      <h4 className="mb-0 font-weight-bold">Agency View</h4>
                    </Link>
                    <ul className="submenu">
                      <ShowForPermission permission="agent_dashboard">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.MANAGERIALVIEW}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.MANAGERIALVIEW
                                ? "active"
                                : ""
                            }
                          >
                            Dashboard
                          </Link>
                        </li>
                      </ShowForPermission>

                      <ShowForPermission permission="agent_dashboard">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.MYAGENTS}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.MYAGENTS
                                ? "active"
                                : ""
                            }
                          >
                            My Agents
                          </Link>
                        </li>
                      </ShowForPermission>

                      <ShowForPermission permission="AGENT_CHAT">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.AGENT_CHAT}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.AGENT_CHAT
                                ? "active"
                                : ""
                            }
                          >
                            Chat
                          </Link>
                        </li>
                      </ShowForPermission>

                      {/* <ShowForPermission permission='chat'>
                        <li className='sidebar-link'>
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.LEADCONVERSATIONS}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.LEADCONVERSATIONS
                                ? "active"
                                : ""
                            }
                          >
                            Chat
                          </Link>
                        </li>
                      </ShowForPermission> */}
                    </ul>
                  </div>
                  <hr className="border-line" />
                </li>
              )}

              {/* Presentation Mode */}
              {checkWebSiteSettings(planData, myWebsiteOptions) && (
                <li
                  className={
                    "sidebar-link " +
                    checkActiveStatus([
                      AmbassadorPagesRoutes.MYREFERRALS,
                      MyWebsiteRoutes.HOMEPAGE,
                      MyWebsiteRoutes.ABOUTPAGE,
                      MyWebsiteRoutes.WEBSETTING,
                    ])
                  }
                >
                  <div>
                    <Link
                      to="#"
                      className={
                        "setting-tour green-dropdown sidebar-menu-item mywebsite-tour link-info " +
                        checkSubmenuStatus([
                          AmbassadorPagesRoutes.MYREFERRALS,
                          MyWebsiteRoutes.HOMEPAGE,
                          MyWebsiteRoutes.ABOUTPAGE,
                          MyWebsiteRoutes.WEBSETTING,
                        ])
                      }
                      // style={{ border: "1px solid #2ba84a" }}
                      onClick={(e) => sidebarSubMenuToggle(e)}
                    >
                      <Link
                        onClick={closeSidebar}
                        to={MyWebsiteRoutes.WEBSETTING}
                        className={
                          window.location.pathname ===
                            MyWebsiteRoutes.WEBSETTING
                            ? "active p-0"
                            : "p-0"
                        }
                      >
                        <img
                          title="Presentation Mode"
                          className="sidebar-icon"
                          src={browsingJourneyIcon}
                          alt=""
                        />
                      </Link>
                      <h4 className="with-arrow">
                        Presentation Mode
                      </h4>
                    </Link>
                    <ul className="submenu">
                      <ShowForPermission permission="my_website_settings">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={MyWebsiteRoutes.WEBSETTING}
                            className={
                              window.location.pathname ===
                                MyWebsiteRoutes.WEBSETTING
                                ? "active"
                                : ""
                            }
                          >
                            Presentation Links
                          </Link>
                        </li>
                      </ShowForPermission>

                      <ShowForPermission permission="home_page">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={MyWebsiteRoutes.HOMEPAGE}
                            className={
                              window.location.pathname ===
                                MyWebsiteRoutes.HOMEPAGE
                                ? "active"
                                : ""
                            }
                          >
                            Landing Page Design
                          </Link>
                        </li>
                      </ShowForPermission>
                      <ShowForPermission permission="about_page">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={MyWebsiteRoutes.ABOUTPAGE}
                            className={
                              window.location.pathname ===
                                MyWebsiteRoutes.ABOUTPAGE
                                ? "active"
                                : ""
                            }
                          >
                            About Page Design
                          </Link>
                        </li>
                      </ShowForPermission>
                      <ShowForPermission permission="visitors">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={AmbassadorPagesRoutes.MYREFERRALS}
                            className={
                              window.location.pathname ===
                                AmbassadorPagesRoutes.MYREFERRALS
                                ? "active"
                                : ""
                            }
                          >
                            Visitor Analytics
                          </Link>
                        </li>
                      </ShowForPermission>
                    </ul>
                  </div>
                  <hr className="border-line" />
                </li>
              )}

              {checkCRM(planData, CRMtoolOptions) && (
                <li
                  className={
                    "sidebar-link " + checkActiveStatus([
                      DashboardPagesRoutes.CLIENTPIPELINE,
                      DashboardPagesRoutes.LEADCONVERSATIONS,
                      DashboardPagesRoutes.TEXT,
                      DashboardPagesRoutes.REMINDERS,
                      !CheckAgency(planData, agencyViewOptions)
                        ? DashboardPagesRoutes.AGENT_CHAT
                        : "",
                    ])
                  }
                >
                  <div>
                    <Link
                      to="#"
                      className={
                        "crm-tour sidebar-menu-item " +
                        checkSubmenuStatus([
                          DashboardPagesRoutes.CLIENTPIPELINE,
                          DashboardPagesRoutes.LEADCONVERSATIONS,
                          DashboardPagesRoutes.TEXT,
                          DashboardPagesRoutes.REMINDERS,
                          !CheckAgency(planData, agencyViewOptions)
                            ? DashboardPagesRoutes.AGENT_CHAT
                            : "",
                        ])
                      }
                      onClick={(e) => sidebarSubMenuToggle(e)}
                    >
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.CLIENTPIPELINE}
                        className={
                          window.location.pathname ===
                            DashboardPagesRoutes.DASHBOARD ||
                          window.location.pathname ===
                            DashboardPagesRoutes.CLIENTPIPELINE ||
                          window.location.pathname ===
                            DashboardPagesRoutes.REMINDERS
                            ? "active p-0"
                            : "p-0"
                        }
                      >
                        <img
                          src={crmIcon}
                          title="CRM"
                          className="sidebar-icon"
                          alt=""
                        />
                      </Link>
                      <h4 className="with-arrow ">CRM</h4>
                    </Link>
                    <ul className="submenu">
                      <ShowForPermission permission="client_pipeline">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.CLIENTPIPELINE}
                            className={
                              window.location.pathname ===
                                DashboardPagesRoutes.DASHBOARD ||
                              window.location.pathname ===
                                DashboardPagesRoutes.CLIENTPIPELINE
                                ? "active"
                                : ""
                            }
                          >
                            Client Pipeline
                          </Link>
                        </li>
                      </ShowForPermission>
                      <ShowForPermission permission="REMINDERS">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.REMINDERS}
                            className={clsx({
                              active:
                                pathname === DashboardPagesRoutes.REMINDERS,
                            })}
                          >
                            Reminders
                            {/* <div className="tooltip-btn text-right ml-2">
                              <OverlayTrigger
                                placement="bottom-start"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipOfVideo}
                              >
                                <sup className="material-icons">
                                  help_outline
                                </sup>
                              </OverlayTrigger>
                            </div> */}
                          </Link>
                        </li>
                      </ShowForPermission>
                      {/* client chat */}
                      {/* <ShowForPermission permission='chat'>
                        <li className='sidebar-link'>
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.LEADCONVERSATIONS}
                            className={
                              window.location.pathname ===
                                DashboardPagesRoutes.LEADCONVERSATIONS
                                ? "active"
                                : ""
                            }
                          >
                            Chat
                          </Link>
                        </li>
                      </ShowForPermission> */}
                      {!CheckAgency(planData, agencyViewOptions) && (
                        <ShowForPermission permission="AGENT_CHAT">
                          <li className="sidebar-link">
                            <Link
                              onClick={closeSidebar}
                              to={DashboardPagesRoutes.AGENT_CHAT}
                              className={
                                window.location.pathname ===
                                DashboardPagesRoutes.AGENT_CHAT
                                  ? "active"
                                  : ""
                              }
                            >
                              Chat
                            </Link>
                          </li>
                        </ShowForPermission>
                      )}
                      {showTextMessages ? (
                        <ShowForPermission permission="TEXT_MESSAGES">
                          <li className="sidebar-link">
                            <Link
                              onClick={closeSidebar}
                              to={DashboardPagesRoutes.TEXT}
                              className={
                                window.location.pathname ===
                                DashboardPagesRoutes.TEXT
                                  ? "active"
                                  : ""
                              }
                            >
                              Text messages
                            </Link>
                          </li>
                        </ShowForPermission>
                      ) : null}
                    </ul>
                  </div>
                </li>
              )}

              {/* <ShowForPermission permission="PRODUCT_BROWSING"> */}
              <li
                className={
                  "sidebar-link " +
                  checkActiveStatus([DashboardPagesRoutes.PRODUCT_BROWSING])
                }
              >
                <div>
                  <Link
                    to="#"
                    className={
                      "clientSupport-tour sidebar-menu-item " +
                      checkSubmenuStatus([
                        DashboardPagesRoutes.PRODUCT_BROWSING,
                      ])
                    }
                    onClick={(e) => sidebarSubMenuToggle(e)}
                  >
                    <Link
                      onClick={closeSidebar}
                      to={DashboardPagesRoutes.PRODUCT_BROWSING}
                      className={
                        window.location.pathname ===
                        DashboardPagesRoutes.PRODUCT_BROWSING
                          ? "active p-0"
                          : "p-0"
                      }
                    >
                      <img
                        title="Quoting Tool"
                        src={quotingToolIcon}
                        className="sidebar-icon"
                        alt=""
                      />
                    </Link>
                    <h4 className="with-arrow ">Quoting Tool</h4>
                  </Link>
                  <ul className="submenu">
                    <li className="sidebar-link">
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.PRODUCT_BROWSING}
                        className={
                          window.location.pathname ===
                          DashboardPagesRoutes.PRODUCT_BROWSING
                            ? "active"
                            : ""
                        }
                      >
                        Product Browsing
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* </ShowForPermission> */}
              {/* {checkMarketing(planData, showTextMessages) && ( */}
              <li
                className={
                  "sidebar-link " +
                  checkActiveStatus([
                    DashboardPagesRoutes.EMAIL,
                    DashboardPagesRoutes.SOCIALMEDIA,
                  ])
                }
              >
                <div>
                  <Link
                    to="#"
                    className={
                      "marketing-tour sidebar-menu-item " +
                      checkSubmenuStatus([
                        DashboardPagesRoutes.EMAIL,
                        DashboardPagesRoutes.SOCIALMEDIA,
                      ])
                    }
                    onClick={(e) => sidebarSubMenuToggle(e)}
                  >
                    <Link
                      onClick={closeSidebar}
                      to={DashboardPagesRoutes.EMAIL}
                      className={
                        window.location.pathname === DashboardPagesRoutes.EMAIL
                          ? "active p-0"
                          : "p-0"
                      }
                    >
                      <img
                        title="Marketing Hub"
                        className="sidebar-icon"
                        src={marketingHubIcon}
                        alt=""
                      />
                    </Link>
                    <h4 className="with-arrow">Marketing Hub</h4>
                  </Link>
                  <ul className="submenu">
                    <li className="sidebar-link">
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.EMAIL}
                        className={
                          window.location.pathname ===
                          DashboardPagesRoutes.EMAIL
                            ? "active"
                            : ""
                        }
                      >
                        Email campaigns
                      </Link>
                    </li>

                    <li className="sidebar-link">
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.SOCIALMEDIA}
                        className={
                          window.location.pathname ===
                          DashboardPagesRoutes.SOCIALMEDIA
                            ? "active"
                            : ""
                        }
                      >
                        Social media
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {CheckBusiness(planData, busniessToolOptions) && (
                <li
                  className={
                    "sidebar-link " +
                    checkActiveStatus([
                      DashboardPagesRoutes.ILIFESTORE,
                      DashboardPagesRoutes.PURCHASES,
                    ])
                  }
                >
                  <div>
                    <Link
                      to="#"
                      className={
                        "business-tour sidebar-menu-item " +
                        checkSubmenuStatus([
                          DashboardPagesRoutes.ILIFESTORE,
                          DashboardPagesRoutes.PURCHASES,
                        ])
                      }
                      onClick={(e) => sidebarSubMenuToggle(e)}
                    >
                      <Link
                        onClick={closeSidebar}
                        to={DashboardPagesRoutes.ILIFESTORE}
                        className={
                          window.location.pathname ===
                          DashboardPagesRoutes.ILIFESTORE
                            ? "active d-flex p-0"
                            : "d-flex p-0"
                        }
                      >
                        <img
                          title="Business Tools"
                          src={businessToolsIcon}
                          alt=""
                          className="sidebar-icon"
                        />
                      </Link>
                      <h4 className="with-arrow">Business Tools</h4>
                    </Link>
                    <ul className="submenu">
                      <ShowForPermission permission="ilife_store">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.ILIFESTORE}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.ILIFESTORE
                                ? "active d-flex"
                                : "d-flex"
                            }
                          >
                            iLife Store
                            {storeLogo !== "" && (
                              <img
                                src={storeLogo}
                                className="store-img"
                                alt=""
                              />
                            )}
                          </Link>
                        </li>
                      </ShowForPermission>
                      <ShowForPermission permission="purchases">
                        <li className="sidebar-link">
                          <Link
                            onClick={closeSidebar}
                            to={DashboardPagesRoutes.PURCHASES}
                            className={
                              window.location.pathname ===
                              DashboardPagesRoutes.PURCHASES
                                ? "active"
                                : ""
                            }
                          >
                            Purchases
                          </Link>
                        </li>
                      </ShowForPermission>
                    </ul>
                  </div>
                </li>
              )}

              <ShowForPermission permission="notifications">
                <li
                  className={
                    "sidebar-link notification-tour " +
                    checkActiveStatus([DashboardPagesRoutes.NOTIFICATIONS])
                  }
                  onClick={() => getNotifications(true)}
                >
                  <Link
                    onClick={closeSidebar}
                    to={DashboardPagesRoutes.NOTIFICATIONS}
                    className={`${
                      !onLoadClass ? "notification-responsive" : "notification"
                    } ${
                      window.location.pathname ===
                      DashboardPagesRoutes.NOTIFICATIONS
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      title="Notifications"
                      src={notificationsIcon}
                      alt=""
                      className="sidebar-icon notification-item"
                    />
                    <h4>Notifications</h4>

                    {unReadNotificationCount !== 0 && (
                      <span className="notification-count">
                        {unReadNotificationCount > 99
                          ? "99+"
                          : unReadNotificationCount}
                      </span>
                    )}
                  </Link>
                </li>
              </ShowForPermission>
              <ShowForPermission permission="my_subscription">
                <li
                  className={
                    "sidebar-link my-subscription-tour " +
                    checkActiveStatus([DashboardPagesRoutes.MYSUBSCRIPTIONS])
                  }
                >
                  <Link
                    onClick={closeSidebar}
                    to={DashboardPagesRoutes.MYSUBSCRIPTIONS}
                    className={
                      window.location.pathname ===
                      DashboardPagesRoutes.MYSUBSCRIPTIONS
                        ? "active"
                        : ""
                    }
                  >
                    <img
                      title="My Subscriptions"
                      src={mySubscriptionIcon}
                      alt=""
                      className="sidebar-icon"
                    />
                    <h4>My Subscription</h4>
                  </Link>
                </li>
              </ShowForPermission>
              <ShowForPermission permission="RESOURCE">
                <li className={`sidebar-link my-subscription-tour`}>
                  <a
                    href="https://help.ilife.tech/en"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeSidebar}
                  >
                    <img
                      title="Resources"
                      src={resourceIcon}
                      alt=""
                      className="sidebar-icon"
                      width={20}
                      height={20}
                    />
                    <h4>Resources</h4>
                  </a>
                </li>
              </ShowForPermission>
              {path === RegistrationPagesRoutes.PAYMENT ||
              sign_in?.data?.showBecomeMemberPage === true ? null : (
                <li
                  className={
                    "sidebar-link my-subscription-tour " +
                    checkActiveStatus([
                      DashboardPagesRoutes.ACCOUNTSETTINGS_PROFILE,
                      DashboardPagesRoutes.ACCOUNTSETTINGS_LICENSE_STATE,
                      DashboardPagesRoutes.ACCOUNTSETTINGS_CARRIER_OPTIONS,
                      DashboardPagesRoutes.ACCOUNTSETTINGS_MESSAGES,
                      DashboardPagesRoutes.ACCOUNTSETTINGS_ADMIN_USERS,
                    ])
                  }
                >
                  <a
                    href="/account-settings/profile"
                    rel="noopener noreferrer"
                    onClick={closeSidebar}
                  >
                    <img
                      title="Settings"
                      src={settingsIcon}
                      alt=""
                      className="sidebar-icon"
                      width={20}
                      height={20}
                    />
                    <h4>Settings</h4>
                  </a>
                </li>
              )}

              {/* <div className='dropdown mt-auto'>
                    {checkAccess(planData, "RESOURCE") &&
                      (onLoadClass == "open" || sidebarOpened == "open") &&
                      menu_open && (
                        <a
                          className={`resources-link`}
                          href='https://help.ilife.tech/en'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Resources
                        </a>
                      )}
                    {checkAccess(planData, "RESOURCE") &&
                      onLoadClass == "" &&
                      sidebarOpened == "close" && (
                        <a
                          className='resources-link'
                          href='https://help.ilife.tech/en'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='material-icons font-20 ml-2'>
                            book
                          </span>
                        </a>
                      )}
                  </div> */}
            </ul>
            <div
              style={{
                bottom: "0px",
                backgroundColor: "white",
              }}
              className="mt-auto position-sticky py-3 profile-responsive-mobile"
            >
              {path === DashboardPagesRoutes.DASHBOARD ||
              path === DashboardPagesRoutes.CLIENTPIPELINE ||
              path === DashboardPagesRoutes.MYAGENTS ||
              path === DashboardPagesRoutes.AGENT_CHAT ||
              // path === DashboardPagesRoutes.MYPROFILE ||
              // path === DashboardPagesRoutes.NOTIFICATION_SETTINGS ||
              // path === DashboardPagesRoutes.LIVELT_SETTINGS ||
              path.split("/").indexOf("account-settings") > -1 ||
              path === DashboardPagesRoutes.MYSUBSCRIPTIONS ||
              path === DashboardPagesRoutes.MYPROFILE_PROFESSIONAL ||
              "/" + splitpath[1] === AmbassadorPagesRoutes.MYREFERRALS ||
              path === AmbassadorPagesRoutes.MYREFERRALSDETAILS ||
              path === DashboardPagesRoutes.LEADCONVERSATIONS ||
              path === DashboardPagesRoutes.CONTACTS ||
              path === DashboardPagesRoutes.TEXT ||
              "/" + splitpath[1] === DashboardPagesRoutes.APPLICATIONS ||
              path === DashboardPagesRoutes.APPLICATIONSBASICDETAILS ||
              path === DashboardPagesRoutes.PROSPECTSVIAAFFILIATE ||
              path === DashboardPagesRoutes.MANAGERIALVIEW ||
              path === DashboardPagesRoutes.AGENTDETAILS ||
              path === DashboardPagesRoutes.APPLICATIONSDOCUMENTS ||
              path === DashboardPagesRoutes.APPLICATIONSEDITABLEPDF ||
              path === DashboardPagesRoutes.APPLICATIONPOLICYINFORMATION ||
              path === DashboardPagesRoutes.APPLICATIONUNDERWRITINGSTEPS ||
              path === DashboardPagesRoutes.APPLICATIONSCONVERSATIONS ||
              path === DashboardPagesRoutes.SETTINGDEXPERT ||
              path === DashboardPagesRoutes.BASICDETAILSEXPERT ||
              path === DashboardPagesRoutes.POLICYINFORMATIONEXPERT ||
              path === DashboardPagesRoutes.CONVERSATIONEXPERT ||
              path === DashboardPagesRoutes.MYREFERENCEDETAILSEXPERT ||
              path === DashboardPagesRoutes.NOTIFICATIONS ||
              path === DashboardPagesRoutes.AVBL_LEADPACKAGES ||
              path === DashboardPagesRoutes.PAYOUTS ||
              path === SettingsRoutes.MYSETTINGS ||
              path === DashboardPagesRoutes.INVITEAGENT ||
              path === DashboardPagesRoutes.AGENTREQUESTS ||
              path === DashboardPagesRoutes.LEADASSIGNMENT ||
              path === DashboardPagesRoutes.UNASSIGNLEADS ||
              path === DashboardPagesRoutes.INVITEDAGENTS ||
              path === DashboardPagesRoutes.THEMESETTINGS ||
              path === MyWebsiteRoutes.HOMEPAGE ||
              path === MyWebsiteRoutes.ABOUTPAGE ||
              path === MyWebsiteRoutes.WEBSETTING ||
              path === MyWebsiteRoutes.MYWEBSITE ||
              path === SettingsRoutes.SUBUSERS ||
              path === DashboardPagesRoutes.SELECT_APPOINTMENTS ||
              path === DashboardPagesRoutes.APPOINTMENT_FORM ||
              path === DashboardPagesRoutes.SBLI_APPOINTMENT_FORM ||
              (path === RegistrationPagesRoutes.PAYMENT && !showLogin) ||
              path === DashboardPagesRoutes.MYEARNINGBASICDETAILSEXPERT ||
              path === SettingsRoutes.PAYMENTINFORMATION ||
              path === DashboardPagesRoutes.ILIFESTORE ||
              path === DashboardPagesRoutes.EMAIL ||
              path === DashboardPagesRoutes.REMINDERS ||
              path === DashboardPagesRoutes.PRODUCT_BROWSING ||
              path === DashboardPagesRoutes.SOCIALMEDIA ||
              path === DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN ||
              path === DashboardPagesRoutes.SENT_EAMIL_DETAILS ||
              path ===
                DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS ||
              path === DashboardPagesRoutes.CLIENT_EMAIL_DETAIL ||
              path === DashboardPagesRoutes.PURCHASES ||
              "/" + splitpath[1] + "/" + splitpath[2] ==
                RegistrationPagesRoutes.LEADPACKAGEOFFER ||
              path === DashboardPagesRoutes.STORETRANSACTION ? (
                <div
                  className={`${
                    onLoadClass && "profile-mobile"
                  } profile-dropdown flex-shrink-0`}
                >
                  <div className="dropdown">
                    <li
                      className={`sidebar-link my-subscription-tour logout-btn`}
                    >
                      <a rel="noopener noreferrer" onClick={Logout}>
                        <img
                          title="Log Out"
                          src={logoutIcon}
                          alt=""
                          className="sidebar-icon"
                          width={16}
                          height={16}
                        />
                        <h4>Log Out</h4>
                      </a>
                    </li>
                    {/* <div
                      className="d-flex dropdown-arrow cursor-pointer "
                      //id="dropdownMenuButton"
                      onClick={() => {
                        if (!onLoadClass) {
                          getmenuOpen(true);
                          sidebarToggle();
                        }

                        setDropdownView((val) => !val);
                      }}
                      //data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <div
                        className='profile-img'
                        onClick={(e) => sidebarSubMenuToggle(e)}
                      >
                        <img
                          src={
                            profilePhoto
                              ? profilePhoto
                              : "/assets/images/no-image.jpg"
                          }
                          alt=''
                          title=''
                        />
                      </div>
                      {onLoadClass === "open" && (
                        <div className='d-md-block'>
                          <h4 className='font-16 txt-light-black font-weight-bold mb-0 lh-1'>
                            Hi{" "}
                            {sign_in?.data?.firstName
                              ? `${decryptData(sign_in?.data?.firstName)}!`
                              : ""}
                          </h4>
                          <p className='font-14 lh-1 pb-0'>
                            Account
                            <svg
                                className='ml-2 mb-1 '
                                width='12px'
                                height='8px'
                              >
                                <g transform='matrix(1 0 0 1 -334 -43 )'>
                                  <path
                                    d='M 0 1.625  L 1.415 0.21000000000000085  L 6 4.795  L 10.585 0.21000000000000085  L 12 1.625  L 6 7.625  L 0 1.625  Z '
                                    fillRule='nonzero'
                                    fill='#797979'
                                    stroke='none'
                                    transform='matrix(1 0 0 1 334 43 )'
                                  />
                                </g>
                              </svg>
                          </p>
                        </div>
                      )}
                    </div> */}

                    {/* <div
                      className={`${onLoadClass === "open" && showDropdownView
                        ? "d-block"
                        : "d-none"
                        } dropdown-menu-profile  dropdown-menu-right px-0 py-1`}
                    //aria-labelledby="dropdownMenuButton"
                    >
                      {path === RegistrationPagesRoutes.PAYMENT ||
                      sign_in?.data?.showBecomeMemberPage === true ? null : (
                        <>
                          <Link
                            to={"/account-settings/profile"}
                            className='dropdown-item py-2'
                          >
                            <span className='dropdown-icon material-icons'>
                              account_circle
                            </span>
                            Account Settings
                          </Link>
                        </>
                      )}

                      <Link
                        to='#'
                        className='dropdown-item py-2'
                        onClick={Logout}
                      >
                        <span className='dropdown-icon material-icons'>
                          input
                        </span>
                        Logout
                      </Link>
                    </div> */}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    referal_code: state.agentReducer.referalCode,
    profile_pic: state.agentReducer.profile_pic,
    getLeadCount: state.agentReducer.getLeadCount,
    planData: state.agentReducer.planDetails?.data?.data,
    menu_open: state?.agentReducer?.showMenu?.data,
  };
};
export default connect(mapStateToProps, {
  commonApiCall,
  saveLeadCount,
  updateAgent,
  globelLoader,
})(withRouter(Sidebar));
