import axios from "axios";
import { API_URL } from "../config/config";
import {
  showErrorToast,
  getJwt,
  setJwt,
  setItem,
  setPayment,
  DashboardPagesRoutes,
  BasicsPagesRoutes,
} from "../utlis";
import history from "./history";
import errorMessages from "../redux/actions/errorMessages";
import { decryptData, formatDateInTimezone, showSuccessToast } from "./common";
import moment from "moment";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const path = window.location.pathname;
    if (error && error.response && 400 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 404 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 403 === error.response.status) {
      if (error.response !== "") return Promise.resolve(error.response);
    } else if (error && error.response && 500 === error.response.status) {
      showErrorToast(errorMessages.INTERNAL_SERVER_ERROR);
      return Promise.resolve();
    } else if (error && error.response && 401 === error.response.status) {
      localStorage.clear();
      window.location.pathname = DashboardPagesRoutes.DASHBOARD;
      setItem("lastScreen", path);
      return Promise.resolve(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);
/*******************************************************************************************
     @PURPOSE      	: 	Call api.
     @Parameters 	: 	{
       url : <url of api>
       data : <data object (JSON)>
       method : String (get, post)
       isForm (Optional) : Boolean - to call api with form data
       isPublic (Optional) : Boolean - to call api without auth header
     }
     /*****************************************************************************************/
let cancelToken;

const manageRequestHeader = (headers) => {
  return {
    ...headers,
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Frame-Options": "SAMEORIGIN",
    "x-content-type-options": "nosniff",
    "cross-origin-resource-policy": "cross-origin",
    "Permissions-Policy": "geolocation=(), microphone=*, camera=*;",
    "X-XSS-Protection": "1; mode=block",
    "X-Download-Options": "noopen",
    "x-date": formatDateInTimezone(new Date())
      .utc()
      .format("MM/DD/YYYY hh:mm A"),
  };
};
export const commonApiCall =
  (
    url,
    data,
    method,
    Type = null,
    isAuthorized = false,
    isForm = false,
    chatUrl = false,
    clientToken
  ) =>
  (dispatch) => {
    let URL;
    //encrypt
    if (chatUrl) URL = url;
    else URL = API_URL + url;
    url = URL;
    let headers = { "Content-Type": "application/json" },
      token = getJwt() ? "Bearer " + getJwt() : null;
    if (isAuthorized) headers.Authorization = token;
    if (isForm) headers["Content-Type"] = "multipart/form-data";
    if (clientToken) headers.Authorization = "Bearer " + clientToken;
    headers.vendorToken = "ilifein1";
    // Api calling
    headers = manageRequestHeader(headers);
    cancelToken = axios.CancelToken.source();
    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        headers,
        data,
        cancelToken: cancelToken.token,
        validateStatus: (status) => {
          if (status === 500 || status === 200) return true;
          // I'm returning true if the status is being 500, you may want to do it depending on the status received
          else return false;
        },
      })
        .then((response) => {
          if (response && response.status === 200) {
            if (Type !== null) {
              const payload = { data: response.data };
              dispatch({ type: Type, payload });
            }
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 404) {
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 400) {
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 403) {
            showErrorToast(response.data.status.message);
            //window.location.pathname = DashboardPagesRoutes.DASHBOARD;
          } else if (response && response.status === 401) {
            localStorage.clear();
            window.location.pathname = BasicsPagesRoutes.LOGIN;
            cancelToken.cancel("Operation canceled due to new request.");
            if (
              response.data.status.message.includes(
                "We are currently reviewing your details"
              )
            ) {
              showSuccessToast(response.data.status.message);
            } else {
              showErrorToast(response.data.status.message);
            }
            dispatch({ type: "LOGOUT", payload: "" });
            setJwt("");
            setPayment("");
            history.push("/login");
          } else if (response && response.status === 500) {
            showErrorToast(errorMessages.INTERNAL_SERVER_ERROR);
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

let _cancelToken;
export const commonApiCallHeaders =
  (
    url,
    data,
    method,
    Type = null,
    isAuthorized = false,
    isForm = false,
    chatUrl = false,
    _headers
  ) =>
  (dispatch) => {
    let URL;
    //encrypt
    if (chatUrl) URL = url;
    else URL = API_URL + url;
    url = URL;
    let headers = _headers;
    headers["Content-Type"] = "application/json";
    let token = getJwt() ? "Bearer " + getJwt() : null;
    if (isAuthorized) headers.Authorization = token;
    if (isForm) headers["Content-Type"] = "multipart/form-data";
    headers = manageRequestHeader(headers);
    // Api calling
    _cancelToken = axios.CancelToken.source();
    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        headers,
        data,
        cancelToken: _cancelToken.token,
        validateStatus: (status) => {
          if (status === 500 || status === 200) return true;
          // I'm returning true if the status is being 500, you may want to do it depending on the status received
          else return false;
        },
      })
        .then((response) => {
          if (response && response.status === 200) {
            if (Type !== null) {
              const payload = { data: response.data };
              dispatch({ type: Type, payload });
            }
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 404) {
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 400) {
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          } else if (response && response.status === 403) {
            window.location.pathname = DashboardPagesRoutes.DASHBOARD;
          } else if (response && response.status === 401) {
            localStorage.clear();
            window.location.pathname = BasicsPagesRoutes.LOGIN;
            cancelToken.cancel("Operation canceled due to new request.");
            if (
              response.data.status.message.includes(
                "We are currently reviewing your details"
              )
            ) {
              showSuccessToast(response.data.status.message);
            } else {
              showErrorToast(response.data.status.message);
            }
            dispatch({ type: "LOGOUT", payload: "" });
            setJwt("");
            setPayment("");
            history.push("/login");
          } else if (response && response.status === 500) {
            showErrorToast(errorMessages.INTERNAL_SERVER_ERROR);
            let Data = { data: response.data, status: response.status };
            resolve(Data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export const createHubspotContact = async (data) => {
  fetch(
    fetch(
      "https://api.hubapi.com/contacts/v1/contact/?hapikey=94d3e0eb-9880-4f1c-b445-785f522458b1",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
          "Access-Control-Allow-Origin": "http://agent.ilifestaging.tech",
          "Access-Control-Allow-Credentials": "true",
        },
        // redirect: 'follow', // manual, *follow, error
        referrerPolicy: "origin-when-cross-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
  ).catch((error) => {
    showErrorToast("Could not create contact.");
  });
};

//To identify the plan
export const AgentPlan = (data) => async (dispatch) => {
  dispatch({ type: "AGENT_PLAN", payload: data });
};

//for work style of agent to select options
export const selectAgentProfessional = (data) => async (dispatch) => {
  dispatch({ type: "SELECT_PROFESSIONAL", payload: data });
};

// for recommonded plans for agent if select yes or no
export const recommendedPlans = (data) => async (dispatch) => {
  dispatch({ type: "RECOMMENDED_PLANS", payload: data });
};
//To store AgentUserId
export const AgentUserId = (data) => async (dispatch) => {
  dispatch({ type: "AGENT_USERID", payload: data });
};

export const RegisterUrl = (data) => async (dispatch) => {
  dispatch({ type: "REGISTER_URL", payload: data });
};
//referal code
export const ReferalCode = (data) => async (dispatch) => {
  dispatch({ type: "REFERAL_CODE", payload: data });
};
export const VisitorsMonth = (data) => async (dispatch) => {
  dispatch({ type: "VISITOR_MONTH", payload: data });
};
export const CarrierAppointmentData = (data) => async (dispatch) => {
  dispatch({ type: "CARRIER_APPOINTMENT_DATA", payload: data });
};
export const ProductSelctionPageData = (data) => async (dispatch) => {
  dispatch({ type: "PRODUCT_SELCTION_PAGE_DATA", payload: data });
};
export const GetActiveCarriersProduct = (data) => async (dispatch) => {
  dispatch({ type: "ACTIVE_CARRIERS_PRODUCT", payload: data });
};
export const GetCarrierByproduct = (data) => async (dispatch) => {
  dispatch({ type: "GET_CARRIERS_BY_PRODUCT", payload: data });
};
export const GetSelectedCarrierByproduct = (data) => async (dispatch) => {
  dispatch({ type: "GET_SELECTED_CARRIERS_BY_PRODUCT", payload: data });
};
export const GetSelectedLicendState = (data) => async (dispatch) => {
  dispatch({ type: "GET_SELECTED_LICEND_STATE", payload: data });
};
export const UserOnboardEnableCarriers = (data) => async (dispatch) => {
  dispatch({ type: "USER_ONBOARD_EBABLE_CARRIERS", payload: data });
};
export const saveUnderWritingData = (data) => async (dispatch) => {
  dispatch({ type: "UNDERWRITING_DETAILS", payload: data });
};
export const UserOnboardDisableCarriers = (data) => async (dispatch) => {
  dispatch({ type: "USER_ONBOARD_DISABLE_CARRIERS", payload: data });
};
export const UserOnboardSelectedState = (data) => async (dispatch) => {
  dispatch({ type: "USER_ONBOARD_SELECTED_STATE", payload: data });
};
export const AppointmentedCarrier = (data) => async (dispatch) => {
  dispatch({ type: "APPOINTMENTED_CARRIER", payload: data });
};
export const DiscountAmount = (data) => async (dispatch) => {
  dispatch({ type: "DISCOUNT_AMOUNT", payload: data });
};
export const actionId = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_ID", payload: data });
};
//logout
export const logout = () => async (dispatch) => {
  dispatch({ type: "LOGOUT", payload: "" });
};

//profilePicture
export const profilePicture = (value) => async (dispatch) => {
  dispatch({ type: "PROFILE_PICTURE", payload: value });
};
// anonymus application carrier Id  for applications module
export const applicationId = (id) => async (dispatch) => {
  dispatch({ type: "APPLICATION_ID", payload: id });
};
// Conversation details in chat
export const conversationDetails = (conversation) => async (dispatch) => {
  dispatch({ type: "CONVERSATION_DETAILS", payload: conversation });
};
//Agent Availiabity in dashboard
export const AgentAvailability = (data) => async (dispatch) => {
  dispatch({ type: "AGT_AVALIABILITY", payload: data });
};
//save carrier details in registration
export const saveCarrierDetails = (data) => async (dispatch) => {
  dispatch({ type: "SAVE_CARRIER", payload: data });
};
// Active lead packages
export const activeLeadPackage = (data) => async (dispatch) => {
  dispatch({ type: "LEAD_PACKAGES", payload: data });
};
export const updateAgent = (data) => async (dispatch) => {
  const payload = { data: data };
  dispatch({ type: "AGENT_LOGIN", payload });
};
export const inviteAgent = (data) => async (dispatch) => {
  const payload = { data: data };
  dispatch({ type: "INVITE_AGENT", payload });
};

//logout
export const menuOpenClick = (data) => async (dispatch) => {
  const payload = { data };
  dispatch({ type: "SHOWMENU", payload: payload });
};

export const setAccordion = (data) => async (dispatch) => {
  const payload = { data: data };
  dispatch({ type: "ACCORDION_DATA", payload });
};

export const setFullDetailsAccordion = (data) => async (dispatch) => {
  const payload = { data: data };
  dispatch({ type: "FULL_DETAILS_ACCORDION_DATA", payload });
};

export const resetFullDetailsAccordian = () => async (dispatch) => {
  dispatch({ type: "FULL_DETAILS_ACCORDION_RESET" });
};
export const segmentTracking = (eventName, eventObject) => {
  window.analytics.track(eventName, eventObject);
};
export const segmentIdentify = (eventName, eventObject) => {
  window.analytics.identify(eventName, eventObject);
};
export const updatePaymentDetails = (data) => async (dispatch) => {
  const payload = data;
  dispatch({ type: "PAYMENT_DETAILS", payload });
};

export const savedMyTemplateDate = (data) => async (dispatch) => {
  dispatch({ type: "SAVED_MY_TEMPLATE_DRAFT_DATA", payload: data });
};
export const clientPolicyDetail = (data) => async (dispatch) => {
  dispatch({ type: "CLIENT_POLICY_DETAIL", payload: data });
};

export const SaveApplicationDetails = (data) => async (dispatch) => {
  dispatch({ type: "SAVE_APPLICATION_DETAIL", payload: data });
};
export const clientPolicyDetailOnCall = (data) => async (dispatch) => {
  dispatch({ type: "CLIENT_POLICY_DETAIL_On_CALL", payload: data });
};
export const saveLeadCount = (data) => async (dispatch) => {
  dispatch({ type: "GET_LEAD_COUNT_DETAILS", payload: data });
};
// export const endCall = () => async (dispatch) => {
//   dispatch({ type: "DISCONNECT_CALL", payload: null });
// };
export const twillioDeviceCheck = (data) => async (dispatch) => {
  dispatch({ type: "TWILLIO_DEVICE_CHECK", payload: data });
};
export const callStarted = (data) => async (dispatch) => {
  dispatch({ type: "CALL_STARTED", payload: data });
};
export const globelLoader = (data) => async (dispatch) => {
  dispatch({ type: "GLOBAL_LOADER", payload: data });
};
export const redirectId = (data) => async (dispatch) => {
  dispatch({ type: "REDIRECT", payload: data });
};

export const applicationDetils = (data) => async (dispatch) => {
  dispatch({ type: "APPLICATION_DETAIL_DATA", payload: data });
};

export const agentDetils = (data) => async (dispatch) => {
  dispatch({ type: "AGENT_DETAIL_DATA", payload: data });
};

export const setAgencyFilters = (data) => async (dispatch) => {
  dispatch({ type: "SET_AGENCY_FILTER", payload: data });
};

export const calcAge = (dateString) => {
  let birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / 31557600000);
};
export const calcAgeOnMonth = (dateString) => {
  let today = new Date();
  dateString = new Date(dateString);
  let y = [today.getFullYear(), dateString.getFullYear()];
  let ydiff = y[0] - y[1];
  let m = [today.getMonth(), dateString.getMonth()];
  let mdiff = m[0] - m[1];
  let d = [today.getDate(), dateString.getDate()];
  let ddiff = d[0] - d[1];

  if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
  if (mdiff < 0) mdiff += 12;
  if (ddiff < 0) {
    dateString.setMonth(m[1] + 1, 0);
    ddiff = dateString.getDate() - d[1] + d[0];
    --mdiff;
  }
  if (mdiff > 0 && mdiff < 6 && ddiff > 0 && ddiff < 30 && ydiff > 0) {
    return ydiff;
  } else if (mdiff < 0 && ydiff > 0) {
    return ydiff + 1;
  } else if (mdiff >= 6 && ydiff > 0) {
    return ydiff + 1;
  }
  // else if(mdiff == 5 && ddiff==30 && ydiff > 0) {
  //   return ydiff + 1 ;
  // }
  else {
    return ydiff;
  }
};
export function getSliderVariables(screen, amount, policyType) {
  let sliderLables;
  switch (screen) {
    case "MONTHLY":
      sliderLables = {
        labels: {
          100: "$100",
          2500: "$2500",
          5000: "$5000",
        },
        min: 100,
        max: 5000,
        defaultValue:
          amount && amount <= 5000 && amount >= 100 ? amount : "300",
      };
      break;

    case "QUARTERLY":
      sliderLables = {
        labels: {
          50: "$50",
          1500: "$1,500",
          3000: "$3,000",
        },
        min: 50,
        max: 3000,
        defaultValue:
          amount && amount <= 3000 && amount >= 50 ? amount : "1400",
      };
      break;

    case "ANNUALLY":
      sliderLables = {
        labels: {
          1000: "$1000",
          50000: "$50,000",
          100000: "$100,000",
        },
        min: 1000,
        max: 100000,
        defaultValue:
          amount && amount <= 100000 && amount >= 1000 ? amount : "3500",
      };
      break;

    case "ONETIME":
      sliderLables = {
        labels: {
          1000: "$1,000",
          500000: "$500,000",
          1000000: "$1,000,000",
        },
        min: 1000,
        max: 1000000,
        defaultValue:
          amount && amount <= 1000000 && amount >= 1000 ? amount : "42000",
      };
      break;

    case "INSURE":
      sliderLables =
        policyType === "PERMANENT_LIFE_INSURANCE"
          ? {
              labels: {
                100000: "$100,000",
                500000: "$500,000",
                // 1000000: "$1,000,000",
                1000000: "$1,000,000",
              },
              min: 100000,
              // max: 1000000,
              max: 1000000,
              stepAmount: 50000,
              defaultValue:
                amount && amount <= 1000000 && amount >= 100000
                  ? amount
                  : "350000",
            }
          : policyType === "FINAL_EXPENSE_INSURANCE"
          ? {
              labels: {
                1000: "$1000",
                25000: "$25,000",
                // 1000000: "$1,000,000",
                50000: "$50,000",
              },
              min: 1000,
              // max: 1000000,
              max: 50000,
              stepAmount: 1000,
              defaultValue:
                amount && amount <= 50000 && amount >= 1000 ? amount : "10000",
            }
          : {
              labels: {
                25000: "$25,000",
                1500000: "$1,500,000",
                // 1000000: "$1,000,000",
                3000000: "$3,000,000",
              },
              min: 25000,
              // max: 1000000,
              max: 3000000,
              stepAmount: 25000,
              defaultValue:
                amount && amount <= 3000000 && amount >= 25000
                  ? amount
                  : "350000",
            };

      break;

    default:
      sliderLables = {};
      break;
  }
  return sliderLables;
}
export const getState = (zipcode) => {
  /* Ensure param is a string to prevent unpredictable parsing results */
  // if (typeof zipString !== "string") {
  //   console.log("Must pass the zipcode as a string.");
  //   return;
  // }

  /* Ensure we have exactly 5 characters to parse */
  // if (zipString.length !== 5) {
  //   console.log("Must pass a 5-digit zipcode.");
  //   return;
  // }

  /* Ensure we don't parse strings starting with 0 as octal values */
  // const zipcode = parseInt(zipString, 10);

  let st;
  let state;
  let id;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    id = 1;
    st = "AL";
    state = "Alabama";
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    id = 2;
    st = "AK";
    state = "Alaska";
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    id = 3;
    st = "AZ";
    state = "Arizona";
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    id = 4;
    st = "AR";
    state = "Arkansas";
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    id = 5;
    st = "CA";
    state = "California";
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    id = 6;
    st = "CO";
    state = "Colorado";
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    id = 7;
    st = "CT";
    state = "Connecticut";
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    id = 8;
    st = "DE";
    state = "Delaware";
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    id = 10;
    st = "FL";
    state = "Florida";
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    id = 11;
    st = "GA";
    state = "Georgia";
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    id = 12;
    st = "HI";
    state = "Hawaii";
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    id = 13;
    st = "ID";
    state = "Idaho";
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    id = 14;
    st = "IL";
    state = "Illinois";
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    id = 15;
    st = "IN";
    state = "Indiana";
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    id = 16;
    st = "IA";
    state = "Iowa";
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    id = 17;
    st = "KS";
    state = "Kansas";
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    id = 18;
    st = "KY";
    state = "Kentucky";
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    id = 19;
    st = "LA";
    state = "Louisiana";
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    id = 20;
    st = "ME";
    state = "Maine";
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    id = 21;
    st = "MD";
    state = "Maryland";
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode == 5501 ||
    zipcode == 5544
  ) {
    id = 22;
    st = "MA";
    state = "Massachusetts";
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    id = 23;
    st = "MI";
    state = "Michigan";
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    id = 24;
    st = "MN";
    state = "Minnesota";
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    id = 25;
    st = "MS";
    state = "Mississippi";
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    id = 26;
    st = "MO";
    state = "Missouri";
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    id = 27;
    st = "MT";
    state = "Montana";
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    id = 34;
    st = "NC";
    state = "North Carolina";
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    id = 35;
    st = "ND";
    state = "North Dakota";
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    id = 28;
    st = "NE";
    state = "Nebraska";
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    id = 29;
    st = "NV";
    state = "Nevada";
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    id = 30;
    st = "NH";
    state = "New Hampshire";
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    id = 31;
    st = "NJ";
    state = "New Jersey";
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    id = 32;
    st = "NM";
    state = "New Mexico";
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    id = 33;
    st = "NY";
    state = "New York";
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    id = 36;
    st = "OH";
    state = "Ohio";
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    id = 37;
    st = "OK";
    state = "Oklahoma";
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    id = 38;
    st = "OR";
    state = "Oregon";
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    id = 39;
    st = "PA";
    state = "Pennsylvania";
  } else if (zipcode >= 300 && zipcode <= 999) {
    id = 40;
    st = "PR";
    state = "Puerto Rico";
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    id = 41;
    st = "RI";
    state = "Rhode Island";
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    id = 42;
    st = "SC";
    state = "South Carolina";
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    id = 43;
    st = "SD";
    state = "South Dakota";
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    id = 44;
    st = "TN";
    state = "Tennessee";
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    id = 45;
    st = "TX";
    state = "Texas";
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    id = 46;
    st = "UT";
    state = "Utah";
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    id = 47;
    st = "VT";
    state = "Vermont";
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    id = 48;
    st = "VA";
    state = "Virginia";
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    id = 9;
    st = "DC";
    state = "District of Columbia";
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    id = 49;
    st = "WA";
    state = "Washington";
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    id = 50;
    st = "WV";
    state = "West Virginia";
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    id = 51;
    st = "WI";
    state = "Wisconsin";
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    id = 52;
    st = "WY";
    state = "Wyoming";
  } else {
    // console.log("No state found matching", zipcode);
    return null;
  }

  return { id, st, state };
};

export const formatDate = (date) => {
  let newDate = moment.unix(date / 1000).format("MM/DD/YYYY");

  return newDate;
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const searchTableData = ({
  search = "",
  item = [],
  filterFields,
  isEncrypted = false,
}) => {
  let result = item;
  if (search) {
    result = item?.filter((data) =>
      filterFields?.some((key) =>
        isEncrypted
          ? decryptData(data?.[key])
              ?.toLowerCase()
              .indexOf(search?.toLowerCase()) !== -1
          : data?.[key]?.toLowerCase().indexOf(search?.toLowerCase()) !== -1
      )
    );
  }
  return result;
};

export const globaleFormValidations = ({ formData, errors }) => {
  let errorMsgs = errors;
  const fields = Object.keys(formData);
  for (let index = 0; index < fields.length; index++) {
    const fieldName = fields[index];
    if (formData?.[fieldName] === "") {
      const errorField = fieldName?.toUpperCase();
      errorMsgs = {
        ...errorMsgs,
        [fieldName]: errorMessages?.[errorField]
          ? errorMessages?.[errorField]
          : "Please fill the details.",
      };
    } else {
      delete errorMsgs?.[fieldName];
    }
  }

  const isFormValid = Object.keys(errorMsgs).length !== 0 ? false : true;

  return { isFormValid, errors: errorMsgs, formData };
};


export const validatSpecialCharacter = (value) =>{
return /^[!@#$%^&*(),.?":{}|<>]/.test(value)
}