import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const StorePackageLoader = () => {
  const columns = [...Array(6)];
  return (
    <SkeletonTheme>
      <div className="row m-0">
        {columns.map((column, key) => (
          <div className="col-xl-6" key={key}>
            <div className="product-box">
              <div className="product-detail d-flex flex-row flex-wrap">
                <div className="product-img">
                  <Skeleton count={1} height={82} width={82} />
                </div>
                <div className="product-desc-box">
                  <h3>
                    <Skeleton count={1} height={26} />
                  </h3>
                  <p className="desc">
                    <Skeleton count={2} height={24} />
                  </p>
                </div>
              </div>
              <div className="payment-type d-flex flex-row flex-wrap">
                <p className="price-desc mr-auto">
                  <Skeleton count={1} height={22} width={160} />
                </p>
                <div className="price-desc">
                  <Skeleton
                    count={1}
                    height={32}
                    width={128}
                    style={{ borderRadius: 30 }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SkeletonTheme>
  );
};
