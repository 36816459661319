import React from "react";
import { Modal } from "react-bootstrap";

export const HubspotModel = ({ scheduleModal, handleDemoModel, meetingLink, getPayloadDetails }) => {
    return (
        <Modal
            className="schedule-model"
            show={scheduleModal}
            onHide={() => handleDemoModel("scheduleModal", false)}
            centered
            size="lg"
        >
            <Modal.Body>
                <div
                    style={{
                        height: '670px',
                        minWidth: '320px'
                    }}
                >
                    <iframe
                        frameBorder="0"
                        height="100%"
                        src={meetingLink}
                        width="100%"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => {
                    handleDemoModel("scheduleModal", false)
                }} type="submit" className=" btn btn-primary btn-sm  ml-3">
                    <span>Close</span>
                </button>
            </Modal.Footer>
        </Modal>
    );
};
