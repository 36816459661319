import React, { useState, useEffect } from "react";
import { Button, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, capitalize } from "lodash";
import { Animated } from "react-animated-css";
import errorMessages from "../../redux/actions/errorMessages";
import FactFinder from "../factFinder/factFinder";
import FactFinderSecond from "../factFinder/factFinderSecond";
import FactFinderThird from "../factFinder/factFinderThird";
import UserDetails from "../commonComponents/userDetails";
import { API_POINT_UNDERWRITE, API_POINT_MUNICH } from "../../config/config";
import {
  commonApiCall,
  showErrorToast,
  NumberFormatter,
  decryptData,
} from "../../utlis";

const BasicDetails = ({
  commonApiCall,
  conversation_details,
  application_details,
  ...props
}) => {
  const [accordionData, setAccordionData] = useState({
    basicDetails: true,
    sourceDetails: true,
    policyDetails: true,
    policyInformationTab: true,
    factFinderFirst: true,
    factFinderSecond: true,
    factFinderThird: true,
  });
  const [policyInformation, setPolicyInformation] = useState({});
  const [factFinderQuestions, setFactFinderQuestions] = useState({});
  const [applicationDetails, setApplicationDetails] = useState({});

  const handleAccordion = (e) => {
    setAccordionData({
      ...accordionData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      getDetailsOfApplications(props.applicationCarrierId);
      getDetailsOfPolicyInformation(props.applicationCarrierId);

      // conversationDetails({});
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailsOfApplications = async (id) => {
    if (id && id != null && id != "") {
      let response = await commonApiCall(
        API_POINT_UNDERWRITE +
          "/applicationBasicDetails?anonymousApplicationCarrierId=" +
          id,
        "",
        "get",
        "GET_DETAILS_APPLICATIONS",
        true
      );
      setApplicationDetails(response.data.data);
      // getFactFinderAnswers(response.data.data.applicationid);
    }
  };

  // const downLoadFile = (url) => {
  //   try {
  //     if (url) {
  //       window.open(url, "_blank");
  //     } else {
  //       return false;
  //     }
  //   } catch (err) {
  //     showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
  //   }
  // };

  const getDetailsOfPolicyInformation = async (id) => {
    if (id && id != null && id != "") {
      let response = await commonApiCall(
        API_POINT_UNDERWRITE +
          "/policyDetail?anonymousApplicationCarrierId=" +
          id,
        "",
        "get",
        null,
        true
      );
      setPolicyInformation(response.data.data);
    }
  };

  // const getFactFinderAnswers = async (id) => {
  //   if (id && id != null && id != "") {
  //     let body = {
  //       applicationId: id,
  //     };
  //     let response = await commonApiCall(
  //       API_POINT_MUNICH + "/getApplicationQuestionnaire",
  //       body,
  //       "post",
  //       null,
  //       false
  //     );

  //     if (response.data.status.code === 200)
  //       setFactFinderQuestions(response.data.data);
  //   }
  // };

  const {
    basicDetails,
    sourceDetails,
    policyDetails,
    factFinderFirst,
    factFinderSecond,
    factFinderThird,
    policyInformationTab,
  } = accordionData;
  if (applicationDetails && !isEmpty(applicationDetails)) {
    var {
      firstName,
      lastName,
      email,
      mobile,
      //ssn,
      drivingLicense,
      dob,
      addressLine1,
      location,
      applicationid,
      reference,
      carrierName,
      productName,
      pay,
      initialDeposit,
      policyState,
      humanId,
      healthRating,
      hideDetail,
    } = applicationDetails;
  }
  return (
    <div>
      <div>
        <Animated
          animationIn="fadeIn"
          animationOut="faderOutLeft"
          animationInDuration={1200}
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="white-box2">
            <Accordion className="application-accordian" defaultActiveKey={"0"}>
              <Accordion.Toggle
                className="application-toggle"
                as={Button}
                variant="link"
                eventKey="0"
                onClick={() =>
                  handleAccordion({
                    target: { name: "basicDetails", value: !basicDetails },
                  })
                }
              >
                <Link
                  className="box-title no-border mt-3 space-between"
                  to="#"
                  onClick={() =>
                    handleAccordion({
                      target: { name: "basicDetails", value: !basicDetails },
                    })
                  }
                >
                  <h3>Basic details</h3>
                  <span className="material-icons">
                    {basicDetails ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                  </span>
                </Link>
              </Accordion.Toggle>
              <Accordion.Collapse className="application-collapse" eventKey="0">
                <UserDetails
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  mobile={mobile}
                  drivingLicense={drivingLicense}
                  dob={dob}
                  addressLine1={addressLine1}
                  applicationid={applicationid}
                  policyState={policyState}
                  healthRating={healthRating}
                />
              </Accordion.Collapse>
            </Accordion>
            <Accordion className="application-accordian" defaultActiveKey={"0"}>
              <Accordion.Toggle
                className="application-toggle"
                as={Button}
                variant="link"
                eventKey="0"
                onClick={() =>
                  handleAccordion({
                    target: { name: "sourceDetails", value: !sourceDetails },
                  })
                }
              >
                <Link
                  className="box-title no-border mt-3 space-between"
                  to="#"
                  onClick={() =>
                    handleAccordion({
                      target: { name: "sourceDetails", value: !sourceDetails },
                    })
                  }
                >
                  <h3>Source details</h3>
                  <span className="material-icons">
                    {sourceDetails
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </span>
                </Link>
              </Accordion.Toggle>
              <Accordion.Collapse className="application-collapse" eventKey="0">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group filled-value">
                      <label>Source</label>
                      <h4>{reference ? reference : "N/A"}</h4>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group filled-value">
                      <label>Location</label>
                      <h4>{location ? decryptData(location) : "N/A"}</h4>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
            <Accordion className="application-accordian" defaultActiveKey={"0"}>
              <Accordion.Toggle
                className="application-toggle"
                as={Button}
                variant="link"
                eventKey="0"
                onClick={() =>
                  handleAccordion({
                    target: { name: "policyDetails", value: !policyDetails },
                  })
                }
              >
                <Link
                  className="box-title no-border mt-3"
                  to="#"
                  onClick={() =>
                    handleAccordion({
                      target: { name: "policyDetails", value: !policyDetails },
                    })
                  }
                >
                  <h3>Policy interests</h3>
                  <span className="material-icons">
                    {policyDetails
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </span>
                </Link>
              </Accordion.Toggle>
              <Accordion.Collapse className="application-collapse" eventKey="0">
                <div className="row">
                  {!hideDetail && (
                    <>
                      <div className="col-sm-4">
                        <div className="form-group filled-value">
                          <label>Carrier</label>
                          <h4>{carrierName ? carrierName : "N/A"}</h4>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group filled-value">
                          <label>Product Name</label>
                          <h4>
                            {productName
                              ? productName.replace("15", pay)
                              : "N/A"}
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-sm-4">
                    <div className="form-group filled-value">
                      <label>Initial premium amount</label>
                      <h4>
                        {initialDeposit
                          ? NumberFormatter(initialDeposit, "$")
                          : "N/A"}
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group filled-value">
                      <label>Medical Information</label>
                      <h4>{humanId ? "Available" : "Not available"}</h4>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
            <Accordion className="application-accordian" defaultActiveKey={"0"}>
              <Accordion.Toggle
                className="application-toggle"
                as={Button}
                variant="link"
                eventKey="0"
                onClick={() =>
                  handleAccordion({
                    target: {
                      name: "policyInformationTab",
                      value: !policyInformationTab,
                    },
                  })
                }
              >
                <Link
                  className="box-title no-border mt-3"
                  to="#"
                  onClick={() =>
                    handleAccordion({
                      target: {
                        name: "policyInformationTab",
                        value: !policyInformationTab,
                      },
                    })
                  }
                >
                  <h3>Policy Information</h3>
                  <span className="material-icons">
                    {policyInformationTab
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </span>
                  {/* </div> */}
                </Link>
              </Accordion.Toggle>
              <Accordion.Collapse className="application-collapse" eventKey="0">
                <div>
                  <div className="box-title no-border">
                    <h3 className="font-24"></h3>
                    {/* <div className="plan-icons-outer">
                      {policyInformation.url === "" ||
                      policyInformation.url === null ? (
                        <button className="btn btn-primary btn-lg disabled">
                          Download Application
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-lg"
                          onClick={() => downLoadFile(policyInformation.url)}
                        >
                          Download Application
                        </button>
                      )}
                    </div> */}
                  </div>
                  <div className="white-box2 with-border pb-0">
                    {!hideDetail && (
                      <div className="box-title">
                        <div className="with-semititle">
                          <h3>
                            {policyInformation.productName
                              ? policyInformation.productName.replace("15", pay)
                              : "N/A"}
                          </h3>

                          <h4 className="mb-0">
                            {policyInformation.productDescription
                              ? policyInformation.productDescription
                              : "N/A"}
                            {/* {carrierName &&
                            policyInformation.productName &&
                            pay &&
                            getProductDescription(
                              carrierName,
                              policyInformation.productName,
                              pay,
                              policyInformation.productDescription,
                            )} */}
                            {/* Limited Payment{" "}
                          {policyInformation.productName
                            ? policyInformation.productName.replace("15", pay)
                            : "N/A"}{" "}
                          with Premiums Payable for {pay} Years */}
                          </h4>
                        </div>
                      </div>
                    )}
                    <div className="row mt-3">
                      {!hideDetail && (
                        <div className="col-sm-6">
                          <div className="form-group filled-value">
                            <label>Issuing Company</label>
                            <h4>
                              {policyInformation.companyName
                                ? policyInformation.companyName
                                : "N/A"}
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>Application Id</label>
                          <h4>
                            {policyInformation.applicationId
                              ? policyInformation.applicationId
                              : "N/A"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-box2 with-border pb-0">
                    <div className="box-title">
                      <div className="with-semititle">
                        <h3>Initial Premium Information</h3>
                      </div>
                      <h3 className="text-primary">
                        {policyInformation.basePremium
                          ? NumberFormatter(policyInformation.basePremium, "$")
                          : "N/A"}
                      </h3>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>Premium Payment Mode</label>
                          <h4>
                            {policyInformation.frequency
                              ? capitalize(policyInformation.frequency)
                              : "N/A"}
                          </h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>Base Premium</label>
                          <h4>
                            {policyInformation.basePremium
                              ? NumberFormatter(
                                  policyInformation.basePremium,
                                  "$"
                                )
                              : "N/A"}
                          </h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>
                            Accelerated Death Benefit for Terminal Illness Rider
                          </label>
                          <h4>Included in premium</h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group filled-value">
                          <label>Transfer of Insured Rider</label>
                          <h4>Included in premium</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-box2 with-border pb-0 mb-0">
                    <div className="box-title">
                      <div className="with-semititle">
                        <h3>Base Policy Face Amount</h3>
                      </div>
                      <h3 className="text-primary">
                        {policyInformation.faceAmount
                          ? NumberFormatter(policyInformation.faceAmount, "$")
                          : "N/A"}
                      </h3>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
            {factFinderQuestions.data &&
              factFinderQuestions.data.length > 0 && (
                <Accordion
                  className="application-accordian"
                  defaultActiveKey={"0"}
                >
                  <Accordion.Toggle
                    className="application-toggle"
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      handleAccordion({
                        target: {
                          name: "factFinderFirst",
                          value: !factFinderFirst,
                        },
                      })
                    }
                  >
                    <Link
                      className="box-title no-border mt-3"
                      to="#"
                      onClick={() =>
                        handleAccordion({
                          target: {
                            name: "factFinderFirst",
                            value: !factFinderFirst,
                          },
                        })
                      }
                    >
                      <h3>Fact Finder Step-1</h3>
                      <span className="material-icons">
                        {factFinderFirst
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"}
                      </span>
                    </Link>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    className="application-collapse"
                    eventKey="0"
                  >
                    <div>
                      {!isEmpty(factFinderQuestions) && (
                        <div>
                          <FactFinder
                            factFinderQuestions={factFinderQuestions}
                          ></FactFinder>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              )}
            {factFinderQuestions.data &&
              factFinderQuestions.data.length > 4 && (
                <Accordion
                  className="application-accordian"
                  defaultActiveKey={"0"}
                >
                  <Accordion.Toggle
                    className="application-toggle"
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      handleAccordion({
                        target: {
                          name: "factFinderSecond",
                          value: !factFinderSecond,
                        },
                      })
                    }
                  >
                    <Link
                      className="box-title no-border mt-3"
                      to="#"
                      onClick={() =>
                        handleAccordion({
                          target: {
                            name: "factFinderSecond",
                            value: !factFinderSecond,
                          },
                        })
                      }
                    >
                      <h3>Fact Finder Step-2</h3>
                      <span className="material-icons">
                        {factFinderSecond
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"}
                      </span>
                    </Link>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    className="application-collapse"
                    eventKey="0"
                  >
                    <div>
                      {!isEmpty(factFinderQuestions) && (
                        <div>
                          <FactFinderSecond
                            factFinderQuestions={factFinderQuestions}
                          ></FactFinderSecond>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              )}
            {factFinderQuestions.data &&
              factFinderQuestions.data.length > 4 && (
                <Accordion
                  className="application-accordian"
                  defaultActiveKey={"0"}
                >
                  <Accordion.Toggle
                    className="application-toggle"
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() =>
                      handleAccordion({
                        target: {
                          name: "factFinderThird",
                          value: !factFinderThird,
                        },
                      })
                    }
                  >
                    <Link
                      className="box-title no-border mt-3"
                      to="#"
                      onClick={() =>
                        handleAccordion({
                          target: {
                            name: "factFinderThird",
                            value: !factFinderThird,
                          },
                        })
                      }
                    >
                      <h3>Fact Finder Step-3</h3>
                      <span className="material-icons">
                        {factFinderThird
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"}
                      </span>
                    </Link>
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    className="application-collapse"
                    eventKey="0"
                  >
                    <div>
                      {!isEmpty(factFinderQuestions) && (
                        <div>
                          <FactFinderThird
                            factFinderQuestions={factFinderQuestions}
                          ></FactFinderThird>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              )}
          </div>
        </Animated>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    conversation_details: state.agentReducer.conversationdetails,
    application_details: state.agentReducer.applicationDetails,
  };
};
export default connect(mapStateToProps, { commonApiCall })(BasicDetails);
