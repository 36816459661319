import React from "react";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import security from "../assets/images/lock.png";

const AccessDenied = ({ loader, ...props }) => {
  return (
    <div className="col-lg-12">
      {loader ? (
        <div className="text-align-center mt-5">
          <Spinner
            className="global-loader"
            animation="border"
            role="status"
            variant={"primary"}
            size={"lg"}
          >
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      ) : (
        <div className="access-denied-page">
          <img
            className="mr-3"
            width="50"
            height="50"
            src={security}
            alt="Password Protection"
          />
          <h1 className="title font-20">
            <strong> You don't have access to this feature.</strong>
          </h1>
          <p> The feature you're trying to access has restricted access.</p>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.agentReducer.globalLoader,
  };
};
export default connect(mapStateToProps, null)(AccessDenied);
