import React, { useState, useEffect, useRef } from "react";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { API_POINT_UNDERWRITE, API_URL_CHATSERVICE } from "../../config/config";
import {
  DashboardPagesRoutes,
  commonApiCall,
  formatDate,
  showErrorToast,
  getAttchmentHtml,
  getAttachmentName,
  getFileIcon,
  truncate,
  segmentTracking,
  formatDateInTimezone,
} from "../../utlis";
import errorMessages from "../../redux/actions/errorMessages";
import { ChatLoader } from "../skeletonLoaders/chatLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

let stompClient, SockJS;

const ChatMessage = ({
  commonApiCall,
  conversation_details,
  application_details,
  isSetFormCloseUpdate,
  sign_in,
  showApplicationDetail,
  handleTabChange,
  planData,
  ...props
}) => {
  const currentChatRef = useRef();
  let isDatePrinted = [];
  const currentChat = window.location.pathname;
  const [conversationDetails, setConversationDetails] = useState({
    applicationId:
      conversation_details && conversation_details?.applicationId
        ? conversation_details?.applicationId
        : "",
    clientId:
      conversation_details &&
      conversation_details.clientDetails &&
      conversation_details.clientDetails.clientId
        ? conversation_details.clientDetails.clientId
        : application_details &&
          application_details.data &&
          application_details.data.clientUserId,
    agentId:
      conversation_details && conversation_details?.agentUserId
        ? conversation_details?.agentUserId
        : application_details &&
          application_details.data &&
          application_details.data.agentUserId
        ? application_details &&
          application_details.data &&
          application_details.data.agentUserId
        : sign_in && sign_in.data && sign_in.data.id,
    applicationCarrierId:
      conversation_details && conversation_details?.applicationCarrierId
        ? conversation_details?.applicationCarrierId
        : application_details?.data?.applicationCarrierId,
    channelId:
      !showApplicationDetail &&
      conversation_details &&
      conversation_details.clientDetails
        ? conversation_details.clientDetails.channelId
        : application_details &&
          application_details.data &&
          application_details.data.channelId,
  });
  const [numberOfitemsShown, setNumberOfItemsToShown] = useState(3);
  const [viewAllDoc, setViewAllDoc] = useState(false);
  const [messageListing, setMessageListing] = useState({});
  const [attachmentListing, setAttachmentListing] = useState([]);
  const [carrierDocuments, setCarrierDocuments] = useState([]);
  const [formData, setFormData] = useState({
    textMessage: "",
    textErr: "",
    totalRecords: 0,
    currentPage: 0,
    isChatLoader: true,
  });
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // create the preview
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [image]);

  const createChannel = async () => {
    try {
      if (conversationDetails) {
        if (
          conversationDetails.channelId === null ||
          conversationDetails.channelId === "" ||
          conversationDetails.channelId === undefined
        ) {
          const request = {
            clientUserId: conversationDetails && conversationDetails.clientId,
            agentUserId: conversationDetails && conversationDetails.agentId,
            applicationCarrierId:
              conversationDetails && conversationDetails.applicationCarrierId,
            companyId: "1",
          };
          const apiEndPoint = API_URL_CHATSERVICE + "/createChannel";
          const response = await commonApiCall(
            apiEndPoint,
            request,
            "post",
            null,
            true,
            false,
            true
          );

          const channelResponse = response.data;
          if (channelResponse.status.code === 200) {
            setConversationDetails({
              ...conversationDetails,
              channelId: channelResponse.data.channelId,
            });
            connect(channelResponse.data.channelId);
          } else {
            showErrorToast(channelResponse.status.message);
          }
        } else {
          connect(conversationDetails.channelId);
        }
      } else {
        showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const renderIcon = () => {
    const name = image?.name?.split(".")[1];
    if (
      name == "jpg" ||
      name == "jpeg" ||
      name == "png" ||
      name == "JPG" ||
      name == "JPEG" ||
      name == "PNG"
    ) {
      return (
        <img width={50} height={50} className='img-fluid' src={preview}></img>
      );
    } else if (
      name == "xls" ||
      name === "xlsx" ||
      name == "XLS" ||
      name == "XLSX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileExcel} />
      );
    } else if (
      name === "doc" ||
      name === "docx" ||
      name === "DOC" ||
      name === "DOCX"
    ) {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileWord} />
      );
    } else if (name === "pdf" || name == "PDF") {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFilePdf} />
      );
    } else {
      return (
        <FontAwesomeIcon className='font-24 flex-shrink-0' icon={faFileLines} />
      );
    }
  };

  const connect = async (channelId) => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "/ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          agentUserId: conversationDetails && conversationDetails.agentId,
          type: "CHATSCREEN",
        },
        function () {
          getMessagesList(channelId);
          getAttachments(channelId);
          onConnected(channelId);
          getCarrierDocuments();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };
  const onConnected = (channelId) => {
    try {
      if (stompClient.connected) {
        if (isSetFormCloseUpdate) props.setCanFormClose(true);

        const _channelId = channelId
          ? channelId
          : conversationDetails.channelId;
        stompClient.subscribe(
          "/user/" + _channelId + "/reply",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
        // Registering user to server as a private chat user
        stompClient.send(
          "/addUser",
          {},
          JSON.stringify({ channelId: _channelId, type: "JOIN" })
        );
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onError = (channelId, error) => {
    //connect(channelId);
  };

  const sendMessage = (document) => {
    try {
      const { textMessage } = formData;
      const { channelId } = conversationDetails;
      if (stompClient) {
        const eventObject = {
          agent_id: sign_in?.data?.id,
        };
        segmentTracking("agent_chat_ilife", eventObject);
        const chatMessage = JSON.stringify({
          message: textMessage,
          carrierSupportDocUrl:
            !isEmpty(document) && document.url !== "" ? document.url : "",
          carrierSupportDocId:
            !isEmpty(document) && document.id !== "" ? document.id : "",
          carrierSupportDocName:
            !isEmpty(document) && document.fileName !== ""
              ? document.fileName
              : "",
          agentUserId:
            conversationDetails && conversationDetails.agentId
              ? conversationDetails.agentId
              : "",
          clientUserId: "",
          channelId: channelId,
        });
        if (
          textMessage.replace(/\s/g, "").length > 0 ||
          (!isEmpty(document) && document?.url)
        ) {
          stompClient.send("/sendMessage", {}, chatMessage);
          setFormData({ ...formData, textMessage: "" });
          if (!isEmpty(document)) {
            getAttachments(channelId);
            getCarrierDocuments();
          }
        } else {
          setFormData({ ...formData, textMessage: "" });
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const message = JSON.parse(payload.body);
        if (message) {
          let chatConversationArray = [];

          chatConversationArray.push({
            channelId: message.channelId,
            agentUserId: message.agentUserId,
            clientUserId: message.clientUserId,
            senderName: message.senderName,
            message: message.message ? message.message : "",
            carrierSupportDocUrl: message.carrierSupportDocUrl
              ? message.carrierSupportDocUrl
              : "",
            carrierSupportDocName: message.carrierSupportDocName
              ? message.carrierSupportDocName
              : "",
            uploadedDoc: message.uploadedDoc ? message.uploadedDoc : "",
            uploadedDocName: message.uploadedDocName
              ? message.uploadedDocName
              : "",
            createdDate: message.createdDate,
          });
          setMessageListing((prevMessageListing) => [
            ...prevMessageListing,
            ...chatConversationArray,
          ]);

          if (message.uploadedDoc) {
            let attachmentListArray = [];

            attachmentListArray.push({
              uploadedDoc: message.uploadedDoc ? message.uploadedDoc : "",
              uploadedDocName: message.uploadedDocName
                ? message.uploadedDocName
                : "",
            });
            setAttachmentListing((prevAttachmentListing) => {
              let returnAttachmentListArray = [];
              if (prevAttachmentListing.length >= 3) {
                prevAttachmentListing.pop();
                returnAttachmentListArray = [
                  ...attachmentListArray,
                  ...prevAttachmentListing,
                ];
              } else {
                returnAttachmentListArray = [
                  ...attachmentListArray,
                  ...prevAttachmentListing,
                ];
              }
              return returnAttachmentListArray;
            });
          }
          // scrollToEnd();
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getMessagesList = async (channelId) => {
    try {
      const _channelId = channelId ? channelId : conversationDetails.channelId;
      if (_channelId) {
        const request = {
          channelId: _channelId,
          agentUserId:
            conversationDetails &&
            conversationDetails.agentId &&
            conversationDetails.agentId,
          pageNo: formData.currentPage,
          pageSize: 100,
        };

        const apiEndPoint = API_URL_CHATSERVICE + "/getChatConversation";
        const response = await commonApiCall(
          apiEndPoint,
          request,
          "post",
          null,
          true,
          false,
          true
        );

        const chatMessageResponse = response.data;
        if (chatMessageResponse.status.code === 200) {
          if (!isEmpty(messageListing)) {
            const conversationMessagesList = [
              ...chatMessageResponse.data.chatConversation,
              ...messageListing,
            ];
            setMessageListing(conversationMessagesList);
          } else {
            setMessageListing(chatMessageResponse.data.chatConversation);
          }
          setFormData({
            ...formData,
            totalRecords: chatMessageResponse.data.totalRecord,
            currentPage: formData.currentPage + 1,
            isChatLoader: false,
          });
        } else {
          showErrorToast(response.status.message);
        }
      }
    } catch (err) {
     // console.log(err);
      //showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const sendAttachments = async () => {
    try {
      let renamedFile;
      const file = image;
      const splitName = file?.name?.split(".");
      const loweCase = splitName[1].toLowerCase();
      const finalString = splitName[0] + "." + loweCase;
      renamedFile = new File([file], finalString, { type: file.type });
      setLoading(true);
      const { channelId } = conversationDetails;
      let requestFormData = new FormData();
      // let attachmentFile = document.querySelector("#file");
      if (checkAllowedAttachment(renamedFile)) {
        requestFormData.append("document", renamedFile);
        requestFormData.append(
          "agentUserId",
          conversationDetails && conversationDetails.agentId
        );
        requestFormData.append("clientUserId", "");
        requestFormData.append("channelId", channelId);
        const apiEndPoint = API_URL_CHATSERVICE + "/fileUpload";

        const isAuthorized = true;
        const response = await commonApiCall(
          apiEndPoint,
          requestFormData,
          "post",
          null,
          isAuthorized,
          true,
          true
        );

        if (response.data.status.code === 200) {
          formData.textMessage && sendMessage();
          let conversationList = [...messageListing, response.data.data];
          setFormData({
            ...formData,
            totalRecords: formData.totalRecords + 1,
            textMessage: "",
          });
          setMessageListing(conversationList);
          getAttachments(channelId);
          setImage(null);
          setPreview("");
          setLoading(false);
        } else {
          showErrorToast(response.data.status.message);
          setLoading(false);
        }
      } else {
        showErrorToast(errorMessages.VALID_ATTACHMENT);
        setLoading(false);
      }
    } catch (err) {
     /// console.log(err);
      setLoading(false);
      //console.log(err, "catch log of sendAttachments!");
      //showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getAttachments = async (channelId) => {
    try {
      if (channelId) {
        const request = {};
        const apiEndPoint =
          API_URL_CHATSERVICE +
          "/getDocuments?channelId=" +
          channelId +
          "&pageNo=0&pageSize=";
        const isAuthorized = true;
        const response = await commonApiCall(
          apiEndPoint,
          request,
          "post",
          null,
          isAuthorized,
          false,
          true
        );
        if (response && response.data.status.code === 200) {
          if (response.data && response.data.data.chatConversation) {
            setAttachmentListing(response.data.data.chatConversation);
          }
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getCarrierDocuments = async () => {
    try {
      const { channelId, applicationCarrierId } = conversationDetails;
      const request = {};
      const carrierId =
        conversation_details && conversation_details.carrierId
          ? conversation_details.carrierId
          : application_details?.data?.carrierId;
      if (carrierId) {
        const apiEndPoint =
          API_POINT_UNDERWRITE +
          "/carrierSupportDocs?carrierId=" +
          carrierId +
          "&channelId=" +
          channelId +
          "&applicationCarrierId=" +
          applicationCarrierId;
        const isAuthorized = true;
        const response = await commonApiCall(
          apiEndPoint,
          request,
          "get",
          "CARRIER_DOCUMENTS",
          isAuthorized,
          false
        );
        if (response && response.data.status.code === 200) {
          if (response.data && response.data.data) {
            setCarrierDocuments(
              response?.data?.data?.carrierSupportDocumentList
            );
          }
        }
      }
    } catch (err) {
      // showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const handleScroll = async (container) => {
    try {
      const { totalRecords } = formData;
      if (messageListing.length === totalRecords) {
        return false;
      } else if (
        container.scrollTop === 0 &&
        messageListing.length <= totalRecords
      ) {
        await getMessagesList();
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const scrollToEnd = () => {
    const { currentPage } = formData;
    let pageOffset = currentPage + 1;
    const container = document.getElementById("chat-area");
    if (container !== null && handleTabChange === undefined) {
      container.getElementsByClassName("scrollbar-container")[0].scrollTop =
        container.getElementsByClassName("scrollbar-container")[0]
          .scrollHeight / pageOffset;
    }
    if (container !== null && handleTabChange !== undefined) {
      container.getElementsByClassName("scrollbar-container")[0].scrollTop =
        container.getElementsByClassName("scrollbar-container")[0].scrollHeight;
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      if (evt.type === "keypress") {
        sendMessage();
      }
      evt.preventDefault();
    }
  };

  const checkAllowedAttachment = (fileVal) => {
    switch (fileVal.type) {
      case "application/msword":
        return true;

      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return true;

      case "application/pdf":
        return true;

      case "image/png":
        return true;

      case "image/jpeg":
        return true;

      case "image/jpg":
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (conversationDetails?.channelId && conversationDetails?.channelId !== undefined) {
      createChannel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetails]);

  useEffect(() => {
    if (showApplicationDetail === false) {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          // console.log("Disconnected. See you next time!");
        });
      }
    }
  }, [showApplicationDetail]);

  useEffect(() => {
    if (handleTabChange === true) {
      //scrollToEnd();
      currentChatRef &&
        currentChatRef.current &&
        currentChatRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTabChange]);

  useEffect(() => {
    currentChatRef &&
      currentChatRef.current &&
      currentChatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [messageListing]);

  useEffect(() => {
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          // console.log("Disconnected. See you next time!");
        });
      }
    };
  }, []);

  const openAttachments = (url) => {
    try {
      window.open(url, "_blank");
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  // const getDetailsOfApplications = async (appCrrid) => {
  //   document.body.classList.remove("modal-open");
  //   if (appCrrid && appCrrid !== null && appCrrid !== "") {
  //     await commonApiCall(
  //       API_POINT_UNDERWRITE +
  //         "/applicationBasicDetails?anonymousApplicationCarrierId=" +
  //         appCrrid,
  //       "",
  //       "get",
  //       "GET_DETAILS_APPLICATIONS",
  //       true
  //     );
  //   }
  // };

  const returnPopOver = (id, content) => {
    return (
      <Popover id={id}>
        <Popover.Content>{content}</Popover.Content>
      </Popover>
    );
  };

  const onViewDoc = () => {
    setNumberOfItemsToShown(attachmentListing?.length);
    setViewAllDoc(true);
  };

  const handleUpload = (e) => {
    const name = e.target.files[0]?.name?.split(".")[1];
    let fileSize = Math.round(e.target.files[0].size / 1024);
    let fileExtension = [
      "JPG",
      "jpg",
      "jpeg",
      "JPEG",
      "png",
      "PNG",
      "doc",
      "DOC",
      "DOCX",
      "docx",
      "pdf",
      "PDF",
    ];
    if (fileExtension.includes(name)) {
      if (!e.target.files[0]) {
        showErrorToast(errorMessages.SELECT_FILE);
      } else if (e.target.files[0] && fileSize > 10 * 1024) {
        showErrorToast(errorMessages.SINGLE_FILE_VALIDATION);
      } else {
        setImage(e.target.files[0]);
      }
    } else {
      showErrorToast("Please upload only .jpeg .jpg .pdf .doc .docx file");
    }
  };

  let { textMessage, isChatLoader } = formData;
  return (
    <div className='converexp'>
      <div className='conexp-left'>
        <div className='convertop'>
          <h3>Conversation</h3>
          <div className='righticon'></div>
        </div>
        {/* Begin: Chat Box */}
        <div className='chat-box-wrapper'>
          <div className='card-outer p-0 mb-0'>
            <div className='chat-box'>
              {messageListing && messageListing.length <= 0 ? (
                <h4 className='no-conve'>No Conversations Found</h4>
              ) : null}
              <ul className='chat-area' id='chat-area'>
                {isChatLoader && <ChatLoader />}
                {/* <PerfectScrollbar onScrollY={(e) => handleScroll(e)}> */}
                {Array.isArray(messageListing) &&
                  messageListing.map((message, key) => {
                    return (
                      <React.Fragment key={key}>
                        {isDatePrinted.includes(
                          formatDate(message.createdDate, "MM/DD/YYYY")
                        ) === false && (
                          <React.Fragment>
                            <li className='chat-msg-item chat-date'>
                              <span className='chat-date-span'>
                                {formatDate(message.createdDate, "MM/DD/YYYY")}
                              </span>
                            </li>
                            <li className='d-none'>
                              {isDatePrinted.push(
                                formatDate(message.createdDate, "MM/DD/YYYY")
                              )}
                            </li>
                          </React.Fragment>
                        )}
                        <li
                          className={
                            message.clientUserId === ""
                              ? "chat-msg-item sent-msg"
                              : "chat-msg-item "
                          }
                          ref={
                            key === messageListing.length - 1
                              ? currentChatRef
                              : null
                          }
                        >
                          <div
                            className={
                              (message.uploadedDoc &&
                                message.uploadedDoc !== "") ||
                              (message.carrierSupportDocUrl &&
                                message.carrierSupportDocUrl !== "")
                                ? "chat-msg no-padding"
                                : "chat-msg"
                            }
                          >
                            <p>{message.message ? message.message : ""}</p>

                            <span className='msg-time'>
                              {message.clientUserId !== "" && (
                                <React.Fragment>
                                  <span>{message.senderName}</span>
                                  <br />
                                </React.Fragment>
                              )}

                              {formatDateInTimezone(
                                message.createdDate,
                                planData?.timeZoneId ?? null
                              ).format("hh:mm a")}
                            </span>
                          </div>
                          <div className='document document-row'>
                            <div className='chat-image'>
                              {message.uploadedDoc &&
                                getAttchmentHtml(
                                  message.uploadedDoc,
                                  message.uploadedDocName
                                )}
                              {message.carrierSupportDocUrl &&
                                getAttchmentHtml(
                                  message.carrierSupportDocUrl,
                                  message.carrierSupportDocName
                                )}
                            </div>
                            <div className='document-name'>
                              <span
                                className='list-label'
                                style={{ cursor: "pointer" }}
                              >
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  overlay={returnPopOver(
                                    "popover-basic2",
                                    message.uploadedDocName
                                      ? message.uploadedDocName
                                      : message.carrierSupportDocName
                                      ? message.carrierSupportDocName
                                      : ""
                                  )}
                                >
                                  <span>
                                    {message.uploadedDoc &&
                                    message.uploadedDocName &&
                                    getAttachmentName(message.uploadedDoc)
                                      ? truncate(message.uploadedDocName, 15)
                                      : ""}
                                    {message.carrierSupportDocUrl &&
                                      message.carrierSupportDocName && (
                                        <p>
                                          {getAttachmentName(
                                            message.carrierSupportDocUrl
                                          )
                                            ? truncate(
                                                message.carrierSupportDocName,
                                                15
                                              )
                                            : ""}
                                        </p>
                                      )}
                                  </span>
                                </OverlayTrigger>
                              </span>
                              {/* {message.uploadedDoc && message.uploadedDocName &&
                                  getAttachmentName(message.uploadedDoc) ? truncate(message.uploadedDocName, 15) : ""}
                                {message.carrierSupportDocName && message.carrierSupportDocName && <p>
                                  {getAttachmentName(message.carrierSupportDocUrl) ? truncate(message.carrierSupportDocName, 15) : ""}
                                </p>} */}
                            </div>
                          </div>
                        </li>
                      </React.Fragment>
                    );
                  })}
                {/* </PerfectScrollbar> */}
                {/* </div> */}
              </ul>
            </div>

            <div className='chat-buttons'>
              {image && (
                <>
                  <div className='white-box p-2 border mb-2 d-flex'>
                    {renderIcon()}
                    <span className='ml-2 line-count'>{image.name}</span>
                    <span
                      onClick={() => setImage(null)}
                      class='material-icons cursor-pointer ml-auto'
                    >
                      close
                    </span>
                  </div>
                </>
              )}
              <div className='chat-button-inner'>
                <div className='form-group mb-0 chat-input'>
                  <textarea
                    // placeholder={this.state.isTyping ? this.state.otherUserName + ' is typing...' : "Type Message"}
                    type='text'
                    name='textmsg'
                    value={textMessage}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        textMessage: e.target.value,
                        textErr: "",
                      })
                    }
                    onKeyPress={(e) => handleKeyPress(e)}
                    placeholder='Type message here…'
                    className='form-control'
                    disabled={isChatLoader ? true : false}
                  />
                  <div className='choose-file'>
                    <input
                      className='profile-input'
                      type='file'
                      style={{ display: "block" }}
                      value={""}
                      name='image'
                      id='file'
                      accept='image/*,.doc, .docx,.txt,.pdf'
                      onChange={(e) => handleUpload(e)}
                    />
                    <label className='material-icons add-file' htmlFor='file'>
                      add_photo_alternate
                    </label>
                  </div>
                </div>

                <div className='form-group mb-0'>
                  <Button
                    type='button'
                    value='Submit'
                    className='btn-sm'
                    disabled={loading}
                    onClick={(event) =>
                      image ? sendAttachments() : sendMessage(event)
                    }
                  >
                    {" "}
                    SEND{" "}
                  </Button>
                </div>
              </div>

              <span className='text-danger '>{formData.textErr}</span>
            </div>
          </div>
        </div>
        {/* End: Chat Box */}
      </div>

      <div className='conexp-links'>
        {/* <div className="link-box">
          <h3>Send links</h3>
          <ul>
            <li>
              <Link to="#">Financial Verification Link</Link>
            </li>
            <li>
              <Link to="#">Medical Verification Link</Link>
            </li>
            <li>
              <Link to="#">Paramed Link</Link>
            </li>
            <li>
              <Link to="#">Deposit Payment Link</Link>
            </li>
            <li>
              <Link to="#">Approved Marketing Material</Link>
            </li>
          </ul>
        </div>
        <button
          className="btn btn-primary-outline"
          onClick={() => props.handleModel("chatModel", !props.chatModel)}
        >
          {" "}
          End conversation{" "}
        </button>
     */}
        {!isEmpty(carrierDocuments) &&
          (currentChat === "/chat"
            ? !conversation_details.hideDetail
            : !application_details.data.hideDetail) && (
            <div className='link-box documents mt-4'>
              <h3>Carrier Documents</h3>
              <div className='chat-document-sent-received'>
                {carrierDocuments?.length > 0 &&
                  carrierDocuments.map((documents, key) => {
                    return (
                      <div className='document-row' key={key}>
                        {getFileIcon(documents.url, "")}
                        <div className='document-content'>
                          <div className='document-name'>
                            <span
                              className='list-label'
                              style={{ cursor: "pointer" }}
                            >
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                overlay={returnPopOver(
                                  "popover-basic2",
                                  documents.fileName
                                )}
                              >
                                <span>{truncate(documents.fileName, 15)}</span>
                              </OverlayTrigger>
                            </span>
                          </div>
                          <div className='view-link'>
                            <span
                              className='material-icons'
                              title='View'
                              onClick={() => openAttachments(documents.url)}
                            >
                              remove_red_eye
                            </span>
                            {documents.send ? (
                              <span onClick={() => sendMessage(documents)}>
                                {"Resend"}
                              </span>
                            ) : (
                              <span
                                className='material-icons'
                                onClick={() => sendMessage(documents)}
                              >
                                send
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        {attachmentListing && (
          <div className='link-box documents mt-4'>
            <h3>Documents Sent / Received</h3>
            <div className='chat-document-sent-received'>
              {attachmentListing
                .slice(
                  0,
                  viewAllDoc ? attachmentListing?.length : numberOfitemsShown
                )
                .map((attachments, key) => {
                  return (
                    <div className='document-row' key={key}>
                      {getFileIcon(attachments.uploadedDoc, "")}
                      <div className='document-content'>
                        <div className='document-name'>
                          <span
                            className='list-label'
                            style={{ cursor: "pointer" }}
                          >
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              overlay={returnPopOver(
                                "popover-basic2",
                                attachments.uploadedDocName
                              )}
                            >
                              <span>
                                {/* {truncate(attachments.uploadedDocName, 15)} */}
                                {attachments.uploadedDocName}
                              </span>
                            </OverlayTrigger>
                          </span>
                        </div>
                        <div className='view-link'>
                          {/* <span
                        onClick={() => openAttachments(attachments.uploadedDoc)}
                      >
                        View
                      </span> */}
                          <span
                            className='material-icons'
                            title='View'
                            onClick={() =>
                              openAttachments(attachments.uploadedDoc)
                            }
                          >
                            remove_red_eye
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {/* <div className="document-row">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#2196F3"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"
                />
                <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"
                />
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">Client</div>
              <div className="view-link">
                <Link to={DashboardPagesRoutes.APPLICATIONSDOCUMENTS}>
                  {" "}
                  View{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="document-row">
            <div className="document-icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
              >
                <path
                  fill="#4CAF50"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                />
                <path
                  fill="#FFF"
                  d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                />
                <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
                <path
                  fill="#FFF"
                  d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                />
              </svg>
            </div>
            <div className="document-content">
              <div className="document-name">Agent</div>
              <div className="view-link">
                <Link to={DashboardPagesRoutes.APPLICATIONSDOCUMENTS}>
                  {" "}
                  View{" "}
                </Link>
              </div>
            </div>
          </div> */}
        {/* {attachmentListing &&
          attachmentListing.length >= 3 &&
          conversationDetails &&
          conversationDetails.applicationCarrierId && (
            <Link
              className="btn btn-primary-outline"
              onClick={() =>
                getDetailsOfApplications(
                  conversationDetails.applicationCarrierId
                )
              }
              to={{
                pathname: DashboardPagesRoutes.APPLICATIONSDOCUMENTS,
                state: {
                  id:
                    conversationDetails &&
                    conversationDetails.applicationCarrierId,
                },
              }}
            >
              {" "}
              View All Documents{" "}
            </Link>
          )} */}

        {numberOfitemsShown === 3 &&
          attachmentListing &&
          attachmentListing.length > 3 && (
            <Link
              className='btn btn-primary-outline'
              to='#'
              onClick={() => onViewDoc()}
            >
              {" "}
              View All Documents{" "}
            </Link>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    conversation_details: state.agentReducer.conversationdetails,
    application_details: state.agentReducer.applicationDetails,
    sign_in: state.agentReducer.signIn,
    planData: state.agentReducer.planDetails?.data?.data,
  };
};
export default connect(mapStateToProps, { commonApiCall })(ChatMessage);
