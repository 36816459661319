import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LeadConversationsLoader = () => {
  return (
    <SkeletonTheme>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={260} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={280} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={240} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={260} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={220} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={200} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
      <div className="conversation-person">
        <div className="conversation-person-img">
          <Skeleton count={1} height={48} width={48} circle={true} />
        </div>
        <div className="conversation-person-details">
          <h4 className="mb-0">
            <Skeleton count={1} height={30} width={140} />
          </h4>
          <span className="mr-2">
            <Skeleton count={1} height={30} width={110} />
          </span>
          <Skeleton count={1} height={30} width={100} />
          <h5 className="mb-0 mt-1">
            <Skeleton count={1} height={20} width={260} />
          </h5>
        </div>
        <div className="conver-time">
          <Skeleton count={1} height={24} width={120} />
        </div>
      </div>
    </SkeletonTheme>
  );
};
