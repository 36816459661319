import React, { lazy, Suspense } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { LeafLoader } from "./components/skeletonLoaders/ilifeLogoLoader";
import {
  BasicsPagesRoutes,
  CmsPagesRoutes,
  RegistrationPagesRoutes,
  DashboardPagesRoutes,
  SettingsRoutes,
  AmbassadorPagesRoutes,
  MyWebsiteRoutes,
  ProtectedRoute,
  UnProtectedRoute,
  getJwt,
  // dataDogFunction,
  // getPayment,
  // getIsProfileSet,
} from "./utlis/index";
import history from "./utlis/history";
// import LiveLTSettings from "./screen/dashboard/liveLTSettings";
import AccountSettings from "./screen/dashboard/accountSettings";
import DemoEmailEditor from "./screen/demoEmailEditor";
import DemoEmailTemplate from "./screen/demoEmailTemplate";
import Wrapper from "./components/layoutComponents/Scrolltotop";
import Scrolltotop from "./components/layoutComponents/Scrolltotop";
import sentEmailwithoutmetrics from "./screen/emailCampaign/sentEmailwithoutmetrics";
import myAgents from "./screen/dashboard/ambassador/myAgents";

const HomePage = lazy(() => import("./screen/homepage"));
const AssurityAppointmentForm = lazy(() =>
  import("./screen/dashboard/appointments/assurityAppointmentForm")
);
const WebsiteSettings = lazy(() =>
  import("./screen/dashboard/myWebsite/websiteSettings")
);

const Login = lazy(() => import("./screen/Authentication"));
const LoginWithEmail = lazy(() =>
  import("./screen/Authentication/loginWithEmail")
);
const Register = lazy(() => import("./screen/registration/register"));
// const RegisterAsProf = lazy(() =>
//   import("./screen/registration/registerStepsAsProfessionals")
// );

/**
 * DASHBOARD
 */
const Dashboard = lazy(() => import("./screen/dashboard/index"));
// const Myprofile = lazy(() => import("./screen/dashboard/myProfile"));
// const NotificationSettings = lazy(() =>
//   import("./screen/dashboard/notificationSettings")
// );
const MySubcriptions = lazy(() => import("./screen/dashboard/mySubscription"));
const WelcomePage = lazy(() => import("./screen/dashboard/welcomePage"));
const ProductSelectionPage = lazy(() =>
  import("./screen/dashboard/segmentOnBoard/productSelectionPage")
);
const CarriersSelectionPage = lazy(() =>
  import("./screen/dashboard/segmentOnBoard/carriersSelectionPage")
);
const StateSelectionPage = lazy(() =>
  import("./screen/dashboard/segmentOnBoard/stateSelectionPage")
);
const TimeZoneSelectionPage = lazy(() =>
  import("./screen/dashboard/segmentOnBoard/timeZoneSelectionPage")
);
// const MyprofileProf = lazy(() =>
//   import("./screen/dashboard/profileProfessionals")
// );

const Myreferralas = lazy(() =>
  import("./screen/dashboard/ambassador/myReferrals")
);
const MyreferralasDetails = lazy(() =>
  import("./screen/dashboard/ambassador/myReferenceDetails")
);

const LeadConversation = lazy(() =>
  import("./screen/dashboard/professionals/leadConversations")
);
const Contacts = lazy(() =>
  import("./screen/dashboard/crm/contactDetails/contacts")
);
const Text = lazy(() => import("./screen/dashboard/crm/textConversation/text"));
const Payouts = lazy(() => import("./screen/dashboard/professionals/payouts"));
const AvblLeadPackages = lazy(() =>
  import("./screen/dashboard/professionals/avblLeadPackages")
);
const Applications = lazy(() =>
  import("./screen/dashboard/applications/applications")
);

const ApplicationsDetails = lazy(() =>
  import("./screen/dashboard/applications/applicationDetails")
);

const ApplicationsPolicyInfo = lazy(() =>
  import("./screen/dashboard/applications/policyInformation")
);

const ApplicationsEditPdf = lazy(() =>
  import("./screen/dashboard/applications/editablePdf")
);
const ApplicationsConversation = lazy(() =>
  import("./screen/dashboard/applications/conversations")
);
const ApplicationDocuments = lazy(() =>
  import("./screen/dashboard/applications/documents")
);

const ApplicationUnderWrightStep = lazy(() =>
  import("./screen/dashboard/applications/underwritingSteps")
);
const Notifications = lazy(() => import("./screen/dashboard/notifications"));

/**
 * REGISTRATION ROUTE
 */
const LiscensedProfessionalQuestion = lazy(() =>
  import("./screen/registration/liscensedProfessionalQuestion")
);
const IndividualOrOrganizationQuestion = lazy(() =>
  import("./screen/registration/individualOrOrganizationQuestion")
);
const HaveInsuranceLicenseQuestion = lazy(() =>
  import("./screen/registration/haveInsuranceLicenseQuestion")
);
const BecomeMember = lazy(() => import("./screen/registration/becomeMember"));
const ScanDetails = lazy(() => import("./screen/registration/scanDetails"));
const ScanResults = lazy(() => import("./screen/registration/scanResult"));

const LeadQuestions = lazy(() => import("./screen/registration/leadQuestions"));
const LeadPackage = lazy(() => import("./screen/registration/leadPackage"));
const Payment = lazy(() => import("./screen/registration/payment"));
const LeadPackageOffer = lazy(() =>
  import("./screen/registration/leadPackageOffer")
);

const CarrierAppointment = lazy(() =>
  import("./screen/registration/carrierAppointments")
);

/**
 * CMS PAGES ROUTE
 */
const AboutUs = lazy(() => import("./screen/cms/aboutUs"));
const ServiceRepresent = lazy(() =>
  import("./screen/cms/serviceRepresentative")
);
const ContactUs = lazy(() => import("./screen/cms/contactUs"));
const NotFound = lazy(() => import("./screen/notFound"));

const ManagerialView = lazy(() =>
  import("./screen/dashboard/ambassador/managerialView")
);

const AgentChat = lazy(() => import("./components/agentChat"));

const MYAgents = lazy(() => import("./screen/dashboard/ambassador/myAgents"));

const ProspectsViaAffiliate = lazy(() =>
  import("./screen/dashboard/ambassador/prospectsViaAffiliate")
);
const AgentDetails = lazy(() =>
  import("./screen/dashboard/professionals/agentDetails")
);
const AgentRequests = lazy(() =>
  import("./screen/dashboard/enterprise/agentRequests")
);
const InviteAgent = lazy(() =>
  import("./screen/dashboard/enterprise/inviteAgent")
);
const AssignLeads = lazy(() =>
  import("./screen/dashboard/enterprise/assignLeads")
);
const UnAssignLeads = lazy(() =>
  import("./screen/dashboard/enterprise/unAssignLeads")
);
const InvitedAgents = lazy(() =>
  import("./screen/dashboard/enterprise/invitedAgents")
);
const ThemeComp = lazy(() => import("./screen/settings/ThemeSetting"));
const UnderQuestion = lazy(() =>
  import("./screen/settings/UnderwriteQuestion")
);
const SubUsers = lazy(() => import("./screen/settings/subUsers"));
const MyHomePage = lazy(() => import("./screen/dashboard/myWebsite/homePage"));
const Emailmarketing = lazy(() =>
  import("./screen/emailCampaign/emailMarketing")
);
const Reminders = lazy(() => import("./screen/reminders/reminders"));
const ProductBrowsing = lazy(() =>
  import("./screen/ProductBrowsing/productBrowisngPage")
);
const EmailCampaign = lazy(() =>
  import("./screen/emailCampaign/createEmailCampaign/emailCampaign")
);

const SentEmailDetails = lazy(() =>
  import("./screen/emailCampaign/sentEmailDetails")
);

const SentEmailDetailsWithoutMetrics = lazy(() =>
  import("./screen/emailCampaign/sentEmailwithoutmetrics")
);
const SocialMedia = lazy(() => import("./screen/socialMedia/socialMedia"));
const ClientDetailMail = lazy(() =>
  import("./screen/emailCampaign/clientDetailMail")
);

const MyAboutPage = lazy(() =>
  import("./screen/dashboard/myWebsite/aboutPage")
);
const SelectAppointmentCarriers = lazy(() =>
  import("./screen/dashboard/appointments/selectAppointmentCarriers")
);
const AppointmentForm = lazy(() =>
  import("./screen/dashboard/appointments/applicationForm.js")
);
const SbliAppoinmentForm = lazy(() =>
  import("./screen/dashboard/appointments/sbliAppointmentForm")
);

//Business Tools
const ILifeStore = lazy(() =>
  import("./screen/dashboard/businessTools/iLifeStore")
);
const Purchases = lazy(() =>
  import("./screen/dashboard/businessTools/purchases")
);
const StoreTransaction = lazy(() =>
  import("./screen/dashboard/businessTools/storeTransaction")
);

const MyWebsite = lazy(() => import("./screen/dashboard/myWebsite/myWebsite"));

const ClientPolicyDetail = lazy(() =>
  import("./components/applicationDetails/clientPolicyDetail")
);
const EmailEditTemplate = lazy(() =>
  import("./screen/emailCampaign/EditTemplate/emailEditTemplate")
);

const fakeClientDetailPage = lazy(() =>
  import("./components/fakeClient/fakeDetailpage")
);

const AppRouting = () => {
  const requireAuth = () => {
    return getJwt() ? true : false;
  };

  const routingClickEvent = () => {
    if (requireAuth() && process.env.REACT_APP_NODE_ENV == "production") {
      window.Alvis("page");
    }
  };
  return (
    <React.Fragment>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
        history={history}
      >
        <div className="AppRouting h-100" onClick={() => routingClickEvent()}>
          <Suspense
            fallback={
              <div>
                <LoadingBar
                  color="#2ba84a"
                  shadow={true}
                  height={5}
                  progress={50}
                />
                <LeafLoader />
              </div>
            }
          >
            <Switch>
              <Route exact path={BasicsPagesRoutes.HOME} component={HomePage} />
              <Route exact path={BasicsPagesRoutes.LOGIN} component={Login} />
              <Route
                exact
                path={BasicsPagesRoutes.LOGIN_EMAIL}
                component={LoginWithEmail}
              />
              {/* <Route
                  exact
                  path={RegistrationPagesRoutes.GETSTART}
                  component={GetStart}
                /> */}
              <Route
                exact
                path={RegistrationPagesRoutes.LEADQUESTIONS}
                component={LeadQuestions}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.LEADPACKAGE}
                component={LeadPackage}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.PAYMENT}
                component={Payment}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.PAYMENT + "/:status"}
                component={Payment}
              />
              <ProtectedRoute
                exact
                path={RegistrationPagesRoutes.LEADPACKAGEOFFER + "/:code"}
                component={LeadPackageOffer}
              />
              {/* <UnProtectedRoute
                  exact
                  path={RegistrationPagesRoutes.GETSTART_1}
                  component={GetStart1}
                />
                <UnProtectedRoute
                  exact
                  path={RegistrationPagesRoutes.GETSTART_2}
                  component={GetStart2}
                /> */}
              <Route
                exact
                path={RegistrationPagesRoutes.GETSTART_3}
                component={LiscensedProfessionalQuestion}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.GETSTART_4}
                component={IndividualOrOrganizationQuestion}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.GETSTART_5}
                component={HaveInsuranceLicenseQuestion}
              />
              <UnProtectedRoute
                exact
                path={RegistrationPagesRoutes.BECOMEMEMBER}
                component={BecomeMember}
              />
              <UnProtectedRoute
                exact
                path={RegistrationPagesRoutes.SCANDETAILS}
                component={ScanDetails}
              />
              <UnProtectedRoute
                exact
                path={RegistrationPagesRoutes.SCANRESULT}
                component={ScanResults}
              />
              <Route
                exact
                path={RegistrationPagesRoutes.CARRIERAPPOINMENTS}
                component={CarrierAppointment}
              />
              <UnProtectedRoute
                exact
                path={BasicsPagesRoutes.REGISTER}
                component={Register}
              />
              {/* <UnProtectedRoute
                  exact
                  path={RegistrationPagesRoutes.REGISTERSTEPSASPROFESSIONALS}
                  component={RegisterAsProf}
                /> */}
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.DASHBOARD}
                component={Dashboard}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.CLIENTPIPELINE}
                component={Dashboard}
              />
              {/* <ProtectedRoute
                exact
                path={DashboardPagesRoutes.MYPROFILE}
                component={Myprofile}
              /> */}
              {/* <ProtectedRoute
                exact
                path={DashboardPagesRoutes.NOTIFICATION_SETTINGS}
                component={NotificationSettings}
              /> */}
              {/* <ProtectedRoute
                exact
                path={DashboardPagesRoutes.LIVELT_SETTINGS}
                component={LiveLTSettings}
              /> */}
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.ACCOUNTSETTINGS}
                component={AccountSettings}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.MYSUBSCRIPTIONS}
                component={MySubcriptions}
              />
              {/* <ProtectedRoute
                  exact
                  path={DashboardPagesRoutes.MYPROFILE_PROFESSIONAL}
                  component={MyprofileProf}
                /> */}
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.WELCOMEPAGE + "/:plan"}
                component={WelcomePage}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.PRODUCT_SELECTION}
                component={ProductSelectionPage}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.CARRIERS_SELECTION}
                component={CarriersSelectionPage}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.STATE_SELECTION}
                component={StateSelectionPage}
              />
              <ProtectedRoute
                exact
                path={"/demo-email-editor"}
                component={DemoEmailEditor}
              />
              <ProtectedRoute
                exact
                path={"/demo-email-template"}
                component={DemoEmailTemplate}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.TIME_ZONE_SELECTION}
                component={TimeZoneSelectionPage}
              />
              <ProtectedRoute
                exact
                path={AmbassadorPagesRoutes.MYREFERRALS}
                component={Myreferralas}
              />
              <ProtectedRoute
                exact
                path={AmbassadorPagesRoutes.MYREFERRALS + "/:status"}
                component={Myreferralas}
              />
              <ProtectedRoute
                exact
                path={AmbassadorPagesRoutes.MYREFERRALSDETAILS}
                component={MyreferralasDetails}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.LEADCONVERSATIONS}
                component={LeadConversation}
              />
              {/* <ProtectedRoute
                exact
                path={DashboardPagesRoutes.CONTACTS}
                component={Contacts}
              /> */}
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.TEXT}
                component={Text}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.PAYOUTS}
                component={Payouts}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.AGENTREQUESTS}
                component={AgentRequests}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.INVITEAGENT}
                component={InviteAgent}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.MANAGERIALVIEW}
                component={ManagerialView}
              />
               <ProtectedRoute
                exact
                path={DashboardPagesRoutes.AGENT_CHAT}
                component={AgentChat}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.MYAGENTS}
                component={myAgents}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.PROSPECTSVIAAFFILIATE}
                component={ProspectsViaAffiliate}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.LEADASSIGNMENT}
                component={AssignLeads}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.UNASSIGNLEADS}
                component={UnAssignLeads}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.INVITEDAGENTS}
                component={InvitedAgents}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.AGENTDETAILS}
                component={AgentDetails}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.AVBL_LEADPACKAGES}
                component={AvblLeadPackages}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONS + "/:status"}
                component={Applications}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONS + "/:status/:carrierid"}
                component={Applications}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONSBASICDETAILS}
                component={ApplicationsDetails}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONPOLICYINFORMATION}
                component={ApplicationsPolicyInfo}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONSEDITABLEPDF}
                component={ApplicationsEditPdf}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONSCONVERSATIONS}
                component={ApplicationsConversation}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONSDOCUMENTS}
                component={ApplicationDocuments}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONSDOCUMENTS}
                component={ApplicationDocuments}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPLICATIONUNDERWRITINGSTEPS}
                component={ApplicationUnderWrightStep}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.NOTIFICATIONS}
                component={Notifications}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.THEMESETTINGS}
                component={ThemeComp}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.UNDERWRITE_QUESTION}
                component={UnderQuestion}
              />
              <ProtectedRoute
                exact
                path={SettingsRoutes.SUBUSERS}
                component={SubUsers}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.SELECT_APPOINTMENTS}
                component={SelectAppointmentCarriers}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.APPOINTMENT_FORM}
                component={AppointmentForm}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.SBLI_APPOINTMENT_FORM}
                component={SbliAppoinmentForm}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.ASSURITY_APPOINTMENT_FORM}
                component={AssurityAppointmentForm}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.STORETRANSACTION}
                component={StoreTransaction}
              />{" "}
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.ILIFESTORE}
                component={ILifeStore}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.PURCHASES}
                component={Purchases}
              />
              <Route exact path={CmsPagesRoutes.ABOUTUS} component={AboutUs} />
              <Route
                exact
                path={CmsPagesRoutes.SERVICE_REPRESENTATIVE}
                component={ServiceRepresent}
              />
              <Route
                exact
                path={CmsPagesRoutes.CONTACTUS}
                component={ContactUs}
              />
              <ProtectedRoute
                exact
                path={MyWebsiteRoutes.HOMEPAGE}
                component={MyHomePage}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.PRODUCT_BROWSING}
                component={ProductBrowsing}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.EMAIL}
                component={Emailmarketing}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.REMINDERS}
                component={Reminders}
              />
              <ProtectedRoute
                exact
                path={
                  DashboardPagesRoutes.CREATE_BLANK_EMAIL_CAMPAIGN + "/:blank"
                }
                component={EmailCampaign}
              />
              <ProtectedRoute
                exact
                path={
                  DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN + "/:campaignId"
                }
                component={EmailCampaign}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.EDIT_EMAIL_TEMPLATE + "/:templateId"}
                component={EmailCampaign}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.CLIENT_DETAIL_PAGE}
                component={ClientPolicyDetail}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.EDIT_TEMPLATE}
                component={EmailEditTemplate}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.FAKE_CLIENT_PAGE}
                component={fakeClientDetailPage}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.SENT_EAMIL_DETAILS}
                component={SentEmailDetails}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS}
                component={sentEmailwithoutmetrics}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.CLIENT_EMAIL_DETAIL}
                component={ClientDetailMail}
              />
              <ProtectedRoute
                exact
                path={DashboardPagesRoutes.SOCIALMEDIA}
                component={SocialMedia}
              />
              <ProtectedRoute
                exact
                path={MyWebsiteRoutes.ABOUTPAGE}
                component={MyAboutPage}
              />
              <ProtectedRoute
                exact
                path={MyWebsiteRoutes.WEBSETTING}
                component={WebsiteSettings}
              />
              <ProtectedRoute
                exact
                path={MyWebsiteRoutes.MYWEBSITE}
                component={MyWebsite}
              />
              {/* <Route
                  exact
                  path={SettingsRoutes.PAYMENTINFORMATION}
                  component={UpdatePaymentInformation}
                /> */}
              <Route path={CmsPagesRoutes.NOTFOUND} component={NotFound} />
              <Redirect to={CmsPagesRoutes.NOTFOUND} />
            </Switch>
          </Suspense>
        </div>
        <Scrolltotop />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default AppRouting;
