import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import Switch from "react-switch";
import { commonApiCall, showSuccessToast, showErrorToast } from "../../utlis";
import { API_POINT } from "../../config/config";
import { OverlayTrigger } from "react-bootstrap";
import LiveLTSettingsLoader from "../../components/skeletonLoaders/liveLTSettingsLoader";
import Select from "react-select";
import errorMessages from "../../redux/actions/errorMessages";
import moment from "moment";
import pauseLeads from "../../assets/images/icon/notify-off.svg";
import pauseLeadsOff from "../../assets/images/icon/notify-off-red.svg";


const LiveLTSettings = ({ commonApiCall }) => {
  const [retreaverData, setRetreaverData] = useState([]);
  const [timeRetreaverData, setTimeRetreaverData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [checkRetreaver, setCheckRetreaver] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [checkSelectALL, setCheckSelectAll] = useState(false);
  const [timeSlotChecked, setTimeSlotChecked] = useState({});
  const [startTime, setStartTime] = useState(moment("2023-08-28 09:00:00"));
  const [endTime, setEndTime] = useState(moment("2023-08-28 17:00:00"))
  // console.log(timeSlotChecked, checkRetreaver)


  const getRetreaverData = async () => {
    setLoader(true);
    const isAuthorized = true;
    let response = await commonApiCall(
      API_POINT + "/retreaverData",
      "",
      "get",
      null,
      isAuthorized
    );
    if (response?.data?.status?.code === 200) {
      setRetreaverData(response?.data?.data?.target?.business_hours);
      let timeZoneData =
        response?.data?.data?.mstTimeZone &&
        response?.data?.data?.mstTimeZone?.map((item) => {
          return {
            value: item,
            label: item?.timeZoneName,
          };
        });
      setTimeRetreaverData(timeZoneData);
      setSelectedTime({
        value: response?.data?.data?.target?.time_zone,
        label: response?.data?.data?.target?.time_zone,
      });
      setCheckRetreaver(response?.data?.data?.target?.paused);
      setTargetId(response?.data?.data?.target?.id);
      setLoader(false);
    } else {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
      setLoader(false);
    }
  };

  const getTimeValue = (timeSlotArray, close, open) => {
    const intervalDuration = 60; // 60 minutes

    // Calculate the number of intervals
    const totalIntervals = endTime.diff(startTime, 'minutes') / intervalDuration;
    const timeSlot = { ...timeSlotArray }
    // console.log(timeSlot);
    // Loop through the intervals and create time interval
    Array.from(Array(totalIntervals), (interval, index) => {
      const intervalStart = startTime.clone().add(index * intervalDuration, 'minutes');
      const intervalEnd = intervalStart.clone().add(intervalDuration, 'minutes');
      timeSlot[index] = {
        time_close: intervalEnd.format("h"),
        time_open: intervalStart.format("h"),
        time_close_24hr: intervalEnd.format("H"),
        time_open_24hr: intervalStart.format("H"),
        time: intervalEnd.format("a"),
        checked: (intervalEnd.format("Hmm") <= close && intervalStart.format("Hmm") >= open) || timeSlot[index]?.checked
      };
    });
    return timeSlot;
  };

  const handleChangeRetreaverId = async () => {
    let businessHours = retreaverData.filter((item) => item.updated);
  
    businessHours = [];
    Object.keys(timeSlotChecked).map((key) => {
      Object.values(timeSlotChecked[key]).map((item) => {
        if (item.checked) {
          businessHours.push({
            time_open: parseInt(`${item?.time_open_24hr}00`),
            time_close: parseInt(`${item?.time_close_24hr}00`),
            work_day: true,
            day_of_week: parseInt(key),
            target_id: targetId,
          });
        }
      });
    });
    let body = "";
    if (businessHours.length > 0) {
      body = {
        target: {
          paused: checkRetreaver,
          business_hours_attributes: businessHours,
          time_zone: selectedTime?.value?.timeZoneName,
        },
      };
    } else {
      body = {
        target: {
          paused: checkRetreaver,
          time_zone: selectedTime?.value?.timeZoneName,
        },
      };
    }
    var response = await commonApiCall(
      API_POINT + "/retreaverTarget",
      body,
      "post",
      null,
      true
    );
    if (response) {
      showSuccessToast(response?.data?.status.message);
      getRetreaverData();
    }
  };

/*   const handleChangeWorkDay = (index) => {
    let data = retreaverData;
    data[index].work_day = !data[index].work_day;
    data[index].updated = true;
    setRetreaverData([...data]);
  }; */

  useEffect(() => {
    getRetreaverData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* const renderTooltipOfPauseLead = (props) => (
    <div id="button-tooltip" {...props}>
      <div className="tooltip-outer">
        <p>
          Leads will not be assigned to you
          <br /> for as long as Pause leads is
          <br />
          selected.
        </p>
      </div>
    </div>
  ); */

  useEffect(() => {
    const timeSlot = { ...timeSlotChecked };
    const result = retreaverData && retreaverData?.map((item, index) => {
      if (
        item?.day_of_week !== 0 &&
        item?.day_of_week !== 6
      ) {
        timeSlot[item?.day_of_week] = getTimeValue(timeSlot[item?.day_of_week], item?.time_close, item?.time_open);
      }
    });
    setTimeSlotChecked({ ...timeSlot });
  }, [retreaverData]);

  const setAllTimeSlots = (value) => {
    const timeSlot = { ...timeSlotChecked };
    Object.keys(timeSlot).map((key) => {
      Object.keys(timeSlot[key]).map((innerkey) => {
        timeSlot[key][innerkey] = {
          ...timeSlot[key][innerkey],
          checked: value
        }
      })
    });
    setCheckSelectAll(value);
    setTimeSlotChecked({ ...timeSlot });
  };


  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <Animated
            animationIn="fadeIn"
            animationOut="faderOutLeft"
            animationInDuration={1200}
            animationOutDuration={800}
            isVisible={true}
          >
            <div>

              {loader ? (
                <LiveLTSettingsLoader />
              ) : (
                <>
                  <div>
                    <div className="live-lt-table">
                      <div className="d-flex justify-content-between flex-column flex-md-row w-100 text-align-center mb-5">
                        <div className="d-flex flex-column">
                          <h3
                            className="text-left font-24"
                            style={{ fontSize: "18px" }}
                          >
                            Call hours
                          </h3>
                          <h5 className="title-text">
                            Select the days and times when you would like to receive
                            lead calls.
                          </h5>
                        </div>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-gradient"
                            onClick={() => handleChangeRetreaverId()}
                          >
                            <span className="align-middle d-inline-block">Save</span>
                          </button>
                        </div>

                      </div>
                      <div className="d-flex justify-content-between flex-column flex-md-row w-100 text-align-center">
                        <div className="d-flex mb-5">
                          <div className="custom-checkbox mr-4">
                            <label className="mr-4 mb-0 txt-black tooltip-btn">
                              <input
                                type="checkbox"
                                name="selectAll"
                                checked={checkSelectALL}
                                disabled={checkRetreaver}
                                onChange={(e) => setAllTimeSlots(e.target.checked)}
                              />
                              <span></span>
                              Select all

                            </label>
                          </div>
                          <div >
                            <img src={checkRetreaver ? pauseLeadsOff : pauseLeads} className="mr-1 cursor-pointer" onClick={() => setCheckRetreaver(!checkRetreaver)} />
                            {checkRetreaver ? "Calls Paused" : "Pause Calls"}
                            {/* <OverlayTrigger
                    placement="bottom-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipOfPauseLead}
                    className=""
                  >
                    <span className="material-icons">help_outline</span>
                  </OverlayTrigger> */}

                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="mr-2 title-text">Timezone:</span>
                          <div className="default-select with-border sourcefilter col-md-6 pl-0">
                            <Select
                              options={timeRetreaverData}
                              isSearchable={false}
                              className={"custom-select-menu"}
                              classNamePrefix={"custom-select"}
                              value={selectedTime}
                              onChange={(value) => setSelectedTime(value)}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="row row-minus">
                        <table className="">
                          {timeSlotChecked && Object.keys(timeSlotChecked)?.map((key, index) => {

                            return (
                              <tr key={index}>
                                <th className="weekdays-title">
                                  <span>
                                    {key == 1
                                      ? "Monday"
                                      : key == 2
                                        ? "Tuesday"
                                        : key == 3
                                          ? "Wednesday"
                                          : key == 4
                                            ? "Thursday"
                                            : key == 5
                                              ? "Friday"
                                              : null}
                                  </span>
                                </th>
                                {/*  <td className="switch-block">

                                    {" "}
                                    <div className="d-flex flex-wrap align-items-center ">
                                      <Switch
                                        checked={item?.work_day}
                                        onChange={() =>
                                          handleChangeWorkDay(index)
                                        }
                                        height={24}
                                        width={48}
                                        handleDiameter={18}
                                        uncheckedIcon={
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "100%",
                                              fontSize: 16,
                                              color: "#fff",
                                              paddingRight: 2,
                                              fontWeight: "500",
                                            }}
                                          />
                                        }
                                        checkedIcon={
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "100%",
                                              fontSize: 16,
                                              color: "#fff",
                                              paddingRight: 2,
                                              fontWeight: "500",
                                            }}
                                          />
                                        }
                                      />
                                      <label className="font-20 text-black-color font-weight-bold mb-0 ml-3">
                                        {item.work_day
                                          ? "Available"
                                          : "Unavailable"}
                                      </label>
                                    </div>
                                /td> */}
                                <td>
                                  {" "}
                                  <div className="form-group custom-datepicker date-pickar-range justify-content-start mb-0">
                                    {timeSlotChecked[key] ? (
                                      <div className=" d-flex w-100 align-items-center justify-content-between">
                                        {timeSlotChecked[key] && Object.keys(timeSlotChecked[key])?.map((innerkey) => (
                                          <span className=" mr-2 time-interval-box d-flex w-100 justify-content-start aligh-items-center">
                                            <div className="ml-3 custom-checkbox mt-3 ">
                                              <label className="mr-4 mb-0 txt-black tooltip-btn ">
                                                <input
                                                  type="checkbox"
                                                  disabled={checkRetreaver}
                                                  checked={timeSlotChecked?.[key]?.[innerkey]?.checked}
                                                  onChange={(e) => setTimeSlotChecked({ ...timeSlotChecked, [key]: { ...timeSlotChecked[key], [innerkey]: { ...timeSlotChecked[key][innerkey], checked: e.target.checked } } })}
                                                />
                                                <span></span>

                                              </label>
                                            </div>
                                            <span >{`${timeSlotChecked[key][innerkey]?.time_open} - ${timeSlotChecked[key][innerkey]?.time_close} ${timeSlotChecked[key][innerkey]?.time}`}</span>
                                          </span>
                                        ))}

                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            );

                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Animated>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  commonApiCall,
})(withRouter(LiveLTSettings));
