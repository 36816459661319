import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { isEmpty, find } from 'lodash'
import Select from 'react-select'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import errorMessages from '../../redux/actions/errorMessages'
import {
  customFilter,
  commonApiCall,
  encryptData,
  showErrorToast,
  DashboardPagesRoutes,
  updateAgent,
  AgentPlan,
  NumberFormatter,
  DiscountAmount,
  showSuccessToast,
  setPayment,
  getItem,
  RegistrationPagesRoutes,
  validatecardName,
  validateCardHolder,
} from '../../utlis'
import { API_POINT } from '../../config/config'
import Skeleton from 'react-loading-skeleton'
import { Modal } from 'react-bootstrap'
import TermsAndConditionModel from '../../components/modelComponents/termsAndConditionModel'
import security from '../../assets/images/lock.png'

const AuthorizePayment = ({
  isCardDetails,
  isUpdatePaymentInformation = false,
  commonApiCall,
  cardDetails = false,
  billingInfo = false,
  selectedItem,
  cardData,
  loader,
  onPayNow,
  sign_in,
  updateAgent,
  onCancel,
  isRegister = false,
  planName,
  isStore = false,
  packageName,
  packagePrice,
  packageFrequency,
  fromUpgrade = false,
  isUpdateCard,
  upgradePlanPrice,
  upgradePlanName,
  isLeadPackageOffer = false,
  selectedPackage,
  AgentPlan,
  DiscountAmount,
  trialPeriod,
  ...props
}) => {
  const [formData, setFormData] = useState({
    cardNumber: '',
    cardExpireDate: '',
    cardHolderName: '',
    cardCVCNumber: '',
    billingAddress: billingInfo ? billingInfo?.address : '',
    billingState: billingInfo ? billingInfo?.state : '',
    billingCity: billingInfo ? billingInfo?.city : '',
    billingZipCode: billingInfo ? billingInfo?.zip : '',
    promoCode: '',
    agreementModal: false,
    errors: {},
  })
  const [isofferValid, setIsofferValid] = useState({
    valid: false,
    message: '',
    discountPrice: '',
    priceAfterDiscount: '',
    isDisable: false,
  })
  const [showForm, setShowForm] = useState(billingInfo ? true : false)
  const [cardListLoading, setCardLoading] = useState(true)
  const [savedCardList, setSavedCardList] = useState([])
  const [loading, setLoading] = useState(false)
  const [stateOptions, setStateOptions] = useState([])
  const [selectedProfileId, setProfileId] = useState(null)
  const [activePaymentGate, setActivePayment] = useState(null)
  const [showFreeModal, setShowFreeModal] = useState(false)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      errors: Object.assign(formData.errors, { [e.target.name]: '' }),
    })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleBlur = (e) => {
    let data = validateCardHolder(e.target.value)
    setFormData({
      ...formData,
      errors: Object.assign(formData.errors, { [e.target.name]: '' }),
    })
    !data &&
      setFormData({
        ...formData,
        errors: Object.assign(formData.errors, {
          [e.target.name]: 'Please enter valid card holder name',
        }),
      })
  }

  const fetchStates = async () => {
    const response = await commonApiCall(
      API_POINT + '/getState?countryId=' + 1,
      '',
      'get'
    )
    let stateList = []
    response.data.data &&
      response.data.data.map((element) => {
        stateList.push({
          value: element.id,
          label: element.name,
        })
        return true
      })
    setStateOptions(stateList)
    if (billingState) {
      const billState = find(stateList, { label: billingState })
      setFormData({ ...formData, billingState: billState })
    }
  }

  const fetchSavedCards = async () => {
    setCardLoading(true)
    const response = await commonApiCall(
      '/paymentService/pay-profiles',
      '',
      'get',
      '',
      true
    )
    if (response.data.data) {
      setActivePayment(response.data.data.activePaymentGateway)
      let cardList = response.data.data.paymentProfileResponse
      setSavedCardList(cardList && cardList.length > 0 ? cardList : [])
      setProfileId(
        cardList && cardList.length > 0
          ? cardList[cardList.length - 1].paymentProfileId
          : null
      )
    }
    setCardLoading(false)
  }

  // const doAuthorizePayment = async () => {
  //   if (validation()) {
  //     const {
  //       cardNumber,
  //       cardExpireDate,
  //       cardHolderName,
  //       cardCVCNumber,
  //       billingAddress,
  //       billingState,
  //       billingCity,
  //       billingZipCode,
  //     } = formData;
  //     getpaymentSuccess(
  //       "",
  //       cardNumber,
  //       cardExpireDate,
  //       cardHolderName,
  //       cardCVCNumber,
  //       billingAddress,
  //       billingState,
  //       billingCity,
  //       billingZipCode
  //     );
  //   }
  // };

  const onTryIlifeFree = async () => {
    try {
      const response = await commonApiCall(
        API_POINT + '/continueFree',
        '',
        'get',
        '',
        true
      )
      if (response?.data?.status?.code == 200) {
        await setPayment('')
        let agent = sign_in
        agent.data.currentPlanSlug = response?.data?.data?.currentPlanSlug
        agent.data.role = response?.data?.data?.role
        await updateAgent(agent)
        await AgentPlan('FREE ACCOUNT')
        showSuccessToast(response?.data?.status?.message)
        if (getItem('fromRegistration')) {
          props.history.push(DashboardPagesRoutes?.WELCOMEPAGE + `/free`)
        } else {
          props.history.push(response?.data?.data?.defaultPageUrl)
        }
      }
    } catch (err) {
      showErrorToast(err)
    }
  }

  const validation = () => {
    const { cardNumber, cardExpireDate, cardHolderName, cardCVCNumber } =
      formData
    let errors = {},
      isFormValid
    const expireDateRegEx = new RegExp(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)
    let data = validateCardHolder(cardHolderName)
    if (formData.hasOwnProperty('cardNumber')) {
      if (isEmpty(cardNumber))
        errors.cardNumber = errorMessages.PROVIDE_CARD_NUMBER
      else if (cardNumber.length < 14)
        errors.cardNumber = errorMessages.CARD_NUMBER_TOO_SHORT
      else delete errors.cardNumber
    }

    if (formData.hasOwnProperty('cardExpireDate')) {
      if (isEmpty(cardExpireDate))
        errors.cardExpireDate = errorMessages.PROVIDE_CARD_EXPIRE_DATE
      else if (!expireDateRegEx.test(cardExpireDate))
        errors.cardExpireDate = errorMessages.INVALID_EXPIRE_DATE
      else delete errors.cardExpireDate
    }

    if (formData.hasOwnProperty('cardHolderName')) {
      if (isEmpty(cardHolderName) || !data)
        errors.cardHolderName = data
          ? errorMessages.PROVIDE_CARD_HOLDER_NAME
          : errorMessages.PROVIDE_VALID_CARD_NAME
      else delete errors.cardHolderName
    }

    if (formData.hasOwnProperty('cardCVCNumber')) {
      if (isEmpty(cardCVCNumber))
        errors.cardCVCNumber = errorMessages.PROVIDE_CVC_NUMBER
      else if (cardCVCNumber.length < 3)
        errors.cardCVCNumber = errorMessages.CVC_NUMBER_TOO_SHORT
      else delete errors.cardCVCNumber
    }

    if (formData.hasOwnProperty('billingAddress')) {
      if (isEmpty(billingAddress))
        errors.billingAddress = errorMessages.PROVIDE_BILLING_ADDRESS
      else if (billingAddress.length < 5)
        errors.billingAddress = errorMessages.BILLING_ADDRESS_TOO_SHORT
      else delete errors.billingAddress
    }

    if (formData.hasOwnProperty('billingState')) {
      if (isEmpty(billingState))
        errors.billingState = errorMessages.PROVIDE_BILLING_STATE
      else delete errors.billingState
    }

    if (formData.hasOwnProperty('billingCity')) {
      if (isEmpty(billingCity))
        errors.billingCity = errorMessages.PROVIDE_BILLING_CITY
      else delete errors.billingCity
    }

    if (formData.hasOwnProperty('billingZipCode')) {
      if (isEmpty(billingZipCode))
        errors.billingZipCode = errorMessages.PROVIDE_BILLING_ZIPCODE
      else delete errors.billingZipCode
    }

    isFormValid = Object.keys(errors).length !== 0 ? false : true
    setFormData({ ...formData, errors: errors })
    return isFormValid
  }

  useEffect(() => {
    fetchStates()
    fetchSavedCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleCardForm = () => {
    if (isRegister) {
      setShowFreeModal(true)
    } else {
      onCancel()
      setShowForm(!showForm)
      setFormData({ ...formData, errors: {} })
    }
  }
  const showTryFreePoup = async () => {
    sign_in.data.showBecomeMemberPage = true
    await updateAgent(sign_in)
    props.history.push(RegistrationPagesRoutes.PAYMENT)
  }

  const addNewCardForm = () => {
    setShowForm(!showForm)
    setFormData({ ...formData, errors: {} })
  }

  const {
    cardNumber,
    cardExpireDate,
    cardHolderName,
    cardCVCNumber,
    billingAddress,
    billingState,
    billingCity,
    billingZipCode,
    errors,
    promoCode,
    agreementModal,
  } = formData

  const saveNewCard = async () => {
    try {
      if (validation()) {
        const cardDetails = {
          billingAddress: await encryptData(formData.billingAddress),
          billingCity: await encryptData(formData.billingCity),
          billingState: await encryptData(formData.billingState.label),
          billingZipCode: await encryptData(formData.billingZipCode),
          cardHolderName: await encryptData(formData.cardHolderName),
          cardNumber: await encryptData(formData.cardNumber),
          cvcNumber: await encryptData(formData.cardCVCNumber),
          expiration: await encryptData(formData.cardExpireDate),
        }
        setLoading(true)
        if (!isUpdatePaymentInformation) {
          const response = await commonApiCall(
            '/paymentService/pay-profiles ',
            cardDetails,
            'post',
            '',
            true
          )
          let _apiResponse = response.data

          if (_apiResponse.status.code !== 200) {
            setLoading(false)
            return showErrorToast(_apiResponse.status.message)
          } else {
            setLoading(false)
            onPayNow(activePaymentGate, response.data.data, promoCode, valid)
          }
        } else {
          setLoading(false)

          onPayNow(cardDetails)
        }
      }
    } catch (err) {
      showErrorToast(err)
    }
  }
  const checkDiscount = async () => {
    const { promoCode } = formData
    setIsofferValid({ ...isofferValid, isDisable: true })
    let body = {
      price: selectedPackage?.price,
      promocode: promoCode,
    }
    const response = await commonApiCall(
      '/paymentService/getDiscountedPriceDetails',
      body,
      'post',
      '',
      true
    )
    if (response.data.status.code === 200) {
      setIsofferValid({
        valid: true,
        discountPrice: response.data.data.discountPrice,
        priceAfterDiscount: response.data.data.priceAfterDiscount,
        isDisable: false,
        message: '',
      })
      DiscountAmount(response.data.data.priceAfterDiscount)
    } else {
      setIsofferValid({
        valid: false,
        isDisable: false,
        message: response.data.status.message,
        discountPrice: '',
        priceAfterDiscount: '',
      })
    }
  }

  const handleModal = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide })
  }

  const { valid, message, discountPrice, priceAfterDiscount, isDisable } =
    isofferValid

  const leadPackageOfferBlock = () => {
    return (
      <div className="col-md-4 mt-5">
        <div className="form-group mb-1">
          <div className="input-effect with-icon">
            <input
              className="form-control"
              placeholder="Promo Code"
              name="promoCode"
              id="promoCode"
              value={promoCode}
              type="text"
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="cardHolderName">Promo Code</label>
            <em className="input-icon"></em>
          </div>
        </div>
        {!valid && message !== '' && <p className="error-block">{message} </p>}
        {valid && (
          <div>
            <p className="status-success mt-2">
              Discount Amount : {NumberFormatter(discountPrice, '$', '')}{' '}
            </p>
            <p className="status-success mt-2">
              Price after discount:{' '}
              {NumberFormatter(priceAfterDiscount, '$', '')}{' '}
            </p>
          </div>
        )}
        <button
          className="btn btn-primary mt-md-4 mt-2 mb-2"
          type="button"
          disabled={promoCode == '' || isDisable || loader}
          onClick={checkDiscount}
        >
          Apply Now
        </button>
      </div>
    )
  }
  return (
    <div className="payment-form position-relative ">
      {!showForm && savedCardList.length > 0 && !cardData && (
        <div className="checkoutbox ml-md-5">
          <div className="row">
            <div className="col-12 text-center mb-md-4 mb-3">
              <h2 className="font-24 font-weight-bold txt-light-black">
                Complete payment
              </h2>
              <p className="pb-0 text-capitalize txt-light-black  font-weight-bold font-18">
                {/* You can now be more efficient with shorter sales cycles! */}
                {isRegister && planName && planName}
                {isStore && packageName && packageName}
                {isStore &&
                  packagePrice &&
                  NumberFormatter(packagePrice, '$', '')}
                {isStore && packageFrequency && (
                  <span className="text-lowercase">{packageFrequency}</span>
                )}
                {fromUpgrade &&
                  !isUpdateCard &&
                  upgradePlanName &&
                  upgradePlanName}
                {fromUpgrade && !isUpdateCard && `${upgradePlanPrice} /month`}
              </p>
            </div>
            <div className="col-12">
              <div className="checkout-outer mx-md-3">
                <div className="checkout-box">
                  <div className="mastercard-row">
                    <h3 className="carttitle">Your saved cards</h3>
                  </div>
                  {cardListLoading && (
                    <div className="cardList">
                      <Skeleton count={1} width="100%" />
                      <Skeleton count={1} width="100%" />
                    </div>
                  )}
                  {savedCardList.length > 0 &&
                    savedCardList.map((card, index) => {
                      return (
                        <div
                          className="mastercard-detail"
                          key={card.paymentProfileId}
                        >
                          <div className="cartname">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id={`customRadio1${index}`}
                                name="customRadio"
                                defaultChecked={
                                  index === savedCardList.length - 1
                                }
                                onClick={() =>
                                  setProfileId(card.paymentProfileId)
                                }
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label cardrow"
                                htmlFor={`customRadio1${index}`}
                              >
                                <div
                                  className={`card-type ${
                                    card.cardDetail
                                      ? card.cardDetail
                                      : card.cardDetailStripe.cardType
                                  }`}
                                ></div>
                                <strong className="cardname mr-1">Card</strong>
                                <span>
                                  Ending in{' '}
                                  {card.cardDetail
                                    ? card.cardDetail.cardNumber
                                    : card.cardDetailStripe.cardNumber}
                                </span>
                              </label>
                            </div>
                          </div>
                          {/* <div className="expiredate">
                          {card.cardDetail.expirationDate}
                        </div> */}
                        </div>
                      )
                    })}

                  <div className="mastercard-detail">
                    <div className="cartname">
                      <div className="custom-control p-0">
                        <button
                          className="btn btn-link p-0"
                          onClick={addNewCardForm}
                        >
                          <label className=" cardrow cursor-pointer">
                            <svg
                              className="add"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#afaeae"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                            </svg>
                            <p className="cardname mr-1 ml-2 txt-light-black">
                              Add New Card
                            </p>
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <p className="pb-0 mt-3">
                    <span className="custom-checkbox ">
                      <label className="m-0 p-0">
                        <span> All sales are final, Please see our</span>
                      </label>
                    </span>
                    <Link
                      to="#"
                      className="text-link font-weight-normal"
                      title="Terms of Service"
                      onClick={() => handleModal('agreementModal', true)}
                    >
                      iLife’s Terms of Conditions
                    </Link>
                    <span className="custom-checkbox">
                      <span> for more information</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {isLeadPackageOffer && leadPackageOfferBlock()}

            <div className="col-12 text-center">
              <button
                className="btn btn-primary d-block mx-auto mt-2"
                type="button"
                disabled={loader || cardListLoading || showForm}
                onClick={() =>
                  onPayNow(
                    activePaymentGate,
                    selectedProfileId,
                    promoCode,
                    valid
                  )
                }
              >
                PAY NOW
              </button>
              <button
                className="btn btn-link  d-block mx-auto mt-2 mt-md-4"
                type="button"
                disabled={loading || loader}
                onClick={() =>
                  trialPeriod && trialPeriod === true
                    ? showTryFreePoup()
                    : toggleCardForm()
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {cardListLoading ? (
        <h3 className="text-center">Please wait....</h3>
      ) : (
        (showForm || savedCardList.length < 1) && (
          <>
            <img
              className="img-fluid bgback"
              src="/assets/images/Gradient_Transparent_v1.0.png"
              alt="Desktop"
            />
            <form
              className={isCardDetails ? 'card-details active' : 'card-details'}
            >
              <div className="row">
                <div className="title-header">
                  {isUpdatePaymentInformation ? (
                    <>
                      <h2>Update your payment information</h2>
                    </>
                  ) : (
                    <>
                      <h2 className="font-24 font-weight-bold txt-light-black">
                        Complete payment
                      </h2>
                      <p className="pb-0 text-capitalize txt-light-black  font-weight-bold font-16">
                        {/* You can now be more efficient with shorter sales cycles! */}
                        {isRegister && planName && planName}
                        {isStore && packageName && packageName}
                        {isStore &&
                          packagePrice &&
                          NumberFormatter(packagePrice, '$', '')}
                        {isStore && packageFrequency && (
                          <span className="text-lowercase">
                            {packageFrequency}
                          </span>
                        )}
                        {fromUpgrade &&
                          !isUpdateCard &&
                          upgradePlanName &&
                          upgradePlanName}
                        {fromUpgrade &&
                          !isUpdateCard &&
                          `${upgradePlanPrice} /month`}
                      </p>
                    </>
                  )}
                </div>
                {/* <h4 style={{ color: "#091f1f" }}>Credit Card Information</h4> */}

                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <div className="input-effect with-icon">
                      <input
                        className="form-control"
                        placeholder="Card Holdername"
                        name="cardHolderName"
                        id="cardHolderName"
                        value={cardHolderName}
                        type="text"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                      />
                      <label htmlFor="cardHolderName">Cardholder name</label>
                      <em className="input-icon"></em>
                    </div>
                    {errors.cardHolderName && (
                      <small className="text-danger err-msg">
                        {errors.cardHolderName}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <div className="input-effect with-icon">
                      <NumberFormat
                        name="cardNumber"
                        id="cardNumber"
                        className="form-control"
                        style={{
                          base: {
                            lineHeight: '28px',
                            height: '28px !important',
                            fontFamily:
                              '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '16px',
                            '::placeholder': {
                              color: 'rgba(0, 0, 0, 0.5)',
                            },
                          },
                        }}
                        placeholder="Card Number"
                        format="#### #### #### ####"
                        value={cardNumber}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: 'cardNumber',
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label htmlFor="cardHolderName">Card number</label>
                      <em className="input-icon"></em>
                    </div>
                    {errors.cardNumber && (
                      <small className="text-danger err-msg">
                        {errors.cardNumber}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-md-7 pr-md-0">
                  <div className="form-group">
                    <div className="input-effect with-icon">
                      <NumberFormat
                        name="cardExpireDate"
                        id="cardExpireDate"
                        className="form-control"
                        style={{
                          base: {
                            lineHeight: '28px',
                            height: '28px !important',
                            fontFamily:
                              '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '16px',

                            '::placeholder': {
                              color: 'rgba(0, 0, 0, 0.5)',
                            },
                          },
                        }}
                        placeholder="Card Expiration Date"
                        format="##/##"
                        value={cardExpireDate}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: 'cardExpireDate',
                              value: e.formattedValue,
                            },
                          })
                        }
                      />
                      <label htmlFor="cardHolderName">Expiration date</label>
                      <em className="input-icon"></em>
                    </div>
                    {errors.cardExpireDate && (
                      <small className="text-danger err-msg">
                        {errors.cardExpireDate}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-group">
                    <div className="input-effect with-icon">
                      <NumberFormat
                        name="cardCVCNumber"
                        id="cardCVCNumber"
                        className="form-control"
                        style={{
                          base: {
                            lineHeight: '28px',
                            height: '28px !important',
                            fontFamily:
                              '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '16px',

                            '::placeholder': {
                              color: 'rgba(0, 0, 0, 0.5)',
                            },
                          },
                        }}
                        placeholder="CVC Number"
                        format="####"
                        value={cardCVCNumber}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: 'cardCVCNumber',
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label htmlFor="cardHolderName">CVC Number</label>
                      <em className="input-icon"></em>
                    </div>
                    {errors.cardCVCNumber && (
                      <small className="text-danger err-msg">
                        {errors.cardCVCNumber}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <hr className="thinborder" />
                </div>
                {/*
            <h4 style={{ color: "#091f1f" }}>Billing Address</h4> */}

                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="input-effect with-icon">
                          <input
                            className="form-control"
                            placeholder="Billing Address"
                            name="billingAddress"
                            id="billingAddress"
                            value={billingAddress}
                            type="text"
                            maxLength="60"
                            onChange={(e) => handleChange(e)}
                          />
                          <label htmlFor="cardHolderName">
                            Billing Address
                          </label>
                          <em className="input-icon"></em>
                        </div>

                        <div className="d-flex justify-content-between">
                          <small className="text-danger err-msg">
                            {errors.billingAddress}
                          </small>
                          {/* <p style={{ fontSize: 12, textAlign: "right" }}>
                          Character Limit: {60 - billingAddress.length}{" "}
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <div className="input-effect with-icon">
                      <input
                        className="form-control"
                        placeholder="Card Holdername"
                        name="billingCity"
                        id="billingCity"
                        value={billingCity}
                        maxLength="40"
                        type="text"
                        onChange={(e) => handleChange(e)}
                      />
                      <label htmlFor="cardHolderName">City</label>
                      <em className="input-icon"></em>
                    </div>

                    <div className="d-flex justify-content-between">
                      <small className="text-danger err-msg">
                        {errors.billingCity}
                      </small>
                      {/* <p style={{ fontSize: 12, textAlign: "right" }}>
                          Character Limit: {40 - billingCity.length}{" "}
                        </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-7 pr-md-0">
                  <div className="form-group fixed-floating-label mb-3">
                    <div className="default-select with-border">
                      <Select
                        options={stateOptions}
                        className={'custom-select-menu'}
                        isSearchable={true}
                        filterOption={(e, searchWith) =>
                          customFilter(e, searchWith)
                        }
                        menuPlacement="top"
                        onChange={(value) =>
                          setFormData({
                            ...formData,
                            billingState: value,
                            errors: Object.assign(formData.errors, {
                              billingState: '',
                            }),
                          })
                        }
                        classNamePrefix={'custom-select'}
                        value={billingState}
                      />
                      <span className="fixed-label">State</span>
                      <span className="text-danger ">
                        {errors.billingState}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group mb-3">
                    <div className="input-effect with-icon">
                      <NumberFormat
                        name="billingZipCode"
                        id="billingZipCode"
                        className="form-control"
                        style={{
                          base: {
                            lineHeight: '28px',
                            height: '28px !important',
                            fontFamily:
                              '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '16px',

                            '::placeholder': {
                              color: 'rgba(0, 0, 0, 0.5)',
                            },
                          },
                        }}
                        placeholder="Zip Code"
                        format="######"
                        value={billingZipCode}
                        onValueChange={(e) =>
                          handleChange({
                            target: {
                              name: 'billingZipCode',
                              value: e.value,
                            },
                          })
                        }
                      />
                      <label htmlFor="cardHolderName">Zip Code</label>
                      <em className="input-icon"></em>
                    </div>
                    {errors.billingZipCode && (
                      <small className="text-danger err-msg">
                        {errors.billingZipCode}
                      </small>
                    )}
                  </div>
                </div>

                {isLeadPackageOffer && leadPackageOfferBlock()}
                {/* <div className="col-12">
              <div className="notes">
                <p>*Transaction fee may apply</p>
              </div>
            </div> */}
                <div className="col-12">
                  <div className="pay-fee flex-column pt-0 border-0">
                    <div className="pay-fee-text mb-3">
                      <img width={20} height={20} src={security} alt="" />
                      <h6 className="font-14 font-weight-bold ml-2 txt-light-black">
                        All payments secured with SSL encryption
                      </h6>
                      {/* <span className="material-icons">security</span> */}
                    </div>
                    <div className="pay-fee-btn d-flex flex-column mt-3 mx-auto">
                      <button
                        className="btn btn-primary btn-lg mb-3"
                        type="button"
                        onClick={saveNewCard}
                        disabled={loading || loader}
                      >
                        {loader && !isUpdatePaymentInformation
                          ? 'loading...'
                          : !isUpdatePaymentInformation
                          ? loading || loader
                            ? 'Processing... '
                            : 'PAY NOW'
                          : 'Update'}
                      </button>
                      {!isUpdatePaymentInformation && (
                        <button
                          className="btn btn-link btn-lg "
                          type="button"
                          disabled={loading || loader}
                          onClick={() =>
                            trialPeriod && trialPeriod === true
                              ? showTryFreePoup()
                              : toggleCardForm()
                          }
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )
      )}
      <Modal
        className="ilife-free-modal"
        size="sm"
        centered
        show={showFreeModal}
        onHide={() => setShowFreeModal(false)}
      >
        <Modal.Header
          closeButton
          onClick={() => setShowFreeModal(false)}
          className="position-absolute w-100 d-block"
          style={{ zIndex: 999 }}
        ></Modal.Header>
        <Modal.Body>
          <h2 className="text-center">Try iLife Free!</h2>
          <ul className="plan-info mt-md-4 mt-2 mb-5">
            <li className="info-list mb-2">
              Generate up to 10 organic sales prospects
            </li>
            <li className="info-list mb-2">Create your own iLife website</li>
            <li className="info-list mb-2">
              View and send products illustrations instantly
            </li>
            <li className="info-list mb-2">
              View pre-app forms as they are completed
            </li>
            <li className="info-list mb-2">Purchase iLife exclusive leads</li>
          </ul>
          <div className="text-center">
            <button
              className="btn btn-gradient btn mx-3"
              type="submit"
              onClick={() => onTryIlifeFree()}
            >
              <strong>Try iLife Free</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {agreementModal && (
        <TermsAndConditionModel
          agreementModal={agreementModal}
          handleModal={handleModal}
        ></TermsAndConditionModel>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
  }
}

export default connect(mapStateToProps, {
  commonApiCall,
  updateAgent,
  AgentPlan,
  DiscountAmount,
})(withRouter(AuthorizePayment))
