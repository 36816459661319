import React from "react";
import { Modal } from "react-bootstrap";

const TermsAndConditionModel = ({ agreementModal, handleModal }) => {
  return (
    <Modal
      className="pdf-modal"
      show={agreementModal}
      onHide={() => handleModal("agreementModal", false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>iLife - Service Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title="PDF Viewer"
          src="/assets/agreement/iLife - Terms And Conditions Agreement.pdf"
          width="100%"
          height="600px"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndConditionModel;
