import React from "react";

import loadingGif from "../../assets/images/iLifeLoader.gif";

export const LeafLoader = ({className}) => {
  return (
    <div className={`ilife-loading-gif ${className}`}>
      <img src={loadingGif} alt="a GIF of iLife logo" />
    </div>
  );
};
