import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LiveLTSettingsLoader = () => {
  return (
    <div className="w-100">
      <SkeletonTheme>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={500} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
        <div className="mb-2">
          <Skeleton count={1} height={40} width={900} />
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default LiveLTSettingsLoader;
