import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const TryFreeLoader = () => {
  return (
    <SkeletonTheme>
      <div className="row">
        <div className="col-md-4">
          <div className="free-tiral-card text-center mb-3 mb-md-0">
            <div className="icon-block">
              <Skeleton count={1} height={50} width={50} />
            </div>
            <h4 className="font-22 lh-1 text-black-color font-weight-semibold mt-md-4 mt-3 mb-3">
              <Skeleton count={1} height={25} width={180} />
            </h4>
            <p className="text-black-color font-16 mb-3">
              <Skeleton count={3} height={10} width={250} />
            </p>
            <Skeleton count={1} height={25} width={180} />
            <p className="amount-text cursor-icon">
              <Skeleton count={1} height={10} width={100} />
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="free-tiral-card text-center mb-3 mb-md-0">
            <div className="icon-block">
              <Skeleton count={1} height={50} width={50} />
            </div>
            <h4 className="font-22 lh-1 text-black-color font-weight-semibold mt-md-4 mt-3 mb-3">
              <Skeleton count={1} height={25} width={180} />
            </h4>
            <p className="text-black-color font-16 mb-3">
              <Skeleton count={3} height={10} width={250} />
            </p>
            <Skeleton count={1} height={25} width={180} />
            <p className="amount-text cursor-icon">
              <Skeleton count={1} height={10} width={100} />
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="free-tiral-card text-center mb-3 mb-md-0">
            <div className="icon-block">
              <Skeleton count={1} height={50} width={50} />
            </div>
            <h4 className="font-22 lh-1 text-black-color font-weight-semibold mt-md-4 mt-3 mb-3">
              <Skeleton count={1} height={25} width={180} />
            </h4>
            <p className="text-black-color font-16 mb-3">
              <Skeleton count={3} height={10} width={250} />
            </p>
            <Skeleton count={1} height={25} width={180} />
            <p className="amount-text cursor-icon">
              <Skeleton count={1} height={10} width={100} />
            </p>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
