import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getJwt,
  DashboardPagesRoutes,
  callStarted,
  clientPolicyDetailOnCall,
  clientPolicyDetail,
  removeItem,
  progressBarBoolean,
  menuOpenClick,
} from "../../utlis";

import { sidebarRoutes } from "../../utlis";

import Header from "./header";
import TryFreePopup from "../../screen/tryFreePopup";
import ProgressLayout from "./progressLayout";
import Sidebar from "./sidebar";

class Layout extends Component {
  constructor(props) {
    super();
    this.state = {
      status: false,
      isMenuOpen: false,
      onLoadClass : ""
    };
  }

  checkEvent = () => {
    this.setState({
      status: !this.state.status,
    });
  };

  getmenuOpen = (value) => {
    this.setState({ ...this.state, isMenuOpen: !this.state.isMenuOpen }, () => {
      this.props.menuOpenClick(value ? value : this.state.isMenuOpen);
    });
  };

  hangUpCall = () => {
    this.props.twillioDevice.disconnectAll();
    callStarted("no");
    clientPolicyDetailOnCall(null);
    removeItem("latestState");
  };

  onNameCick = async (data) => {
    await this.props.clientPolicyDetail(data);
    if (this.props.currentClientDetails) {
      this.props.history.push(DashboardPagesRoutes.CLIENT_DETAIL_PAGE);
    }
  };

  checksidebar = () => {
    const path = window.location.pathname;
    if (!getJwt() || (getJwt() && !sidebarRoutes(path))) {
      document.body.classList.add("nosidebar");
      // document.body.classList.remove("navbar-is-open");
    } else {
      document.body.classList.remove("nosidebar");
      // document.body.classList.add("navbar-is-open");
    }
  };
  navbarIsOpen = () => {
    const path = window.location.pathname;
    if (!getJwt() || (getJwt() && !sidebarRoutes(path))) {
      document.body.classList.remove("navbar-is-open");
    }
  };
  render() {
    const { data } = this.props.sign_in;
    const path = window.location.pathname;
    getJwt() &&
      data &&
      data?.showBecomeMemberPage === true &&
      document.body.classList.add("active-modal");

    return (
      <React.Fragment>
        {this.checksidebar()}
        {this.navbarIsOpen()}
        <Header
          checkEvent={this.checkEvent}
          props={this.props}
          getmenuOpen={this.getmenuOpen}
        ></Header>
        {getJwt() && data && data?.showBecomeMemberPage === true ? (
          <TryFreePopup />
        ) : null}

        <div className={`page-wrapper`}>
          <div
            className={`container ${this.props.CustomClass} ${
              progressBarBoolean(path) && "content-container pt-5"
            }`}
          >
            {getJwt() && sidebarRoutes(path) && (
              <div className='sidebar-container'>
                <Sidebar
                  state={this.state.isMenuOpen}
                  getmenuOpen={this.getmenuOpen}
                />
              </div>
            )}
            {this.props.children}
            {this.props.isCallStarted === "yes" && (
              <div className='hangCallButton font-14'>
                <div className='mb-4'>
                  <img
                    src='/assets/images/mic.svg'
                    alt=''
                    width={12}
                    height={18}
                  />
                  <span
                    className='font-16 text-green cursor-icon ml-2 text-decoration-underline font-weight-bold'
                    onClick={() =>
                      this.onNameCick(this.props.currentClientDetailsOnCall)
                    }
                  >
                    {this.props.currentClientDetailsOnCall?.Name}
                  </span>
                </div>
                <button
                  className='btn btn-danger'
                  onClick={() => this.hangUpCall()}
                >
                  <span className='material-icons mr-2'>call_end</span>End call
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    twillioDevice: state.agentReducer.activeConnection,
    isCallStarted: state.agentReducer.callStarted,
    currentClientDetailsOnCall: state.agentReducer.getClientPolicyDetailOnCall,
    currentClientDetails: state.agentReducer.getClientPolicyDetail,
  };
};

export default connect(mapStateToProps, {
  callStarted,
  clientPolicyDetailOnCall,
  clientPolicyDetail,
  menuOpenClick,
})(withRouter(Layout));
