import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {
  validateName,
  validateEmail,
  validateMobileNumber,
} from "../../../utlis/common";
import { isEmpty } from "lodash";

const _agencyOwners15 = "https://meetings.hubspot.com/nakoi/agency-owner-life";
const _agencyOwners30 = "https://meetings.hubspot.com/nakoi/agency-owner-30";
const _independentAgentt15 =
  "https://meetings.hubspot.com/nakoi/independent-agent-life";
const _independentAgentt30 =
  "https://meetings.hubspot.com/nakoi/independent-agent-30";
const _partnership30 = "https://meetings.hubspot.com/jeffk8/partner-30";
const _partnership15 = "https://meetings.hubspot.com/jeffk8/partner-15";
const _carrier15 = "https://meetings.hubspot.com/jeffk8/carrier-15";
const _carrier30 = "https://meetings.hubspot.com/jeffk8/carrier-30";

export const ScheduleDetailsModel = ({
  scheduleDetailModal,
  handleDemoModel,
  onSubmitHubspotDetails,
  meetingType,
  agentType = "",
}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    your_company_name: "",
    email: "",
    phone_number: "",
    agent_type: agentType,
    errors: {},
  });

  const [agentTypeOptions] = useState([
    {
      label: "Agency Owner (Life)",
      value: "agency_owner_life",
    },
    {
      label: "Agency Owner (P&C)",
      value: "agency_owner_p_n_c",
    },
    {
      label: "Independent Agent (Life)",
      value: "independent_agent_life",
    },
    {
      label: "Independent Agent (P&C)",
      value: "independent_agent_p_n_c",
    },
    {
      label: "Interested in Partnership",
      value: "interested_in_partnership",
    },
    {
      label: "Carrier",
      value: "carrier",
    },
  ]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      agent_type: e,
    });
  };

  const validateForm = () => {
    const {
      first_name,
      last_name,
      your_company_name,
      email,
      phone_number,
      agent_type,
    } = formData;

    let errors = {};

    if (formData.hasOwnProperty("first_name")) {
      if (isEmpty(first_name)) errors.first_name = "Please enter first name";
      else if (!validateName(first_name))
        errors.first_name = "Please enter valid first name";
      else delete errors.first_name;
    }
    if (formData.hasOwnProperty("last_name")) {
      if (isEmpty(last_name)) errors.last_name = "Please enter last name";
      else if (!validateName(last_name))
        errors.last_name = "Please enter valid last name";
      else delete errors.last_name;
    }
    if (formData.hasOwnProperty("your_company_name")) {
      if (isEmpty(your_company_name))
        errors.your_company_name = "Please enter company name";
      else delete errors.your_company_name;
    }
    // for email
    if (formData.hasOwnProperty("email")) {
      if (isEmpty(email)) errors.email = "Please enter email";
      else if (!validateEmail(email))
        errors.email = "Please enter valid email format";
      else delete errors.email;
    }

    if (formData.hasOwnProperty("phone_number")) {
      if (isEmpty(phone_number))
        errors.phone_number = "Please enter phone number";
      else if (
        !validateMobileNumber(phone_number) &&
        phone_number.toString().length !== 10
      )
        errors.phone_number = "Please enter valid phone number";
      else delete errors.phone_number;
    }

    if (formData.hasOwnProperty("agent_type")) {
      if (isEmpty(agent_type)) errors.agent_type = "Please select agent type";
      else delete errors.agent_type;
    }
    const isFormValid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    if (isFormValid) {
      if (
        agent_type.value === "agency_owner_life" ||
        agent_type.value === "agency_owner_p_n_c"
      ) {
        if (meetingType === 15) {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _agencyOwners15,
          });
        } else {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _agencyOwners30,
          });
        }
      } else if (
        agent_type.value === "independent_agent_life" ||
        agent_type.value === "independent_agent_p_n_c"
      ) {
        if (meetingType === 15) {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _independentAgentt15,
          });
        } else {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _independentAgentt30,
          });
        }
      } else if (agent_type.value === "interested_in_partnership") {
        if (meetingType === 15) {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _partnership15,
          });
        } else {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _partnership30,
          });
        }
      } else {
        if (meetingType === 15) {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _carrier15,
          });
        } else {
          onSubmitHubspotDetails({
            first_name,
            last_name,
            your_company_name,
            email,
            phone_number,
            agent_type,
            meetLink: _carrier30,
          });
        }
      }
    }
    return isFormValid;
  };

  const {
    first_name,
    last_name,
    your_company_name,
    email,
    phone_number,
    errors,
    agent_type,
  } = formData;
  return (
    <Modal
      className="schedule-model rounded-modal"
      show={scheduleDetailModal}
      onHide={() => handleDemoModel("scheduleDetailModal", false)}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <span className="schedule-details-title">
            {meetingType === 15
              ? "Schedule your 15 minute solutions call"
              : "Schedule your 30 minute demo video call"}
          </span>
          <br />
          <br />
          <div className="contact-form-group mb-3">
            <div className="input-effect">
              <input
                className="form-control"
                placeholder="First Name"
                name="first_name"
                id="first_name"
                value={first_name}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="first_name">First Name</label>
              <span className="text-danger">{errors.first_name}</span>
            </div>
          </div>
          <div className="contact-form-group mb-3">
            <div className="input-effect">
              <input
                className="form-control"
                placeholder="Last Name"
                name="last_name"
                id="last_name"
                value={last_name}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="last_name">Last Name</label>
              <span className="text-danger">{errors.last_name}</span>
            </div>
          </div>
          <div className="contact-form-group mb-3">
            <div className="input-effect">
              <input
                className="form-control"
                placeholder="Your Company Name"
                name="your_company_name"
                id="your_company_name"
                value={your_company_name}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="your_company_name">Your Company Name</label>
              <span className="text-danger">{errors.your_company_name}</span>
            </div>
          </div>
          <div className="contact-form-group mb-3">
            <div className="input-effect">
              <input
                className="form-control"
                placeholder="Email Address"
                name="email"
                id="email"
                type="mail"
                value={email}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="email">Email Address</label>
              <span className="text-danger">{errors.email}</span>
            </div>
          </div>
          <div className="contact-form-group mb-3">
            <div className="input-effect">
              <input
                className="form-control"
                placeholder="Phone Number"
                name="phone_number"
                id="phone_number"
                type="phone"
                maxLength={14}
                value={phone_number}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="phone_number">Phone Number</label>
              <span className="text-danger">{errors.phone_number}</span>
            </div>
          </div>
          <div className="contact-form-group fixed-floating-label mb-4">
            <div className="default-select with-border">
              <Select
                options={agentTypeOptions}
                className={"custom-select-menu"}
                classNamePrefix={"custom-select"}
                isSearchable={false}
                value={agent_type}
                onChange={(e) => handleSelectChange(e)}
                menuPlacement="top"
                placeholder="Agent Type"
              />
              <span className="text-danger">{errors.agent_type}</span>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={() => handleDemoModel("scheduleDetailModal", false)}
              type="submit"
              className=" btn btn-primary ml-3"
            >
              <span>CANCEL</span>
            </button>
            <button
              onClick={validateForm}
              type="submit"
              className=" btn btn-primary  ml-3"
            >
              <span>GO TO CALENDAR</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
