import React, { useRef } from "react";
import Layout from "../components/layoutComponents/layout";

const DemoEmailEditor = (props) => {
  return (
    <Layout>
      <div className="select-template content-container">
        <h2>Email Template Builder</h2>
        <div className="d-flex">
          <p className="font-18 text-muted mb-2">
            Select a advance email template to get started.
          </p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="template-main bg-blue"
              onClick={() => props.history.push("/demo-email-template")}
            >
              <div className="temp-content ">
                <div className="mail-content"></div>
              </div>
              <div className="temp-footer">
                <div className="d-flex justify-content-between flex-sm-row flex-column">
                  <h5 className="font-16 font-weight-bold txt-light-black mb-1">
                    Blank
                  </h5>
                </div>
                <p className="font-14 pb-0 lh-1">
                  Create an email to send to a group of contacts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DemoEmailEditor;
