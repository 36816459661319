import React, { useState, useCallback, useRef } from "react";
import ReactCrop from "react-image-crop";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  commonApiCall,
  profilePicture,
  encryptData,
  showSuccessToast,
} from "../../utlis";
import { API_POINT } from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";

const ImageCrop = ({ commonApiCall, profilePicture, ...props }) => {
  const { email, firstName, setImage, isCompanyImage } = props;
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });

  const [previewUrl, setPreviewUrl] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [isModelOpen, setisModelOpen] = useState(false);

  const onSelectFile = (e) => {
    setisModelOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const makeClientCrop = async (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, "newFile.png");
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          let fileUrl = "";
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(previewUrl);
          setPreviewUrl(window.URL.createObjectURL(blob));
          fileUrl = canvas.toDataURL("image/png");
          setImageUrl(fileUrl);
          resolve(fileUrl);
        },
        "image/png,image/jpeg",
        1
      );
    });
  };

  const fileUpload = async () => {
    let formData1 = new FormData();
    formData1.append("imageString", imageUrl);
    const response = await commonApiCall(
      API_POINT + "/uploadImageFile?uploadedImageType=PROFILE",
      formData1,
      "post",
      null,
      true,
      true
    );

    if (response.status === 200 && response.data.status.code === 200) {
      if (!isCompanyImage) {
        setImage("profileImage", response.data.data.fileDownloadUri);
        setisModelOpen(false);
        let reduxStore = {
          profilePic: response.data.data.fileDownloadUri,
          email: await encryptData(email),
          firstName: await encryptData(firstName),
        };
        await profilePicture(reduxStore);
        showSuccessToast(errorMessages.PROFILE_IMAGE_SUCCESS);
      } else {
        setImage("companyImage", response.data.data.fileDownloadUri);
        showSuccessToast(errorMessages.COMPANY_IMAGE_SUCCESS);
      }
      setisModelOpen(false);
    } else {
      showSuccessToast(response.data.status.message);
    }
  };

  return (
    <React.Fragment>
      {/* <img src="/assets/images/susie.jpeg" alt="" title="user profile" /> */}
      <span className="material-icons profile-cam">photo_camera</span>
      <input
        className="profile-input"
        type="file"
        style={{ display: "block" }}
        name="image"
        id="file"
        value={""}
        accept="image/*,image/png, image/jpeg"
        onChange={(e) => onSelectFile(e)}
      />
      {/* Model Popup */}
      <Modal
        className="dragdrop-modal"
        centered
        show={isModelOpen}
        onHide={() => setisModelOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isCompanyImage ? "Set Company Image" : "Set Profile Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="crop-image">
          <div className="row">
            <div className="col-sm-6">
              <label className="crop-lable">Crop</label>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                // minHeight={100}
                // maxHeight={100}
                // minWidth={100}
                // maxWidth={100}
                // locked={true}
                onChange={(c) => setCrop(c)}
                onComplete={makeClientCrop}
              />
            </div>
            <div className="col-sm-6">
              <label className="crop-lable">Preview</label>
              {previewUrl && (
                <img
                  alt="Crop preview"
                  className={!isCompanyImage ? "crp-img" : ""}
                  src={previewUrl}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="btn-lg" onClick={() => fileUpload()}>
              Set Image
            </Button>
            <Button
              onClick={() => setisModelOpen(false)}
              className="btn-lg ml-3 primary-outline"
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default connect(null, {
  commonApiCall,
  profilePicture,
})(withRouter(ImageCrop));
