import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import * as _ from "lodash";
import { Modal } from "react-bootstrap";
import {
  API_POINT_UNDERWRITE,
  API_POINT_MUNICH,
  API_URL_CHATSERVICE,
} from "../../config/config";
import errorMessages from "../../redux/actions/errorMessages";
import {
  conversationDetails,
  decryptData,
  commonApiCall,
  showErrorToast,
  NumberFormatter,
  getMissingInfo,
} from "../../utlis";
import ChatMessage from "./chatMessage";
import BasicDetails from "../applicationDetails/basicDetails";
import DownloadMedicalData from "../applicationDetails/downloadMedicalData";
import CreditRatingSvg from "../svg/creditRatingSvg";

let stompClient, SockJS;

const PrivateChat = ({
  commonApiCall,
  conversation_details,
  chatModel,
  handleModel,
}) => {
  const [applicationDetailsVisible, setApplicationDetailsVisible] =
    useState(false);
  const [chatStatus, setChatStatus] = useState("");
  const [applicationDetails, setApplicationDetails] = useState({});

  const [eAppInformation, setEAppInformation] = useState({});
  const [eAppFactFinders, seteAppFactFinders] = useState({});
  const [missingAppInfo, setMissingAppInfo] = useState([]);

  const setEAppInvite = async (appCarrierId, applicationId) => {
    let response = await commonApiCall(
      API_POINT_UNDERWRITE +
        "/applicationBasicDetails?anonymousApplicationCarrierId=" +
        appCarrierId,
      "",
      "get",
      null,
      true
    );
    if (response.status === 200 && response.data.status.code === 200) {
      // let body = {
      //   applicationId: response.data.data.applicationid,
      // };
      // let applicationQuestions = await commonApiCall(
      //   API_POINT_MUNICH + "/getApplicationQuestionnaire",
      //   body,
      //   "post",
      //   null,
      //   false
      // );
      // if (
      //   applicationQuestions.status === 200 &&
      //   applicationQuestions.data.status.code === 200
      // ) {
      //   seteAppFactFinders(applicationQuestions.data.data.data);
      //   let missingAppInfo = await getMissingInfo(
      //     response.data.data,
      //     applicationQuestions.data.data.data
      //   );
      //   setMissingAppInfo(missingAppInfo);
      // } else {
      //   let missingAppInfo = await getMissingInfo(
      //     response.data.data,
      //     eAppFactFinders
      //   );
      //   setMissingAppInfo(missingAppInfo);
      // }
      let missingAppInfo = await getMissingInfo(
        response.data.data,
        eAppFactFinders
      );
      setMissingAppInfo(missingAppInfo);
      setEAppInformation(response.data.data);
    } else showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
  };
  const CreditRate = (
    <Popover id="popover-basic">
      <Popover.Content>
        Credit ratings indicate financial strength and stability of insurance
        carrier
      </Popover.Content>
    </Popover>
  );
  const ValueDividend = (
    <Popover id="popover-basic">
      <Popover.Content>
        Cash value dividends are the portion of the insurance company's profits
        that are paid to policyholders
      </Popover.Content>
    </Popover>
  );
  const InterestRate = (
    <Popover id="popover-basic">
      <Popover.Content>
        The minimum interest rate insurance companies are legally guaranteed to
        apply to your cash value each year
      </Popover.Content>
    </Popover>
  );

  const removeModelClass = () => {
    document.body.classList.remove("modal-open");
  };
  const connect = async () => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "/ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          agentUserId: conversation_details && conversation_details.agentUserId,
          type: "CHATSCREEN",
        },
        function () {
          onConnected();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };
  const onConnected = () => {
    try {
      if (stompClient.connected) {
        stompClient.subscribe(
          "/user/agent-" + conversation_details.agentUserId + "/chatStatus",
          function (responsePayload) {
            onStatusReceived(responsePayload);
          }
        );
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onStatusReceived = (payload) => {
    try {
      if (payload) {
        const statusPayload = JSON.parse(payload.body);
        if (
          conversation_details.clientDetails.clientId == statusPayload.selfId
        ) {
          setChatStatus(statusPayload.status);
        }
      }
    } catch (err) {}
  };

  const onError = () => {
   // connect();
  };

  const [conversationDetails, setConversationDetails] = useState({});
  const [canFormClose, setCanFormClose] = useState(false);
  useEffect(() => {
    if (conversation_details && !_.isEmpty(conversation_details)) {
      setConversationDetails(conversation_details);
      setChatStatus(conversation_details.status);
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      conversationDetails &&
      !_.isEmpty(conversationDetails) &&
      conversationDetails?.applicationCarrierId &&
      conversationDetails.applicationId
    ) {
      setEAppInvite(
        conversationDetails.applicationCarrierId,
        conversationDetails.applicationId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationDetails]);

  useEffect(() => {
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(
          {
            agentUserId: conversation_details.agentUserId,
          },
          function () {
            // console.log("Disconnected. See you next time!");
          }
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminMissingInfo =
    eAppInformation && eAppInformation?.missingInfo
      ? eAppInformation.missingInfo
      : "";
  const dynamicMissingInfo = missingAppInfo ? missingAppInfo.join(", ") : "";
  return (
    <React.Fragment>
      <div
        className={
          chatModel === false
            ? "conversation-popup"
            : "conversation-popup active"
        }
      >
        <div className="conversation-inner">
          {canFormClose && (
            <span
              onClick={() => {
                handleModel("chatModel", !chatModel);
                setCanFormClose(false);
              }}
              className="close-btn"
              title="Close"
            >
              <span className="material-icons" style={{ cursor: "pointer" }}>
                close
              </span>
            </span>
          )}

          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="sidebar-profile text-center white-box2">
                  <div className="sidebar-profile-img">
                    <img
                      src={
                        conversationDetails &&
                        conversationDetails.clientDetails &&
                        conversationDetails.clientDetails.profilePic
                          ? conversationDetails.clientDetails.profilePic
                          : "/assets/images/no-image.jpg"
                      }
                      alt=""
                      title="profile"
                    />{" "}
                    {chatStatus === "ON" ? (
                      <span className="status-online"></span>
                    ) : (
                      <span className="status-offline"></span>
                    )}
                  </div>

                  <h3>
                    {conversationDetails &&
                      conversationDetails.clientDetails &&
                      decryptData(
                        conversationDetails.clientDetails.firstName
                      )}{" "}
                    {conversationDetails &&
                      conversationDetails.clientDetails &&
                      decryptData(conversationDetails.clientDetails.lastName)}
                  </h3>
                  <div className="col-12 px-0" style={{ marginTop: 15 }}>
                    <Link
                      className="btn btn-primary"
                      type="submit"
                      to="#"
                      onClick={() => {
                        removeModelClass();
                        setApplicationDetailsVisible(true);
                      }}
                    >
                      View Application
                    </Link>
                  </div>
                  {conversation_details.clientDetails && (
                    <DownloadMedicalData
                      applicationCarrierId={
                        conversation_details.applicationCarrierId
                      }
                      url={conversation_details.clientDetails.medicalReportUrl}
                    />
                  )}
                  {adminMissingInfo || dynamicMissingInfo ? (
                    <div className="chat-notification-text">
                      <h5>
                        Hey! Don't forget to validate your client's information
                        and send out the e app link! You will still need to ask
                        for {adminMissingInfo ? adminMissingInfo + ", " : ""}
                        {dynamicMissingInfo ? dynamicMissingInfo : ""} to set up
                        the case!
                      </h5>
                    </div>
                  ) : (
                    <div className="chat-notification-text">
                      <h5>
                        Hey! Don't forget to validate your client's information
                        and send out the e app link!
                      </h5>
                    </div>
                  )}
                </div>
                {conversation_details.applicationCarrierId &&
                  !eAppInformation?.hideDetail && (
                    <div className="sidebar-profile text-center white-box2">
                      <h3>
                        {conversation_details?.productName
                          ? conversation_details.productName.replace(
                              "15",
                              conversation_details.pay
                            )
                          : ""}
                      </h3>
                      <div className="chat-policy">
                        <div className="page-title-logo">
                          <img
                            src={conversation_details.carrierLogo}
                            alt="Carrier Logo"
                            title={conversation_details.carrierName}
                          />
                        </div>
                        <span>
                          {conversation_details &&
                            conversation_details.carrierName}
                        </span>
                      </div>
                      <ul className="card-value-list border-0">
                        <li className="value-list-item">
                          <span className="list-label">
                            Credit Rating
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="right"
                              overlay={CreditRate}
                            >
                              <span className="list-info">
                                <CreditRatingSvg />
                              </span>
                            </OverlayTrigger>
                          </span>
                          <h3>
                            {conversation_details &&
                              conversation_details.creditRating}
                          </h3>
                        </li>
                        {conversation_details.carrierName !== "Pacific Life" &&
                          conversation_details.carrierName !==
                            "John Hancock" && (
                            <li className="value-list-item">
                              {conversation_details.cashValueDividend !== 0 && (
                                <>
                                  <span className="list-label">
                                    Cash Value Dividend
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="right"
                                      overlay={ValueDividend}
                                    >
                                      <span className="list-info">
                                        <CreditRatingSvg />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                  <h3>
                                    {conversation_details
                                      ? NumberFormatter(
                                          conversation_details.cashValueDividend,
                                          "",
                                          "%"
                                        )
                                      : "N/A"}
                                  </h3>
                                </>
                              )}
                            </li>
                          )}
                        <li className="value-list-item">
                          {conversation_details.minInterestRate !== 0 && (
                            <>
                              <span className="list-label">
                                Minimum Interest Rate
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="right"
                                  overlay={InterestRate}
                                >
                                  <span className="list-info">
                                    <CreditRatingSvg />
                                  </span>
                                </OverlayTrigger>
                              </span>
                              <h3>
                                {conversation_details
                                  ? NumberFormatter(
                                      conversation_details.minInterestRate,
                                      "",
                                      "%"
                                    )
                                  : "N/A"}
                              </h3>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-lg-9">
              {chatModel && (
                <ChatMessage
                  chatModel={chatModel}
                  isSetFormCloseUpdate={true}
                  setCanFormClose={setCanFormClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={chatModel === false ? "conv-overlay" : "conv-overlay active"}
      ></div>
      <Modal
        className="dragdrop-modal"
        centered
        show={applicationDetailsVisible}
        onHide={() => setApplicationDetailsVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Application Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BasicDetails
            application_details={applicationDetails}
            applicationCarrierId={conversation_details.applicationCarrierId}
          ></BasicDetails>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    conversation_details: state.agentReducer.conversationdetails,
  };
};
export default connect(mapStateToProps, { conversationDetails, commonApiCall })(
  withRouter(PrivateChat)
);
