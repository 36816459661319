import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

const FactFinderSecond = ({ ...props }) => {
  useEffect(() => window.scrollTo(0, 0), []);

  const [factFinderQuestions] = useState(props.factFinderQuestions);

  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Do you have any or pending misdemeanor, felony,
                      probationary, parole program or criminal charges?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="yes">
                            <input
                              type="radio"
                              id="yes"
                              name="tobacco"
                              checked={data[4]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="no">
                            <input
                              type="radio"
                              id="no"
                              name="tobacco"
                              checked={data[4]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Have you had any driving/moving violations in the last 5
                      years?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="drugs">
                            <input
                              type="radio"
                              id="drugs"
                              name="drugs"
                              checked={data[5]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="nodrugs">
                            <input
                              type="radio"
                              id="nodrugs"
                              name="drugs"
                              checked={data[5]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Marital status
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label
                            className="m-0"
                            htmlFor="married"
                            // onClick={this.OpenOption2}
                          >
                            <input
                              type="radio"
                              id="married"
                              name="marital-status"
                              checked={
                                data[6]?.answer === "Married" ? true : false
                              }
                            />
                            <span></span> Married
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="single">
                            <input
                              type="radio"
                              id="single"
                              name="marital-status"
                              checked={
                                data[6]?.answer === "Single" ? true : false
                              }
                            />
                            <span></span> Single
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="separated">
                            <input
                              type="radio"
                              id="separated"
                              name="marital-status"
                              checked={
                                data[6]?.answer === "Separated" ? true : false
                              }
                            />
                            <span></span> Separated
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Do you have any existing life insurance policies?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="travelled">
                            <input
                              type="radio"
                              id="travelled"
                              name="travelled"
                              checked={data[7]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="notravelled">
                            <input
                              type="radio"
                              id="notravelled"
                              name="travelled"
                              checked={data[7]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                    {data[7]?.answer === "Yes"
                      ? data[7]?.options &&
                        data[7]?.options.map((list, index) => {
                          return (
                            <div
                              className="form-block-questions register-step3"
                              key={index}
                            >
                              <div className="questions-wrapper pr-4">
                                <div className="form-group">
                                  <div className="input-effect">
                                    <input
                                      className="form-control"
                                      placeholder="Carrier name"
                                      name={"carriername" + index}
                                      id={"carriername" + index}
                                      type="text"
                                      disabled
                                      value={list[0].answer}
                                    />
                                    <label htmlFor="carriername">
                                      Carrier Name
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="input-effect">
                                    <NumberFormat
                                      value={list[1].answer}
                                      thousandSeparator={true}
                                      // prefix={"$"}
                                      className="form-control"
                                      name="face-amount"
                                      inputmode="numeric"
                                      disabled
                                      placeholder="Face amount"
                                    />

                                    <label htmlFor="face-amount">
                                      Face Amount
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(FactFinderSecond));
