import React, { memo } from "react";
import { decryptData } from "../../utlis";
import NumberFormat from "react-number-format";

function userDetails({
  firstName,
  lastName,
  email,
  mobile,
  drivingLicense,
  dob,
  addressLine1,
  applicationid,
  policyState,
  healthRating,
}) {
  return (
    <div className="row">
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>First Name</label>
          <h4> {firstName ? decryptData(firstName) : "N/A"}</h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Last Name</label>
          <h4>{lastName ? decryptData(lastName) : "N/A"}</h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Email</label>
          <h4 style={{wordBreak:"break-all", textTransform:"inherit"}}>{email ? decryptData(email) : "N/A"}</h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Mobile Number</label>
          <h4>
            <NumberFormat
              displayType={"text"}
              format="###-###-####"
              value={mobile ? decryptData(mobile) : "N/A"}
            />
          </h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Driver's License Number</label>
          <h4>
            {drivingLicense && decryptData(drivingLicense) !== ""
              ? decryptData(drivingLicense)
              : "N/A"}
          </h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Date of Birth </label>
          <h4>{dob ? decryptData(dob) : "N/A"}</h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Address Line 1</label>
          <h4>{addressLine1 ? decryptData(addressLine1) : "N/A"}</h4>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Application Id</label>
          <h4>{applicationid ? applicationid : "N/A"}</h4>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="form-group filled-value">
          <label>Policy State</label>
          <h4>{policyState ? policyState : "N/A"}</h4>
        </div>
      </div>

      {healthRating !== "none" && (
        <div className="col-sm-4">
          <div className="form-group filled-value">
            <label>Health Rating</label>
            <h4>{healthRating ? healthRating : "N/A"}</h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(userDetails);
