import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonApiCall, showErrorToast } from "../../utlis";
import errorMessages from "../../redux/actions/errorMessages";

const DownloadMedicalData = ({ commonApiCall, url, ...props }) => {
  const [medicalDataHtml, setMedicalDataHtml] = useState(null);
  const [loading, setLoading] = useState(false);
  const downLoadMedicalData = async () => {
    setLoading(true);
    setMedicalDataHtml(null);
    try {
      const response = await commonApiCall(
        "/humanapiService/downloadMedicalReport?applicationCarrierId=" +
          props.applicationCarrierId,
        "",
        "get",
        null,
        true
      );
      if (response.status === 200) {
        let html = response.data;
        html = html.replace(
          new RegExp("</html>", "g"),
          "<script>window.print()</script></html>"
        );
        setMedicalDataHtml(html);
      }
      setLoading(false);
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  return (
    <div className="row">
      {medicalDataHtml && (
        <iframe
          width="0"
          height="0"
          style={{ visibility: "hidden" }}
          srcDoc={medicalDataHtml}
        ></iframe>
      )}
      <div className="extra-block" style={{ flex: 1 }}></div>
      {url !== null ? (
        <button
          className={"medical-data-btn btn btn-primary btn-lg"}
          title={""}
          disabled={loading}
          onClick={downLoadMedicalData}
        >
          Download Medical Data
        </button>
      ) : (
        <button
          className={"medical-data-btn btn btn-primary btn-lg disabled"}
          title={"Data not available"}
        >
          Download Medical Data
        </button>
      )}
    </div>
  );
};

export default connect(null, {
  commonApiCall,
})(withRouter(DownloadMedicalData));
