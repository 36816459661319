import React from "react";

export default function BottomToaster({ message, actionJSX, actionHandler }) {
  return (
    <div className="archive-box">
      <span className="archive-text">{message}</span>

      <span className="archive-button" onClick={actionHandler}>
        {actionJSX}
      </span>
    </div>
  );
}
