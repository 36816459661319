import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import Select from "react-select";

const FactFinder = ({ ...props }) => {
  const [factFinderQuestions] = useState(props.factFinderQuestions);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [error, setError] = useState({
  //     errorData: ["", "", "", "", "", "", "", "", "", ""],
  // });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const typeOfProduct = [
  //     { value: "Cigarettes", label: "Cigarettes" },
  //     { value: "E-cigarettes", label: "E-cigarettes" },
  //     { value: "Cigars", label: "Cigars" },
  //     { value: "Pipe", label: "Pipe" },
  //     { value: "Hookah", label: "Hookah" },
  //     { value: "Others", label: "Others" },
  // ];

  // const Frequency = [
  //     { value: "Day", label: "Day" },
  //     { value: "Month", label: "Month" },
  //     { value: "Year", label: "Year" },
  // ];
  // const Quantity = [
  //     { value: "0", label: "0" },
  //     { value: "1", label: "1" },
  //     { value: "2", label: "2" },
  //     { value: "3", label: "3" },
  //     { value: "4", label: "4" },
  //     { value: "5", label: "5" },
  //     { value: "6", label: "6" },
  //     { value: "7", label: "7" },
  //     { value: "8", label: "8" },
  //     { value: "9", label: "9" },
  //     { value: "10", label: "10" },
  //     { value: "10 +", label: "10 +" },
  // ];

  // const unitType = [
  //   { value: "Cigarettes", label: "Cigarettes" },
  //   { value: "Packs", label: "Packs" },
  //   { value: "Cigars", label: "Cigars" },
  //   { value: "Others", label: "Others" },
  // ];

  // const typeOfDrugs = [
  //     {
  //         value: "Opium based pain medication",
  //         label: "Opium based pain medication",
  //     },
  //     { value: "Barbiturates", label: "Barbiturates" },
  //     { value: "Sedatives/ tranquilizers", label: "Sedatives/ tranquilizers" },
  //     { value: "Amphetamines/ stimulants", label: "Amphetamines/ stimulants" },
  //     { value: "Cocaine/ crack", label: "Cocaine/ crack" },
  //     { value: "Others", label: "Others" },
  // ];

  // const handleChange = (value, index, valIndex) => {

  // };
  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="register-steps-wrapper">
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Have you been using any form of tobacco or nicotine in the
                      last 3 years?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="yes">
                            <input
                              type="radio"
                              id="yes"
                              name="tobacco"
                              checked={data[0]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="no">
                            <input
                              type="radio"
                              id="no"
                              name="tobacco"
                              checked={data[0]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                    {data[0]?.answer === "Yes" ? (
                      <div className="form-block-questions register-step3">
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[0]?.options[0].answer}
                                // options={typeOfProduct}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Type of Product"
                              />
                            </div>
                            <span className="fixed-label">Type of Product</span>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[0]?.options[1].answer}
                                // options={Frequency}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Frequency"
                              />
                            </div>
                            <span className="fixed-label">Frequency</span>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[0]?.options[2].answer}
                                // options={Quantity}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Quantity"
                              />
                            </div>
                            <span className="fixed-label">Quantity</span>
                          </div>

                          <div className="form-group">
                            <div className="input-effect with-icon">
                              <input
                                className="form-control"
                                placeholder="Time last used (Month/Year)"
                                name="time-last"
                                id="time-last"
                                type="text"
                                disabled
                                value={data[0]?.options[3].answer}
                              />
                              <label htmlFor="time-last">
                                Time Last Used (Month/Year)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Have you been using any prescription drugs/medication in
                      the last 10 years?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="drugs">
                            <input
                              type="radio"
                              id="drugs"
                              name="drugs"
                              checked={data[1]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="nodrugs">
                            <input
                              type="radio"
                              id="nodrugs"
                              name="drugs"
                              checked={data[1]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                    {data[1]?.answer === "Yes" ? (
                      <div className="form-block-questions register-step3">
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[1]?.options[0].answer}
                                // options={typeOfDrugs}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Type of Medication"
                              />
                            </div>
                            <span className="fixed-label">
                              Type of Medication
                            </span>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[1]?.options[1].answer}
                                // options={Frequency}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Frequency"
                              />
                            </div>
                            <span className="fixed-label">Frequency</span>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[1]?.options[2].answer}
                                // options={Quantity}
                                isDisabled
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Quantity"
                              />
                            </div>
                            <span className="fixed-label">Quantity</span>
                          </div>
                          <div className="form-group">
                            <div className="input-effect with-icon">
                              <NumberFormat
                                value={data[1]?.options[3].answer}
                                format="##/## - ##/##"
                                className="form-control"
                                name="face-amount"
                                inputmode="numeric"
                                disabled
                                placeholder="Face amount"
                              />
                              <label htmlFor="Time last used">
                                Time last used From - To ( Month/Year –
                                Month/Year )
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Do you participate in activities such as flying as a
                      pilot, parachute jumping, scuba diving,
                      auto/motorboat/motorcycle racing, hang gliding, or
                      mountain climbing?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="Avocation">
                            <input
                              type="radio"
                              id="Avocation"
                              name="Avocation"
                              checked={data[2]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="noAvocation">
                            <input
                              type="radio"
                              id="noAvocation"
                              name="Avocation"
                              checked={data[2]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Have you travelled frequently, lived or worked outside of
                      the U.S. in the past 2 years?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="travelled">
                            <input
                              type="radio"
                              id="travelled"
                              name="travelled"
                              checked={data[3]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="notravelled">
                            <input
                              type="radio"
                              id="notravelled"
                              name="travelled"
                              checked={data[3]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(FactFinder));
