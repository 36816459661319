import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";

const FactFinderThird = ({ ...props }) => {
  useEffect(() => window.scrollTo(0, 0), []);

  const [factFinderQuestions, setFactFinderQuestions] = useState(
    props.factFinderQuestions
  );

  // const Years = [
  //     { value: "0 Year", label: "0 Year" },
  //     { value: "1 Year", label: "1 Year" },
  //     { value: "2 Years", label: "2 Years" },
  //     { value: "3 Years", label: "3 Years" },
  //     { value: "4 Years", label: "4 Years" },
  //     { value: "5 Years", label: "5 Years" },
  //     { value: "6 Years", label: "6 Years" },
  //     { value: "7 Years", label: "7 Years" },
  //     { value: "8 Years", label: "8 Years" },
  //     { value: "9 Years", label: "9 Years" },
  //     { value: "10 Years", label: "10 Years" },
  //     { value: "11 Years", label: "11 Years" },
  //     { value: "12 Years", label: "12 Years" },
  //     { value: "13 Years", label: "13 Years" },
  //     { value: "14 Years", label: "14 Years" },
  //     { value: "15 Years", label: "15 Years" },
  //     { value: "16 Years", label: "16 Years" },
  //     { value: "17 Years", label: "17 Years" },
  //     { value: "18 Years", label: "18 Years" },
  //     { value: "19 Years", label: "19 Years" },
  //     { value: "20 Years", label: "20 Years" },
  //     { value: "21 Years", label: "21 Years" },
  //     { value: "22 Years", label: "22 Years" },
  //     { value: "23 Years", label: "23 Years" },
  //     { value: "24 Years", label: "24 Years" },
  //     { value: "25 Years", label: "25 Years" },
  //     { value: "26 Years", label: "26 Years" },
  //     { value: "27 Years", label: "27 Years" },
  //     { value: "28 Years", label: "28 Years" },
  //     { value: "29 Years", label: "29 Years" },
  //     { value: "30 Years", label: "30 Years" },
  //     { value: "31 Years", label: "31 Years" },
  //     { value: "32 Years", label: "32 Years" },
  //     { value: "33 Years", label: "33 Years" },
  //     { value: "34 Years", label: "34 Years" },
  //     { value: "35 Years", label: "35 Years" },
  //     { value: "36 Years", label: "36 Years" },
  //     { value: "37 Years", label: "37 Years" },
  //     { value: "38 Years", label: "38 Years" },
  //     { value: "39 Years", label: "39 Years" },
  //     { value: "40 Years", label: "40 Years" },
  // ];
  // const Months = [
  //     { value: "0 Month", label: "0 Month" },
  //     { value: "1 Month", label: "1 Month" },
  //     { value: "2 Months", label: "2 Months" },
  //     { value: "3 Months", label: "3 Months" },
  //     { value: "4 Months", label: "4 Months" },
  //     { value: "5 Months", label: "5 Months" },
  //     { value: "6 Months", label: "6 Months" },
  //     { value: "7 Months", label: "7 Months" },
  //     { value: "8 Months", label: "8 Months" },
  //     { value: "9 Months", label: "9 Months" },
  //     { value: "10 Months", label: "10 Months" },
  //     { value: "11 Months", label: "11 Months" },
  // ];

  let { data } = factFinderQuestions;
  return (
    <React.Fragment>
      <div className="register-steps">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      What's your legal status in the U.S.?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group fact-finder-third-question-option1">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="resident_citizen">
                            <input
                              type="radio"
                              id="resident_citizen"
                              name="tobacco"
                              checked={
                                data[8]?.answer === "Resident U.S. Citizen"
                                  ? true
                                  : false
                              }
                            />
                            <span></span> Resident U.S. Citizen
                          </label>
                        </div>
                      </div>
                      <div className="form-group fact-finder-third-question-option2">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="non_resident_citizen">
                            <input
                              type="radio"
                              id="non_resident_citizen"
                              name="tobacco"
                              checked={
                                data[8]?.answer === "Non-resident U.S. Citizen"
                                  ? true
                                  : false
                              }
                            />
                            <span></span> Non-resident U.S. Citizen
                          </label>
                        </div>
                      </div>
                      <div className="form-group fact-finder-third-question-option3">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="resident_alien">
                            <input
                              type="radio"
                              id="resident_alien"
                              name="tobacco"
                              checked={
                                data[8]?.answer === "Resident Alien"
                                  ? true
                                  : false
                              }
                            />
                            <span></span> Resident Alien
                          </label>
                        </div>
                      </div>
                      <div className="form-group fact-finder-third-question-option4">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="non_resident_alien">
                            <input
                              type="radio"
                              id="non_resident_alien"
                              name="tobacco"
                              checked={
                                data[8]?.answer === "Non-resident Alien"
                                  ? true
                                  : false
                              }
                            />
                            <span></span> Non-resident Alien
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}

                    {data[8]?.answer === "Resident Alien" && (
                      <div className="form-block-questions register-step3">
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          Are you a permanent resident?
                        </h3>
                        <div className="form-block radio-block">
                          <div className="form-group">
                            <div className="custom-radio">
                              <label
                                className="m-0"
                                htmlFor="yes_permanent_resident"
                              >
                                <input
                                  type="radio"
                                  id="yes_permanent_resident"
                                  name="yes_permanent_resident"
                                  checked={
                                    data[8]?.options[1].answer === "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <span></span> Yes
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="custom-radio">
                              <label
                                className="m-0"
                                htmlFor="no_permanent_resident"
                              >
                                <input
                                  type="radio"
                                  id="no_permanent_resident"
                                  name="no_permanent_resident"
                                  checked={
                                    data[8]?.options[1].answer === "No"
                                      ? true
                                      : false
                                  }
                                />
                                <span></span> No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {data[8]?.answer === "Resident U.S. Citizen" ||
                    data[8]?.answer === "Non-resident U.S. Citizen" ? (
                      <div className="form-block-questions">
                        <div className="questions-wrapper">
                          <div className="form-group full">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Where Were You Born ( Country, i.e. U.S.)"
                                name="where-were-you-born"
                                id="where-were-you-born"
                                value={data[8]?.options[0].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="Country">Place of Birth</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {data[8]?.options[1].answer === "Yes" ? (
                      <div className="form-block-questions">
                        <div className="questions-wrapper">
                          <div className="form-group full">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Where Were You Born ( Country, i.e. U.S.)"
                                name="where-were-you-born"
                                id="where-were-you-born"
                                value={data[8].options[0].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="Country">Place of Birth</label>
                            </div>
                          </div>
                        </div>
                        <form>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            What’s your citizenship?
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="State"
                                  name="State"
                                  id="State"
                                  value={data[8]?.options[3].answer}
                                  type="text"
                                  disabled
                                />
                                <label htmlFor="Country">Country</label>
                              </div>
                            </div>
                          </div>
                          <h3 className="form-text card-text mt-4 mb-3 text-center">
                            What’s your visa type?
                          </h3>
                          <div className="questions-wrapper">
                            <div className="form-group fixed-floating-label">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="State"
                                  name="State"
                                  id="State"
                                  value={data[8]?.options[9].answer}
                                  type="text"
                                  disabled
                                />
                                <label htmlFor="Country">Type of Visa</label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {data[8]?.options[1].answer === "No" ||
                    data[8]?.answer === "Non-resident Alien" ? (
                      <div className="form-block-questions register-step3">
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          Where Were You Born?
                        </h3>
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <div className="input-effect">
                                <input
                                  className="form-control"
                                  placeholder="Country"
                                  name="Country"
                                  id="Country"
                                  value={data[8]?.options[0].answer}
                                  type="text"
                                  disabled
                                />
                                <label htmlFor="Country">Country</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="State"
                                name="State"
                                id="State"
                                value={data[8]?.options[2].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="State">State</label>
                            </div>
                          </div>
                        </div>
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          What’s your citizenship?
                        </h3>
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="State"
                                name="State"
                                id="State"
                                value={data[8]?.options[3].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="Country">Country</label>
                            </div>
                          </div>
                        </div>
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          What’s your visa type?
                        </h3>
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="State"
                                name="State"
                                id="State"
                                value={data[8]?.options[9].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="Country">Type of Visa</label>
                            </div>
                          </div>
                        </div>
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          {" "}
                          Where do you live?
                        </h3>
                        <div className="questions-wrapper">
                          <div className="form-group fixed-floating-label">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Country"
                                name="Country"
                                id="Country"
                                value={data[8]?.options[4].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="Country">Country</label>
                            </div>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="State"
                                name="State"
                                id="State"
                                value={data[8]?.options[5].answer}
                                type="text"
                                disabled
                              />
                              <label htmlFor="State">State</label>
                            </div>
                          </div>
                        </div>
                        <h3 className="form-text card-text mt-4 mb-3 text-center">
                          Have you been to U.S.?
                        </h3>
                        <div className="form-block radio-block">
                          <div className="form-group">
                            <div className="custom-radio">
                              <label className="m-0" htmlFor="yes2">
                                <input
                                  type="radio"
                                  id="yes2"
                                  name="beenus"
                                  checked={
                                    data[8]?.options[6].answer === "Yes"
                                      ? true
                                      : false
                                  }
                                />
                                <span></span> Yes
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="custom-radio">
                              <label className="m-0" htmlFor="no2">
                                <input
                                  type="radio"
                                  id="no2"
                                  name="beenus"
                                  checked={
                                    data[8]?.options[6].answer === "No"
                                      ? true
                                      : false
                                  }
                                />
                                <span></span> No
                              </label>
                            </div>
                          </div>
                        </div>
                        {data[8]?.options[6].answer === "Yes" ? (
                          <div className="form-block-questions pb-0">
                            <div className="questions-wrapper">
                              <div className="form-group custom-datepicker">
                                <DatePicker
                                  selected={
                                    data[8]?.options[7].answer === ""
                                      ? ""
                                      : new Date(data[8]?.options[7].answer)
                                  }
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled
                                  dateFormatCalendar="MMMM"
                                  maxDate={new Date()}
                                />
                                <span className="datepicker-label">
                                  Date of entry
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*End: Page Title */}
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Are you currently employed?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="drugs">
                            <input
                              type="radio"
                              id="drugs"
                              name="drugs"
                              checked={data[9]?.answer === "Yes" ? true : false}
                            />
                            <span></span> Yes
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="nodrugs">
                            <input
                              type="radio"
                              id="nodrugs"
                              name="drugs"
                              checked={data[9]?.answer === "No" ? true : false}
                            />
                            <span></span> No
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                    {data[9]?.answer === "Yes" ? (
                      <div className="form-block-questions register-step3">
                        <div className="questions-wrapper">
                          <div className="form-group">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Employer name"
                                name="employer-name"
                                id="employer-name"
                                type="text"
                                disabled
                                value={data[9]?.options[0].answer}
                              />
                              <label htmlFor="employer-name">
                                Employer Name
                              </label>
                            </div>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[9]?.options[1].answer}
                                // options={Years}
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Duration in Years"
                                isDisabled
                              />
                            </div>
                            <span className="fixed-label">
                              Duration in Years
                            </span>
                          </div>
                          <div className="form-group fixed-floating-label">
                            <div className="default-select with-border">
                              <Select
                                value={data[9]?.options[2].answer}
                                // options={Months}
                                className={"custom-select-menu"}
                                classNamePrefix={"custom-select"}
                                placeholder="Duration in Months"
                                isDisabled
                              />
                            </div>
                            <span className="fixed-label">
                              Duration in Months
                            </span>
                          </div>

                          <div className="form-group">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Occupation"
                                name="occupation"
                                id="occupation"
                                type="text"
                                value={data[9]?.options[3].answer}
                                disabled
                              />
                              <label htmlFor="face-amount">Occupation</label>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Nature of Business"
                                name="nature-of-business"
                                id="nature-of-business"
                                type="text"
                                value={data[9]?.options[4].answer}
                                disabled
                              />
                              <label htmlFor="face-amount">
                                Nature of Business
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>

      <div className="register-steps register-step3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {/*Begin: About your health Form Wrapper */}
            <div className="register-steps-wrapper">
              {/*Begin: Animate.css Element */}
              <div className="form-wrapper">
                <form>
                  <div className="register-form-block">
                    <h3 className="form-text card-text mt-0 mb-3 text-center">
                      Who will be the policy beneficiary?
                    </h3>
                    {/*Begin: Form Group */}
                    <div className="form-block radio-block">
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="travelled">
                            <input
                              type="radio"
                              id="travelled"
                              name="travelled"
                              checked={
                                data[10]?.answer === "Decide Now" ? true : false
                              }
                              onChange={() => {
                                data[10].answer = "Decide Now";

                                setFactFinderQuestions({
                                  ...factFinderQuestions,
                                  data,
                                });
                              }}
                            />
                            <span></span> Decide Now
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-radio">
                          <label className="m-0" htmlFor="notravelled">
                            <input
                              type="radio"
                              id="notravelled"
                              name="travelled"
                              checked={
                                data[10]?.answer === "Decide Later"
                                  ? true
                                  : false
                              }
                            />
                            <span></span> Decide Later
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*End: Form Group */}
                    {data[10]?.answer === "Decide Now" ? (
                      <div className="form-block-questions">
                        <div className="questions-wrapper">
                          <div className="form-group full">
                            <div className="input-effect">
                              <input
                                className="form-control"
                                placeholder="Name"
                                name="decide-name"
                                id="decide-name"
                                type="text"
                                disabled
                                value={data[10]?.options[0].answer}
                              />
                              <label htmlFor="decide-name">Name</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
              {/*End: Animate.css Element */}
            </div>
            {/*End: About your health Form Wrapper */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(FactFinderThird));
