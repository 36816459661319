import { find, isEmpty } from "lodash";

export const getMissingInfo = (
  applicationDetails,
  applicationQuestionnaire
) => {
  let missingAppInfo = [];
  if (!applicationDetails.firstName) missingAppInfo.push("First name");
  if (!applicationDetails.lastName) missingAppInfo.push("Last name");
  if (!applicationDetails.gender) missingAppInfo.push("Gender");
  if (!applicationDetails.dob) missingAppInfo.push("Date of Birth");

  if (applicationDetails.carrierId === 3) {
    const isUsResident = find(applicationQuestionnaire, {
      question: "us_citizen_or_permanent_resident_last_two_year",
    });
    const isMarried = find(applicationQuestionnaire, {
      question: "marital_status",
    });
    const isEmployed = find(applicationQuestionnaire, {
      question: "are_you_currently_employed",
    });

    if (!applicationDetails.email) missingAppInfo.push("Email");
    if (
      !applicationDetails.policyState ||
      !applicationDetails.city ||
      !applicationDetails.postalCode
    )
      missingAppInfo.push("Residence address");
    if (!applicationDetails.howLongYear && !applicationDetails.howLongMonth)
      missingAppInfo.push(
        "Residence address : how long have they lived at this address"
      );
    if (!applicationDetails.clientDetails.ssn) missingAppInfo.push("SSN");
    if (!applicationDetails.drivingLicense)
      missingAppInfo.push("Driver License number");

    if (
      isEmpty(isUsResident) ||
      // isUsResident?.answer !== "Resident U.S. Citizen"
      (isUsResident?.options && isEmpty(isUsResident?.options[0].answer))
    )
      missingAppInfo.push("Country of Birth");

    if (isEmpty(isMarried)) {
      missingAppInfo.push("Marital Status");
      missingAppInfo.push("Life Insurance Amount on Spouse If Married");
    }

    if (!isEmpty(isMarried) && isMarried?.answer === "Married")
      missingAppInfo.push("Life Insurance Amount on Spouse If Married");

    if (isEmpty(isEmployed) || isEmployed?.answer === "No")
      missingAppInfo.push("Employer Information");
    else if (!isEmpty(isEmployed) && isEmployed.answer === "Yes")
      missingAppInfo.push(
        "Employer Information (current employer address, city, state and zip) "
      );

    if (isEmpty(isUsResident)) missingAppInfo.push("Citizenship");
    else if (!isEmpty(isUsResident) && isUsResident?.answer === "")
      missingAppInfo.push("Citizenship");
  }

  if (applicationDetails.carrierId === 2) {
    const isUsResident = find(applicationQuestionnaire, {
      question: "us_citizen_or_permanent_resident_last_two_year",
    });

    if (!applicationDetails.policyState) missingAppInfo.push("Contract State");
    if (!applicationDetails.email) missingAppInfo.push("Email");
    if (!applicationDetails.healthRating) missingAppInfo.push("Health rating");
    if (isEmpty(isUsResident)) missingAppInfo.push("Citizenship");
    else if (!isEmpty(isUsResident) && isUsResident?.answer === "")
      missingAppInfo.push("Citizenship");
  }

  if (applicationDetails.carrierId === 1) {
    const isUsResident = find(applicationQuestionnaire, {
      question: "us_citizen_or_permanent_resident_last_two_year",
    });
    if (!applicationDetails?.clientDetails.ssn) missingAppInfo.push("SSN");
    if (!applicationDetails.policyState) missingAppInfo.push("Resident State");
    if (isEmpty(isUsResident)) missingAppInfo.push("Insured Citizenship");
    if (!applicationDetails.dob && !applicationDetails.age)
      missingAppInfo.push("Age");
  }

  return missingAppInfo;
};

export function getProductDescription(carrierName, productName, pay) {
  let description;
  switch (carrierName) {
    case "MassMutual":
      if (carrierName.trim() === "MassMutual") {
        if (productName.trim() === "Whole Life Legacy 100" && pay === 100)
          description =
            "Level Premium Whole Life Policy with Premiums Payable to Age 100";
        else if (
          productName.trim() === "Whole Life Legacy 12 Pay" &&
          pay === 12
        )
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 12 Years";
        else if (
          productName.trim() === "Whole Life Legacy 10 Pay" &&
          pay === 10
        )
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 10 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }

    case "Guardian":
      if (carrierName.trim() === "Guardian") {
        if (productName.trim() === "Whole Life 121" && pay === 121)
          description =
            "Level Premium Whole Life Policy with Premiums Payable to Age 121";
        else if (productName.trim() === "10 Pay Whole Life" && pay === 10)
          description =
            "Level Premium Whole Life Policy with Premiums Payable for 10 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }

    case "Pacific Life":
      if (carrierName.trim() === "Pacific Life") {
        if (productName === "Pacific Discovery Xelerator IUL 2")
          description =
            "Flexible Premium Index Universal Life Policy with Premiums Payable for 6 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }

    case "John Hancock":
      if (carrierName.trim() === "John Hancock") {
        if (productName.trim() === "Accumulation IUL")
          description =
            "Flexible Premium Index Universal Life Policy with Premiums Payable for 6 Years";
        else
          description = `Level Premium Term Policy with Premiums Payable for ${pay} Years`;
      }
  }
  return description;
}
