import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, Form, Modal, Dropdown, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  commonApiCall,
  logout,
  showErrorToast,
  getJwt,
  updateAgent,
  setJwt,
  setPayment,
  setProfilePending,
  BasicsPagesRoutes,
  getPageHeader,
  CmsPagesRoutes,
  DashboardPagesRoutes,
  AmbassadorPagesRoutes,
  RegistrationPagesRoutes,
  MyWebsiteRoutes,
  formatDate,
  SettingsRoutes,
  conversationDetails,
  setAccordion,
  getItem,
  encryptData,
  showSuccessToast,
  saveLeadCount,
  passUserIdentifyInHtml,
  decryptData,
  getPayment,
  hashData,
  sidebarRoutes,
  redirectId,
  progressBarBoolean,
  formatDateInTimezone,
  checkAccess,
  setAgencyFilters,
} from "../../utlis/index";
import { isEmpty } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import errorMessages from "../../redux/actions/errorMessages";
import AuthorizePayment from "../../screen/registration/authorizePayment";
import {
  API_POINT,
  API_URL_CHATSERVICE,
  API_POINT_UNDERWRITE,
  AGENT_PORTAL_URL,
  API_POINT_PAYMENT,
  LANDING_PAGE_URL,
} from "../../config/config";
import PrivateChat from "../chat/privateChat";
import logo from "../../assets/images/logo.svg";
import ProgressLayout from "./progressLayout";
import Select from "react-select";
import moment from "moment";

let stompClient, SockJS;
const Header = ({
  router,
  commonApiCall,
  conversationDetails,
  logout,
  updateAgent,
  sign_in,
  profile_pic,
  accordionDetails,
  setAccordion,
  checkEvent,
  planData,
  saveLeadCount,
  redirectId,
  getmenuOpen,
  setAgencyFilters,
  selected_agency_filter,
  ...props
}) => {
  //variable declaration
  const [profilePhoto, setprofilePhoto] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [bannerLifeNotify, setBannerLifeNotify] = useState();
  const [notificationListing, setNotificationListing] = useState([]);
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(0);
  const [planDetails, setPlanDetails] = useState({});
  const [formData, setFormData] = useState({
    chatModel: false,
  });
  const [modelData, setModelData] = useState({
    isPaymentModel: false,
    isUpdateCard: false,
  });
  const [loader, setLoader] = useState(false);

  //use Effect
  useEffect(() => {
    if (!isEmpty(profile_pic) && !isEmpty(profile_pic.profilePic)) {
      let profilePhoto = profile_pic.profilePic;
      setprofilePhoto(profilePhoto);
    }
    let token = getJwt();
    if (!isEmpty(sign_in) && sign_in && sign_in.data && token) {
      setShowLogin(false);
      if (
        process.env.REACT_APP_NODE_ENV == "production" &&
        getItem("ajs_user_id")
      ) {
        let getIntercomId = getItem("ajs_user_id");
        let id = JSON.parse(getIntercomId);
        window.Alvis("identify", id);
      }
    }
    if (
      process.env.REACT_APP_NODE_ENV !== "development" &&
      getJwt() &&
      !isEmpty(sign_in)
    ) {
      let { email, firstName, lastName, id, currentPlanSlug, mobile } =
        sign_in.data;
      let name = `${decryptData(firstName)} ${decryptData(lastName)}`;
      let free_trial =
        sign_in.data.remainingTrialDays && sign_in.data.remainingTrialDays > 0
          ? true
          : planData?.remainingTrialDays && planData?.remainingTrialDays > 0
          ? true
          : false;
      let subscription_active =
        currentPlanSlug.toLowerCase() === "free" ||
        currentPlanSlug.toLowerCase() === "ambassador"
          ? true
          : sign_in?.data?.remainingTrialDays &&
            sign_in?.data?.remainingTrialDays > 0
          ? true
          : getPayment()
          ? false
          : true;
      let user_hash = hashData(id.toString());
      passUserIdentifyInHtml(
        decryptData(email),
        name,
        id.toString(),
        currentPlanSlug,
        subscription_active,
        decryptData(mobile),
        free_trial,
        user_hash
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sign_in, profile_pic, planData]);

  const path = window.location.pathname;
  const splitpath = path.split("/");
  const [isToggle, setIsToggle] = useState(false);

  const getClick = () => {
    const sidebarContainer = document.querySelector(".sidebar-container");
    const sidebar = document.querySelector(".sidebar");
    if (isToggle) {
      document.body.classList.remove("sidebar-open");
      sidebarContainer.classList.remove("sidebar-open");
      sidebar.classList.remove("open");
      setIsToggle(false);
    } else {
      sidebar.classList.add("open");
      sidebarContainer.classList.add("sidebar-open");
      document.body.classList.add("sidebar-open");
      setIsToggle(true);
    }
  };
  const closeSidebar = () => {
    document.body.classList.remove("sidebar-open");
    const sidebarContainer = document.querySelector(".sidebar-container");
    const sidebar = document.querySelector(".sidebar");
    sidebarContainer.classList.remove("sidebar-open");
    sidebar.classList.remove("open");
    setIsToggle(false);
  };

  //logout
  const Logout = async () => {
    if (sign_in?.data?.signUpFromAdmin === false) {
      setCurrentAccessedScreen("Logout");
    } else {
      let response = await commonApiCall(
        API_POINT + "/signOut",
        "",
        "get",
        null,
        true
      );

      if (response?.status === 200 && response?.data?.status?.code === 200) {
        localStorage.clear();
        await logout();
        setShowLogin(true);
        window.location.replace(LANDING_PAGE_URL);
      }
    }
  };

  const connect = async () => {
    try {
      const Stomp = require("stompjs");
      SockJS = require("sockjs-client");
      SockJS = new SockJS(API_URL_CHATSERVICE + "/ws");
      stompClient = Stomp.over(SockJS);
      stompClient.debug = null;
      stompClient.connect(
        {
          agentUserId: sign_in.data && sign_in.data.id,
          type: "OTHERSCREEN",
        },
        function () {
          onConnected();
        },
        function () {
          onError();
        }
      );
    } catch (err) {
      // showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onConnected = () => {
    try {
      if (stompClient.connected) {
        //Subscribing to the private notification room
        stompClient.subscribe(
          "/user/agent-" + sign_in.data.id + "/notify",
          function (responsePayload) {
            onMessageReceived(responsePayload);
          }
        );
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const onError = (error) => {
    // console.log(error, "error");
    // console.log(
    //   "Could not connect you to the Chat Room Server. Please refresh this page and try again!"
    // );
  };

  const onMessageReceived = (payload) => {
    try {
      if (payload) {
        const notification = JSON.parse(payload.body);
        if (notification) {
          let notificationArray = [];
          notificationArray.push(notification);
          setNotificationListing((prevNotificationListing) => {
            let returnNotificationArray = [];
            if (prevNotificationListing) {
              returnNotificationArray = [
                ...notificationArray,
                ...prevNotificationListing,
              ];
            } else {
              returnNotificationArray = notificationArray;
            }
            return returnNotificationArray;
          });
          setUnReadNotificationCount(
            (prevUnReadNotificationCount) => prevUnReadNotificationCount + 1
          );
          enableDesktopNotification(
            notification.data.subject,
            notification.data.message
          );
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getNotifications = async (isRead = false) => {
    try {
      const request = {};
      const apiEndPoint = isRead
        ? API_URL_CHATSERVICE +
          "/notifications?agentUserId=" +
          sign_in.data.id +
          "&isNotificationRead=true"
        : API_URL_CHATSERVICE +
          "/notifications?agentUserId=" +
          sign_in.data.id +
          "&type=unread&pageSize=3&pageNo=0";
      const isAuthorized = true;

      const response = await commonApiCall(
        apiEndPoint,
        request,
        "get",
        null,
        isAuthorized,
        false,
        true
      );

      if (response.data.status.code === 200) {
        setCurrentAccessedScreen();
        if (!isRead) {
          setNotificationListing(response.data.data.notifications);
          if (path !== DashboardPagesRoutes.NOTIFICATIONS)
            setUnReadNotificationCount(response.data.data.unreadCount);
        } else {
          setUnReadNotificationCount(0);
        }
      } else {
        showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getLastAccessedScreenName = () => {
    // if (path === DashboardPagesRoutes.MYPROFILE) return "My Account";
    // else if (path === DashboardPagesRoutes.LIVELT_SETTINGS)
    //   return "Live LT Settings";
    if (path === DashboardPagesRoutes.ACCOUNTSETTINGS)
      return "Account Settings";
    else if (path === RegistrationPagesRoutes.PAYMENT) return "Payment Screen";
    else if (
      path === DashboardPagesRoutes.SELECT_APPOINTMENTS ||
      path === DashboardPagesRoutes.APPOINTMENT_FORM ||
      path === DashboardPagesRoutes.SBLI_APPOINTMENT_FORM
    )
      return "Get Appointment";
    // else if (path === DashboardPagesRoutes.NOTIFICATION_SETTINGS)
    //   return "Notification Settings";
    else if (
      path === DashboardPagesRoutes.AVBL_LEADPACKAGES ||
      path === DashboardPagesRoutes.LEADCONVERSATIONS ||
      path === DashboardPagesRoutes.CONTACTS ||
      path === DashboardPagesRoutes.TEXT ||
      path === DashboardPagesRoutes.APPLICATIONS + "/myLink" ||
      path === DashboardPagesRoutes.PROSPECTSVIAAFFILIATE ||
      path === AmbassadorPagesRoutes.MYREFERRALS + "/otherRegions" ||
      path === DashboardPagesRoutes.UNASSIGNLEADS ||
      path === DashboardPagesRoutes.DASHBOARD ||
      path === DashboardPagesRoutes.CLIENTPIPELINE
    )
      return "CRM";
    else if (path === DashboardPagesRoutes.PRODUCT_BROWSING)
      return "Client Support";
    else if (
      path === DashboardPagesRoutes.EMAIL ||
      path === DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN ||
      path === DashboardPagesRoutes.SENT_EAMIL_DETAILS ||
      path === DashboardPagesRoutes.CLIENT_EMAIL_DETAIL ||
      path === DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS
    )
      return "Marketing";
    else if (
      path === MyWebsiteRoutes.HOMEPAGE ||
      path === MyWebsiteRoutes.ABOUTPAGE ||
      path === MyWebsiteRoutes.WEBSETTING ||
      path === MyWebsiteRoutes.MYWEBSITE ||
      path === AmbassadorPagesRoutes.MYREFERRALS
    )
      return "Website Settings";
    else if (
      path === DashboardPagesRoutes.ILIFESTORE ||
      path === DashboardPagesRoutes.PURCHASES ||
      path === DashboardPagesRoutes.STORETRANSACTION
    )
      return "Business Tools";
    else if (
      path === DashboardPagesRoutes.MANAGERIALVIEW ||
      path === DashboardPagesRoutes.LEADASSIGNMENT ||
      path === DashboardPagesRoutes.INVITEDAGENTS
    )
      return "Agency View";
    else if (path === DashboardPagesRoutes.NOTIFICATIONS)
      return "Notifications";
    else if (path === SettingsRoutes.MYSETTINGS) return "My Subscriptions";
    else if (path === DashboardPagesRoutes.THEMESETTINGS)
      return "Theme Settings";
    else return undefined;
  };

  const setCurrentAccessedScreen = async (logoutPath = undefined) => {
    if (
      sign_in?.data?.signUpFromAdmin === false &&
      (getLastAccessedScreenName() || logoutPath != undefined)
    ) {
      const body = {
        lastScreenAccessed: logoutPath
          ? logoutPath
          : getLastAccessedScreenName(),
      };
      let response = await commonApiCall(
        API_POINT + "/agentSettings",
        body,
        "post",
        null,
        true
      );
      if (response?.status === 200 && response?.data?.status?.code === 200) {
        if (logoutPath) {
          let response = await commonApiCall(
            API_POINT + "/signOut",
            "",
            "get",
            null,
            true
          );
          if (
            response?.status === 200 &&
            response?.data?.status?.code === 200
          ) {
            localStorage.clear();
            await logout();
            setShowLogin(true);
            window.location.replace(LANDING_PAGE_URL);
          }
        }
      } else {
        showErrorToast(response?.data?.status?.message);
      }
    }
  };

  const redirectNotifications = async (notification) => {
    switch (notification.type) {
      case "REQUEST":
        props.history.push(DashboardPagesRoutes.INVITEDAGENTS);
        break;
      case "AGENT_REGISTER":
        props.history.push(DashboardPagesRoutes.MANAGERIALVIEW);
        break;
      case "UPGRADE_PLAN":
        props.history.push(SettingsRoutes.MYSETTINGS);
        break;
      case "EMAIL":
        redirectId({
          appId: notification.applicationCarrierId,
          agentId: notification.agentUserId,
        });
        props.history.push(DashboardPagesRoutes.CLIENT_DETAIL_PAGE);
      default:
        getDetailsOfApplications(notification);
        break;
    }
  };

  const getDetailsOfApplications = async (req) => {
    try {
      if (
        (req.applicationCarrierId &&
          req.applicationCarrierId !== null &&
          req.applicationCarrierId !== "") ||
        (req.applicationId &&
          req.applicationId !== null &&
          req.applicationId !== "")
      ) {
        const reqURL = req.applicationCarrierId
          ? "anonymousApplicationCarrierId=" + req.applicationCarrierId
          : "applicationId=" + req.applicationId;

        let response = await commonApiCall(
          API_POINT_UNDERWRITE + "/applicationBasicDetails?" + reqURL,
          "",
          "get",
          "GET_DETAILS_APPLICATIONS",
          true
        );
        if (response.data.status.code === 200) {
          conversationDetails(response.data.data);
          setFormData({
            ...formData,
            chatModel: true,
          });
          // setUnReadNotificationCount(response.data.data.unreadCount);
        }
      }
    } catch (err) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  const showNotification = (subject, message) => {
    const notification = new Notification(subject, {
      body: message,
      icon: AGENT_PORTAL_URL + "/favicon.ico",
    });
    notification.onclick = (e) => {
      window.location.href = AGENT_PORTAL_URL + "/leadConversations";
    };
  };

  const enableDesktopNotification = (subject, message) => {
    if (!window.Notification) {
    } else {
      // check if permission is already granted
      if (Notification.permission === "granted") {
        // show notification here
        showNotification(subject, message);
      } else {
        // request permission from user
        Notification.requestPermission()
          .then(function (p) {
            if (p === "granted") {
              // show notification here
              showNotification(subject, message);
            } else {
              // console.log("User blocked notifications.");
            }
          })
          .catch(function (err) {
            // console.error(err);
          });
      }
    }
  };

  const handleModel = (name, isHide) => {
    setFormData({ ...formData, [name]: isHide });
    if (isHide) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  };

  useEffect(() => {
    if (getJwt() && planData && planData?.remainingTrialDays > 0) {
      getSubscriptionDetails();
      document.body.classList.add("try-free-mobile");
    } else {
      document.body.classList.remove("try-free-mobile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData?.remainingTrialDays > 0]);

  const getSubscriptionDetails = async () => {
    try {
      let response = await commonApiCall(
        API_POINT + "/getSubscriptionPlanByToken",
        "",
        "get",
        null,
        true
      );
      if (response?.data?.status?.code === 200) {
        setPlanDetails(response?.data?.data[0]);
      }
    } catch (error) {
      showErrorToast(errorMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    const isAuthorized = sign_in.data && sign_in.data.token ? true : false;
    if (
      isAuthorized &&
      !(
        path === RegistrationPagesRoutes.PAYMENT ||
        "/" + splitpath[1] === DashboardPagesRoutes.WELCOMEPAGE ||
        path === DashboardPagesRoutes.PRODUCT_SELECTION ||
        path === DashboardPagesRoutes.CARRIERS_SELECTION ||
        path === DashboardPagesRoutes.STATE_SELECTION ||
        path === DashboardPagesRoutes.TIME_ZONE_SELECTION
      ) &&
      checkAccess(planData, "notifications")
    ) {
      connect();
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect(function () {
          // console.log("Disconnected. See you next time!");
        });
      }
    };
  }, []);

  const handleLogoClick = async () => {
    if (!getJwt()) {
      // window.location.replace(LANDING_PAGE_URL);
    } else if (
      !isEmpty(sign_in) &&
      sign_in?.data?.isActive !== undefined &&
      !sign_in?.data?.isActive
    ) {
      updateAgent("");
      await setJwt(null);
      await setPayment(null);
      await setProfilePending(null);
      await logout();
      localStorage.clear();
    } else if (
      !showLogin &&
      getJwt() &&
      !isEmpty(sign_in) &&
      sign_in?.data?.isActive &&
      (path === RegistrationPagesRoutes.PAYMENT ||
        "/" + splitpath[1] === DashboardPagesRoutes.WELCOMEPAGE ||
        path === DashboardPagesRoutes.PRODUCT_SELECTION ||
        path === DashboardPagesRoutes.CARRIERS_SELECTION ||
        path === DashboardPagesRoutes.STATE_SELECTION ||
        path === DashboardPagesRoutes.TIME_ZONE_SELECTION)
        ? null
        : props.history.push(DashboardPagesRoutes.CLIENTPIPELINE)
    ) {
    }
  };

  const truncate = (textstring, n) => {
    if (textstring?.length <= n) {
      return textstring;
    }
    let subString = textstring?.substr(0, n - 1);
    return subString + "...";
  };

  const handlePaymentModel = (name, isHide) => {
    setModelData({ ...modelData, [name]: isHide });
  };

  const onCancel = () => {
    setModelData({
      ...modelData,
      isPaymentModel: false,
      isUpdateCard: false,
    });
  };

  const getLeadStatistics = async () => {
    let response = await commonApiCall(
      API_POINT + "/getLeadCount",
      "",
      "get",
      "PLAN_DETAILS",
      true
    );
    if (response.data.status.code === 200) {
      let { data } = response.data;
      handlePaymentModel("isPaymentModel", false);
      saveLeadCount(data);
      setLoader(false);
      props.history.push(response?.data?.data?.defaultPageUrl);
    } else {
      handlePaymentModel("isPaymentModel", false);
      setLoader(false);
      props.history.push(DashboardPagesRoutes.CLIENTPIPELINE);
    }
  };

  const getpaymentSuccess = async (paymentGatewayName, paymentProfileId) => {
    let body;
    setLoader(true);
    body = {
      paymentGatewayName: "STRIPE",
      agentId: sign_in.data.id,
      planId: planDetails?.id,
      customerPaymentProfileId: paymentGatewayName
        ? await encryptData(paymentProfileId)
        : isNaN(Number(paymentProfileId))
        ? paymentProfileId
        : await encryptData(paymentProfileId),
    };
    let response = await commonApiCall(
      API_POINT_PAYMENT + "/planUpgrade",
      body,
      "post",
      null,
      true
    );
    if (response.status === 200 && response.data.status.code === 200) {
      sign_in.data.isPayment = true;
      await updateAgent(sign_in);
      await getLeadStatistics();
      showSuccessToast(response?.data?.status?.message);
    } else {
      setLoader(false);
      showErrorToast(response?.data?.status?.message);
    }
  };
  const { chatModel } = formData;
  const { isPaymentModel, isUpdateCard } = modelData;

  // header component
  const showLogoInHeader = (
    <Link
      to={"#"}
      onClick={() => {
        handleLogoClick();
      }}
      className={!getJwt() ? "not-signed-in" : ""}
    >
      <p className='page-header'>
        {
          <img
            src={logo}
            alt='iLife logo'
            width={140}
            style={{ minWidth: "80px" }}
          />
        }
      </p>
    </Link>
  );

  const visitorsFilterDropdown = [
    { value: "all", label: "All Time", radioIcon: true },
    { value: "this_week", label: "Last 7 days", radioIcon: true },
    { value: "14_days", label: "Last 14 days", radioIcon: true },
    { value: "last_month", label: "Last 30 days", radioIcon: true },
    { value: "six_month", label: "Last 6 months", radioIcon: true },
    // { value: "last_month", label: "Last Month" },
    { value: "custom", label: "Select Date", plusIcon: true },
  ];

  const customStyles = {
    option: (provided, state) => ({
      //...provided,
      display: "flex",
      alignItems: "center",
      // padding: '5px 10px',
      cursor: "pointer",
      backgroundColor: state.isSelected ? "#f0f0f0" : "white",
      borderBottom: "none",
    }),
  };

  const customOption = ({ data, isSelected, selectOption }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className={`${data.plusIcon && "border-top"} ${
          isSelected && "active"
        } agent-date-select`}
        onClick={() => selectOption(data)}
      >
        {data.plusIcon && (
          <span className='pl-2 w-100' style={{ color: "rgb(121 121 121 / 80%)" }}>
            <label className='m-0'>
              <span
                className='mr-2 font-26'
                style={{ verticalAlign: "middle", marginLeft: "-4px" }}
              >
                +
              </span>
              {data.label}
            </label>{" "}
          </span>
        )}
        {data.radioIcon && (
          <span className='custom-radio custom-radio-small text-center'>
            <label className='m-0'>
              <input type='radio' name={"checkbox-row"} checked={isSelected} />
              <span className='mr-0'></span>
              {data.label}
            </label>
          </span>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <header>
        <div className='container pb-0'>
          {/*Begin: Navbar */}
          <Navbar
            expand='lg'
            className={
              path === DashboardPagesRoutes.MANAGERIALVIEW
                ? "col-md-9"
                : path === DashboardPagesRoutes.MYAGENTS
                ? "col-md-12"
                : ""
            }
          >
            <div className='d-flex justify-content-sm-between align-items-md-end align-items-center w-100'>
              {/*Begin: Logo */}
              <Navbar.Brand
                style={{ marginTop: "20px" }}
                className=' font-24 p-0'
              >
                {showLogin || !sidebarRoutes(path) ? (
                  showLogoInHeader
                ) : (
                  <p className='page-header pl-0 text-header'>
                    {getPageHeader(path)}
                  </p>
                )}
              </Navbar.Brand>
              {[
                DashboardPagesRoutes.MANAGERIALVIEW,
                DashboardPagesRoutes.MYAGENTS,
              ].includes(path) ? (
                <div className='d-flex justify-content-end align-items-end flex-column flex-md-row mr-md-3'>
                  <div className='d-flex '>
                    {selected_agency_filter?.value === "custom" &&
                      selected_agency_filter?.dateOption?.startDate &&
                      selected_agency_filter?.dateOption?.endDate && (
                        <span
                          className='bg-white form-control mr-2 p-0'
                          style={{ height: "40px" }}
                        >
                          <span className='d-block font-14 mt-2 px-3'>
                            {moment(
                              selected_agency_filter?.dateOption?.startDate
                            ).format("MM/DD/YYYY") +
                              " - " +
                              moment(
                                selected_agency_filter?.dateOption?.endDate
                              ).format("MM/DD/YYYY")}
                          </span>
                        </span>
                      )}
                    <div className='default-select with-border calander-filter flex-shrink-0'>
                      <Select
                        role='menuitemradio'
                        styles={customStyles}
                        isSearchable={false}
                        options={visitorsFilterDropdown}
                        components={{ Option: customOption }}
                        onChange={(e) => {
                          setAgencyFilters({
                            value: e.value,
                            dateOption: { startDate: "", endDate: "" },
                          });
                        }}
                        defaultValue={{
                          label: "This Week",
                          value: "this_week",
                        }}
                        value={visitorsFilterDropdown.find(
                          (obj) => obj.value === selected_agency_filter?.value
                        )}
                        className={"custom-select-menu"}
                        classNamePrefix={"custom-select"}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {/* notifications and image  */}
              {path === DashboardPagesRoutes.DASHBOARD ||
              path === DashboardPagesRoutes.CLIENTPIPELINE ||
              path === DashboardPagesRoutes.MYAGENTS ||
              path === DashboardPagesRoutes.AGENT_CHAT ||
              // path === DashboardPagesRoutes.MYPROFILE ||
              // path === DashboardPagesRoutes.NOTIFICATION_SETTINGS ||
              // path === DashboardPagesRoutes.LIVELT_SETTINGS ||
              path.split("/").indexOf("account-settings") > -1 ||
              path === DashboardPagesRoutes.MYSUBSCRIPTIONS ||
              path === DashboardPagesRoutes.MYPROFILE_PROFESSIONAL ||
              "/" + splitpath[1] === AmbassadorPagesRoutes.MYREFERRALS ||
              path === AmbassadorPagesRoutes.MYREFERRALSDETAILS ||
              path === DashboardPagesRoutes.LEADCONVERSATIONS ||
              path === DashboardPagesRoutes.CONTACTS ||
              path === DashboardPagesRoutes.TEXT ||
              "/" + splitpath[1] === DashboardPagesRoutes.APPLICATIONS ||
              path === DashboardPagesRoutes.APPLICATIONSBASICDETAILS ||
              path === DashboardPagesRoutes.PROSPECTSVIAAFFILIATE ||
              path === DashboardPagesRoutes.MANAGERIALVIEW ||
              path === DashboardPagesRoutes.AGENTDETAILS ||
              path === DashboardPagesRoutes.APPLICATIONSDOCUMENTS ||
              path === DashboardPagesRoutes.APPLICATIONSEDITABLEPDF ||
              path === DashboardPagesRoutes.APPLICATIONPOLICYINFORMATION ||
              path === DashboardPagesRoutes.APPLICATIONUNDERWRITINGSTEPS ||
              path === DashboardPagesRoutes.APPLICATIONSCONVERSATIONS ||
              path === DashboardPagesRoutes.SETTINGDEXPERT ||
              path === DashboardPagesRoutes.BASICDETAILSEXPERT ||
              path === DashboardPagesRoutes.POLICYINFORMATIONEXPERT ||
              path === DashboardPagesRoutes.CONVERSATIONEXPERT ||
              path === DashboardPagesRoutes.MYREFERENCEDETAILSEXPERT ||
              path === DashboardPagesRoutes.NOTIFICATIONS ||
              path === DashboardPagesRoutes.AVBL_LEADPACKAGES ||
              path === DashboardPagesRoutes.PAYOUTS ||
              path === SettingsRoutes.MYSETTINGS ||
              path === DashboardPagesRoutes.INVITEAGENT ||
              path === DashboardPagesRoutes.AGENTREQUESTS ||
              path === DashboardPagesRoutes.LEADASSIGNMENT ||
              path === DashboardPagesRoutes.UNASSIGNLEADS ||
              path === DashboardPagesRoutes.INVITEDAGENTS ||
              path === DashboardPagesRoutes.THEMESETTINGS ||
              path === MyWebsiteRoutes.HOMEPAGE ||
              path === MyWebsiteRoutes.ABOUTPAGE ||
              path === MyWebsiteRoutes.WEBSETTING ||
              path === MyWebsiteRoutes.MYWEBSITE ||
              path === SettingsRoutes.SUBUSERS ||
              path === DashboardPagesRoutes.SELECT_APPOINTMENTS ||
              path === DashboardPagesRoutes.APPOINTMENT_FORM ||
              path === DashboardPagesRoutes.SBLI_APPOINTMENT_FORM ||
              (path === RegistrationPagesRoutes.PAYMENT && !showLogin) ||
              path === DashboardPagesRoutes.MYEARNINGBASICDETAILSEXPERT ||
              path === SettingsRoutes.PAYMENTINFORMATION ||
              path === DashboardPagesRoutes.ILIFESTORE ||
              path === DashboardPagesRoutes.EMAIL ||
              path === DashboardPagesRoutes.REMINDERS ||
              path === DashboardPagesRoutes.PRODUCT_BROWSING ||
              path === DashboardPagesRoutes.SOCIALMEDIA ||
              path === DashboardPagesRoutes.CREATE_EMAIL_CAMPAIGN ||
              path === DashboardPagesRoutes.SENT_EAMIL_DETAILS ||
              path ===
                DashboardPagesRoutes.SENT_EMAIL_DETAILS_WITHOUT_METRICS ||
              path === DashboardPagesRoutes.CLIENT_EMAIL_DETAIL ||
              path === DashboardPagesRoutes.PURCHASES ||
              "/" + splitpath[1] + "/" + splitpath[2] ==
                RegistrationPagesRoutes.LEADPACKAGEOFFER ||
              path === DashboardPagesRoutes.STORETRANSACTION ? (
                <div
                  className='profile-dropdown flex-shrink-0 d-sm-none'
                  style={{ marginRight: "12px" }}
                >
                  {sidebarRoutes(path) && (
                    <div className='dropdown responsive-menu'>
                      <span
                        className='material-icons'
                        title='menu'
                        onClick={() => {
                          localStorage.setItem("sidebarIsOpen", "open");
                          getmenuOpen(true);
                          getClick();
                        }}
                      >
                        menu
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {/*End: Logo */}
            {/* Plan Massage */}
            {getJwt() &&
            !isEmpty(planData) &&
            planData?.remainingTrialDays &&
            planData?.remainingTrialDays > 0 ? (
              <div className='planmsg w-100 text-center'>
                <h4 className='mb-0 txt-light-black  font-18'>
                  <strong>{planData?.remainingTrialDays}</strong> days remaining
                  in your free trial.{" "}
                  <Link
                    to='#'
                    className='text-primary text-decoration-underline font-weight-normal opacity-hover ml-1'
                    onClick={() => handlePaymentModel("isPaymentModel", true)}
                  >
                    Purchase now
                  </Link>
                </h4>
              </div>
            ) : (
              ""
            )}
            {/* Plan Massage  End */}
            {/*Begin: Collapsing Navbar */}
            <Navbar.Collapse id='basic-navbar-nav'>
              {/*Begin: Login Navbar */}
              {path === BasicsPagesRoutes.HOME ||
              path === CmsPagesRoutes.ABOUTUS ||
              path === CmsPagesRoutes.SERVICE_REPRESENTATIVE ||
              path === CmsPagesRoutes.CONTACTUS ? (
                <Nav className='login-navbar'>
                  <Link
                    to={CmsPagesRoutes.ABOUTUS}
                    className={
                      path === CmsPagesRoutes.ABOUTUS
                        ? "nav-link active"
                        : "nav-link"
                    }
                    title='About iLife'
                  >
                    About iLife
                  </Link>
                  <Link
                    to={RegistrationPagesRoutes.GETSTART_3}
                    className={
                      path === RegistrationPagesRoutes.GETSTART_3
                        ? "nav-link active"
                        : "nav-link"
                    }
                    title='iLife Service Reps'
                  >
                    iLife Service Reps
                  </Link>
                  <Link
                    to={CmsPagesRoutes.CONTACTUS}
                    className={
                      path === CmsPagesRoutes.CONTACTUS
                        ? "nav-link active"
                        : "nav-link"
                    }
                    title='Contact Us'
                  >
                    Contact Us
                  </Link>
                </Nav>
              ) : (
                ""
              )}
              {/*End: Login Navbar */}
            </Navbar.Collapse>
            <div>
              {path === BasicsPagesRoutes.HOME ||
              path === CmsPagesRoutes.ABOUTUS ||
              path === CmsPagesRoutes.SERVICE_REPRESENTATIVE ||
              path === CmsPagesRoutes.CONTACTUS ? (
                <Form inline className='login login-here'>
                  {showLogin ? (
                    <div>
                      <Link
                        to={BasicsPagesRoutes.LOGIN}
                        className='text-link login-link'
                        title='Log In'
                      >
                        Log In
                      </Link>
                      <Navbar.Toggle aria-controls='basic-navbar-nav'>
                        <span className='material-icons'>menu</span>
                      </Navbar.Toggle>
                    </div>
                  ) : (
                    <div className='profile-dropdown flex-shrink-0'>
                      <div className='dropdown'>
                        <span
                          className='notification-icon'
                          id='dropdownNotification'
                          title='Notification'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                          onClick={() => getNotifications(true)}
                        >
                          <span className='material-icons'>notifications</span>
                          {unReadNotificationCount !== 0 && (
                            <span className='counter-icon'>
                              {unReadNotificationCount}
                            </span>
                          )}
                        </span>

                        <div
                          className='dropdown-menu dropdown-menu-notifications dropdown-menu-right'
                          aria-labelledby='dropdownNotification'
                        >
                          {!isEmpty(notificationListing) ? (
                            <React.Fragment>
                              <PerfectScrollbar>
                                {notificationListing.map(
                                  (notification, key) => {
                                    return (
                                      <div
                                        className='notification-outer'
                                        key={key}
                                      >
                                        <div
                                          className='notification-msg'
                                          style={{
                                            cursor:
                                              notification.applicationCarrierId
                                                ? "pointer"
                                                : "auto",
                                          }}
                                          onClick={() => {
                                            redirectNotifications(notification);
                                            // getDetailsOfApplications(
                                            //   notification.applicationCarrierId
                                            // )
                                          }}
                                        >
                                          <h4 className='notification-title'>
                                            {notification.data.subject}
                                          </h4>
                                          <h4
                                            style={{ wordBreak: "break-word" }}
                                          >
                                            {truncate(
                                              notification.data.message,
                                              45
                                            )}
                                          </h4>
                                        </div>
                                        <div className='notification-date'>
                                          <h6>
                                            {formatDateInTimezone(
                                              notification.createdDate,
                                              planData?.timeZoneId ?? null
                                            ).format("hh:mm a")}{" "}
                                            •{" "}
                                            {formatDateInTimezone(
                                              notification.createdDate,
                                              planData?.timeZoneId ?? null
                                            ).format("MM/DD/YYYY")}
                                          </h6>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </PerfectScrollbar>
                              <Link
                                to={DashboardPagesRoutes.NOTIFICATIONS}
                                className='dropdown-item view-notification'
                              >
                                View all notifications
                              </Link>
                            </React.Fragment>
                          ) : (
                            <div className='notification-outer'>
                              <div className='notification-msg pl-3 pr-2'>
                                There are no new notifications
                              </div>
                              <Link
                                to={DashboardPagesRoutes.NOTIFICATIONS}
                                className='dropdown-item view-notification'
                              >
                                View all notifications
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='dropdown'>
                        <div
                          className='profile-img dropdown-toggle'
                          id='dropdownMenuButton'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <img
                            src={
                              profilePhoto
                                ? profilePhoto
                                : "/assets/images/no-image.jpg"
                            }
                            alt=''
                            title=''
                          />
                        </div>
                        <div
                          className='dropdown-menu dropdown-menu-right'
                          aria-labelledby='dropdownMenuButton'
                        >
                          {/* {path === RegistrationPagesRoutes.PAYMENT ||
                          sign_in?.data?.showBecomeMemberPage ===
                            true ? null : (
                            <>
                              <Link
                                to={DashboardPagesRoutes.MYPROFILE}
                                className="dropdown-item"
                              >
                                <span className="dropdown-icon material-icons">
                                  account_circle
                                </span>
                                My Account
                              </Link>
                            </>
                          )} */}
                          {path !== RegistrationPagesRoutes.PAYMENT ||
                          sign_in?.data?.showBecomeMemberPage ===
                            true ? null : (
                            <>
                              <Link
                                to={"/account-settings/profile"}
                                className='dropdown-item'
                              >
                                <span className='dropdown-icon material-icons'>
                                  account_circle
                                </span>
                                Account Settings
                              </Link>
                            </>
                          )}
                          {/* {sign_in?.data?.retreaverId ? (
                            <Link
                              to={DashboardPagesRoutes.LIVELT_SETTINGS}
                              className="dropdown-item"
                            >
                              <span className="dropdown-icon material-icons">
                                settings
                              </span>
                              Live LT Settings
                            </Link>
                          ) : null} */}
                          {/* {path === RegistrationPagesRoutes.PAYMENT ||
                          sign_in?.data?.currentPlanSlug === "ambassador" ||
                          sign_in?.data?.showBecomeMemberPage ===
                            true ? null : (
                            <Link
                              to={DashboardPagesRoutes.NOTIFICATION_SETTINGS}
                              className="dropdown-item"
                            >
                              <span className="dropdown-icon material-icons">
                                settings
                              </span>
                              Notification Settings
                            </Link>
                          )} */}
                          <Link
                            to='#'
                            className='dropdown-item'
                            onClick={Logout}
                          >
                            <span className='dropdown-icon material-icons'>
                              input
                            </span>
                            Logout
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              ) : (
                ""
              )}
            </div>
            {/*End: Collapsing Navbar */}
          </Navbar>
          {/*End: Navbar */}
        </div>
        {chatModel && (
          <PrivateChat
            chatModel={chatModel}
            handleModel={handleModel}
          ></PrivateChat>
        )}
        <Modal
          className='payment-model'
          show={isPaymentModel}
          onHide={() =>
            setModelData({
              ...modelData,
              isPaymentModel: false,
              isUpdateCard: false,
            })
          }
          centered
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isUpdateCard
                ? "Update Your Card Details"
                : `Upgrade your plan to ${planDetails.planName}!`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AuthorizePayment
              loader={loader}
              onPayNow={getpaymentSuccess}
              isCardDetails={isPaymentModel}
              isUpdatePaymentInformation={false}
              onCancel={onCancel}
              fromUpgrade={true}
              isUpdateCard={isUpdateCard}
              upgradePlanName={`${planDetails?.planName} : $`}
              upgradePlanPrice={
                planDetails?.planPrice ? planDetails?.planPrice : ""
              }
            ></AuthorizePayment>
          </Modal.Body>
        </Modal>
        {progressBarBoolean(path) && <ProgressLayout />}
      </header>
      <div className='sidebar-overlay' onClick={closeSidebar}></div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sign_in: state.agentReducer.signIn,
    profile_pic: state.agentReducer.profile_pic,
    accordionDetails: state.agentReducer.accordionData,
    planData: state.agentReducer.planDetails?.data?.data,
    selected_agency_filter: state.agentReducer.agencyFilter,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    commonApiCall,
    logout,
    conversationDetails,
    updateAgent,
    setAccordion,
    saveLeadCount,
    redirectId,
    setAgencyFilters,
  })(Header)
);
