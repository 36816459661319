import React from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

const UpsertSubUser = ({
  upsertModel,
  userDetails,
  handleChange,
  doSubmit,
  handleModel,
}) => {
  const { agent_id, firstName, lastName, mobileNumber, errors } = userDetails;
  return (
    <Modal
      show={upsertModel}
      onHide={() => handleModel("upsertModel", !upsertModel)}
      centered
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{agent_id ? "Update" : "Add"} an Admin User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <input
                  className="form-control"
                  placeholder="First Name"
                  name="firstName"
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => handleChange(e)}
                  maxLength="50"
                />
                <label htmlFor="firstName">First Name</label>
                <span className="text-danger">{errors.firstName}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <input
                  className="form-control"
                  placeholder="Last Name"
                  name="lastName"
                  id="lastName"
                  type="text"
                  maxLength="50"
                  value={lastName}
                  onChange={(e) => handleChange(e)}
                />
                <label htmlFor="firstName">Last Name</label>
                <span className="text-danger">{errors.lastName}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-effect with-icon">
                <NumberFormat
                  name="mobileNumber"
                  id="mobileNumber"
                  className="form-control"
                  placeholder="Mobile Number"
                  format="(###) ###-####"
                  value={mobileNumber}
                  onValueChange={(e) =>
                    handleChange({
                      target: { name: "mobileNumber", value: e.value },
                    })
                  }
                />
                <label htmlFor="firstName">Mobile Number</label>
                <span className="text-danger">{errors.mobileNumber}</span>
              </div>
            </div>
          </div>
        </form>
        <div className="col-12 px-0">
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            onClick={() => doSubmit()}
          >
            {agent_id ? "Update" : "Add"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertSubUser;
