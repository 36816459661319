import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const EmailDetailsLoader = () => {
  return (
    <SkeletonTheme>
      <div className="row">
        <div className="col-12">
          <Skeleton count={1} height={10} width={100} />
          <div className="white-box2 email-detail">
            <h2 className="font-20 txt-light-black mb-4 text-capitalize">
              <Skeleton count={1} height={10} width={100} />
            </h2>
            <div className="mb-4">
              <p className="font-14 pb-1">
                {" "}
                <Skeleton count={1} height={10} width={70} />
              </p>
              <h3 className="font-16 txt-light-black">
                <Skeleton count={1} height={30} width={100} />
              </h3>
            </div>
            <div className="mb-4">
              <p className="font-14 pb-1">
                <Skeleton count={1} height={10} width={70} />
              </p>
              <h3 className="font-16 txt-light-black">
                <Skeleton count={1} height={30} width={100} />
              </h3>
            </div>
            <div className="mb-4">
              <p className="font-14 pb-1">
                <Skeleton count={1} height={10} width={70} />
              </p>
              <Skeleton count={1} height={30} width={100} />
            </div>
            <div className="mb-4">
              <p className="font-14 pb-1">
                {" "}
                <Skeleton count={1} height={10} width={70} />
              </p>
              <div className="content-box ">
                <div className="d-flex flex-wrap ">
                  <div className="form-group  mt-2 mb-5 pb-md-3 w-100">
                    <Skeleton count={1} height={100} width={500} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};
