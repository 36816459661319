import React from "react";
import { Modal, Button } from "react-bootstrap";
import { isEmpty } from "lodash";

const ColumnModel = ({
  columnModal,
  setColumnModal,
  addColumn,
  setcolumnName,
  columnName,
}) => {
  return (
    <Modal
      onHide={() => setColumnModal(false)}
      show={columnModal}
      centered
      size="sm"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      className="delete-modal addcontact"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New Column</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mt-2">Please enter a name for this column</p>
        <div className="input-effect with-placeholder">
          <input
            type="text"
            className="form-control"
            name="columnName"
            placeholder="Column Name"
            value={columnName}
            onChange={(e) => setcolumnName(e.target.value)}
            maxLength={"15"}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-grey btn-sm-radius"
          onClick={() => {
            setColumnModal(false);
            setcolumnName("");
          }}
        >
          Cancel
        </Button>
        <Button
          variant="info btn-sm-radius"
          disabled={isEmpty(columnName) ? true : false}
          onClick={addColumn}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ColumnModel;
